import { useSearchParams } from "react-router-dom";
import PrintMedicalPhysical from "./PrintMedicalPhysical";

const MedicalPhysicalGroupPrint = () => {
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get("userIds");
  const userIdsArr = userIds ? userIds?.split(",") : [];
  // console.log('d',userIds)

  return (
    <div>
      {userIdsArr?.map((user, index) => {
        return (
          <div key={user}>
            <div>
              <PrintMedicalPhysical user_id={user} />
            </div>
            {index < userIdsArr?.length - 1 ? (
              <div className="print-page-break"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default MedicalPhysicalGroupPrint;

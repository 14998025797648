import React from "react";
import UserInvoice from "../Investigation/Invoice/UserInvoice";
import MedicalForm from "../Investigation/Report/MedicalForm/MedicalForm";
import StaticXrayWithID from "../StaticXray/StaticXrayWithId";

const PrintInvoiceFormXray = () => {
  const patientData = 1;

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.print();
  //   }, 2000);
  // }, []);

  return (
    <div>
      <UserInvoice />

      <div style={{ pageBreakAfter: "always" }}></div>

      <MedicalForm />

      <div style={{ pageBreakAfter: "always" }}></div>

      <StaticXrayWithID />
    </div>
  );
};

export default PrintInvoiceFormXray;

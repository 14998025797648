import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../Context/UserContextAPI";
import useFetch from "../../../hooks/useFetch";
import request from "../../../utils/request";
import MoneyReceipt from "./components/MoneyReceipt";

const UserInvoice = () => {
  const { accessPerm, settingsData } = useContext(UserContext);
  const { userId } = useParams();

  const {
    data: { userDetail },
    isSuccess,
  } = useFetch(() => request.getOne("user-details/test", userId), userId);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.focus();
        window.print();
      }, 3000);
    }

    window.onafterprint = window.close;
  }, [isSuccess]);

  return (
    <div className="patient-invoice p-3">
      {userDetail && (
        <>
          <MoneyReceipt userDetail={userDetail} />

          <hr
            style={{ borderTop: "3px dotted black", height: "0" }}
            className="m-0"
          />

          <MoneyReceipt userDetail={userDetail} office />
        </>
      )}

      <div className="invoice-footer d-flex mt-5 align-items-center justify-content-end">
        <div className="d-flex gap-2">
          {accessPerm(32, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}
          <button className="btn btn-secondary" onClick={() => window.close()}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default UserInvoice;

import { Link } from "react-router-dom";

import QRCode from "qrcode.react";

const SignatureInReport = ({
  urlWithBase64ID,
  currentUser,
  hasRadiology,
  hasPathology,
  user,
}) => {
  function firstSignatureName() {
    if (user?.role_id === 5 && hasRadiology) {
      return "Radiologist";
    } else if (user?.role_id === 5 && hasPathology) {
      return "Pathologist ";
    } else {
      return "Chief Physician";
    }
  }
  return (
    <div className="d-flex justify-content-between w-100 medical-xray-print px-4">
      <div className="row w-100">
        <div className="d-flex justify-content-between gap-5 align-items-end">
          <div className="text-dark p-0 m-0 ">
            <div
              className="border-top mt-0 border-dark"
              style={{ width: "125px" }}
            ></div>

            {firstSignatureName()}
          </div>
          <div className="d-flex align-items-end gap-5">
            <div className="text-dark ">
              <div
                className="border-top mt-0 border-dark"
                style={{ width: "125px" }}
              ></div>
              Signature
            </div>
            <div className="text-end">
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={70} />
              </Link>
              <div className="text-end text-dark p-0 text-wrap">
                Printed By: {currentUser?.f_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignatureInReport;

import axios from "axios";
import JsBarcode from "jsbarcode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";

const CollectionLabel = () => {
  const { accessPerm, settingsData } = useContext(UserContext);
  const [specimenCats, setSpecimenCats] = useState([]);
  const [patientData, setPatientData] = useState({});
  const [selectedSpecimens, setSelectedSpecimens] = useState([]);
  const [introducerUser, setIntroducerUser] = useState({});
  const [representativeId, setRepresentativeId] = useState(null);
  const [representative, setRepresentative] = useState();

  const { uId } = useParams();
  const navigate = useNavigate();

  const patientId = patientData?.user?.registration_no;
  const Gender = parseInt(patientData?.gender?.id) === 1 ? "Male" : "Female";
  const birthYear = patientData?.user?.date_of_birth
    ? new Date(patientData?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "";
  console.log(age);
  const patientName = patientData?.user?.f_name;
  const formattedBirthDate = patientData?.user?.date_of_birth
    ? new Date(patientData?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";
  const saveData = async (update) => {
    try {
      // Make the API call to create or update data
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/collection/create-update/${uId}`,
        update,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      // Fetch specimen data after updating notifications
      await getSpecimenData();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSpecimenChange = useCallback((event) => {
    const value = JSON.parse(event.target.value);
    const isChecked = event.target.checked;

    setSelectedSpecimens((prevSelectedSpecimens) => {
      if (isChecked) {
        // Add the selected specimen
        return [...prevSelectedSpecimens, value];
      } else {
        // Remove the deselected specimen
        return prevSelectedSpecimens.filter((specimen) => {
          return specimen?.id !== value?.id || specimen.name !== value.name;
        });
      }
    });
  }, []);

  const handlePrint = async () => {
    const currentDateTime = new Date().toLocaleString();

    const printedSpecCats = selectedSpecimens.map((obj) => {
      obj.createdAt = currentDateTime;
      return obj;
    });

    const updatedSpecimenCats = specimenCats.map((obj1) => {
      const matchedSpecimen = printedSpecCats.find(
        (obj2) => parseInt(obj2?.id) === parseInt(obj1?.id)
      );

      // If a specimen from printedSpecCats matches an existing one, update its createdAt, else keep the old data
      if (matchedSpecimen) {
        return { ...obj1, createdAt: matchedSpecimen.createdAt };
      }
      return obj1;
    });

    // Combine newly selected specimens with the existing ones
    const finalUpdatedSpecimenCats = [...updatedSpecimenCats];

    // Update the state and trigger saveData
    setSpecimenCats(finalUpdatedSpecimenCats);
    saveData(finalUpdatedSpecimenCats);

    //clear checkboxes after print
    setSelectedSpecimens([]);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    const newWindow = window.open(
      "about:blank",
      "newWin",
      `width=900,height=600,left=${window.screen.availWidth / 2 - 450},top=${
        window.screen.availHeight / 2 - 300
      }`
    );

    newWindow.document.open();
    newWindow.document.write(
      `<html><head><title>Print</title></head><body>${selectedSpecimens
        .map((specimen) => {
          const patientIdBarcode = document.createElement("canvas");
          JsBarcode(patientIdBarcode, String(patientId), {
            height: 30,
            margin: 0,
            displayValue: false,
          });

          const patientIdDataUrl = patientIdBarcode.toDataURL();

          return `<div class="container" style="text-align: left; font-size: 10px;">
          <div class="specimen" style="display: inline-block; text-align: center;">
            <p style="margin: 0; font-size: 12px; font-weight: 900;">${currentDateTime}</p>
            <p style="margin: 0; font-size: 14px; font-weight: 900;">${patientId}</p>
            <p class="gender-dob" style="margin:0; font-weight: 900;">
              <span>Gender: ${Gender}</span>
                ${age !== "" ? "|" : ""}
                ${age !== "" ? `Age: ${age}` : ""}
            </p>
           
            <img src="${patientIdDataUrl}" class="barcode-img" style="display: block; margin: 0 auto; padding: 0; max-width: 100%; height: auto; max-height: 100px;" />
            <h3 style="margin: 4px 0;">${specimen?.name}</h3>
          </div>
        </div>`;
        })
        .join("")}</body></html>`
    );
    newWindow.document.close();

    setTimeout(() => {
      newWindow.print();
      window.location.reload();
    }, 1000);
  };
  console.log(age);
  const handlePrintAll = async () => {
    const currentDateTime = new Date().toLocaleString();

    // console.log(specimenCats);
    const update = specimenCats.map((obj1) => {
      return { ...obj1, createdAt: currentDateTime };
    });
    setSpecimenCats(update);
    saveData(update);
    const newWindow = window.open(
      "about:blank",
      "newWin",
      `width=900,height=600,left=${window.screen.availWidth / 2 - 450},top=${
        window.screen.availHeight / 2 - 300
      }`
    );

    newWindow.document.open();
    newWindow.document.write(
      `<html><head><title>Print</title></head><body>${specimenCats
        .map((specimen) => {
          const patientIdBarcode = document.createElement("canvas");
          JsBarcode(patientIdBarcode, String(patientId), {
            height: 30,
            margin: 0,
            displayValue: false,
          });

          const patientIdDataUrl = patientIdBarcode.toDataURL();

          return `<div class="container" style="text-align: left; font-size: 10px;">
          <div class="specimen" style="display: inline-block; text-align: center;">
            <p style="margin: 0; font-size: 12px; font-weight: 900;">${currentDateTime}</p>
            <p style="margin: 0; font-size: 14px; font-weight: 900;">${patientId}</p>
            <p class="gender-dob" style="margin:0; font-weight: 900;">
              <span>Gender: ${Gender}</span>
                ${age !== "" ? "|" : ""}
                ${age !== "" ? `Age: ${age}` : ""}
            </p>
           
            <img src="${patientIdDataUrl}" class="barcode-img" style="display: block; margin: 0 auto; padding: 0; max-width: 100%; height: auto; max-height: 100px;" />
            <h3 style="margin: 4px 0;">${specimen?.name}</h3>
          </div>
        </div>`;
        })
        .join("")}</body></html>`
    );
    newWindow.document.close();

    setTimeout(() => {
      newWindow.print();
      window.location.reload();
    }, 1000);
  };

  const getSpecimenData = async (invArray) => {
    try {
      const dataToSend = {
        invIds: invArray,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/collection/${uId}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res) {
        return res?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSpecimenData = async () => {
    try {
      // console.log(dataToSend);
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/collection/delete/${uId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      await fetchUserDetailsById();
      window.location.reload();
    }
  };

  const fetchUserDetailsById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/test/${uId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = res?.data?.data?.userDetail;
      setIntroducerUser(data?.introducer_user);
      setRepresentativeId(data?.representative_id);
      if (data) {
        let invArray = [];

        if (data?.user?.role_id === 4) {
          invArray = Array.isArray(data?.package?.investigation_id)
            ? data.package.investigation_id
            : data.package.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        } else if (data?.user?.role_id === 5) {
          invArray = Array.isArray(data?.investigation_id)
            ? data.investigation_id
            : data.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        }

        const response = await getSpecimenData(invArray);
        if (response) {
          setPatientData(data);
          setSpecimenCats(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const introducer_id = patientData?.introducer_id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [patientData]);

  useEffect(() => {
    fetchUserDetailsById();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Collection Label </h6>
            </div>
            <hr />
            <div className="row mb-2 bg-primary text-white px-2 py-2">
              <h6 className="mb-0">
                Collection Label for {patientData?.user?.f_name}[
                {patientData?.user?.reg_prefix || ""}
                {patientData?.user?.registration_no}]
              </h6>
            </div>
            <div className="row justify-content-center h-100">
              <>
                <div className="col-sm-2">
                  <div
                    className="pres-photo border border-end-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {patientData?.user?.image ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.image}`}
                        className="img-fluid"
                        alt="patient"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src="./dummy.jpg"
                        alt="patient"
                        className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    PHOTO
                  </p>
                </div>
                <div className="col-sm-8 text-dark">
                  <div className="row h-100">
                    <table
                      className="responsive-width1 table-bordered h-100"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        textAlign: "left",
                      }}
                    >
                      <tbody>
                        {/* Row 1 - Name and Client ID */}
                        <tr>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Name
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {patientData?.user?.f_name || "---"}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Client ID
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {patientData?.user?.reg_prefix || ""}
                            {patientData?.user?.registration_no || "---"}
                          </td>
                        </tr>

                        {/* Row 2 - Gender and Passport No */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Gender
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.gender?.name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Passport No
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.user?.passport_no || "---"}
                          </td>
                        </tr>

                        {/* Row 3 - Date of Birth and Travelling To */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Date of Birth
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {formattedBirthDate || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Country
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.country?.name || "---"}
                          </td>
                        </tr>

                        {/* Row 4 - Father Name and Contact Number */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Father Name
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.father_name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Contact Number
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.user?.mobile || "---"}
                          </td>
                        </tr>

                        {/* Row 5 - Nationality and Agency */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Nationality
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.national_country?.nationality ||
                              "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {settingsData?.introducer_label}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            colSpan={3}
                          >
                            {patientData?.introducer_user?.f_name || "---"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-2 h-100">
                  <div
                    className="pres-photo border border-start-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {patientData?.user?.finger_print ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.finger_print}`}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      >
                        No Fingerprint Found
                      </div>
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    THUMB PRINT
                  </p>
                </div>
              </>
            </div>
            <hr />
            <div>
              <table className="table table-striped">
                <thead className="bg-primary text-white">
                  <tr>
                    <th scope="col">Specimen</th>
                    <th scope="col">Collection Date and Time</th>
                  </tr>
                </thead>
                <tbody>
                  {specimenCats?.map((specimen, i) => (
                    <>
                      <tr key={i}>
                        {accessPerm(35, 15) && (
                          <>
                            <th scope="row">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  id={i}
                                  value={JSON.stringify({
                                    id: specimen?.id,
                                    name: specimen?.name,
                                  })}
                                  defaultChecked={specimen?.createdAt !== "---"}
                                  // checked={isChecked}
                                  onChange={handleSpecimenChange}
                                  disabled={specimen?.createdAt !== "---"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="physicalExam"
                                >
                                  {specimen?.name}
                                </label>
                              </div>
                            </th>
                            <td>{specimen?.createdAt}</td>
                          </>
                        )}
                      </tr>
                      {/* <tr key={i}>
                        {accessPerm(35, 15) && specimen?.id === 7 && (
                          <>
                            <th scope="row">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  id={i}
                                  value={JSON.stringify({
                                    id: specimen?.id,
                                    name: specimen?.name,
                                  })}
                                  defaultChecked={specimen?.createdAt !== "---"}
                                  // checked={isChecked}
                                  onChange={handleSpecimenChange}
                                  disabled={specimen?.createdAt !== "---"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="physicalExam"
                                >
                                  {specimen?.name}
                                </label>
                              </div>
                            </th>
                            <td>{specimen?.createdAt}</td>
                          </>
                        )}
                      </tr>
                      <tr key={i}>
                        {accessPerm(35, 14) && specimen?.id === 4 && (
                          <>
                            <th scope="row">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  id={i}
                                  value={JSON.stringify({
                                    id: specimen?.id,
                                    name: specimen?.name,
                                  })}
                                  defaultChecked={specimen?.createdAt !== "---"}
                                  // checked={isChecked}
                                  onChange={handleSpecimenChange}
                                  disabled={specimen?.createdAt !== "---"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="physicalExam"
                                >
                                  {specimen?.name}
                                </label>
                              </div>
                            </th>
                            <td>{specimen?.createdAt}</td>
                          </>
                        )}
                      </tr>
                      <tr key={i}>
                        {accessPerm(35, 14) && specimen?.id === 5 && (
                          <>
                            <th scope="row">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  id={i}
                                  value={JSON.stringify({
                                    id: specimen?.id,
                                    name: specimen?.name,
                                  })}
                                  defaultChecked={specimen?.createdAt !== "---"}
                                  // checked={isChecked}
                                  onChange={handleSpecimenChange}
                                  disabled={specimen?.createdAt !== "---"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="physicalExam"
                                >
                                  {specimen?.name}
                                </label>
                              </div>
                            </th>
                            <td>{specimen?.createdAt}</td>
                          </>
                        )}
                      </tr>
                      <tr key={i}>
                        {accessPerm(35, 13) && specimen?.id === 1 && (
                          <>
                            <th scope="row">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  id={i}
                                  value={JSON.stringify({
                                    id: specimen?.id,
                                    name: specimen?.name,
                                  })}
                                  defaultChecked={specimen?.createdAt !== "---"}
                                  // checked={isChecked}
                                  onChange={handleSpecimenChange}
                                  disabled={specimen?.createdAt !== "---"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="physicalExam"
                                >
                                  {specimen?.name}
                                </label>
                              </div>
                            </th>
                            <td>{specimen?.createdAt}</td>
                          </>
                        )}
                      </tr> */}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-sm-12">
              <div className="btn-group btn-group-justified">
                {accessPerm(35, 23) && (
                  <button
                    onClick={handlePrint}
                    className="btn btn-primary px-5 m-2 rounded"
                    disabled={selectedSpecimens.length === 0}
                  >
                    Print
                  </button>
                )}
                {accessPerm(35, 24) && (
                  <button
                    onClick={handlePrintAll}
                    className="btn btn-primary px-5 m-2 rounded"
                    disabled={selectedSpecimens.length !== 0}
                  >
                    Print All
                  </button>
                )}

                <div className="">
                  {accessPerm(35, 8) && (
                    <button
                      className="btn btn-danger px-5 text-white m-2 rounded"
                      data-bs-toggle="modal"
                      data-bs-target={`#resetCollection`}
                    >
                      Reset
                    </button>
                  )}
                  <div
                    className="modal fade"
                    id={`resetCollection`}
                    tabIndex={-1}
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            Reset Collection Label
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row ">
                            <div className="col-sm-10">
                              <p>
                                Are you sure you want to reset this collection
                                label?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={deleteSpecimenData}
                            data-bs-dismiss="modal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#resetCollection`}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/specimen-collection");
                  }}
                  className="btn btn-outline-secondary px-5 m-2 rounded"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionLabel;

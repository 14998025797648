import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./MedicalSummary.scss";
import axios from "axios";
import { format } from "date-fns";
import Select from "react-select";
import { UserContext } from "../../Context/UserContextAPI";
import { savePDF } from "@progress/kendo-react-pdf";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Loading from "../../utils/Loader/Loading";

const MedicalSummary = () => {
  const {
    introducerUsers,
    accessPerm,
    companyUsers,
    delegateUsers,
    representativeUsers,
    packages,
  } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [packageDetails, setPackageDetails] = useState([]);
  const [total, setTotal] = useState([]);
  const [regInputField, setRegInputField] = useState("");
  const [loading, setLoading] = useState(false);
  const pdfRef = useRef();

  const [selectedValues, setSelectedValues] = useState({
    representative: null,
    introducer: null,
    company: null,
    delegate: null,
  });

  const [searchInputs, setSearchInputs] = useState({
    from_date: "",
    to_date: "",
    representative: "",
    introducer: "",
    company: "",
    delegate: "",
  });

  const options = [
    { value: "", label: "Select Introducer" }, // Default "All" option
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const companyOptions = [
    { value: "", label: "Select Company" },
    ...(companyUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const delegateOptions = [
    { value: "", label: "Select Delegate" },
    ...(delegateUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const representativeOptions = [
    { value: "", label: "Select Representative" },
    ...representativeUsers.map((user) => {
      const representativeMobile = user?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "";

      return {
        value: parseInt(user?.id),
        label: `${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const handleInputChange = (name, value) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        introducer: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        representative: null,
        introducer: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const pdfGenerate = async () => {
    const element = pdfRef.current;

    element.style.fontWeight = "bold";
    element.style.height = "75vh";
    element.style.fontSize = "12px";
    // element.style.fontFamily = "Verdana";
    savePDF(element, {
      paperSize: "auto",
      margin: 10,
      padding: 0,
      fontWeight: "900",
      fontFamily: "Lucida Sans Unicode",
      fileName: `Summary Report`,
    });
  };

  const handlePrint = async () => {
    pdfGenerate();
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const representative = form.representative.value;
    const introducer = form.introducer.value;
    const company = "";
    const delegate = "";
    setFromDate(new Date(fromDate));
    setToDate(new Date(toDate));
    const filterData = {
      fromDate,
      toDate,
      representative,
      introducer,
      company,
      delegate,
    };
    methodFilterPatientsDetails(filterData, "form");
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-summary`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      console.log(response?.data);
      const data = response?.data;
      if (data) {
        setUserDetails(response?.data?.result);
        setPackageDetails(response?.data?.packageDetails);
        setTotal(response?.data?.counts);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor("country_name", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
    ];

    const packageColumns = packages?.map((packageItem) => {
      return columnHelper.accessor(`packages.package_name.${packageItem.id}`, {
        header: () => (
          <span className="d-flex justify-content-center w-100">
            <span className="d-flex justify-content-center w-100">
              <table className="w-100 p-0 m-0 w-100">
                <tr className="p-0 m-0 d-flex justify-content-center border">
                  {packageItem?.name}
                </tr>
                <tr className="p-0 m-0 d-flex justify-content-around border">
                  <th className="p-0 m-0 border border-end w-100 text-center">
                    FIT
                  </th>
                  <th className="p-0 m-0 border border-end w-100 text-center">
                    UNFIT
                  </th>
                  <th className="p-0 m-0 border border-end w-100 text-center">
                    HELDUP
                  </th>
                  <th className="p-0 m-0 border border-end w-100 text-center">
                    TOTAL
                  </th>
                </tr>
              </table>
            </span>
          </span>
        ),
        cell: (info, index) => {
          const getUserPackage = (packageId) => {
            return info?.row?.original?.packages.find(
              (item) => item?.package_id === packageId
            );
          };

          const currentPackage = getUserPackage(packageItem.id);

          return (
            <div className="d-flex justify-content-around w-100 fw-bold">
              <span
                key={index}
                className="w-100 d-flex justify-content-between p-0 m-0"
              >
                <td className="border-end-2 border-end text-end w-100 h-100">
                  {currentPackage?.fit !== undefined ? currentPackage.fit : 0}
                </td>
                <td className="border-end text-end w-100">
                  {currentPackage?.unfit !== undefined
                    ? currentPackage.unfit
                    : 0}
                </td>
                <td className="border-end text-end w-100">
                  {currentPackage?.heldup !== undefined
                    ? currentPackage.heldup
                    : 0}
                </td>
                <td className="text-end w-100">
                  {currentPackage?.total !== undefined
                    ? currentPackage.total
                    : 0}
                </td>
              </span>
            </div>
          );
        },
      });
    });

    const grandTotalColumn = columnHelper.accessor("total", {
      header: (info, index) => (
        <span className="d-flex justify-content-center w-100">
          <span className="d-flex justify-content-center w-100">
            <table className="w-100 p-0 m-0 w-100">
              <tr className="p-0 m-0 d-flex justify-content-center border">
                Grand Total
              </tr>
              <tr className="p-0 m-0 d-flex justify-content-around border">
                <th className="p-0 m-0">FIT</th>
                <th className="p-0 m-0">UNFIT</th>
                <th className="p-0 m-0">HELDUP</th>
                <th className="p-0 m-0">TOTAL</th>
              </tr>
            </table>
          </span>
        </span>
      ),
      cell: (info, index) => (
        <div className="d-flex justify-content-around w-100 fw-bold">
          <span
            key={info?.row?.original?.id}
            className="w-100 d-flex justify-content-between"
          >
            <td className="border-end-2 border-end text-end w-100">
              {info?.row?.original?.total_fit}
            </td>
            <td className="border-end text-end w-100">
              {info?.row?.original?.total_unfit}
            </td>
            <td className="border-end text-end w-100">
              {info?.row?.original?.total_heldup}
            </td>
            <td className="text-end w-100 p-0">{info?.row?.original?.total}</td>
          </span>
        </div>
      ),
    });

    return [...baseColumns, ...packageColumns, grandTotalColumn];
  }, [accessPerm, userDetails, packages]);

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });

  useEffect(() => {
    const today = new Date();
    // const formattedDate = format(today, "dd-MM-yyyy");

    setFromDate(new Date(today));
    setToDate(new Date(today));
  }, []);

  // useEffect(() => {
  //   if (userDetails?.length > 0) {
  //     pdfGenerate();
  //   }
  // }, [userDetails]);

  return (
    <div className="">
      <div className="card">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 btn btn-danger text-white text-capitalize fw-bold m-0 p-0"
                  style={{ cursor: "default" }}
                >
                  Medical Summary Report
                </h6>
                <div className="d-flex flex-wrap">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12"
                  >
                    <div className="d-flex flex-wrap w-100">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("from_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("to_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Representative</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={representativeOptions}
                            placeholder="Select Representative"
                            onChange={(selectedOptions) => {
                              handleSelect("representative", selectedOptions);
                              handleInputChange(
                                "representative",
                                selectedOptions
                              );
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.representative}
                            name="representative"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) => {
                              handleSelect("introducer", selectedOptions);
                              handleInputChange("introducer", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>

                      <div className=" p-1">
                        <label className="col-form-label">Company</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="company"
                            className="fw-light"
                            options={companyOptions}
                            value={selectedValues.company}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("company", selectedOptions);
                              handleInputChange("company", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 290,
                                maxWidth: 310,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Delegate</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="delegate"
                            className="fw-light"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 290,
                                maxWidth: 310,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            options={delegateOptions}
                            value={selectedValues.delegate}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("delegate", selectedOptions);
                              handleInputChange("delegate", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1 d-flex align-items-end justify-content-between gap-2">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button
                            type="submit"
                            className="btn btn-primary text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-success border border-3"
                            onClick={(e) => handlePrint(e)}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Download PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div
                ref={pdfRef}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <div className="p-0 d-flex flex-column align-items-center justify-content-center mx-auto">
                  <p className="text-center fw-bold fs-6 p-0 m-0 d-flex align-items-center justify-content-center">
                    {fromDate && toDate && `Medical Summary Report`}
                  </p>
                  <p className="text-center fw-bold fs-6 p-0 m-0">
                    {fromDate &&
                      toDate &&
                      `Date: ${format(fromDate, "dd-MM-yyyy")} to ${format(
                        toDate,
                        "dd-MM-yyyy"
                      )}`}
                  </p>
                </div>

                <table className="table align-middle table-hover table-bordered mb-0 rounded">
                  <thead className="bg-primary">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, i) => (
                          <th key={i} id={`th-${i}`} className="text-light p-1">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, i) => {
                      return (
                        <tr key={i}>
                          {row.getVisibleCells().map((cell, i) => (
                            <td key={i} className="p-1">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                    <tr className="border">
                      <td>Total</td>
                      {packageDetails?.map((packageObj, i) => (
                        <td key={i}>
                          <span className="d-flex justify-content-around">
                            <td className="text-end w-100 border-end">
                              {packageObj?.total_fit}
                            </td>
                            <td className="text-end w-100 border-end">
                              {packageObj?.total_unfit}
                            </td>
                            <td className="text-end w-100 border-end">
                              {packageObj?.total_heldup}
                            </td>
                            <td className="text-end w-100">
                              {packageObj?.total}
                            </td>
                          </span>
                        </td>
                      ))}
                      {packageDetails?.length === packages?.length
                        ? ""
                        : Array.from(
                            {
                              length: packages?.length - packageDetails?.length,
                            },
                            (_, i) => <td key={`empty_${i}`}></td>
                          )}
                      <td>
                        <span className="d-flex justify-content-around">
                          <td className="text-end w-100 border-end">
                            {total?.totalFitCount}
                          </td>
                          <td className="text-end w-100 border-end">
                            {total?.totalUnfitCount}
                          </td>
                          <td className="text-end w-100 border-end">
                            {total?.totalHeldupCount}
                          </td>
                          <td className="text-end w-100">
                            {total?.totalUserCount}
                          </td>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalSummary;

import toast from "react-hot-toast";

const errorHandler = (error) => {
  const { response } = error;

  // if (response.data && response.data.jwtExpired) {
  //     const result = window.localStorage.getItem('auth');
  //     const jsonFile = window.localStorage.getItem('isLogout');
  //     const { isLogout } = (jsonFile && JSON.parse(jsonFile)) || false;
  //     window.localStorage.removeItem('auth');
  //     window.localStorage.removeItem('isLogout');
  //     if (result || isLogout) {
  //         window.location.href = '/logout';
  //     }
  // }

  if (response && response.status) {
    const message = response.data && response.data.message;

    console.log(response);

    // toast.error(response?.data?.data?.error);
    return response.data;
  }
  if (navigator.onLine) {
    // Code to execute when there is internet connection
    toast.error("Cannot connect to the server, Try again later");
    return {
      success: false,
      result: null,
      message: "Cannot connect to the server, Check your internet network",
    };
  }
  // Code to execute when there is no internet connection
  toast.error("No internet connection");
  return {
    success: false,
    result: null,
    message: "Cannot connect to the server, Check your internet network",
  };
};

export default errorHandler;

import { format, addDays } from "date-fns";
import React from "react";
const MedicalExamination = ({
  groupedData,
  radiologyData,
  radiologyResult,
  user,
  userDetail,
  from,
  groupedPhysicalInvTest,
}) => {
  const IndividualTestRadiology = radiologyData?.filter(
    (radio) => radio?.investigation_id === 31,
  );

  const hasDataInGroupData =
    groupedData && Object.keys(groupedData)?.length > 0;
  return (
    <div>
      <table className="table border border-dark m-0 table-content-fit">
        <thead>
          <tr>
            <th
              className="text-uppercase text-center"
              style={{ color: "black" }}
              colSpan={3}
            >
              {user?.role_id === 5 && radiologyData?.length > 0
                ? "CHEST X-RAY P/A VIEW"
                : "Medical Examination"}
            </th>
          </tr>
          <tr>
            <th
              colSpan={1}
              className="border text-center py-1 border border-dark text-nowrap  "
              style={{ color: "black" }}
            >
              EXAMINATION TYPE
            </th>
            <th
              className="border text-center border py-1 border-dark text-nowrap  "
              style={{ color: "black" }}
            >
              RESULTS
            </th>
            {/* <th
                      className="border text-center border py-1 border-dark text-nowrap  "
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th> */}
          </tr>
        </thead>
        <tbody className="">
          {Object.entries(groupedData)?.map(([categoryId, categoryData]) => {
            if (categoryId === "PHYSICAL") {
              return null;
            }

            if (
              categoryData?.items?.filter(
                (inv) =>
                  inv?.investigation_id === 10 ||
                  inv?.investigation_id === 9 ||
                  inv?.investigation_id === 7 ||
                  inv?.investigation_id === 8,
              ).length > 0
            ) {
              return (
                <React.Fragment key={categoryId}>
                  <tr className="fw-bold text-dark">
                    <td className="w-25 text-dark">
                      {categoryData?.name || "---"}
                    </td>
                    <td className="p-0 m-0">
                      <table className="w-100 m-0 border-dark">
                        <tr className="">
                          <td className="border border-dark w-25 border-top-0 ps-1">
                            R
                          </td>
                          <td className="border-bottom border-dark ps-1">
                            {categoryData?.items[0]?.result || "---"}{" "}
                            {categoryData?.items[0]?.result &&
                              categoryData?.items[0]?.result !== "N/A" &&
                              categoryData?.items[0]?.investigation
                                ?.investigation_tests[0]?.uom?.name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderTop: "none",
                            }}
                            className="ps-1"
                          >
                            L
                          </td>
                          <td className="border-bottom-0 ps-1">
                            {categoryData?.items[1]?.result || "---"}{" "}
                            {categoryData?.items[1]?.result &&
                              categoryData?.items[1]?.result !== "N/A" &&
                              categoryData?.items[1]?.investigation
                                ?.investigation_tests[1]?.uom?.name}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={categoryId}>
                <tr>
                  <td
                    colSpan="3"
                    className="text-nowrap fw-bold text-center text-dark"
                  >
                    {categoryData?.name}
                  </td>
                </tr>
                {categoryData?.items?.map((data, index) => {
                  // Log the data object for debugging
                  if (data?.investigation_id === 14) {
                    return (
                      <tr key={index} className="w-100 fw-bold border-0">
                        <td
                          className="border-0 w-100 text-dark fontSize-13"
                          colSpan={2}
                          // style={{ height: "30px" }}
                        >
                          {data?.investigation?.name || "---"}:
                          <span
                            className={`border-0 py-1 text-start fontSize-13 ps-2 ${
                              data?.result &&
                              data?.result !== "N/A" &&
                              data?.result !== "---"
                                ? data?.result === "Normal"
                                  ? "text-success"
                                  : "text-danger"
                                : "text-dark"
                            }`}
                            colSpan={2}
                          >
                            {data?.result || "---"}{" "}
                            {data?.result &&
                              data?.result !== "N/A" &&
                              data?.investigation?.investigation_tests[0]?.uom
                                ?.name}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr
                      key={index}
                      className="text-dark"
                      style={{ fontWeight: "lighter" }}
                    >
                      <td
                        className={`${
                          data?.investigation_id === 14 && "fw-bold"
                        }`}
                      >
                        {data?.investigation?.name || "---"}
                      </td>
                      <td
                        className={`border text-start py-1 border-dark text-dark fw-semibold ${
                          data?.investigation_id === 14 && "fw-bold "
                        }`}
                        colSpan={data?.investigation_id === 14 && 2}
                      >
                        {data?.result || "---"}{" "}
                        {data?.result &&
                          data?.result !== "N/A" &&
                          data?.investigation?.investigation_tests[0]?.uom
                            ?.name}
                      </td>
                      {/* {data?.investigation_id !== 14 && (
                                  <td className="border text-start py-1 border-dark text-dark">
                                    {data?.investigation?.investigation_tests[0]
                                      ?.report_normal_value || ""}
                                  </td>
                                )} */}
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}

          {!hasDataInGroupData &&
            from === "medicalForm" &&
            groupedPhysicalInvTest &&
            Object.entries(groupedPhysicalInvTest)?.map(
              ([categoryId, categoryData]) => {
                // console.log("cat", categoryData);
                if (categoryId.toUpperCase() === "PHYSICAL") {
                  return null;
                }
                if (
                  categoryId.toUpperCase() === "EAR" ||
                  categoryId.toUpperCase() === "EYE Visual Acuity".toUpperCase()
                ) {
                  return (
                    <React.Fragment key={categoryId}>
                      <tr className="fw-bold text-nowrap">
                        <td>{categoryData?.name || ""}</td>
                        <td className="p-0 m-0">
                          <table className="w-100 m-0 table  border-dark ">
                            <tbody>
                              <tr className="">
                                <td
                                  style={{
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                    borderTop: "none",
                                  }}
                                >
                                  R
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                    borderBottom: "none",
                                  }}
                                >
                                  L
                                </td>
                                <td className="border-bottom-0"></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={categoryId}>
                    <tr>
                      <td className="text-nowrap text-uppercase text-center fw-bold">
                        {categoryData?.name}
                      </td>
                      <td></td>
                    </tr>
                    {categoryData?.items?.map((data, index) => {
                      return (
                        <tr key={index} style={{ fontWeight: "lighter" }}>
                          {data?.id === 14 ? (
                            <td className="fw-bold text-nowrap w-25">
                              {data?.investigation_tests[0]?.name || ""}
                              {data?.id === 14 ? ":" : ""}
                            </td>
                          ) : (
                            <td className="w-50 text-nowrap">
                              {data?.investigation_tests[0]?.name || ""}
                              {data?.id === 14 ? ":" : ""}
                            </td>
                          )}

                          {data?.id === 14 ? (
                            <td className="text-start py-1 "></td>
                          ) : (
                            <td className="border text-start py-1 border-dark "></td>
                          )}
                          {/* <td className="border text-start py-1 border-dark "></td> */}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              },
            )}
          {/* {radiologyData && (
                    <tr>
                      <td colSpan={3} className="text-nowrap text-uppercase fw-bold text-center">
                        CHEST X-RAY
                      </td>
                    </tr>
                  )} */}

          {/* {radiologyData && ( */}
          {/*   <tr className=""> */}
          {/*     <td */}
          {/*       colSpan={2} */}
          {/*       className="fw-bold border border-dark text-dark fontSize-13" */}
          {/*       style={{ height: "30px" }} */}
          {/*     > */}
          {/*       Chest X-Ray:{" "} */}
          {/*       <span */}
          {/*         className={`fw-bold fontSize-13 ${ */}
          {/*           radiologyResult === "Normal CXR" */}
          {/*             ? "text-success" */}
          {/*             : "text-primary" */}
          {/*         }`} */}
          {/*       > */}
          {/*         {radiologyData?.find( */}
          {/*           (report) => report?.investigation_test?.id === 35, */}
          {/*         )?.result || ""} */}
          {/*       </span> */}
          {/*     </td> */}
          {/*   </tr> */}
          {/* )} */}

          {/* <tr className="fw-bold "> */}
          {/*   <td className="border border-dark text-dark fontSize-13"> */}
          {/*     Expiry Date{" "} */}
          {/*   </td> */}
          {/*   <td className="fontSize-13 text-dark"> */}
          {/*     {format( */}
          {/*       addDays(new Date(user?.createdAt || new Date()), 90), */}
          {/*       "dd MMM yyyy", */}
          {/*     )} */}
          {/*   </td> */}
          {/* </tr> */}

          {/* <td colSpan={3} style={{ height: "50px" }}> */}
          {/*   <p */}
          {/*     className=" m-0 p-0 fst-italic text-dark ps-2 my-1" */}
          {/*     style={{ lineHeight: "12px", fontSize: "10px" }} */}
          {/*   > */}
          {/*     Mentioned above is the medical report for{" "} */}
          {/*     <span style={{ fontStyle: "normal" }} className="text-uppercase "> */}
          {/*       {userDetail?.user?.f_name} */}
          {/*     </span> */}
          {/*     ,<span className="d-block" /> */}
          {/*     Who is */}
          {/*     <span */}
          {/*       className="fw-bold text-uppercase fs-6 fst-normal p-0 m-0" */}
          {/*       style={{ */}
          {/*         color: `${ */}
          {/*           userDetail?.user?.status === 1 */}
          {/*             ? "black" */}
          {/*             : userDetail?.user?.status === 2 */}
          {/*               ? "#5cb85c" */}
          {/*               : userDetail?.user?.status === 3 */}
          {/*                 ? "red" */}
          {/*                 : userDetail?.user?.status === 4 && "#0000FF" */}
          {/*         }`, */}
          {/*       }} */}
          {/*     > */}
          {/*       {userDetail?.user?.status === 1 && ` PROCESSING`} */}
          {/*       {userDetail?.user?.status === 2 && ` FIT`} */}
          {/*       {userDetail?.user?.status === 3 && ` UNFIT`} */}
          {/*       {userDetail?.user?.status === 4 && ` HELD UP`} */}
          {/*     </span>{" "} */}
          {/*     for the above mentioned job according to the medical criteria.{" "} */}
          {/*   </p> */}
          {/* </td> */}
          {/* only for Individual test  */}
          {user?.role_id === 5 &&
            IndividualTestRadiology?.map((radiology) => (
              <tr key={radiology?.id} style={{ fontWeight: "lighter" }}>
                <td
                  className={`border border-dark text-uppercase ${radiology?.test_id === 35 && "fw-bold"}`}
                >
                  {" "}
                  {radiology?.investigation_test?.name}
                </td>
                <td
                  className={`${
                    radiology?.test_id === 35
                      ? radiology?.result === "Normal CXR"
                        ? "text-success fw-bold"
                        : "text-primary fw-bold"
                      : ""
                  }`}
                >
                  {" "}
                  {radiology?.result}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {user?.role_id !== 5 && (
        <ChestXRay
          user={user}
          radiologyData={radiologyData}
          radiologyResult={radiologyResult}
          from={from}
        />
      )}
    </div>
  );
};
export default MedicalExamination;

const ChestXRay = ({ user, radiologyResult, radiologyData, from }) => {
  if (from === "printMedicalPhysical") {
    return null;
  }
  return (
    <table className="table border border-top-0 border-dark mb-0 table-content-fit">
      <tbody>
        <tr className=" border-dark">
          <td
            style={{ width: "98px" }}
            className={`text-uppercase fw-bold ${user?.role_id === 5 && "text-center"}`}
          >
            Chest X-Ray{" "}
          </td>
          <td
            colSpan={2}
            className="fw-bold border  border-top-0 border-dark text-wrap text-break"
          >
            <span
              className={`fw-bold ${
                radiologyResult === "Normal CXR"
                  ? "text-success"
                  : "text-primary"
              }`}
            >
              {radiologyData?.find(
                (report) => report?.investigation_test?.id === 35,
              )?.result || ""}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

import axios from "axios";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const FingerPrintCapture = ({
  preview,
  setPreview,
  setCapturedFingerprint,
  capturedFingerprint,
  setFingerprintTemplate,
  userData
}) => {
  const [isModalOpen, setMOdalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // console.log('data', preview, capturedFingerprint)

  const handleFingerPrint = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `http://127.0.0.1:8080/api/fingerprint/user`,
      );
      const result = res.data?.data;
      // console.log(result);
      setCapturedFingerprint(result?.imgBlob);
      setFingerprintTemplate(result?.template);
      setMOdalOpen(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      console.log("e", err.response.data?.data?.error);
      // toast.error(err.response.data?.data?.error || "there was a api error");

      toast.error("There is an an error .please try again");
      setTimeout(() => {
        setLoading(false);
        setMOdalOpen(false);
      }, 2000);
    }
  };
  //   console.log(preview)

  function imageShow (){
    if(userData?.finger_print && !preview && !capturedFingerprint){
      return  `${process.env.REACT_APP_UPLOAD_URL}/users/${userData?.finger_print}`
    }else if( preview && !capturedFingerprint){
    return  URL.createObjectURL(preview)
    } else if(capturedFingerprint){
      return capturedFingerprint;
    }else {
      return "no-fingerprint.jpg"
    }
  }

  return (
    <div>
      <label htmlFor="file-input">
        <input
          type="text"
          name="finger_print2"
          id="file-input"
          className="form-control"
          style={{ display: "none" }}
          onClick={() => setMOdalOpen(true)}
        />
        <img
          src={imageShow()}
          // src={
          //   data?.finger_print
          //     ? `${process.env.REACT_APP_UPLOAD_URL}/users/${fingerprintImgName}`
          //     : preview
          //       ? URL.createObjectURL(preview)
          //       : "no-fingerprint.jpg"
          // }
          // src={
          //   preview
          //       ? (preview)
          //       : "no-fingerprint.jpg"
          // }
          alt="Fingerprint"
          className="img-fluid rounded-3"
          style={{ width: "8rem", height: "9rem" }}
        />

        <Modal
          show={isModalOpen}
          onHide={() => setMOdalOpen(false)}
          onShow={handleFingerPrint}
        >
          <ModalHeader closeButton />
          <ModalBody>
            <div className="text-center">
              <h3>
                Please put your finger on fingerprint when white light turn on
              </h3>
            </div>
            <div className="text-center mt-5">
              <Spinner
                animation="border"
                variant="success"
                style={{ width: "5rem", height: "5rem" }}
              />
            </div>
          </ModalBody>
        </Modal>
      </label>
    </div>
  );
};

export default FingerPrintCapture;

import React, { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";

const videoConstraints = {
  facingMode: "user",
};
const initialCrop = {
  unit: "%", // Can be 'px' or '%'
  x: 25, // X position of the crop, either in percentage or pixels
  y: 20, // Y position of the crop, either in percentage or pixels
  width: 50, // Width of the crop area, either in percentage or pixels
  height: 70, // Height of the crop area, either in percentage or pixels
};

const initialCrop2 = {
  unit: "px", // Can be 'px' or '%'
  x: 158, // X position of the crop, either in percentage or pixels
  y: 48, // Y position of the crop, either in percentage or pixels
  width: 388, // Width of the crop area, either in percentage or pixels
  height: 309, // Height of the crop area, either in percentage or pixels
};

const Camera = ({ imgSrc, setImgSrc }) => {
  const [capturedImg, setCapturedImg] = useState(null);
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(initialCrop2);

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: 800,
      height: 500,
    });

    if (imageSrc) {
      setCapturedImg(imageSrc);
    }
  }, []);

  // Handle crop change
  const handleCropChange = (newCrop) => {
    setCrop(newCrop); // Update the crop area interactively
  };

  // Handle completed crop
  const handleCropComplete = (crop, cropPersent) => {
    // console.log("cropPersent", cropPersent);
    setCompletedCrop(crop); // Save the completed crop area
  };

  // When "Capture Photo 2" is clicked, crop the image and show the result
  const handleCropImage = () => {
    if (!capturedImg || !completedCrop) return;

    const image = new Image();
    image.src = capturedImg;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (ctx) {
        const { x, y, width, height } = completedCrop;

        // Set the canvas dimensions to the crop dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the cropped image onto the canvas
        ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

        // Get the cropped image URL
        const croppedImage = canvas.toDataURL("image/jpeg");
        setImgSrc(croppedImage); // Set the cropped image to the parent component
      }
    };
  };

  return (
    <div>
      {/* Display the captured image if it exists */}

      <div style={{ textAlign: "center" }}>
        <ReactCrop
          crop={crop}
          onChange={handleCropChange}
          onComplete={handleCropComplete}
          // aspect={3.7 / 4}
          // disabled
          keepSelection
        >
          {!capturedImg && (
            <div>
              <Webcam
                audio={false}
                width={"100%"}
                height={"100%"}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                mirrored={true}
              />
            </div>
          )}
          {capturedImg && (
            <img
              src={capturedImg}
              alt="Captured"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
        </ReactCrop>
      </div>

      <div className="modal-footer pb-0">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setImgSrc(null);
            setCapturedImg(null);
          }}
          // data-bs-dismiss="modal"
        >
          Clear Photo
        </button>
        {!capturedImg && (
          <button
            // data-bs-dismiss="modal"
            type="button"
            className="btn btn-primary"
            onClick={capture}
          >
            Capture Photo
          </button>
        )}

        {capturedImg && (
          <button
            data-bs-dismiss="modal"
            type="button"
            className="btn btn-primary"
            onClick={handleCropImage}
          >
            Insert
          </button>
        )}
      </div>

      {/* Display the cropped image */}
      {/* {imgSrc && ( */}
      {/*   <div style={{ textAlign: "center", marginTop: "20px" }}> */}
      {/*     <h4>Cropped Image</h4> */}
      {/*     <img src={imgSrc} alt="Cropped" style={{ maxWidth: "100%" }} /> */}
      {/*   </div> */}
      {/* )} */}
    </div>
  );
};

export default Camera;

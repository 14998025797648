import axios from "axios";
import {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import "./MedicalPhysical.scss";
import { UserContext } from "../../../../Context/UserContextAPI";

const MedicalPhysicalEntry = forwardRef(
  ({
    same,
    onPhysicalReportData,
    onInputChange,
    onStatusChange,
    onInputStatusChange,
    physicalRef,
    onRiskFactorChange,
  }) => {
    const { accessPerm, settingsData } = useContext(UserContext);
    const [changeColor, setChangeColor] = useState();
    const [fit, setFit] = useState(0);
    const [unfit, setUnfit] = useState(0);
    const [heldUp, setHeldUp] = useState(0);
    const [status, setStatus] = useState(0);
    const [statusValue, setStatusValue] = useState(null);
    const [riskFactor, setRiskFactor] = useState("");
    const [ifNormal, setIfNormal] = useState(false);
    const { userId } = useParams();

    // for dropdown investigation

    const [invTestsData, setInvTestsData] = useState([]);

    const detailsRef = useRef(null);

    const [searchingId, setSearchingId] = useState("");

    const [userDetail, setUserDetail] = useState(null);
    const [user, setUser] = useState(null);
    const [investigations, setInvestigations] = useState([]);
    const [physicalTests, setPhysicalTests] = useState([]);
    const [physicalReportData, setPhysicalReportData] = useState([]);
    const [physicalComment, setPhysicalComment] = useState({
      user_id: userId,
      comments: "",
      status: "",
      investigation_group_id: 3,
    });
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const formattedBirthDate = user?.user?.date_of_birth
      ? new Date(user?.user?.date_of_birth).toLocaleDateString("en-GB")
      : "---";
    let physicalRiskFactor = ""; // Initialize physicalRiskFactor

    const statusCheckHandler = (reports) => {
      let fitCount = 0;
      let unfitCount = 0;
      let heldUpCount = 0;

      reports.forEach((report) => {
        const { investigation_id, result } = report;

        // Common invalid results
        const isInvalid =
          result === "" ||
          result === "N/A" ||
          result === "---" ||
          result === "NAD";

        // Helper function to set fit/unfit/held-up status and append risk factor
        const setStatusBasedOnResult = (
          fitCondition,
          unfitCondition,
          heldUpCondition = false,
          name,
        ) => {
          if (fitCondition) {
            fitCount = 1; // Mark as fit
            report.status = 2;
          } else if (unfitCondition) {
            unfitCount = 1; // Mark as unfit
            report.status = 3;
            // Add risk factor for unfit cases with static name
            physicalRiskFactor += `<span style="color: #FF0000;">${name}</span>, `;
          } else if (heldUpCondition) {
            heldUpCount = 1; // Mark as held-up
            report.status = 4;
            // Add risk factor for held-up cases with static name
            physicalRiskFactor += `<span style="color: #0000FF;">${name}</span>, `;
          }
        };

        switch (investigation_id) {
          case 8: // Right Eye
            setStatusBasedOnResult(
              result === "6/6" || isInvalid,
              result !== "6/6" && !isInvalid,
              false,
              "Right Eye",
            );
            break;

          case 7: // Left Eye
            setStatusBasedOnResult(
              result === "6/6" || isInvalid,
              result !== "6/6" && !isInvalid,
              false,
              "Left Eye",
            );
            break;

          case 10: // Right Ear
            setStatusBasedOnResult(
              result === "Normal" || isInvalid,
              false,
              result !== "Normal" && !isInvalid,
              "Right Ear",
            );
            break;

          case 9: // Left Ear
            setStatusBasedOnResult(
              result === "Normal" || isInvalid,
              false,
              result !== "Normal" && !isInvalid,
              "Left Ear",
            );
            break;

          case 14: // Physical
            setStatusBasedOnResult(
              result === "Normal" || isInvalid,
              false,
              result !== "Normal" && !isInvalid,
              "Physical",
            );
            break;

          case 57: // C.N.S
            setStatusBasedOnResult(
              result === "Normal" || isInvalid,
              false,
              result !== "Normal" && !isInvalid,
              "C.N.S",
            );
            break;

          case 13: // Skin
            setStatusBasedOnResult(
              result === "Clear" || isInvalid,
              result !== "Clear" && !isInvalid,
              false,
              "Skin",
            );
            break;

          case 11: // Hernia
            setStatusBasedOnResult(
              result === "Absent" || isInvalid,
              result !== "Absent" && !isInvalid,
              false,
              "Hernia",
            );
            break;

          case 12: // Hydrocele
            setStatusBasedOnResult(
              result === "Absent" || isInvalid,
              result !== "Absent" && !isInvalid,
              false,
              "Hydrocele",
            );
            break;

          case 56: // Deformities
            setStatusBasedOnResult(
              result === "Absent" || isInvalid,
              result !== "Absent" && !isInvalid,
              false,
              "Deformities",
            );
            break;

          case 58: // Extremities
            setStatusBasedOnResult(
              result === "Absent" || isInvalid,
              result !== "Absent" && !isInvalid,
              false,
              "Extremities",
            );
            break;

          case 60: // Age Determinations
            setStatusBasedOnResult(
              result === "Normal" || isInvalid,
              result !== "Normal" && !isInvalid,
              false,
              "Age Determinations",
              result === "Normal" || isInvalid ? 0 : 100,
            );
            break;

          default:
            // Handle any other cases or default behavior if needed
            break;
        }
      });
      setRiskFactor(physicalRiskFactor);
      onRiskFactorChange(physicalRiskFactor);
      // After the loop, set the React states based on the calculated fit/unfit/heldUp counts
      setFit(fitCount);
      setUnfit(unfitCount);
      setHeldUp(heldUpCount);

      if (unfitCount === 1) {
        setStatus(3); // Unfit
      } else if (heldUpCount === 1) {
        setStatus(4); // Held Up
      } else if (fitCount === 1) {
        setStatus(2); // Fit
      }
      onStatusChange(status);
    };

    const handleOnChange = (event, testId) => {
      setPhysicalReportData((previousReport) => {
        const updatedReport = previousReport?.map((report) => {
          if (report?.test_id === testId) {
            return {
              ...report,
              result: event.target.value,
            };
          }
          return report;
        });
        return updatedReport;
      });
      onInputChange(event, testId);
      if (testId === 15 && event?.target?.value === "Normal") {
        setIfNormal(true);
      } else {
        setIfNormal(false);
      }
    };

    const handleNormalValue = () => {
      investigations?.forEach((investigation, index) => {
        setChangeColor("text-dark");
        setPhysicalReportData((previousReport) => {
          const updatedReport = previousReport.map((report) => {
            if (report?.test_id === investigation?.investigation_tests[0]?.id) {
              if (investigation?.investigation_tests[0]?.info) {
                return {
                  ...report,
                  result: investigation?.investigation_tests[0]?.info?.replace(
                    /<[^>]*>/g,
                    "",
                  ),
                };
              }
            }
            return report;
          });
          onPhysicalReportData(updatedReport);
          return updatedReport;
        });
      });
    };

    const getActiveInvestigationTests = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/reports/get-data/3`,
          {
            invIds:
              user?.user?.role_id === 5
                ? user?.investigation_id?.split(",")
                : user?.package?.investigation_id?.split(","),
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setInvestigations(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getPhysicalTests = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/reports/physical/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setPhysicalTests(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getUserDetail = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/user-details/test/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setUserDetail(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getComment = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/reports/get-physical-comments/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        const { comments, status } = response?.data?.data;
        setStatusValue(status);
      } catch (error) {
        console.error(error);
      }
    };

    const savePhysicalComment = async () => {
      let currentStatus;
      // Update status if needed based on conditions
      if (unfit === 1 || statusValue === 3) {
        currentStatus = 3; // Unfit
      } else if (heldUp === 1 || statusValue === 4) {
        currentStatus = 4; // Held Up
      } else if (fit === 1 || statusValue === 2) {
        currentStatus = 2; // Fit
      }
      const combinedData = {
        combinedObject: {
          physicalComment: {
            ...physicalComment,
            status: currentStatus,
          },
          physicalRiskFactor: {
            user_id: userId,
            investigation_group_id: 101,
            status: statusValue,
            comments: riskFactor,
          },
        },
      };

      try {
        const res = await axios.post(
          `${baseUrl}/reports/save-physical-comments`,
          { combinedData },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        if (res) {
          await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const changedInvTestSave = async () => {
      const changedData = physicalReportData
        ?.map((test) => {
          if (
            test?.test_id === 14 ||
            // test?.test_id === 57 ||
            test?.test_id === 15
          ) {
            return {
              test_id: test?.test_id,
              info_value: test?.result.trim(),
            };
          }

          return null;
        })
        .filter((item) => item !== null);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reports/investigation-tests-data/save`,
          changedData,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        console.log("save investigations test", res.data);
      } catch (error) {
        console.log(error);
      }
    };

    const savePhysicalReport = async () => {
      try {
        // const emptyResult = physicalReportData.filter(
        //   (item) => item.result === ""
        // ).length;
        // const dashCount = physicalReportData.filter(
        //   (item) => item.result === "---"
        // ).length;
        // let checkedValue = 0;
        // if (user?.package?.id !== 2) {
        //   if (emptyResult >= 1 || dashCount >= 3) {
        //     setChecked(1);
        //     checkedValue = 1;
        //   }
        // } else if (user?.package?.id === 2) {
        //   setChecked(0);
        //   checkedValue = 0;
        // } else {
        //   setChecked(0);
        //   checkedValue = 0;
        // }
        let checkedValue = 0;
        // Save physical status
        const physicalStatusResponse = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/physical-status/${userId}`,
          { data: checkedValue },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        if (physicalStatusResponse.status === 200) {
          // Save physical report
          const response = await axios.put(
            `${baseUrl}/reports/physical/${userId}`,
            physicalReportData,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          );

          // Save physical comment
          await savePhysicalComment();

          // save changed Investigation test data
          await changedInvTestSave();

          // toast.success(response?.data?.data);

          physicalReportData.forEach((report) => {
            if (report?.test_id === 15 && report?.result === "Normal") {
              setChangeColor("text-success");
            } else {
              setChangeColor("text-danger");
            }
          });
        } else {
          toast.error("Failed to update physical entry!");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleStatus = (event) => {
      const selectedStatus = Number(event.target.value);
      setStatusValue(selectedStatus);
      onInputStatusChange(selectedStatus);
    };

    const sendReportDataToParent = () => {
      onPhysicalReportData(physicalReportData); // Call parent function with data
    };

    useImperativeHandle(physicalRef, () => ({
      handleNormalValue,
      savePhysicalReport,
    }));

    useEffect(() => {
      if (physicalRef) {
        physicalRef.current = { handleNormalValue };
      }
    }, [physicalRef]);

    useEffect(() => {
      if (physicalRef) {
        physicalRef.current = { savePhysicalReport };
      }
    }, [physicalRef]);

    useEffect(() => {
      sendReportDataToParent();
    }, [userId, physicalReportData, fit, unfit, heldUp]);

    useEffect(() => {
      statusCheckHandler(physicalReportData);
    }, [userId, physicalReportData, fit, unfit, heldUp]);

    useEffect(() => {
      physicalReportData.forEach((report) => {
        if (report?.test_id === 15 && report?.result === "Normal") {
          setIfNormal(true);
        } else {
          setChangeColor(false);
        }
      });
    }, [physicalReportData]);

    useEffect(() => {
      if (physicalTests.length <= 0) {
        investigations?.forEach((investigation) => {
          setPhysicalReportData((previousReport) => {
            previousReport = [
              ...previousReport,
              {
                user_id: userId,
                investigation_id: investigation?.id,
                test_id: investigation?.investigation_tests[0]?.id,
                user_file: "",
                result: "",
                riskFactor: "",
              },
            ];

            return [...previousReport];
          });
        });
      }
    }, [investigations, physicalTests, userId]);

    useEffect(() => {
      setPhysicalReportData([]);
      if (physicalTests?.length > 0) {
        const initialData = investigations.map((investigation) => {
          // Find matching pathologyTest for the current investigation
          const matchedTest = physicalTests.find(
            (test) => test.investigation_id === investigation.id,
          );
          // Determine the result and test_id based on matchedTest or default to the first investigation_test
          const result =
            matchedTest?.result?.replace(/<[^>]*>/g, "") || matchedTest?.result;
          const test_id = matchedTest
            ? matchedTest.test_id
            : investigation.investigation_tests[0]?.id;
          if (test_id === 15 && result === "Normal") {
            setIfNormal(true);
          } else {
            setIfNormal(false);
          }
          return {
            user_id: userId,
            investigation_id: investigation.id,
            test_id: test_id,
            user_file: matchedTest ? matchedTest.user_file : "",
            result: result,
            riskFactor: "",
          };
        });
        setPhysicalReportData(initialData);
      } else {
        // If no fetched values, initialize pathologyReportData based on investigations
        const initialData = investigations.map((investigation) => ({
          user_id: userId,
          investigation_id: investigation.id,
          test_id: investigation.investigation_tests[0].id,
          user_file: "",
          result: "",
          riskFactor: "",
        }));
        setIfNormal(false);
        setPhysicalReportData(initialData);
      }
    }, [userId, physicalTests, investigations]);

    // Get user, Introducer, and Representative---------------------
    const [representative, setRepresentative] = useState();
    useEffect(() => {
      userDetail && setUser(userDetail.userDetail);
      const introducer_id = userDetail?.userDetail?.introducer_user?.id;

      if (introducer_id) {
        axios(
          `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        )
          .then((res) => {
            const representative_id = res?.data?.user_id;

            axios(
              `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
                },
              },
            )
              .then((res) => {
                setRepresentative(res?.data?.data?.userQuery);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, [userDetail]);

    useEffect(() => {
      getUserDetail();
    }, [userId]);

    useEffect(() => {
      getPhysicalTests();
    }, [userId]);

    useEffect(() => {
      userId && getComment();
    }, [userId]);

    useEffect(() => {
      user && getActiveInvestigationTests();
    }, [user]);

    const fetchInvTestData = async (e, invTest) => {
      // for skin and physical remarks will work, otherwise it will reeturn null
      if (![14, 15].includes(invTest?.investigation_tests[0]?.id)) {
        return null;
      }
      const dataToSend = {
        test_id: invTest?.investigation_tests[0]?.id,
        info_value: e.target.value.trim(),
      };
      if (dataToSend.test_id) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reports/investigation-tests-data`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        if (response) {
          setInvTestsData(response?.data);
        }
      }
    };

    const handleSelect = (value, index, testId) => {
      setChangeColor("text-dark");
      setPhysicalReportData((previousReport) => {
        const updatedReport = previousReport?.map((report) => {
          if (report?.test_id === testId) {
            return {
              ...report,
              result: value,
            };
          }
          return report;
        });
        return updatedReport;
      });
      //
      setSearchingId("");
      // console.log("handleSelect", value, index, testId);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (detailsRef.current && !detailsRef.current.contains(event.target)) {
          setSearchingId(null);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [detailsRef]);

    return (
      <>
        <div className="card p-3 medical-report">
          <div className="row mb-2 bg-primary text-white px-2 py-2">
            <h6 className="mb-0">
              Medical Physical Report for {user?.user?.f_name}{" "}
              {user?.user?.l_name} [{user?.user?.reg_prefix || ""}
              {user?.user?.registration_no}]
            </h6>
          </div>

          <div
            className={`row bg-white
             mt-1 p-3 px-3 rounded-3 
              text-dark border
            `}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              {investigations?.map((investigation, index) => {
                const isMaleAndPregnancyTest =
                  (user?.gender?.name === "Male" ||
                    user?.gender?.name === "male") &&
                  investigation?.name === "Pregnancy Test";
                if (isMaleAndPregnancyTest) {
                  return null;
                }
                return (
                  <div key={index} className="d-flex align-items-center fs-6">
                    <label
                      htmlFor={`${investigation?.name?.toLowerCase()}`}
                      className="w-25"
                    >
                      {investigation?.name}
                    </label>
                    {investigation?.investigation_tests[0]?.value_type?.toLowerCase() ===
                    "dropdown" ? (
                      <select
                        name="dropdown"
                        value={
                          physicalReportData.find(
                            (report) =>
                              report?.test_id ===
                              investigation?.investigation_tests[0]?.id,
                          )?.result
                        }
                        className="form-select w-75"
                        id={`${investigation?.name?.toLowerCase()}`}
                        onChange={(event) => {
                          handleOnChange(
                            event,
                            investigation?.investigation_tests[0]?.id,
                          );
                        }}
                      >
                        {investigation?.investigation_tests[0]?.dropdown_value
                          ?.split(",")
                          .map((v, index) => (
                            <option key={index} value={v}>
                              {v}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <div className="w-75">
                        <div className="input-group w-100">
                          <input
                            type="text"
                            id={`${investigation?.name?.toLowerCase()}`}
                            value={
                              physicalReportData.find(
                                (report) =>
                                  report?.test_id ===
                                  investigation?.investigation_tests[0]?.id,
                              )?.result
                            }
                            className={`form-control w-100 ${
                              investigation?.name === "Physical"
                                ? physicalReportData.find(
                                    (report) =>
                                      report?.test_id ===
                                      investigation?.investigation_tests[0]?.id,
                                  )?.result === "Normal"
                                  ? "text-success"
                                  : "text-danger"
                                : "text-dark"
                            }`}
                            onChange={(event) => {
                              handleOnChange(
                                event,
                                investigation?.investigation_tests[0]?.id,
                              );

                              setSearchingId(index);
                              fetchInvTestData(event, investigation);
                            }}
                          />
                          {searchingId === index &&
                            invTestsData?.length > 0 && (
                              <div
                                className="position-absolute border-bottom top-100 bg-light text-dark w-100 z-10"
                                style={{
                                  zIndex: "100",
                                  left: "0%",
                                  overflowY: "scroll",
                                  maxHeight: "410px",
                                  width: "100%",
                                }}
                                ref={detailsRef}
                              >
                                {invTestsData?.map((item) => (
                                  <div
                                    className="search-row"
                                    onClick={(event) =>
                                      handleSelect(
                                        item?.info,
                                        index,
                                        item?.test_id,
                                      )
                                    }
                                    style={{
                                      padding: "2px 12px",
                                      cursor: "pointer",
                                      width: "100%",
                                      color: "#000",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {item?.info}
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              <div className="d-flex align-items-center mt-4">
                <label htmlFor="risk-factor" className="w-25 fw-bold">
                  Status
                </label>
                <select
                  name="dropdown"
                  className="form-select fw-bold w-75"
                  id="risk-factor"
                  onChange={(event) => handleStatus(event)}
                  value={statusValue}
                >
                  <option value="" className="fw-bold">
                    Select Status
                  </option>
                  <option value={2} className="fw-bold">
                    FIT
                  </option>
                  <option value={3} className="fw-bold">
                    UNFIT
                  </option>
                  <option value={4} className="fw-bold">
                    HELDUP
                  </option>
                </select>
              </div>
            </div>
          </div>
          {same !== 1 && (
            <div className="row mt-3">
              <div className="col-6">
                {/* <div className="d-flex gap-2"> */}
                {/*   {userDetail?.nextPage && ( */}
                {/*     <Link */}
                {/*       to={`/dashboard/medical-reports/physical/${userDetail?.nextPage}?data=3`} */}
                {/*       className="btn btn-primary" */}
                {/*     > */}
                {/*       Previous */}
                {/*     </Link> */}
                {/*   )} */}
                {/*   {userDetail?.previousPage && ( */}
                {/*     <Link */}
                {/*       to={`/dashboard/medical-reports/physical/${userDetail?.previousPage}?data=3`} */}
                {/*       className="btn btn-primary" */}
                {/*     > */}
                {/*       Next */}
                {/*     </Link> */}
                {/*   )} */}
                {/* </div> */}
              </div>
              <div className="col-6">
                <div className="d-flex gap-2 justify-content-end">
                  {accessPerm(33, 22) && (
                    <button
                      className="btn btn-primary"
                      onClick={handleNormalValue}
                    >
                      Set Normal
                    </button>
                  )}
                  {/* <button */}
                  {/*   className="btn btn-success px-2" */}
                  {/*   onClick={savePhysicalReport} */}
                  {/* > */}
                  {/*   Save Results */}
                  {/* </button> */}

                  {/* <button */}
                  {/*   className="btn btn-outline-secondary" */}
                  {/*   onClick={() => { */}
                  {/*     window.close(); */}
                  {/*   }} */}
                  {/* > */}
                  {/*   Close */}
                  {/* </button> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default MedicalPhysicalEntry;

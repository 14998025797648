import axios from "axios";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";

const FingerprintMatch = ({ children, successHandler, userData }) => {
  const [isModalOpen, setMOdalOpen] = useState(false);
  console.log('userData', userData)

  async function handleFingerPrintMatch() {
    try {
      const res = await axios.post(
        `http://127.0.0.1:8080/api/fingerprint/user/match`,userData
      );
      const result = res.data?.data;
      console.log(result);
      if (result && successHandler) {
        successHandler(result);
      }

      setMOdalOpen(false);
    } catch (err) {
      console.log(err);
      // toast.error(err?.response?.data?.data?.error || "Something went Wrong");
      if(err.status === 404){
        toast.error("No User Found With This ID")
      }else {
        toast.error("There is an an error .please try again");
      }
      setTimeout(() => {
        setMOdalOpen(false);
      }, 2000);
    }
  }

  return (
    <div>
      <button
        type="button"
        className="border-0 bg-transparent mt-2"
        onClick={() => setMOdalOpen(true)}
      >
        {children}
      </button>
      <Modal
        show={isModalOpen}
        onHide={() => setMOdalOpen(false)}
        onShow={handleFingerPrintMatch}
      >
        <ModalHeader closeButton />
        <ModalBody>
          <div className="text-center">
            <h3>
              Please put your finger on fingerprint when white light turn on
            </h3>
          </div>
          <div className="text-center mt-5">
            <Spinner
              animation="border"
              variant="success"
              style={{ width: "5rem", height: "5rem" }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default FingerprintMatch;

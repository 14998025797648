import formatDate from "../../../../utils/formatDate";
const MrUserTableInfo = ({ userDetail, settingsData, age }) => {
  return (
    <table
      className="table-bordered invoice-table h-100 text-dark fw-bold"
      style={{
        width: "100%",
        height: "100%",
        borderCollapse: "collapse",
        textAlign: "left",
        // fontSize: "20px",
      }}
    >
      <tbody>
        {/* Row 1 - Name and Client ID */}
        <tr style={{ height: "1.5rem" }}>
          <td
            className="text-uppercase border border-dark  align-middle fw-bold"
            style={{ width: "22%" }}
          >
            Name
          </td>
          <td
            className="text-uppercase border border-dark  align-middle fw-bold"
            // style={{ width: "40%" }}
            colSpan={4}
          >
            {userDetail?.user?.f_name || "---"}
          </td>
        </tr>

        {/* Row 2 - Gender and Passport No */}
        <tr style={{ height: "1.5rem" }}>
          <td
            className="text-uppercase border border-dark  align-middle fw-bold"
            // style={{ fontSize: "30px" }}
          >
            Passport No
          </td>
          <td
            style={{ width: "23%" }}
            className="text-uppercase border border-dark  align-middle fw-bold"
          >
            {userDetail?.user?.passport_no || "---"}
          </td>

          <td className="text-uppercase border border-dark  align-middle fw-bold">
            Country
          </td>
          <td
            colSpan={2}
            className="text-uppercase border border-dark  align-middle fw-bold"
          >
            {userDetail?.country?.name || "---"}
          </td>
        </tr>

        {/* Row 3 - Date of Birth and Travelling To */}
        <tr style={{ height: "1.5rem" }}>
          <td className="text-uppercase border border-dark  align-middle">
            Profession
          </td>
          <td
            colSpan={4}
            className="text-uppercase border border-dark  align-middle"
          >
            {userDetail?.profession?.name || "---"}
          </td>
        </tr>

        {/* Row 4 - Father Name and Contact Number */}
        <tr style={{ height: "1.5rem" }}>
          <td className="text-uppercase border border-dark  align-middle">
            GCC Slip No
          </td>
          <td
            colSpan={2}
            className="text-uppercase border border-dark  align-middle"
          >
            {userDetail?.user?.gcc_slip_no || ""}
          </td>
          <td
            style={{ width: "18%" }}
            className="text-uppercase border border-dark  align-middle fw-bold"
          >
            Slip Ex. Dt.
          </td>
          <td
            style={{ width: "20%" }}
            className="text-uppercase border border-dark  align-middle fw-bold"
          >
            {userDetail?.user?.gcc_expairy_date &&
            !isNaN(new Date(userDetail?.user?.gcc_expairy_date))
              ? formatDate(
                  new Date(userDetail?.user?.gcc_expairy_date),
                  "dd MMM yyyy",
                ).toUpperCase()
              : "---"}
          </td>
        </tr>

        {/* Row 5 - Nationality and Agency */}
        <tr>
          <td className="text-uppercase border border-dark  align-middle">
            Date of Birth
          </td>
          <td
            colSpan={2}
            className="text-uppercase border border-dark  align-middle"
          >
            {userDetail?.user?.date_of_birth &&
            !isNaN(new Date(userDetail?.user?.date_of_birth))
              ? formatDate(
                  new Date(userDetail?.user?.date_of_birth),
                  "dd MMM yyyy",
                ).toUpperCase()
              : "---"}
            {age && `(${age})`}
          </td>
          <td className="text-uppercase border border-dark  align-middle">
            Report Dt.
          </td>
          <td className="text-uppercase border border-dark  align-middle">
            {userDetail?.user?.report_delivery_date &&
            !isNaN(new Date(userDetail?.user?.report_delivery_date))
              ? formatDate(
                  new Date(userDetail?.user?.report_delivery_date),
                  "dd MMM yyyy",
                ).toUpperCase()
              : "---"}
          </td>
        </tr>
        <tr>
          <td
            style={{ width: "16%" }}
            className="text-uppercase border border-dark  align-middle"
          >
            {settingsData?.introducer_label}
          </td>
          <td
            colSpan={2}
            className="text-uppercase border border-dark  align-middle"
          >
            {userDetail?.introducer_user?.f_name || "---"}
          </td>

          <td className="text-uppercase border border-dark  align-middle">
            Contact
          </td>
          <td className="text-uppercase border border-dark  align-middle">
            {userDetail?.user?.mobile || ""}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default MrUserTableInfo;

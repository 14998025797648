import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { UserContext } from "../../../Context/UserContextAPI";
import Camera from "../../../components/Camera/Camera";
import VirtualizedMenuList from "../../../components/VirtualMenuList/VirtualMenuList";
import FingerPrintCapture from "../../../components/Fingerprint/FingerprintCapture";

const EditPatientInformation = () => {
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-content-groups`;
  const { smsAPI, currentUser } = useContext(UserContext);
  const [genders, setGenders] = useState([]);
  const [meritalStatus, setMeritalStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [staticData, setStaticData] = useState([]);

  const [nationalities, setNationalities] = useState([]);
  const [nationalityName, setNationalityName] = useState({});
  const defaultNationality = [{ value: 18, label: "Bangladeshi" }];

  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState(0);
  // const [selectedIntroducer, setSelectedIntroducer] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const [companyUsers, setCompanyUsers] = useState([]);
  const [delegetsUsers, setDelegetsUsers] = useState([]);
  const [introducerUsers, setIntroducerUsers] = useState([]);
  const [mappedIntroReps, setMappedIntroReps] = useState([]);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodName, setPaymentMethodName] = useState({});

  const [imgSrc, setImgSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewUserPhoto, setPreviewUserPhoto] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [representativeId, setRepresentativeId] = useState(null);
  const [reportDelivery, setReportDelivery] = useState();

  const [refresh, setRefresh] = useState([]);
  const navigate = useNavigate();

  const {
    state: { record },
  } = useLocation();
  const [selectedReligion, setSelectedReligion] = useState(
    record?.user?.religion || "",
  );

  const handleReligionChange = (e) => {
    setSelectedReligion(e.target.value);
  };

    //----- fingerprint state ------//
    const [capturedFingerprint, setCapturedFingerprint] = useState(
       null
    );
    const [fingerprintTemplate, setFingerprintTemplate] = useState(record?.user?.finger_print_temp || null);

  const fetchStaticContentData = async () => {
    await axios
      .get(`${staticURL}/6`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response?.data?.data?.static_contents);
      })
      .catch((err) => console.log(err));
  };

  const getMappedIntroReps = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users-map`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setMappedIntroReps(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlerOnSubmitForm = async (e) => {
    e.preventDefault();
    // if (selectedIntroducer === true) {
    //   toast.error(
    //     "Please assign representative to introducer first to update!"
    //   );
    //   return;
    // }

    const form = e.target;
    
    const f_name = form.f_name.value;
    const l_name = "";
    const mobile = form.mobile.value;
    const religion = form.religion.value;
    const pi_place = form.pi_place.value;
    const pi_date = form.pi_date.value;
    const pe_date = form.pe_date.value;
    const gcc_slip_no = form.gcc_slip_no.value;
    const gcc_expairy_date = form.gcc_expairy_date.value;
    const report_delivery_date =
      reportDelivery || form.report_delivery_date.value;
    const image = form.image.files[0];
    const finger_print = capturedFingerprint ? null :   form?.finger_print?.files[0];
    const gender_id = form.gender_id.value;
    const passport_no = form.passport_no.value;
    const visa_no = form.visa_no.value;
    const visa_date = form.visa_date.value;
    const nid = "";
    const email = "";
    const date_of_birth = form.date_of_birth.value;
    const marital_status_id = form.marital_status_id.value;
    const father_name = form.father_name.value;
    const mother_name = form.mother_name.value;
    const country_id = form.country_id.value;
    const state_id = "";
    const city_id = "";
    const nationality_id = "18";
    const profession_id = form.profession_id.value;
    const company_id = form.company_id.value;
    const delegates_id = form.delegates_id.value;
    const introducer_id = form.introducer_id.value;
    const representative_id = representativeId;

    const package_id = form.package_id.value;
    const address_1 = form.address_1.value;
    const remarks = form.remarks.value;
    const package_price = form.package_price.value;
    const package_name = packageName ? packageName : record?.package?.name;
    const paid_amount = form.paid_amount.value;
    const collection_amount = form.collection_amount.value;
    const payment_method_id = form.payment_method_id.value;

    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }

    if (finger_print) {
      formData.append("finger_print", finger_print);
    }

    const patientData = {
      f_name,
      l_name,
      mobile,
      religion,
      visa_no,
      visa_date: visa_date || null,
      pi_place,
      pi_date: pi_date || null,
      pe_date: pe_date || null,
      gcc_slip_no,
      gcc_expairy_date: gcc_expairy_date || null,
      report_delivery_date: report_delivery_date || null,
      role_id: 4, // 4 for patient
      gender_id,
      passport_no,
      nid,
      email,
      date_of_birth: date_of_birth || null,
      marital_status_id,
      father_name,
      mother_name,
      country_id,
      state_id,
      city_id,
      nationality_id,
      profession_id,
      company_id,
      delegates_id,
      introducer_id,
      representative_id,
      package_id,
      address_1,
      remarks,
      package_name,
      package_price,
      paid_amount:
        parseInt(paid_amount ? paid_amount : 0) +
        parseInt(collection_amount ? collection_amount : 0),
      collection_amount: collection_amount ? parseInt(collection_amount) : 0,
      payment_method_id,
      updated_by: currentUser?.id,
    };

    if (imgSrc && finger_print && !image) {
      // console.log(uploadUserImage(imgSrc));
      const cameraImage = await uploadUserImage(imgSrc);
      if (cameraImage) {
        patientData.image = cameraImage;

        const imagePath = await methodUploadUsersPhotoFile(formData);
        if (imagePath?.finger_print) {
          patientData.finger_print = imagePath?.finger_print;
          methodUpdateUserDetails(patientData, form);
        }
      }
    } else if (imgSrc && !finger_print && !image) {
      const cameraImage = await uploadUserImage(imgSrc);
      if (cameraImage) {
        patientData.image = cameraImage;
        methodUpdateUserDetails(patientData, form);
      }
    } else if (finger_print && !imgSrc && !image) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.finger_print) {
        patientData.finger_print = imagePath?.finger_print;
        methodUpdateUserDetails(patientData, form);
      }
    } else if (image && finger_print && !imgSrc) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.image && imagePath?.finger_print) {
        patientData.image = imagePath?.image;
        patientData.finger_print = imagePath?.finger_print;
        methodUpdateUserDetails(patientData, form);
      } else {
        if (imagePath?.image) {
          patientData.image = imagePath?.image;
          methodUpdateUserDetails(patientData, form);
        }
        if (imagePath?.finger_print) {
          patientData.finger_print = imagePath?.finger_print;
          methodUpdateUserDetails(patientData, form);
        }
      }
    } else if (image && !finger_print && !imgSrc) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.image) {
        patientData.image = imagePath?.image;
        methodUpdateUserDetails(patientData, form);
      }
    } else {
      // console.log(patientData);
      methodUpdateUserDetails(patientData, form);
    }
  };

  const methodUploadUsersPhotoFile = async (formData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user-details/upload-image`,
      formData,
    );
    // console.log(response);
    if (response) {
      const imagePath = response?.data;
      return imagePath;
    }
  };

  const uploadUserImage = async (imgSrc) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/upload-base64-photo`,
        {
          imgSrc: imgSrc,
        },
      );
      // console.log(res);
      const imagePath = res.data;
      // console.log('image', imagePath);
      return imagePath;
    } catch (error) {
      console.log(error);
    }
  };

    // add fingerprint img name and template in patientData
    function fingerprintAdd (patientData){
      if(capturedFingerprint){
        patientData.finger_print_capture_data = capturedFingerprint;
        patientData.finger_print_temp = fingerprintTemplate
      }
      return patientData
    }

  const methodUpdateUserDetails = async (patientData, collection_amount) => {
    // console.log(patientData);
    const patientUserData = fingerprintAdd(patientData)

    setIsSubmitting(true);
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/user-details/${record?.id}`,
      patientUserData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );

    if (res && res.status === 200) {
      if (collection_amount) {
        const mobileNumber = record?.user?.mobile;
        const customerName = `${record?.user?.f_name} ${record?.user?.l_name}`;
        const paidAmount = collection_amount;
        const paymentMessage = `Dear ${customerName}, We have received your payment BDT ${paidAmount}. Thank you for your payment!`;

        smsAPI(mobileNumber, paymentMessage)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      toast.success("User Details Updated successfully!!");
      setRefresh(!refresh);
      setIsSubmitting(false);
      navigate("/dashboard/patient-info");
    } else {
      toast.error(res?.data?.message);
      setRefresh(!refresh);
    }
  };

  const fetchMeritalStatusAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/marital-status`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);
    if (res) {
      const filterMeritalStatus = res?.data?.data?.filter(
        (filterMeritalStat) => {
          return filterMeritalStat?.id !== record?.marital_status?.id;
        },
      );
      setMeritalStatus(filterMeritalStatus);
    }
  };

  const fetchCountriesAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/country`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);
    if (res) {
      const filterCountries = res?.data?.data?.filter((filterCountry) => {
        return filterCountry?.id !== record?.country?.id;
      });
      setCountries(filterCountries);
    }

    const filterNotNullNationalities = res?.data?.data?.filter(
      (filterNotNullNationalitie) => {
        return filterNotNullNationalitie?.nationality !== null;
      },
    );

    const filterNationalities = filterNotNullNationalities?.filter(
      (filterNationalitie) => {
        return filterNationalitie?.id !== record?.country?.id;
      },
    );
    setNationalities(filterNationalities);

    const findNationalityName = filterNotNullNationalities?.find(
      (findNationality) => {
        return findNationality?.id === record?.nationality_id;
      },
    );

    setNationalityName(findNationalityName);
  };

  const fetchProfessionsAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/professions`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);

    if (res) {
      const filterProfessions = res?.data?.data?.filter((filterProfession) => {
        return filterProfession?.id !== record?.profession?.id;
      });
      setProfessions(filterProfessions);
    }
  };

  const fetchPackagesAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/packages`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);

    if (res) {
      const filterPackages = res?.data?.data?.filter((filterPackage) => {
        return filterPackage?.id !== record?.package?.id;
      });
      setPackages(filterPackages);
    }
  };

  const fetchGendersAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/gender`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);
    if (res) {
      const filterGenders = res?.data?.data?.filter((filterGender) => {
        return filterGender?.id !== record?.gender?.id;
      });
      setGenders(filterGenders);
    }
  };

  const fetchUsersAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users/all-users`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );

    const filterCompanyUsers = res?.data?.data?.data?.filter(
      (filterCompanyUser) => {
        return filterCompanyUser?.role_id === 6;
      },
    );

    if (filterCompanyUsers) {
      const otherCompanyUsers = filterCompanyUsers?.filter(
        (otherCompanyUser) => {
          return otherCompanyUser?.id !== record?.company_id;
        },
      );
      setCompanyUsers(otherCompanyUsers);
    }

    const filterDelegetesUsers = res?.data?.data?.data?.filter(
      (filterDelegetsUser) => {
        return filterDelegetsUser?.role_id === 7;
      },
    );

    if (filterDelegetesUsers) {
      const otherDelegetesUsers = filterDelegetesUsers?.filter(
        (otherDelegetsUser) => {
          return otherDelegetsUser?.id !== record?.delegates_id;
        },
      );
      setDelegetsUsers(otherDelegetesUsers);
    }
  };

  const getInroducers = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/introducer`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    const allIntroducerUsers = res?.data;
    if (allIntroducerUsers) {
      const otherIntroducerUsers = allIntroducerUsers?.filter(
        (otherIntroducerUser) => {
          return otherIntroducerUser?.id !== record?.introducer_id;
        },
      );
      setIntroducerUsers(otherIntroducerUsers);
    }
  };

  const fetchPaymentMethodsAPI = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/account-head`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);

    const filterPaymentMethods = res?.data?.data?.filter(
      (filterPaymentMethod) => {
        return (
          filterPaymentMethod?.head_type_id === 3 ||
          filterPaymentMethod?.head_type_id === 4
        );
      },
    );

    if (filterPaymentMethods) {
      const otherPaymentMethods = filterPaymentMethods?.filter(
        (otherPaymentMethod) => {
          return otherPaymentMethod?.id !== record?.payment_method_id;
        },
      );
      setPaymentMethods(otherPaymentMethods);
    }

    const findPaymentMethodName = res?.data?.data?.find((findPaymentMethod) => {
      return findPaymentMethod?.id === record?.payment_method_id;
    });
    setPaymentMethodName(findPaymentMethodName);
  };

  const handleSelect = (selectedOptions) => {
    // setSelectedIntroducer(
    //   selectedOptions?.label?.toLowerCase().includes("not assigned")
    // );
    setSelectedValues(selectedOptions);
    const introducerId = selectedOptions.value;
    const mappedIntroRep = mappedIntroReps.find(
      (mapped) => mapped.map_user_id === introducerId,
    );
    const representativeId =
      mappedIntroRep?.representative_user_map?.id || null;
    setRepresentativeId(String(representativeId));
  };

  const options = introducerUsers.map((user) => {
    // const representativeMobile =
    //   user?.user_users_maps[0]?.representative_user_map?.mobile;

    // const mobileLabel = representativeMobile
    //   ? `(${representativeMobile.slice(-2)})`
    //   : "(Not Assigned)";

    return {
      value: parseInt(user?.id),
      // label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      label: `${user?.registration_no} - ${user?.f_name}`,
    };
  });

  const nationalityOptions = countries?.map((country) => ({
    value: parseInt(country?.id),
    label: country?.nationality,
  }));

  useEffect(() => {
    getInroducers();
    getMappedIntroReps();
    fetchStaticContentData();
  }, []);

  useEffect(() => {
    if (record?.country?.id && !record?.state?.id) {
      const fetchStatesAPI = async () => {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user-details/countrys/${record?.country?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        // console.log(res);
        const data = res?.data?.data;
        if (data) {
          const filterStates = data?.filter((filterState) => {
            return filterState?.id !== record?.state?.id;
          });
          setStates(filterStates);
        }
      };
      fetchStatesAPI();
    }
  }, [record?.country?.id, record?.state?.id]);

  useEffect(() => {
    fetchPackagesAPI();
  }, [record?.package?.id]);

  useEffect(() => {
    fetchMeritalStatusAPI();
  }, [record?.marital_status?.id]);

  useEffect(() => {
    setRepresentativeId(String(record?.representative_id));
    console.log(representativeId);
  }, [record?.representative_id]);

  useEffect(() => {
    fetchProfessionsAPI();
  }, [record?.profession?.id]);

  useEffect(() => {
    fetchGendersAPI();
  }, [record?.gender?.id]);

  useEffect(() => {
    fetchPaymentMethodsAPI();
  }, [record?.payment_method_id]);

  useEffect(() => {
    fetchCountriesAPI();
  }, [record?.nationality_id]);

  useEffect(() => {
    fetchUsersAPI();
  }, [record?.company_id, record?.delegates_id, record?.introducer_id]);

  useEffect(() => {
    if (record?.user?.report_delivery_date) {
      setReportDelivery(record?.user?.report_delivery_date);
    }
  }, [record]);

  const countryOptions = [
    { value: -1, label: "Select Country" },
    ...(countries?.map((country) => ({
      value: parseInt(country?.id),
      label: `${country?.shortname} - ${country?.name}`,
    })) || []),
  ];

  const previewImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreview(e.target.files[0]);
    }
  };

  const previewUserPhotoFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewUserPhoto(e.target.files[0]);
    }
  };

  return (
    <>
      <>
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  onSubmit={(e) => handlerOnSubmitForm(e)}
                >
                  <div className="border p-4 rounded">
                    <div className="card-title d-flex align-items-center">
                      <h5 className="mb-0">Edit Passenger Information</h5>
                    </div>
                    <hr />
                    <div className="row ps-3 mb-5 ">
                      <div className="d-flex gap-3 ">
                        <div className="mb-3 d-flex align-items-center justify-content-center ">
                          <div className="d-flex flex-column">
                            <label className=" col-form-label">
                              User Photo
                            </label>

                            <div
                              onClick={() => {
                                setImgSrc(null);
                              }}
                            >
                              <label
                                id="user-photo-input-label"
                                htmlFor="user-photo-input"
                                className="btn btn-light border border-dark py-0"
                                style={{ fontSize: "0.8rem" }}
                              >
                                Select
                              </label>
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              name="image"
                              id="user-photo-input"
                              className="form-control"
                              hidden={true}
                              onChange={(e) => previewUserPhotoFile(e)}
                            />
                          </div>
                          <div
                            className="ms-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal-1"
                            onClick={() => {
                              setPreviewUserPhoto(null);
                            }}
                          >
                            {imgSrc || previewUserPhoto ? (
                              <img
                                src={
                                  imgSrc
                                    ? imgSrc
                                    : URL.createObjectURL(previewUserPhoto)
                                }
                                // name='image'
                                alt="user_photo"
                                className="img-fluid rounded-3 "
                                style={{ width: "8rem", height: "9rem" }}
                              />
                            ) : (
                              <img
                                src={
                                  `${process.env.REACT_APP_UPLOAD_URL}/users/${record?.user?.image}` !==
                                  `${process.env.REACT_APP_UPLOAD_URL}/users/null`
                                    ? `${process.env.REACT_APP_UPLOAD_URL}/users/${record?.user?.image}`
                                    : "/userimg.png"
                                }
                                alt="No_Image"
                                className="img-fluid rounded-3"
                                style={{ width: "8rem", height: "9rem" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mb-3 d-flex align-items-center justify-content-center ">
                          <div className="d-flex align-items-center justify-content-center gap-4">
                            <div className="col-form-label">
                              <p className="">Finger Print</p>
                              <label
                                htmlFor="fingerprint-upload"
                                style={{ cursor: "pointer" }}
                                className="btn btn-light border border-dark py-0"
                              >
                                Upload
                              </label>
                              <input
                                type="file"
                                name="finger_print"
                                accept="image/*"
                                hidden
                                id="fingerprint-upload"
                                onChange={(e) => previewImage(e)}
                              />
                            </div>

                            <FingerPrintCapture
                               preview={preview}
                               setPreview={setPreview}
                               setCapturedFingerprint={setCapturedFingerprint}
                               capturedFingerprint={capturedFingerprint}
                               setFingerprintTemplate={setFingerprintTemplate}
                              userData={record?.user}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-sm-6 px-3">
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Introducer <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              options={options}
                              placeholder="Select Introducer"
                              onChange={handleSelect}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  color: "black",
                                  // border: !selectedIntroducer
                                  //   ? state.border
                                  //   : "1px solid red",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                              }}
                              isSearchable={true}
                              defaultValue={
                                record?.introducer_user
                                  ? {
                                      value: record?.introducer_user?.id,
                                      label: `${
                                        record?.introducer_user?.registration_no
                                      } - ${
                                        record?.introducer_user?.f_name
                                      } - (${record?.introducer_user.user_users_maps[0]?.representative_user_map?.mobile.slice(
                                        -2,
                                      )})`,
                                    }
                                  : null
                              }
                              name="introducer_id"
                              required
                            />
                            {/* {selectedIntroducer && (
                              <p className="text-danger p-0 m-0 text-start">
                                Assign Representative to this Introducer first!
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Full Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="f_name"
                              className="form-control"
                              defaultValue={record?.user?.f_name}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Father's Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="father_name"
                              className="form-control"
                              defaultValue={record?.father_name}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Mother's Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="mother_name"
                              className="form-control"
                              defaultValue={record?.mother_name}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Passport No
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="passport_no"
                              className="form-control"
                              defaultValue={record?.user?.passport_no}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Date of Birth
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="date_of_birth"
                              className="form-control"
                              defaultValue={record?.user?.date_of_birth}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Gender <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="gender_id"
                              aria-label="Default select example"
                              required
                            >
                              <option value={""}>Choose One...</option>
                              {record?.gender?.id && (
                                <option value={record?.gender?.id} selected>
                                  {record?.gender?.name}
                                </option>
                              )}

                              {genders?.map((gender) => (
                                <option
                                  key={gender?.id}
                                  value={parseInt(gender?.id)}
                                >
                                  {gender?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Marital Status
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="marital_status_id"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {record?.marital_status?.id && (
                                <option
                                  value={record?.marital_status?.id}
                                  selected
                                >
                                  {record?.marital_status?.name}
                                </option>
                              )}
                              {meritalStatus?.map((meritSts) => (
                                <option
                                  key={meritSts?.id}
                                  value={parseInt(meritSts?.id)}
                                >
                                  {meritSts?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Nationality
                          </label>
                          <div className="col-sm-9">
                            <Select
                              options={nationalityOptions}
                              placeholder="Select Nationality"
                              isSearchable={true}
                              defaultValue={defaultNationality[0]}
                              name="nationality_id"
                              required
                              isDisabled
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Profession
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="profession_id"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {record?.profession_id && (
                                <option value={record?.profession_id} selected>
                                  {record?.profession?.name}
                                </option>
                              )}

                              {professions?.map((profession) => (
                                <option
                                  key={profession?.id}
                                  value={parseInt(profession?.id)}
                                >
                                  {profession?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Country <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <Select
                              name="country_id"
                              options={countryOptions}
                              value={countryOptions?.value}
                              defaultValue={
                                [
                                  {
                                    value: record?.country?.id,
                                    label: `${record?.country?.shortname} - ${record?.country?.name}`,
                                  },
                                ][0]
                              }
                              isSearchable={true}
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="remarks"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                              defaultValue={record?.user?.remarks}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="address_1"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                              defaultValue={record?.user?.address_1}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 px-3">
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Contact Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="tel"
                              name="mobile"
                              className="form-control"
                              defaultValue={record?.user?.mobile}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Religion
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="religion"
                              className="form-select"
                              value={selectedReligion} // Controlled input
                              onChange={handleReligionChange} // Update state on change
                              aria-label="Default select example"
                            >
                              <option value="">Choose One...</option>
                              {staticData?.map((religion) => (
                                <option
                                  key={religion?.id}
                                  value={religion?.name}
                                >
                                  {religion?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            P.I. Place
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pi_place"
                              className="form-control"
                              defaultValue={record?.user?.pi_place}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            P.I. Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="pi_date"
                              className="form-control"
                              defaultValue={record?.user?.pi_date}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            P.E. Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="pe_date"
                              className="form-control"
                              defaultValue={record?.user?.pe_date}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            VISA No
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="visa_no"
                              className="form-control"
                              defaultValue={record?.user?.visa_no}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            VISA Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="visa_date"
                              className="form-control"
                              defaultValue={record?.user?.visa_date}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            GCC Slip No.
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="gcc_slip_no"
                              className="form-control"
                              defaultValue={record?.user?.gcc_slip_no}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            GCC Slip Expiary Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="gcc_expairy_date"
                              className="form-control"
                              defaultValue={record?.user?.gcc_expairy_date}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Report Delivery Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="report_delivery_date"
                              className="form-control"
                              value={reportDelivery || ""} // Ensure the state is bound to the input field
                              onChange={(e) =>
                                setReportDelivery(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Company
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="company_id"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {record?.company_user?.id && (
                                <option
                                  value={record?.company_user?.id}
                                  selected
                                >
                                  {record?.company_user?.f_name}{" "}
                                  {record?.company_user?.l_name}
                                </option>
                              )}
                              {companyUsers?.map((user) => (
                                <option
                                  key={user?.id}
                                  value={parseInt(user?.id)}
                                >
                                  {user?.f_name} {user?.l_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Delegate
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="delegates_id"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {record?.delegates_user?.id && (
                                <option
                                  value={record?.delegates_user?.id}
                                  selected
                                >
                                  {record?.delegates_user?.f_name}{" "}
                                  {record?.delegates_user?.l_name}
                                </option>
                              )}
                              {delegetsUsers?.map((user) => (
                                <option
                                  key={user?.id}
                                  value={parseInt(user?.id)}
                                >
                                  {user?.f_name} {user?.l_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Package <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="package_id"
                              className="form-select"
                              aria-label="Default select example"
                              required
                              onChange={(e) => {
                                const singlePackage = packages.find(
                                  (p) => p.id === parseInt(e.target.value),
                                );
                                setPackageName(singlePackage?.name);
                                setPackagePrice(singlePackage?.price);
                                //show regular always
                                // if (singlePackage) {
                                //   singlePackage?.discounted_price > 0
                                //     ? setPackagePrice(
                                //         singlePackage?.discounted_price
                                //       )
                                //     : setPackagePrice(singlePackage?.price);
                                // }
                              }}
                            >
                              <option value={""}>Choose One...</option>
                              {record?.package_id && (
                                <option value={record?.package_id} selected>
                                  {record?.package?.name}
                                </option>
                              )}

                              {packages?.map((singlePackage) => (
                                <option
                                  key={singlePackage?.id}
                                  value={parseInt(singlePackage?.id)}
                                >
                                  {singlePackage?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Total Amount
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="package_price"
                              value={
                                packagePrice === 0
                                  ? record?.package_price
                                  : packagePrice
                              }
                              onChange={(e) => setPackagePrice(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Paid Amount
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="paid_amount"
                              className="form-control"
                              placeholder="0"
                              defaultValue={record?.paid_amount}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Collection Amount
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="collection_amount"
                              className="form-control"
                              placeholder={`${
                                record?.package_price - record?.paid_amount
                              }`}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Payment Method
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="payment_method_id"
                              aria-label="Default select example"
                              required
                            >
                              <option value={""}>Choose One...</option>
                              {record?.payment_method_id && (
                                <option
                                  value={record?.payment_method_id}
                                  selected
                                >
                                  {paymentMethodName?.name}
                                </option>
                              )}

                              {paymentMethods?.map((payment) => (
                                <option
                                  key={payment?.id}
                                  value={parseInt(payment?.id)}
                                >
                                  {payment?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end gap-2">
                      <div className="">
                        <button
                          className="btn btn-outline-secondary px-2 mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                          }}
                        >
                          Go Back
                          <span>
                            <i className="bi bi-arrow-left" />
                          </span>
                        </button>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          disabled={isSubmitting}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Body */}
        <div
          className="modal fade"
          id="exampleModal-1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Camera
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pt-0">
                {/* <AllWebCameras /> */}
                <Camera imgSrc={imgSrc} setImgSrc={setImgSrc} />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default EditPatientInformation;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import Loading from "../../utils/Loader/Loading";
import { useLocation, useNavigate } from "react-router-dom";

const CollectionReportPrint = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [receivedAmounts, setReceivedAmounts] = useState({});
  const [initialRowSelection, setInitialRowSelection] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [loading, setLoading] = useState(true);
  const pdfRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const queryData = Object.fromEntries(queryParams.entries());
  const filterData = queryData?.toDate || {};
  const [dues, setDues] = useState([]);
  const [received, setReceived] = useState([]);
  const [commission, setCommission] = useState([]);
  const [paid, setPaid] = useState([]);
  const [netTotal, setNetTotal] = useState([]);

  const handlerOnSearch = async () => {
    await methodFilterPatientsDetails(queryData, "form");
  };
  console.log('queryData', queryData)

  const handlePDF = async () => {
    if (!pdfRef.current) {
      console.error("PDF content is not available");
      return;
    }

    const htmlContent = pdfRef.current.outerHTML;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/generate-pdf/collection-report`,
        { htmlContent, filterData },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");

      // Clean up URL object after use
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  // const handlePDF = async () => {
  //   if (!pdfRef.current) {
  //     console.error("PDF content is not available");
  //     return;
  //   }

  //   const htmlContent = pdfRef.current.outerHTML;

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/user-details/generate-pdf/collection-report`,
  //       { htmlContent, filterData },
  //       {
  //         responseType: "blob",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     const link = document.createElement("a");
  //     link.href = pdfUrl;
  //     link.download = "collection-report.pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     // Clean up URL object after download
  //     URL.revokeObjectURL(pdfUrl);
  //   } catch (error) {
  //     console.error("Error generating PDF", error);
  //   }
  // };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-collection-report`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data;
      if (data) {
        setUserDetails(data?.userDetails);

        if (data?.userDetails) {
          const userDetailsData = data?.userDetails?.reduce((acc, user) => {
            // Calculate net amount based on user role and tests/packages
            const netAmount =
              user?.user_payment?.net_amount ||
              (user?.user?.role_id === 5
                ? user?.user?.tests
                    ?.map((test) => test.investigation?.discounted_price || 0)
                    ?.reduce((sum, price) => sum + price, 0)
                : user?.package?.discounted_price);

            // Calculate the diff between netAmount and inputValue
            const diff =
              parseFloat(netAmount || 0) -
              parseFloat(user?.user_payment?.paid_amount || 0);

            // Set due and commission based on the diff calculation
            const due =
              user?.user_payment?.paid > 0
                ? user?.user_payment?.due
                : diff > 0
                  ? diff
                  : 0;

            // Construct the new user object with the updated payment details
            const updatedUser = {
              ...user,
              user_payment: {
                ...user.user_payment,
                net_amount:
                  !!user?.user_payment?.net_amount &&
                  user?.user_payment?.net_amount
                    ? user?.user_payment?.net_amount
                    : user?.user_payment?.paid_amount <= 0
                      ? 0
                      : netAmount,
                due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
                due: due,
                commission:
                  user?.user_payment?.commission ||
                  (diff <= 0 ? Math.abs(diff) : 0),
              },
            };

            // Only add the user to the accumulator if the due is not zero
            if (queryData?.viewOptions === "all") {
              acc.push(updatedUser);
            } else if (queryData?.viewOptions === "due") {
              if (updatedUser?.user_payment?.due > 0) {
                acc.push(updatedUser);
              }
            }

            return acc;
          }, []); 
          const sortData = userDetailsData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/collection-report-all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        const userDetailsData = data?.userDetails?.reduce((acc, user) => {
          // Calculate net amount based on user role and tests/packages
          const netAmount =
            user?.user_payment?.net_amount ||
            (user?.user?.role_id === 5
              ? user?.user?.tests
                  ?.map((test) => test.investigation?.discounted_price || 0)
                  ?.reduce((sum, price) => sum + price, 0)
              : user?.package?.discounted_price);

          // Calculate the diff between netAmount and inputValue
          const diff =
            parseFloat(netAmount || 0) -
            parseFloat(user?.user_payment?.paid_amount || 0);

          // Set due and commission based on the diff calculation
          const due =
            user?.user_payment?.paid > 0
              ? user?.user_payment?.due
              : diff > 0
                ? diff
                : 0;

          // Construct the new user object with the updated payment details
          const updatedUser = {
            ...user,
            user_payment: {
              ...user.user_payment,
              net_amount:
                !!user?.user_payment?.net_amount &&
                user?.user_payment?.net_amount
                  ? user?.user_payment?.net_amount
                  : user?.user_payment?.paid_amount <= 0
                    ? 0
                    : netAmount,
              due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
              due: due,
              commission:
                user?.user_payment?.commission ||
                (diff <= 0 ? Math.abs(diff) : 0),
            },
          };

          // Only add the user to the accumulator if the due is not zero
          if (queryData?.viewOptions === "all") {
            acc.push(updatedUser);
          } else if (queryData?.viewOptions === "due") {
            if (updatedUser?.user_payment?.due > 0) {
              acc.push(updatedUser);
            }
          }

          return acc;
        }, []); 
        if (data?.userDetails) {
          const sortData = userDetailsData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );

          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;

          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.reg_prefix || ""}
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Passenger</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          const representative_Mob =
            info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
              -2,
            );
          return (
            <div>
              <span className="d-flex justify-content-start">
                {representative_Mob ? (
                  <span className="text-center d-flex justify-content-start">
                    {value1} - ({representative_Mob})
                  </span>
                ) : (
                  <span className="text-center d-flex justify-content-start">
                    {value1}
                  </span>
                )}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Country
          </span>
        ),
        cell: (info) => {
          const country = info?.row?.original?.country?.name;
          return <span>{country}</span>;
        },
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Test/Ref By</span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original?.user;

          // Check if role_id is 5
          if (user?.role_id === 5) {
            // Get the tests array from the user
            const tests = user.tests || [];
            let investigation31Added = false;

            const investigationNames = tests
              .filter((test) => {
                if (test?.investigation?.id === 31) {
                  if (investigation31Added) {
                    return false;
                  }
                  investigation31Added = true;
                  return true;
                }
                return true;
              })
              .map((test) => test.investigation?.name)
              .filter(Boolean)
              .join(", ");
            return (
              <div className="d-flex justify-content-start">
                {investigationNames || "No Investigations"}
              </div>
            );
          } else {
            // If role_id is not 5, render the default value
            const reversedIndex = userDetails.length - index - 1;
            return (
              <div className="d-flex justify-content-start">
                {info.renderValue(reversedIndex)}
              </div>
            );
          }
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Received
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <span
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {row?.user_payment?.paid_amount || 0}
            </span>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Net Amount
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <span
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {row?.user_payment?.net_amount || 0}
            </span>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Balance
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <span
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {row?.user_payment?.commission || 0}
            </span>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Dues
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <span
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {row?.user_payment?.due || 0}
            </span>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Paid
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <span
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {row?.user_payment?.paid || 0}
            </span>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Remarks
          </span>
        ),
        cell: (info) => {
          return <span className="py-5"> </span>;
        },
      }),
    ],
    [receivedAmounts],
  );

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (selectedRows) => {
      setRowSelection(selectedRows);
    },
  });

  useEffect(() => {
    if (queryData?.fromDate) {
      handlerOnSearch();
    }
    if (!queryData?.fromDate) {
      getUserDetails();
    }
  }, [queryData?.fromDate]);

  useEffect(() => {
    // Calculate total dues
    const total = userDetails.reduce((sum, row) => {
      const dues = row.user_payment?.due || 0;
      return sum + dues;
    }, 0);
    setDues(total);
  }, [userDetails]);

  useEffect(() => {
    // Calculate total commission
    const total = userDetails.reduce((sum, row) => {
      const commission = row.user_payment?.commission || 0;
      return sum + commission;
    }, 0);
    setCommission(total);
  }, [userDetails]);

  useEffect(() => {
    const total = userDetails.reduce((sum, row) => {
      const receivedAmount = row.user_payment?.paid_amount || 0;
      return sum + receivedAmount;
    }, 0);
    setReceived(total);
  }, [userDetails, receivedAmounts]); // Dependencies

  useEffect(() => {
    const total = userDetails.reduce((sum, row) => {
      const paid = row.user_payment?.paid || 0;
      return sum + paid;
    }, 0);
    setPaid(total);
  }, [userDetails]);

  useEffect(() => {
    const total = userDetails.reduce((sum, row) => {
      const net_total = row.user_payment?.net_amount || 0;
      return sum + net_total;
    }, 0);
    setNetTotal(total);
  }, [userDetails]);

  useEffect(() => {
    if (!loading && userDetails?.length > 0) {
      setTimeout(() => {
        handlePDF();
      }, 3000);
    }
  }, [loading, userDetails]);
  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded d-flex align-items-start justify-content-between">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Collection Report Print
                </h6>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="d-flex align-items-center gap-3 justify-content-end w-100 mt-0">
                    <button
                      className="btn bg-danger btn-lg px-5 text-white"
                      onClick={handlePDF}
                    >
                      Print
                    </button>
                    <button
                      onClick={() => window.close()}
                      className="btn btn-secondary btn-lg px-5"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div ref={pdfRef}>
                <table
                  className="table align-middle table-hover table-bordered mb-0 rounded"
                  style={{ fontSize: "12px" }}
                >
                  <thead className="bg-primary">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, i) => (
                          <th
                            key={i}
                            id={`th-${i}`}
                            className="text-light text-dark p-1"
                            style={{
                              backgroundColor: "#B2BEB5",
                              color: "black",
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, i) => {
                      const {
                        original: {
                          user: {
                            status,
                            is_radiology_done,
                            is_pathology_done,
                            is_physical_done,
                          },
                        },
                      } = row;
                      const collection =
                        row?.original?.user?.user_collections?.map(
                          (collection) =>
                            collection.investigation_category
                              ?.investigation_group_id,
                        );

                      const uniqueCollection = [...new Set(collection)];
                      const done =
                        (uniqueCollection?.length < 2 &&
                          (is_pathology_done ||
                            is_physical_done ||
                            is_radiology_done)) ||
                        (uniqueCollection?.length >= 2 &&
                          uniqueCollection?.length < 3 &&
                          (is_pathology_done ? 1 : 0) +
                            (is_physical_done ? 1 : 0) +
                            (is_radiology_done ? 1 : 0) >=
                            2) ||
                        (uniqueCollection?.length >= 3 &&
                          is_pathology_done &&
                          is_physical_done &&
                          is_radiology_done);

                      return (
                        <tr
                          key={i}
                          className={`${
                            done && status === 4 && "text-primary"
                          } ${done && status === 2 && "text-success"} ${
                            done && status === 3 && "text-danger"
                          } ${status === 1 && "text-dark"} 
                 `}
                        >
                          {row.getVisibleCells().map((cell, i) => (
                            <td key={i} className="p-1">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="p-1">Total</td>
                      <td className="p-1" style={{ textAlign: "end" }}>
                        {received}
                      </td>
                      <td className="p-1" style={{ textAlign: "end" }}>
                        {netTotal}
                      </td>
                      <td className="p-1" style={{ textAlign: "end" }}>
                        {commission}
                      </td>
                      <td className="p-1" style={{ textAlign: "end" }}>
                        {dues}
                      </td>
                      <td className="p-1" style={{ textAlign: "end" }}>
                        {paid}
                      </td>
                      <td className="p-1" style={{ textAlign: "end" }}></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionReportPrint;

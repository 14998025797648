import toast from "react-hot-toast";
// import codeMessage from "./codeMessage";

const successHandler = (
  response,
  // eslint-disable-next-line default-param-last
  options = { notifyOnSuccess: false, notifyOnFailed: true },
  postFunc,
  // eslint-disable-next-line consistent-return
) => {
  const { data } = response;

  if (
    // (data && data.status === "OK") ||
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    const message = response.data && data.message;
    // const successText = message || codeMessage[response.status];
    if (options.notifyOnSuccess) {
      if (postFunc) {
        postFunc();
      }
      if (typeof data.data === "string") {
        return toast.success(data.data);
      }
      // return toast.success(successText);
    }
  } else {
    // const message = response.data && data.message;
    // const errorText = message || codeMessage[response.status];
    const { status } = response;
    if (options.notifyOnFailed) {
      return toast.error(`Request error ${status}`);
    }
  }
};

export default successHandler;

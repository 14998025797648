import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

const OtpVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mobile } = state;

  console.log(mobile);

  const handlerOnSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const otpCode = parseInt(form.otp.value);
    console.log(otpCode);

    methodOtpVerification(otpCode);
  };

  const methodOtpVerification = async (otpCode) => {
    const verifyOtp = {
      mobile: mobile,
      otp: otpCode,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/user/otp-verify`,
      verifyOtp
    );

    if (res.status === 200) {
      console.log(mobile);
      navigate("/reset-password", { state: { mobile } });
    } else {
      toast.error("Authentication Error!!...OTP doesn't match");
    }
  };

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw", // Ensure the container covers the full viewport width
    },
    backgroundImage: {
      position: "fixed", // Fix the background image in place
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed", // Ensure background stays fixed
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <>
      {/* Oto Verification Page Container */}
      <div className="wrapper" style={myStyle.backgroundImage}>
        {/* <!--start content--> */}
        <main
          className="h-100 d-flex align-items-center justify-content-center mx-auto"
          style={myStyle.container}
        >
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card index-card shadow rounded-0 overflow-hidden">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title text-center">
                        Verify Your Phone Number
                      </h5>
                      <p className="card-text text-center mb-5">
                        Enter the verification code we sent to your mobile{" "}
                        {/* {mobile} */}
                      </p>
                      <form
                        className="form-body"
                        onSubmit={(e) => handlerOnSubmit(e)}
                      >
                        <div className="row gap-3">
                          <div className="col-12">
                            <label
                              htmlFor="inputEmailid"
                              className="form-label"
                            >
                              Verification Code
                            </label>
                            <input
                              type="text"
                              name="otp"
                              minLength={"6"}
                              className="form-control form-control-lg radius-30"
                              id="inputEmailid"
                              placeholder="Enter 6 digit code"
                            />
                          </div>
                          <div className="col-12">
                            <div className="d-grid gap-3">
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary radius-30"
                                style={{
                                  backgroundColor: "#14977e",
                                  border: "none",
                                  color: "white",
                                }}
                              >
                                Verify
                              </button>
                              <Link
                                to="/signin"
                                className="btn btn-lg btn-secondary radius-30"
                              >
                                Back to Login
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default OtpVerification;

import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { format, differenceInDays } from "date-fns";
import VirtualizedMenuList from "../../../components/VirtualMenuList/VirtualMenuList";
import Select from "react-select";
import { UserContext } from "../../../Context/UserContextAPI";

const LeaveApplication = () => {
  const { accessPerm } = useContext(UserContext);

  const leaveApplicationURL = `${process.env.REACT_APP_API_BASE_URL}/leave-application`;
  const [data, setData] = useState([]);
  const [totalDays, setTotalDays] = useState(0);
  const [leaveCategory, setLeaveCategory] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [balance, setBalance] = useState();
  const [leave, setLeave] = useState();
  const [phone, setPhone] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [employeeUser, setEmployeeUser] = useState();
  const [userId, setUserId] = useState();
  const [form, setForm] = useState({
    employeeName: "",
    user_id: userId,
    phone: phone,
    leaveCategory: "",
    year: new Date().getFullYear(),
    leaveTaken: leave,
    leaveBalance: balance,
    leaveFrom: "",
    leaveTo: "",
    status: "1",
  });

  const employeeOptions = [
    { value: "", label: "Select Employee" },
    ...(employeeUser?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
      phone: `${user?.mobile}`,
    })) || []),
  ];

  const createData = async (e) => {
    e.preventDefault();

    // Calculate totalDays
    const startDate = new Date(form.leaveFrom);
    const endDate = new Date(form.leaveTo);
    const daysDifference = differenceInDays(endDate, startDate);
    const totalDays = daysDifference;

    // Update balance and leave
    const updatedBalance = balance - totalDays;
    const updatedLeave = leave + totalDays;

    const updatedForm = {
      ...form,
      leaveTaken: updatedLeave,
      leaveBalance: updatedBalance,
      user_id: userId,
      phone: phone,
    };

    try {
      const res = await axios.post(leaveApplicationURL, updatedForm, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });

      if (res?.status === 201) {
        toast.success("Leave Application Created successfully!");
        fetchData();
      }
    } catch (error) {
      toast.error(error?.response?.data);
    }
  };

  const updateData = (e, id) => {
    e.preventDefault();
    // Calculate totalDays
    const startDate = new Date(form.leaveFrom);
    const endDate = new Date(form.leaveTo);
    const daysDifference = differenceInDays(endDate, startDate);
    const totalDays = daysDifference;

    // Update balance and leave
    const updatedBalance = parseInt(balance) + parseInt(leave) - totalDays;
    const updatedLeave = totalDays;
    const updatedForm = {
      ...form,
      employeeName: form?.employeeName || data?.employeeName,
      phone: form?.phone || data?.phone,
      leaveCategory: form?.leaveCategory || data?.leaveCategory,
      year: form?.year || data?.year,
      leaveTaken: updatedLeave,
      leaveBalance: updatedBalance,
      leaveFrom: form?.leaveFrom || data?.leaveFrom,
      leaveTo: form?.leaveTo || data?.leaveTo,
      status: isChecked ? "1" : "0",
    };
    const res = axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/leave-application/${id},`,
        updatedForm,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        fetchData();
        toast.success("Leave Application Updated successfully!!");
      })
      .catch((err) => {
        toast.error(err);
      });
    console.log(res);
  };

  const handlerEdit = (data) => {
    setForm({
      employeeName: form?.employeeName || data?.employeeName,
      phone: form?.phone || data?.phone,
      leaveCategory: form?.leaveCategory || data?.leaveCategory,
      year: form?.year || data?.year,
      leaveTaken: form?.leaveTaken || data?.leaveTaken,
      leaveBalance: form?.leaveBalance || data?.leaveBalance,
      leaveFrom: form?.leaveFrom || data?.leaveFrom,
      leaveTo: form?.leaveTo || data?.leaveTo,
      status: data?.status ? "1" : "0",
    });
    setIsChecked(data?.status === 1);
  };

  const deleteData = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/leave-application/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        fetchData();
        toast.success("Leave Application Deleted successfully!!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSelect = async (e) => {
    if (e.target && e.target.value !== undefined) {
      setForm((prevForm) => ({
        ...prevForm,
        leaveCategory: e.target.value,
      }));
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/leave-application/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        console.log(response?.data?.data);
        const leaveData = response?.data?.data;
        const filteredLeaves = leaveData?.filter(
          (leaveObject) => leaveObject?.leaveCategory === e?.target?.value
        );
        const leaveBalance = leaveCategory?.find(
          (category) => category?.leaveCategoryName === e?.target?.value
        )?.totalLeave;
        const totalLeavesTaken = filteredLeaves?.reduce(
          (accumulator, leaveObject) =>
            accumulator + parseFloat(leaveObject?.leaveTaken),
          0
        );
        console.log("leaveBalance", leaveBalance);
        console.log("totalLeavesTaken", totalLeavesTaken);
        const netBalance = leaveBalance - totalLeavesTaken;
        setBalance(netBalance);
        setLeave(totalLeavesTaken);
      })
      .catch((error) => console.log(error));
  };

  const onchange = async (e) => {
    if (e?.label) {
      setForm((prevForm) => ({
        ...prevForm,
        employeeName: e?.label,
      }));
    }
    if (e.target && e.target.value !== undefined) {
      setForm((prevForm) => ({
        ...prevForm,
        [e.target.name]: e.target.value,
      }));
    }

    if (e.label && e.phone !== undefined && e.value) {
      setSelectedValues(e.label);
      setPhone(e.phone);
      setUserId(e.value);
    }
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
    setForm((prevForm) => ({
      ...prevForm,
      status: !isChecked ? "1" : "0",
    }));
  };

  const fetchLeaveCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/leave-category`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setLeaveCategory(res?.data?.data);
      console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployeeUserAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/employee`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setEmployeeUser(data);
      console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = () => {
    axios
      .get(`${leaveApplicationURL}/all`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setData(response?.data?.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    fetchLeaveCategory();
    fetchEmployeeUserAPI();
  }, [form]);

  useEffect(() => {
    if (form?.leaveFrom && form?.leaveTo) {
      const startDate = new Date(form.leaveFrom);
      const endDate = new Date(form.leaveTo);
      const daysDifference = differenceInDays(endDate, startDate);
      setTotalDays(daysDifference);
    }
  }, [form]);

  const columns = [
    {
      key: "id",
      text: "SL",
      align: "center",
      cell: (data, index) => {
        return <>{index + 1}</>;
      },
      sortable: true,
    },
    {
      key: "employeeName",
      text: "Employee Name",
      align: "center",
      sortable: true,
    },
    {
      key: "phone",
      text: "Phone Number",
      align: "center",
      sortable: true,
    },
    {
      key: "leaveCategory",
      text: "Leave Category",
      align: "center",
      sortable: true,
    },
    {
      key: "year",
      text: "Year",
      className: "info",
      align: "center",
      sortable: true,
    },
    {
      key: "leaveTaken",
      text: "Leave Taken",
      align: "center",
      sortable: true,
    },
    {
      key: "leaveBalance",
      text: "leave Balance",
      align: "center",
      sortable: true,
    },
    {
      key: "leaveFrom",
      text: "Leave From",
      align: "center",
      sortable: true,
      cell: (data) => {
        const formattedDate = format(new Date(data?.leaveFrom), "dd-MM-yyyy");
        return <>{formattedDate}</>;
      },
    },
    {
      key: "leaveTo",
      text: "Leave To",
      align: "center",
      sortable: true,
      cell: (data) => {
        const formattedDate = format(new Date(data?.leaveTo), "dd-MM-yyyy");
        return <>{formattedDate}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <>{data?.status === 1 ? "active" : "inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        console.log(data);
        return (
          <>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(69, 2) && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#editLeaveApplication-${data.id}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handlerEdit(data)}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}

              {/* Modal */}
              <div
                className="modal fade"
                id={`editLeaveApplication-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Leave Application</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={(e) => updateData(e, data?.id)}>
                      <div className="modal-body pb-0">
                        <div className="mb-3 row ">
                          <label
                            htmlFor="inputName"
                            className="col-sm-5 col-form-label d-flex justify-content-start"
                          >
                            Employee Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-12">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              name="employeeName"
                              className="fw-light text-start"
                              options={employeeOptions}
                              isSearchable={true}
                              onChange={onchange}
                              value={employeeOptions.find(
                                (option) => option.label === form.employeeName
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label
                            htmlFor="inputInfo"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Phone Number:
                          </label>
                          <div className="col-sm-9 d-flex align-items-center">
                            {phone}
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label
                            htmlFor="inputInfo"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Leave Category{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-12">
                            <select
                              onChange={handleSelect}
                              name="leaveCategory"
                              className="form-control"
                              id="inputInfo"
                              required
                              value={data?.leaveCategory}
                            >
                              <option value="">Select Type</option>
                              {leaveCategory?.map((category) => (
                                <option
                                  key={category?.id}
                                  value={category?.leaveCategoryName}
                                >
                                  {category?.leaveCategoryName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-6 d-flex">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-8 col-form-label d-flex justify-content-start"
                            >
                              Leave Balance(days):
                            </label>
                            <div className="col-sm-4 d-flex align-items-center text-start">
                              {balance === 0 ? 0 : balance}
                            </div>
                          </div>
                          <div className="col-sm-6 d-flex">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-8 col-form-label d-flex justify-content-start"
                            >
                              Leave Taken(days):
                            </label>
                            <div className="col-sm-4 text-start d-flex align-items-center">
                              {leave === 0 ? 0 : leave}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 row justify-content-between">
                          <div className="col-sm-12">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-12 col-form-label d-flex justify-content-start"
                            >
                              Leave From
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-12">
                              <input
                                onChange={onchange}
                                value={form?.leaveFrom?.split("T")[0]}
                                name="leaveFrom"
                                type="date"
                                className="form-control"
                                id="inputInfo"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-12">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-12 col-form-label d-flex justify-content-start"
                            >
                              Leave To
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-12">
                              <input
                                onChange={onchange}
                                value={form?.leaveTo?.split("T")[0]}
                                name="leaveTo"
                                type="date"
                                className="form-control"
                                id="inputInfo"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <table className="table fs-6">
                            <tr className="d-flex justify-content-end">
                              <td>Total: </td>
                              <td>{totalDays} days</td>
                            </tr>
                          </table>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-12">
                            <div className="form-check d-flex justify-content-end align-items-center">
                              <input
                                onChange={() => handleCheck()}
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                checked={isChecked}
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Update changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(69, 3) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#deleteLeaveApplication-${data.id}`}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}
              {/* Activity */}
              <div
                className="modal fade"
                id={`deleteLeaveApplication-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Leave Application</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3 row ">
                        <div className="col-sm-10">
                          <p>
                            Are you sure you want to delete this leave
                            application?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteData(data?.id)}
                        data-bs-dismiss="modal"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target={`#deleteLeaveApplication-${data?.id}`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      excel: false,
      print: true,
      extra: true,
    },
  };
  console.log(balance);
  console.log(phone);
  const extraButtons = [];
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Leave Application List</h6>
              <div className="col">
                {/* Button trigger modal */}
                {accessPerm(69, 1) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleVerticallycenteredActivity"
                    onClick={isChecked}
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </button>
                )}

                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleVerticallycenteredActivity"
                  tabIndex={-1}
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <form
                      className="modal-content"
                      onSubmit={(e) => createData(e)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            Add New Leave Application
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-5 col-form-label d-flex justify-content-start"
                            >
                              Employee Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-12">
                              <Select
                                components={{ MenuList: VirtualizedMenuList }}
                                name="employeeName"
                                className="fw-light text-start"
                                options={employeeOptions}
                                isSearchable={true}
                                onChange={onchange}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row ">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-3 col-form-label d-flex justify-content-start"
                            >
                              Phone Number:
                            </label>
                            <div className="col-sm-9 d-flex align-items-center">
                              {phone}
                            </div>
                          </div>
                          <div className="mb-3 row ">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-3 col-form-label d-flex justify-content-start"
                            >
                              Leave Category{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-12">
                              <select
                                onChange={handleSelect}
                                name="leaveCategory"
                                className="form-control"
                                id="inputInfo"
                                required
                              >
                                <option value="">Select Type</option>
                                {leaveCategory?.map((category) => (
                                  <option
                                    key={category?.id}
                                    value={category?.leaveCategoryName}
                                  >
                                    {category?.leaveCategoryName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-6 d-flex">
                              <label
                                htmlFor="inputInfo"
                                className="col-sm-8 col-form-label d-flex justify-content-start"
                              >
                                Leave Balance(days):
                              </label>
                              <div className="col-sm-4 d-flex align-items-center text-start">
                                {balance === 0 ? 0 : balance}
                              </div>
                            </div>
                            <div className="col-sm-6 d-flex">
                              <label
                                htmlFor="inputInfo"
                                className="col-sm-8 col-form-label d-flex justify-content-start"
                              >
                                Leave Taken(days):
                              </label>
                              <div className="col-sm-4 text-start d-flex align-items-center">
                                {leave === 0 ? 0 : leave}
                              </div>
                            </div>
                          </div>

                          <div className="mb-3 row justify-content-between">
                            <div className="col-sm-12">
                              <label
                                htmlFor="inputInfo"
                                className="col-sm-12 col-form-label d-flex justify-content-start"
                              >
                                Leave From
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  onChange={onchange}
                                  name="leaveFrom"
                                  type="date"
                                  className="form-control"
                                  id="inputInfo"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-12">
                              <label
                                htmlFor="inputInfo"
                                className="col-sm-12 col-form-label d-flex justify-content-start"
                              >
                                Leave To
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  onChange={onchange}
                                  name="leaveTo"
                                  type="date"
                                  className="form-control"
                                  id="inputInfo"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <table className="table fs-6">
                              <tr className="d-flex justify-content-end">
                                <td>Total: </td>
                                <td>{totalDays} days</td>
                              </tr>
                            </table>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-12">
                              <div className="form-check d-flex justify-content-end align-items-center">
                                <input
                                  onChange={() => handleCheck()}
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  checked={isChecked}
                                  id="flexCheckChecked"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={data}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApplication;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import XrayImage from "../XrayImage/XrayImage";
import PDFPrescriptionModal from "./PrescriptionModal/PDFPrescriptionModal";
import axios from "axios";
import VerificationReport from "./Verification/VerificationReport";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";
import "./Verification.scss";
import formatDate from "../../utils/formatDate";
import BarCodeInInvoice from "../Investigation/Invoice/components/BarCodesInInvoice";
import UserInfoTable from "../Investigation/Report/Certificates/Components/UserInfoTable";
import SignatureInReport from "../Investigation/Report/Certificates/Components/SignatureInReport";
import calculateAge from "../../utils/calculateAge";

const Verification = ({ children }) => {
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;
  const { settingsData } = useContext(UserContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [prescriptionData, setPrescriptionData] = useState([]);
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${state?.user_id}`;
  const formattedBirthDate = state?.user?.date_of_birth
    ? new Date(state?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const registrationNo = state?.user?.registration_no;

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return "";
    }

    const days = Math.floor(hours / 24);

    date.setDate(date.getDate() + days);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const receivingDate = state?.user_payment?.createdAt;
  const preparationDuration =
    state?.user?.role_id !== 5
      ? state?.package?.preparation_duration ?? 0
      : state?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const handlePrescriptionPrint = (e) => {
    navigate("/medical-verification/result/prescription", { state });
    setTimeout(() => {
      window.print();
    }, 1200);
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(
        `${prescriptionURL}/${state?.user_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const prescriptionData = prescriptionResponse.data.data;

      setPrescriptionData(prescriptionData);
    } catch (error) {
      console.error(error);
    }
  };

  const collection = state?.user?.user_collections?.map(
    (collection) => collection.investigation_category?.investigation_group_id
  );
  const uniqueCollection = [...new Set(collection)];
  useEffect(() => {
    fetchPatientInfo();
  }, []);
  return (
    <div className="verification-test">
      {(uniqueCollection?.length < 2 &&
        (state?.user?.is_pathology_done ||
          state?.user?.is_physical_done ||
          state?.user?.is_radiology_done)) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (state?.user?.is_physical_done ? 1 : 0) +
          (state?.user?.is_pathology_done ? 1 : 0) +
          (state?.user?.is_radiology_done ? 1 : 0) >=
          2) ||
        (uniqueCollection?.length >= 3 &&
          state?.user?.is_pathology_done &&
          state?.user?.is_physical_done &&
          state?.user?.is_radiology_done) ? (
        <section className="overflow-hidden">
          {(uniqueCollection?.length < 2 &&
            (state?.user?.is_pathology_done ||
              state?.user?.is_physical_done ||
              state?.user?.is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (state?.user?.is_physical_done ? 1 : 0) +
              (state?.user?.is_pathology_done ? 1 : 0) +
              (state?.user?.is_radiology_done ? 1 : 0) >=
              2) ||
            (uniqueCollection?.length >= 3 &&
              state?.user?.is_pathology_done &&
              state?.user?.is_physical_done &&
              state?.user?.is_radiology_done) ? (
            <VerificationReport />
          ) : (
            <div className=""></div>
          )}
          {(uniqueCollection?.length < 2 &&
            (state?.user?.is_pathology_done ||
              state?.user?.is_physical_done ||
              state?.user?.is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (state?.user?.is_physical_done ? 1 : 0) +
              (state?.user?.is_pathology_done ? 1 : 0) +
              (state?.user?.is_radiology_done ? 1 : 0) >=
              2) ||
            (uniqueCollection?.length >= 3 &&
              state?.user?.is_pathology_done &&
              state?.user?.is_physical_done &&
              state?.user?.is_radiology_done) ? (
            <>
              {state?.user?.status === 4 && prescriptionData?.user_id && (
                <div
                  className="pres"
                // style={{
                //   pageBreakBefore: "always",
                // }}
                >
                  <PDFPrescriptionModal data={state} />
                </div>
              )}
              <style jsx>{`
                @media print {
                  @page {
                    margin: 0;
                  }

                  body {
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                  }

                  .pres {
                    width: 100%;
                    overflow: visible;
                  }
                }
              `}</style>
            </>
          ) : (
            <div></div>
          )}
          {(uniqueCollection?.length < 2 &&
            (state?.user?.is_pathology_done ||
              state?.user?.is_physical_done ||
              state?.user?.is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (state?.user?.is_physical_done ? 1 : 0) +
              (state?.user?.is_pathology_done ? 1 : 0) +
              (state?.user?.is_radiology_done ? 1 : 0) >=
              2) ||
            (uniqueCollection?.length >= 3 &&
              state?.user?.is_pathology_done &&
              state?.user?.is_physical_done &&
              state?.user?.is_radiology_done) ? (
            <div className="p-0 m-0" style={{ pageBreakBefore: "always" }}>
              <XrayImage verification={1} />
            </div>
          ) : (
            <div></div>
          )}
        </section>
      ) : (
        <div
          className="container w-100 "
          style={{ height: "9vh", }}
        >
          <div className="row" style={{ marginTop: '180px' }}>
            <div
              style={{ color: "black" }}
              className="d-flex justify-content-between fw-bolder"
            >
              <div>
                <p className="">
                  Reg No : {state?.user?.reg_prefix}
                  {state?.user?.registration_no}{" "}
                </p>
              </div>
              <div>
                <p className="">
                  Reg Date :{" "}
                  {formatDate(state?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
                </p>
              </div>
            </div>
            <BarCodeInInvoice userDetail={state}
              age={calculateAge(state?.user?.dateOfBirth)}

            />
            <UserInfoTable
              user={state?.user}
              userDetails={state}
              age={calculateAge(state?.user?.dateOfBirth)}
            // physicalData={physicalData}
            />
          </div>

          <div className="row mt-2">
            <div
              className="col-12 d-flex align-items-center border border-dark"
              style={{ height: "10vh" }}
            >
              <h4
                className="text-center fw-bold text-uppercase w-100 fs-5"
                style={{
                  color: "red",
                  fontSize: "22px",
                }}
              >
                Your Report is Under Processing...
              </h4>
            </div>
          </div>
          <div style={{ position: "absolute", bottom: 2, width: "90%" }} >
            <SignatureInReport
              urlWithBase64ID={urlWithBase64ID}
              // hasRadiology={
              //   Object.entries(groupedData)?.length > 0 || radiologyData?.length > 0
              // }
              // hasPathology={Object.entries(groupedPathologyData)?.length > 0}
              user={state?.user}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Verification;

import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useParams } from "react-router-dom";
import "./MedicalXray.scss";
import Loading2 from "../../../../utils/Loader/Loading2";
import LazyLoad from "react-lazyload";
import XrayEditor from "./XrayEditor";
import { UserContext } from "../../../../Context/UserContextAPI";
import useImage from "use-image";

const MedicalXrayCopy = () => {
  const { accessPerm, settingsData } = useContext(UserContext);
  const { userId } = useParams();
  const { state } = useLocation();
  const patientData = state?.record;
  const [xrayImage, setXrayImage] = useState();
  const [xrayLoading, setXrayLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [invTestsData, setInvTestsData] = useState([]);
  const [suggestionData, setSuggestionData] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchingId, setSearchingId] = useState("");
  const [riskFactor, setRiskFactor] = useState("");
  const [loadingState, setLoading] = useState(false);
  const [changeColor, setChangeColor] = useState();
  const [xrayFindings, setXrayFindings] = useState();

  const stageRef = useRef();
  const detailsRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get("data");
  const fetchInvTestData = async (e, invTest) => {
    const dataToSend = {
      test_id: invTest?.id,
      info_value: e.target.value.trim(),
    };
    if (dataToSend.test_id) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/investigation-tests-data`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (response) {
        setInvTestsData(response?.data);
        setShowSearchResult(true);
      }
    }
  };

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [radiologyReportData, setRadiologyReportData] = useState([]);
  const [fit, setFit] = useState(0);
  const [unfit, setUnfit] = useState(0);
  const [heldUp, setHeldUp] = useState(0);
  const [radiologyComment, setRadiologyComment] = useState({
    user_id: userId,
    comments: "",
    status: "",
    investigation_group_id: 2,
  });
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState({
    user_id: patientData?.user_id,
    comments: "",
    status: statusValue,
    investigation_group_id: 100,
  });

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const formattedBirthDate = user?.user?.date_of_birth
    ? new Date(user?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  // Xrey Upload---------------------------
  const xrayImageUpload = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/upload-base64-xray-file`,
        { userXrayFile: stageRef.current.toDataURL() },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const xrayImagePath = res.data;

      setRadiologyReportData((previousReport) => {
        const updatedReport = previousReport.map((item) => {
          if (item.investigation_id === 31 && item.test_id === 1) {
            item.user_file = xrayImagePath;
          }
          return item;
        });
        return updatedReport?.sort((a, b) => a.test_id - b.test_id);
      });

      // Directly update the X-ray image state
      setXrayImage(xrayImagePath);

      return xrayImagePath;
    } catch (error) {
      console.log(error);
    }
  };

  const xrayImageRestore = async () => {
    setLoading(true);
    setXrayImage(user?.user?.registration_no + ".JPG");
    setRiskFactor("");
    await new Promise((resolve) => setTimeout(resolve, 100));

    setXrayLoading(false);
    setLoading(false);
  };

  const handleCopy = (index, investigation) => {
    setChangeColor(null);

    const selectedValue = radiologyReportData[index]?.result?.replace(
      /(<([^>]+)>)/gi,
      "",
    );

    setRadiologyReportData((previousReport) => {
      previousReport[previousReport.length - 1].result = selectedValue;
      return [...previousReport];
    });

    setSuggestionData((previousData) => {
      const updatedData = [...previousData];
      const testId = investigation?.id;

      updatedData[index] = {
        ...updatedData[index],
        test_id: testId,
        info_value: selectedValue,
      };

      return updatedData;
    });
  };

  const handleSelect = (value, index) => {
    setChangeColor("text-dark");
    setRadiologyReportData((previousReport) => {
      previousReport[index].result = value;
      return [...previousReport];
    });

    setSearchingId("");
  };

  const handleNormalValue = async () => {
    setChangeColor("text-dark");
    if (investigations?.investigation_tests) {
      setRadiologyReportData((previousReport) => {
        const updatedReport = previousReport?.map((item, index) => {
          const investigation = investigations?.investigation_tests[index];
          if (investigation) {
            item.result = investigation?.info?.replace(/<[^>]*>/g, "");
            if (investigation?.id === 35) {
              setXrayFindings({
                test_id: 35,
                info_value: investigation?.info?.replace(/<[^>]*>/g, "").trim(),
              });
            }
          }
          return item;
        });

        return updatedReport?.sort((a, b) => a.test_id - b.test_id);
      });
    }
  };

  const handleStatus = (event) => {
    const selectedStatus = Number(event.target.value); // Convert to a number
    setStatusValue(selectedStatus);

    // Ensure radiologyComment gets updated after statusValue is updated
    setRadiologyRiskFactor((previousComment) => ({
      ...previousComment,
      status: selectedStatus, // Use the updated statusValue directly here
    }));
  };

  async function fetchFindings(e, invTest) {
    const dataToSend = {
      test_id: invTest?.id,
      info_value: e.target.value.trim(),
    };
    setXrayFindings(dataToSend);
  }

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const comments = response?.data?.data || [];
      // setRadiologyComment((previousComment) => {
      //   return {
      //     ...previousComment,
      //     user_id: userId,
      //     status: comments[0]?.status,
      //     comments: comments[0]?.comments,
      //   };
      // });
    } catch (error) {
      console.log(error);
    }
  }

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const risk = response?.data?.data || [];
      setStatusValue(risk[0]?.status);
      // if (risk[0]?.comments === null) {
      //   setRiskFactor("");
      // } else {
      //   setRiskFactor(risk[0]?.comments);
      // }
      // setRadiologyRiskFactor((previousComment) => {
      //   return {
      //     ...previousComment,
      //     user_id: userId,
      //     comments: risk[0]?.comments,
      //   };
      // });
    } catch (error) {
      console.log(error);
    }
  }

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setUserDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/${data}`,
        {
          invIds:
            user?.user?.role_id === 5
              ? user?.investigation_id?.split(",")
              : user?.package?.investigation_id?.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setInvestigations(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    setChangeColor("text-success");

    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Update Report Data-----------------------------
  const saveReportData = async () => {
    setChangeColor("text-success");
    // await xrayImageUpload();

    try {
      await axios.put(
        `${baseUrl}/reports/radiology/${userId}`,
        radiologyReportData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      await saveRadiologyComment();
      setRadiologyReportData(radiologyReportData);
    } catch (error) {
      console.error(error);
    } finally {
      window.location.reload();
    }
  };

  // Update Comment--------------------------------
  const saveRadiologyComment = async () => {
    setChangeColor("text-success");
    let status;

    const result = await radiologyReportData?.find(
      (data) => data?.test_id === 35 && data?.investigation_id === 31,
    )?.result;
    if (result === "Normal CXR" || statusValue === 2) {
      setHeldUp(0);
      setUnfit(0);
      setFit(1); // Fit
      status = 2;
    } else if (result !== "Normal CXR" || statusValue === 4) {
      setHeldUp(1); // Heldup
      setUnfit(0);
      setFit(0);
      status = 4;
    } else if (statusValue === 3) {
      // Unfit if statusValue is 3
      setHeldUp(0);
      setUnfit(1);
      setFit(0);
      status = 3; // Unfit
    }

    const combinedData = {
      combinedObject: {
        radiologyComment: {
          ...radiologyComment,
          status: status,
        },
        radiologyRiskFactor: {
          ...radiologyRiskFactor,
          status: statusValue,
        },
      },
    };

    try {
      const res = await axios.post(
        `${baseUrl}/reports/save-comments`,
        combinedData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (res) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveRadiologyReport = async () => {
    if (![1, 2, 3, 4].includes(statusValue)) {
      return toast.error("Please select a status");
    }
    setLoading(true);
    setChangeColor("text-success");
    await xrayImageUpload();
    if (suggestionData) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reports/investigation-tests-data/save`,
          suggestionData,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      } catch (error) {
        console.log(error);
      }
    }

    try {
      await saveReportData();
      toast.success("Radiology saved successfully.");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveFilm = async () => {
    setLoading(true);
    setChangeColor("text-success");
    await xrayImageUpload();
    if (suggestionData) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reports/investigation-tests-data/save`,
          suggestionData,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      } catch (error) {
        console.log(error);
      }
    }
    try {
      await saveReportData();
      toast.success("Film saved successfully.");
    } catch (error) {
      toast.error("Failed to save film.");
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (event, index) => {
    const updatedInvestigations = { ...investigations };

    if (updatedInvestigations.investigation_tests) {
      updatedInvestigations.investigation_tests[index] = {
        ...updatedInvestigations.investigation_tests[index],
        info_value: event.target.value.trim(),
      };
    }

    // Create a new array with updated info_values and test_ids
    const updatedSuggestionData = updatedInvestigations.investigation_tests.map(
      (item, i) => ({
        test_id: item?.id,
        info_value: i === index ? event.target.value.trim() : item?.info_value,
      }),
    );

    setSuggestionData(updatedSuggestionData);

    setInputValue(event.target.value);
    setChangeColor("text-dark");

    setRadiologyReportData((previousReport) => {
      const updatedReport = [...previousReport];
      updatedReport[index] = {
        ...updatedReport[index],
        result: event.target.value,
      };
      return updatedReport;
    });

    setSearchingId(index);
    fetchInvTestData(event);
  };
  console.log("suggestionData", suggestionData);

  const [image, status] = useImage(
    `${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`,
    "anonymous",
    "origin",
  );

  useEffect(() => {
    if (radiologyTests.length <= 0) {
      setChangeColor("text-success");
      investigations?.investigation_tests?.forEach((investigation) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: investigation.investigation_id,
              test_id: investigation.id,
              user_file: "",
              result: "",
            },
          ];
          return [...previousReport];
        });
      });
    }
  }, [investigations, userId]);

  useEffect(() => {
    setLoading(true);
    setRadiologyReportData([]);

    if (radiologyTests?.length > 0) {
      setChangeColor("text-success");

      const newRadiologyReportData = [];

      radiologyTests.forEach((radiologyTest) => {
        const newEntry = {
          user_id: userId,
          investigation_id: radiologyTest.investigation_id || "",
          test_id: radiologyTest.test_id || "",
          user_file: radiologyTest?.user_file,
          result: radiologyTest.result?.replace(/<[^>]*>/g, "") || "",
        };

        // Check for duplicates before adding
        const duplicate = newRadiologyReportData.find(
          (entry) =>
            entry.test_id === newEntry.test_id &&
            entry.investigation_id === newEntry.investigation_id,
        );

        if (!duplicate) {
          newRadiologyReportData.push(newEntry);
        }

        if (radiologyTest.investigation_id && radiologyTest.test_id === 1) {
          setXrayImage(
            (previousImage) => (previousImage = radiologyTest.user_file),
          );
          setXrayLoading(false);
        }
      });
      console.log(newRadiologyReportData);
      setRadiologyReportData(
        newRadiologyReportData?.sort((a, b) => a.test_id - b.test_id),
      );
    }

    setLoading(false);
  }, [userId, radiologyTests]);

  // Get user, Introducer, and Representative-----------
  const [representative, setRepresentative] = useState();
  useEffect(() => {
    userDetail && setUser(userDetail?.userDetail);
    if (riskFactor === undefined) {
      setRiskFactor("");
    }

    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userDetail, userDetail?.nextPage, userDetail?.previousPage, riskFactor]);

  useEffect(() => {
    getComments();
    getUserDetail();
    getRadiologyTests();
  }, [userId]);

  useEffect(() => {
    userId && getRiskFactor();
    // if (riskFactor === undefined) {
    //   setRiskFactor("");
    // }
  }, [userDetail, userDetail?.nextPage, userDetail?.previousPage]);

  useEffect(() => {
    getRiskFactor();
  }, [userId]);

  // useEffect(() => {
  //   setRadiologyComment((previousComment) => {
  //     return {
  //       ...previousComment,
  //       user_id: userId,
  //       comments: radiologyComment,
  //       status: statusValue,
  //     };
  //   });
  // }, [userId]);

  useEffect(() => {
    setRadiologyRiskFactor((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: riskFactor,
      };
    });
  }, [userId]);

  useEffect(() => {
    user && getActiveInvestigationTests();
  }, [user]);

  // useEffect(() => {
  //   if (riskFactor >= 1 && riskFactor <= 70) {
  //     setRadiologyComment((previous) => ({ ...previous, status: 4 }));
  //   } else if (riskFactor === "") {
  //     setRadiologyComment((previous) => ({ ...previous, status: 2 }));
  //   } else {
  //     setRadiologyComment((previous) => ({ ...previous, status: 3 }));
  //   }
  // }, [radiologyReportData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        setSearchingId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [detailsRef, searchingId]);
  console.log(radiologyReportData);
  return (
    <>
      {loadingState || status === "loading" ? (
        <Loading2 />
      ) : (
        <div className="">
          <div
            className="card pt-3 px-3 medical-xray"
            style={{ paddingBottom: !xrayImage ? "70px" : "10px" }}
          >
            <div
              className="row mb-2 text-white px-2 py-2"
              style={{ backgroundColor: "#1D0CFF" }}
            >
              <h6 className="mb-0">
                Medical X-Ray Report for {user?.user.f_name} {user?.user.l_name}{" "}
                [{user?.user.reg_prefix || ""}
                {user?.user.registration_no}]
              </h6>
            </div>

            <div className="row justify-content-center h-100">
              <>
                <div className="col-sm-2">
                  <div
                    className="pres-photo border border-end-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.user?.image ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.image}`}
                        className="img-fluid"
                        alt="patient"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src="./dummy.jpg"
                        alt="patient"
                        className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    PHOTO
                  </p>
                </div>
                <div className="col-sm-8">
                  <div className="row h-100 text-dark">
                    <table
                      className="responsive-width1 table-bordered"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        textAlign: "left",
                      }}
                    >
                      <tbody>
                        {/* Row 1 - Name and Client ID */}
                        <tr>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Name
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.user?.f_name || "---"}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Client ID
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.user?.reg_prefix || ""}
                            {user?.user?.registration_no || "---"}
                          </td>
                        </tr>

                        {/* Row 2 - Gender and Passport No */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Gender
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.gender?.name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Passport No
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.user?.passport_no || "---"}
                          </td>
                        </tr>

                        {/* Row 3 - Date of Birth and Travelling To */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Date of Birth
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {formattedBirthDate || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Country
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.country?.name || "---"}
                          </td>
                        </tr>

                        {/* Row 4 - Father Name and Contact Number */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Father Name
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.father_name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Contact Number
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.user?.mobile || "---"}
                          </td>
                        </tr>

                        {/* Row 5 - Nationality and Agency */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Nationality
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.national_country?.nationality || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {settingsData?.introducer_label}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            colSpan={3}
                          >
                            {user?.introducer_user?.f_name || "---"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-2 h-100">
                  <div
                    className="pres-photo border border-start-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.user?.finger_print ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.finger_print}`}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      >
                        No Fingerprint Found
                      </div>
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    THUMB PRINT
                  </p>
                </div>
              </>
            </div>
            <div className="d-flex justify-content-between mt-1 gap-3">
              <div style={{ width: "600px" }}>
                <div className="bg-orange d-flex flex-column justify-content-between rounded-1 text-white position-relative w-100">
                  <div
                    className="d-flex p-3 flex-column"
                    style={{ gap: "16px" }}
                  >
                    {investigations?.investigation_tests?.map(
                      (investigation, index) => {
                        const matchedReport = radiologyReportData.find(
                          (report) => report.test_id === investigation?.id,
                        );
                        console.log(matchedReport);
                        return (
                          <div key={index}>
                            <div
                              className="d-flex flex-column align-items-start position-relative"
                              style={{ rowGap: "16px" }}
                            >
                              <label
                                htmlFor={`${investigation?.name?.toLowerCase()}`}
                                className="w-25 fs-6 fw-bold text-dark"
                              >
                                {investigation?.name}
                              </label>
                              <div className="input-group w-100">
                                <input
                                  type="text"
                                  name="info"
                                  autoComplete="off"
                                  id={`${investigation?.name?.toLowerCase()}`}
                                  value={matchedReport?.result || ""}
                                  onChange={(event) => {
                                    handleOnChange(event, index);
                                    fetchInvTestData(event, investigation);
                                    fetchFindings(event, investigation);
                                  }}
                                  className={`form-control w-75 fw-bold ${
                                    investigation?.name === "Findings" &&
                                    changeColor
                                      ? matchedReport?.result === "Normal CXR"
                                        ? "text-success"
                                        : "text-primary"
                                      : ""
                                  }`}
                                  required
                                />

                                <span
                                  className="input-group-text"
                                  id="addon-wrapping"
                                  style={{ width: "3rem", height: "3.4rem" }}
                                  onClick={() =>
                                    handleCopy(index, investigation)
                                  }
                                >
                                  <i
                                    className="fa-regular fa-copy px-1"
                                    style={{
                                      fontSize: "1.5rem",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </span>
                              </div>
                              {searchingId === index &&
                                invTestsData?.length > 0 && (
                                  <div
                                    className="position-absolute border-bottom top-100 bg-light text-dark w-100 z-10"
                                    style={{
                                      zIndex: "100",
                                      left: "0%",
                                      overflowY: "scroll",
                                      maxHeight: "410px",
                                      width: "100%",
                                    }}
                                    ref={detailsRef}
                                  >
                                    {invTestsData?.map((item) => (
                                      <div
                                        className="search-row"
                                        onClick={(event) =>
                                          handleSelect(item?.info, index)
                                        }
                                        style={{
                                          padding: "2px 12px",
                                          cursor: "pointer",
                                          width: "100%",
                                          color: "#000",
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {item?.info}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      },
                    )}
                    <div
                      className="d-flex flex-column align-items-start"
                      style={{ rowGap: "16px" }}
                    >
                      <label
                        htmlFor="risk-factor"
                        className="w-25 fs-6 fw-bold text-dark"
                      >
                        Status
                      </label>
                      <select
                        name="dropdown"
                        className="form-select w-100 fw-bold"
                        id="risk-factor"
                        onChange={(event) => handleStatus(event)}
                        defaultValue={statusValue}
                      >
                        <option value="" className="fw-bold">
                          Select Status
                        </option>
                        <option value={2} className="fw-bold">
                          FIT
                        </option>
                        <option value={3} className="fw-bold">
                          UNFIT
                        </option>
                        <option value={4} className="fw-bold">
                          HELDUP
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="py-1 mt-2 rounded-bottom d-flex justify-content-between gap-4">
                  <button
                    className="btn btn-success px-2 btn-sm fs-6 w-100"
                    onClick={saveFilm}
                  >
                    Save Film
                  </button>
                </div> */}
                <div className="py-0 mt-2 rounded-bottom w-100">
                  {/* <button
                    className="btn btn-sm btn-warning w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`#restoreXray`}
                  >
                    Make Status Processing
                  </button> */}
                  <div
                    className="modal fade"
                    id={`restoreXray`}
                    tabIndex={-1}
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title text-dark">
                            Restore X-Ray Film
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row ">
                            <div className="col-sm-10">
                              <p className="text-dark">
                                Are you sure you want to restore X-Ray film?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={xrayImageRestore}
                            data-bs-dismiss="modal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#restoreXray`}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="py-0 rounded-bottom"
                  // style={{ marginTop: "15px" }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                      {userDetail?.nextPage && (
                        <Link
                          to={`/dashboard/medical-reports/radiology/${userDetail?.nextPage}?data=2`}
                          className="btn btn-sm fs-6 px-3 py-2 text-white"
                          style={{
                            backgroundColor:
                              userDetail?.userDetail?.user?.status === 2
                                ? "#28a745" // bg-success
                                : userDetail?.userDetail?.user?.status === 3
                                  ? "#dc3545" // bg-danger
                                  : userDetail?.userDetail?.user?.status === 4
                                    ? "#0000FF" // bg-primary
                                    : "#6c757d", // default color
                          }}
                        >
                          Previous
                        </Link>
                      )}
                      {userDetail?.previousPage && (
                        <Link
                          to={`/dashboard/medical-reports/radiology/${userDetail?.previousPage}?data=2`}
                          className="btn btn-sm fs-6 px-3 py-2 text-white"
                          style={{
                            backgroundColor:
                              userDetail?.userDetail?.user?.status === 2
                                ? "#28a745" // bg-success
                                : userDetail?.userDetail?.user?.status === 3
                                  ? "#dc3545" // bg-danger
                                  : userDetail?.userDetail?.user?.status === 4
                                    ? "#0000FF" // bg-primary
                                    : "#6c757d", // default color
                          }}
                        >
                          Next
                        </Link>
                      )}
                    </div>

                    <div className="d-flex gap-2 justify-content-end align-items-center">
                      {accessPerm(33, 22) && (
                        <button
                          className="btn btn-primary px-2 btn-sm fs-6 w-100 p-2"
                          onClick={handleNormalValue}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Set Normal
                        </button>
                      )}
                      <button
                        className="btn btn-success px-2 btn-md fs-6 w-100 p-2"
                        onClick={saveRadiologyReport}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Save All
                      </button>

                      <button
                        className="btn btn-dark text-white btn-md fs-6 w-100 p-2"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                          window.close();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="w-auto d-flex justify-content-end h-100">
                  {!xrayImage ? (
                    <div
                      className="d-flex align-items-center justify-content-center h-100 fs-4  border border-5 rounded-1 text-dark"
                      style={{ width: "650px" }}
                    >
                      No X-ray Image Found
                    </div>
                  ) : (
                    <div>
                      <LazyLoad height={200} offset={100}>
                        {xrayLoading ? (
                          <Loading2 />
                        ) : (
                          <XrayEditor
                            xrayFilm={xrayImage}
                            stageRef={stageRef}
                            // riskFactor={riskFactor}
                            setRiskFactor={setRiskFactor}
                          />
                        )}
                      </LazyLoad>
                    </div>
                  )}
                </div>
                <div className="w-100">
                  <button
                    className="btn btn-danger btn-sm fs-6 w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`#restoreXray2`}
                  >
                    Restore Film
                  </button>
                  <div
                    className="modal fade"
                    id={`restoreXray2`}
                    tabIndex={-1}
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title text-dark">
                            Restore X-Ray Film
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row ">
                            <div className="col-sm-10">
                              <p className="text-dark">
                                Are you sure you want to restore X-Ray film?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={xrayImageRestore}
                            data-bs-dismiss="modal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#restoreXray2`}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MedicalXrayCopy;

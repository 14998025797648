const PatientInfoTableMultipleSelectBtns = ({ table }) => {
  const selectedRowsData = table
    .getSelectedRowModel()
    .rows.map((item) => item.original?.user_id);

  console.log("selectedRows", selectedRowsData);

  const handleInvoicePrint = () => {
    return window.open(
      `/dashboard/invoice/group-print?userIds=${selectedRowsData.join(",")}`,
      "_blank",
    );
  };

  const handleMedicalPrint = () => {
    return window.open(
      `/dashboard/medical-form/group-print?userIds=${selectedRowsData.join(",")}`,
      "_blank",
    );
  };

  const handleXrayPrint = () => {
    return window.open(
      `/static-xray/group-print?userIds=${selectedRowsData.join(",")}`,
      "_blank",
    );
  };

  return (
    <div>
      {selectedRowsData?.length > 0 && (
        <div className="d-flex justify-content-between">
          <div>Print Multiple</div>
          <div className="d-flex gap-3 ">
            <button
              className="btn btn-sm btn-primary"
              onClick={handleInvoicePrint}
            >
              Invoice
            </button>

            <button
              className="btn btn-sm btn-warning"
              onClick={handleMedicalPrint}
            >
              Medical Form
            </button>

            <button
              className="btn btn-sm btn-success"
              onClick={handleXrayPrint}
            >
              X-Ray
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default PatientInfoTableMultipleSelectBtns;

import { toast } from "react-hot-toast";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../Context/UserContextAPI";

const MedicalReportMultipleSelect = ({ table }) => {
  const { accessPerm } = useContext(UserContext);

  const selectedRowsData = table
    .getSelectedRowModel()
    .rows.map((item) => item.original);

  const handleMedicalReportPrint = () => {
    const radiologyUser = selectedRowsData
      // .filter((usr) => usr?.user?.is_physical_done === 1)
      .map((user) => user?.user_id);
    // if (radiologyUser?.length <= 0) {
    //   return toast.error("No Radiology Report Available For Selected User");
    // }
    //
    return window.open(
      `/header-report/group-print?userIds=${radiologyUser.join(",")}`,
      "_blank",
    );
  };

  // medical-prescription/group-print

  const handlePrescriptionPrint = () => {
    const radiologyUser = selectedRowsData
      .filter(
        (usr) =>
          usr?.user?.is_radiology_done &&
          usr?.user?.is_pathology_done &&
          usr?.user?.is_physical_done &&
          usr?.user?.status === 4,
      )
      .map((user) => user?.user_id);
    if (radiologyUser?.length <= 0) {
      return toast.error("No Prescription  Available For Selected User");
    }

    return window.open(
      `/medical-prescription/group-print?userIds=${radiologyUser.join(",")}`,
      "_blank",
    );
  };

  // medical-health-card/group-print

  const handleHealthCardPrint = () => {
    const radiologyUser = selectedRowsData
      .filter(
        (usr) =>
          usr?.user?.is_radiology_done &&
          usr?.user?.is_pathology_done &&
          usr?.user?.is_physical_done &&
          usr?.user?.status === 2,
      )
      .map((user) => user?.user_id);
    if (radiologyUser?.length <= 0) {
      return toast.error("No Health Card  Available For Selected User");
    }

    return window.open(
      `/medical-health-card/group-print?userIds=${radiologyUser.join(",")}`,
      "_blank",
    );
  };

  return (
    <div>
      {selectedRowsData?.length > 0 && (
        <div className="d-flex justify-content-between">
          <div>Print Multiple</div>

          <div className="d-flex gap-3">
            <button
              className="btn btn-sm btn-primary"
              onClick={handleMedicalReportPrint}
            >
              Report
            </button>
            {accessPerm(68, 10) && (
              <button
                className="btn btn-sm btn-waring"
                onClick={handleHealthCardPrint}
              >
                Health Card
              </button>
            )}

            {accessPerm(68, 12) && (
              <button
                className="btn btn-sm btn-success"
                onClick={handlePrescriptionPrint}
              >
                Prescription
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default MedicalReportMultipleSelect;

import { useSearchParams } from "react-router-dom";
import PrintPrescription from "./PrintPrescription";

const PrescriptionGroupPrint = () => {
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get("userIds");
  const userIdsArr = userIds ? userIds?.split(",") : [];
  return (
    <div>
      {userIdsArr?.map((user, index) => {
        return (
          <div key={user}>
            <div>
              <PrintPrescription user_id={user} />
            </div>
            {index < userIdsArr?.length - 1 ? (
              <div className="print-page-break"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default PrescriptionGroupPrint;

const RemarksInReport = ({
  physicalCommentRisk,
  pathologyCommentRisk,
  radiologyData,
}) => {
  return (
    <div>
      <div className="d-flex flex-wrap w-100 text-dark text-start px-4 w-75">
        <b>Remarks:</b>
        <span className="ps-1"></span>
        <span
          dangerouslySetInnerHTML={{
            __html: physicalCommentRisk,
          }}
        />
        <span className="ps-1"></span>
        {pathologyCommentRisk ? (
          <span
            dangerouslySetInnerHTML={{
              __html: `${pathologyCommentRisk}`,
            }}
          />
        ) : (
          ""
        )}
        <span className="ps-1"></span>
        {radiologyData?.find(
          (report) =>
            report?.investigation_test?.id === 35 &&
            report?.result !== "Normal CXR" &&
            report?.result !== "",
        ) && (
          <>
            <span
              style={{
                color:
                  radiologyData?.find(
                    (report) => report?.investigation_test?.id === 35,
                  )?.result !== "Normal CXR"
                    ? "red"
                    : "black",
              }}
            >
              X-Ray
            </span>
          </>
        )}
      </div>
    </div>
  );
};
export default RemarksInReport;

import { addDays, format } from "date-fns";

const ExpDateAndReportDesc = ({ userDetail, user, from, singleReportData }) => {
  // console.log("singleReport", singleReportData);
  const reportStatus =
    from === "singleReport"
      ? singleReportData?.status
      : userDetail?.user?.status;
  return (
    <div>
      <table className="table">
        <tbody>
          <tr className="fw-bold">
            <td className="border border-top-0 border-dark text-uppercase text-center">
              Expiry Date{" "}
            </td>
            <td className="border border-top-0 border-dark">
              {format(
                addDays(new Date(user?.createdAt || new Date()), 90),
                "dd MMM yyyy",
              )}
            </td>
          </tr>
          <tr className="border border-top-0 border-dark">
            <td colSpan={2} style={{ height: "50px" }}>
              <p
                className=" m-0 p-0 fst-italic text-dark ps-2"
                style={{ lineHeight: "15px", fontSize: "10px" }}
              >
                <span className="d-block">Dear Sir/Madam, </span>
                Mentioned above is the medical report for Mr./Mrs.{" "}
                <b style={{ fontStyle: "normal" }} className="text-uppercase">
                  {userDetail?.user?.f_name}
                </b>{" "}
                <span className="d-block">
                  who is
                  <span
                    className="fw-bold text-uppercase fs-5 fst-normal p-0 m-0"
                    style={{
                      color: `${
                        reportStatus === 1
                          ? "black"
                          : reportStatus === 2
                            ? "#5cb85c"
                            : reportStatus === 3
                              ? "red"
                              : reportStatus === 4 && "#0000FF"
                      }`,
                    }}
                  >
                    {reportStatus === 1 && ` PROCESSING`}
                    {reportStatus === 2 && ` FIT`}
                    {reportStatus === 3 && ` UNFIT`}
                    {reportStatus === 4 && ` HELD UP`}
                  </span>{" "}
                  by above mention details.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default ExpDateAndReportDesc;

import { format } from "date-fns";
import "./UserInfo.scss";

const UserInfoTable = ({
  user,
  userDetails,
  age,
  physicalData,
  staticXray,
}) => {
  const formattedBirthDate = user?.date_of_birth
    ? (() => {
        const date = new Date(user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "";

  const weightData = physicalData?.find((item) => item?.investigation_id === 2);
  const heightData = physicalData?.find((item) => item?.investigation_id === 1);
  // console.log(heightData);

  // console.log(
  //   physicalData?.find((item) => item?.investigation_id === 2)?.investigation
  //     ?.investigation_tests[0]?.uom?.name
  // );
  return (
    <div className="d-flex gap-1 p-0 m-0" style={{ width: "100%" }}>
      <div style={{ width: "80%" }}>
        <table
          style={{
            width: "100%",
            // height: "100%",
            borderCollapse: "collapse",
            textAlign: "left",
            // fontSize: "20px",
          }}
          className="table table-bordered border-dark userinfo-table p-0 m-0 text-uppercase fw-bold"
        >
          <tbody>
            <tr className="fw-bold">
              <td style={{ width: "14%" }}>Name</td>
              <td colspan="4">{user?.f_name || ""}</td>
            </tr>
            <tr>
              <td className="fw-bold">Country</td>
              <td className="fw-bold" style={{ width: "10%" }}>
                {userDetails?.country?.name || ""}
              </td>
              <td style={{ width: "14%" }} rowspan="5">
                <div className="user-fingerprint-img">
                  {user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.finger_print}`}
                      className=""
                      alt=""
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <div style={{ height: "70px", width: "50px" }} />
                  )}
                </div>
              </td>
              <td className="fw-bold" style={{ width: "15%" }}>
                Passport No.
              </td>
              <td className="fw-bold" style={{ width: "17%" }}>
                {user?.passport_no || ""}
              </td>
            </tr>
            <tr>
              <td>Marital Status</td>
              <td>{userDetails?.marital_status?.name || ""}</td>
              <td>Date of Birth</td>
              <td>
                {formattedBirthDate || ""}
                {formattedBirthDate !== "" && `(${age})`}
              </td>
            </tr>
            <tr>
              <td>Contact</td>
              <td>{user?.mobile || ""}</td>
              <td>Religion</td>
              <td>{user?.religion || ""}</td>
            </tr>
            <tr>
              <td>Issue Date</td>
              <td>
                {user?.pi_date && !isNaN(new Date(user?.pi_date))
                  ? format(new Date(user?.pi_date), "dd MMM yyyy").toUpperCase()
                  : ""}
              </td>
              <td>Issue Place</td>
              <td>{user?.pi_place || ""}</td>
            </tr>
            <tr>
              <td>Nationality</td>
              <td> {userDetails?.national_country?.nationality || ""}</td>
              <td>Gender</td>
              <td> {userDetails?.gender?.name || ""}</td>
            </tr>
            <tr>
              <td>Visa No.</td>
              <td colspan="2">{user?.visa_no || ""}</td>
              <td>Visa Date</td>
              <td>
                {user?.visa_date && !isNaN(new Date(user?.visa_date))
                  ? format(
                      new Date(user?.visa_date),
                      "dd MMM yyyy",
                    ).toUpperCase()
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Profession</td>
              <td colspan="2">{userDetails?.profession?.name || ""}</td>
              <td>Height (CM)</td>
              <td>
                {heightData?.result}{" "}
                {heightData?.investigation?.investigation_tests[0]?.uom?.name}
              </td>
            </tr>
            <tr>
              <td>Agency</td>
              <td colspan="2">{userDetails?.introducer_user?.f_name || ""}</td>
              <td>Weight (KG)</td>
              <td>
                {weightData?.result}{" "}
                {weightData?.investigation?.investigation_tests[0]?.uom?.name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ width: "20%", height: "100%" }}>
        <div
          className="border border-dark"
          style={{ height: "100%" }}
        >
          {user?.image ? (
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.image}`}
              alt="patient"
              // className={`${
              //   staticXray === 1 ? `static-report-img` : `report-img`
              // }`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <img
              src={`dummy.jpg`}
              alt="patient"
              // className={`${
              //   staticXray === 1 ? `static-report-img` : `dummy-report-img`
              // }`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default UserInfoTable;

import axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";
import { addDays, format } from "date-fns";
import { UserContext } from "../../../Context/UserContextAPI";
import XrayImage from "../../XrayImage/XrayImage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./VerificationReport.scss";
import UserInfoTable from "../../Investigation/Report/Certificates/Components/UserInfoTable";
import PDFPrescriptionModal from "../PrescriptionModal/PDFPrescriptionModal";
import "./VerificationReport.scss";

const PrintVerificationReport = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;

  const { settingsData } = useContext(UserContext);
  const [xrayImage, setXrayImage] = useState("");
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [pathologyData, setPathologyData] = useState([]);
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const [data, setData] = useState({});
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const websiteUrlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}`;
  const pdfRef = useRef(null);

  const { id } = useParams();
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${id}`;

  const formattedBirthDate = data?.user?.date_of_birth
    ? (() => {
        const date = new Date(data?.user?.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const registrationNo = data?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return "";
    }

    const days = Math.floor(hours / 24);

    date.setDate(date.getDate() + days);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const receivingDate = data?.user_payment?.createdAt;
  const preparationDuration =
    data?.user?.role_id !== 5
      ? data?.package?.preparation_duration ?? 0
      : data?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getPhysicalData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/physical/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(`${prescriptionURL}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      const prescriptionData = prescriptionResponse.data.data;

      setPrescriptionData(prescriptionData);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${id}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRadiologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${id}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical-verification/${id}`)
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;

      setPhysicalCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, data?.gender?.id]);

  const pdfGenerate = () => {
    const input = pdfRef.current;
    const children = Array.from(input.children); // Convert HTMLCollection to an array

    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm

    const processChild = (child, index) => {
      return new Promise((resolve) => {
        // Temporarily increase font size for the capture
        const originalFontSize = getComputedStyle(child).fontSize;
        child.style.fontSize = "16px"; // Set your desired font size here

        // Capture the child with html2canvas
        html2canvas(child, {
          useCORS: true,
          scale: 2, // Increase scale for better quality and to fit more content
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Add a new page for all but the first child
          if (index > 0) {
            pdf.addPage();
          }

          // Adjust height to fit within the page
          const adjustedHeight =
            imgHeight > pageHeight ? pageHeight : imgHeight;

          // Add the captured image to the PDF
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, adjustedHeight);

          // Restore original font size
          child.style.fontSize = originalFontSize;

          resolve();
        });
      });
    };

    const promises = children.map((child, index) => processChild(child, index));

    Promise.all(promises).then(() => {
      pdf.save("myDocument.pdf");
    });
  };

  const headerImage = new Image();
  headerImage.src = `${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`;
  headerImage.onload = () => setHeaderImageLoaded(true);

  const qrCodeImage = new Image();
  qrCodeImage.src = websiteUrlWithBase64ID;

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }
  const birthYear = data?.user?.date_of_birth
    ? new Date(data?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";
  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientRegNameDataUrl = generateBarcode(
    String(`${data?.user?.f_name} - ${age}`)
  );

  useEffect(() => {
    getPhysicalData();
    getRadiologyData();
    getPathologyData();
    getPhysicalComment();
    getPathologyComment();
    fetchPatientInfo();
    getData();
  }, [id]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  const isImagesLoaded = headerImageLoaded;
  console.log(isImagesLoaded);
  useEffect(() => {
    if (
      data?.user?.registration_no &&
      patientIdDataUrl &&
      isImagesLoaded &&
      data?.introducer_user?.f_name
    ) {
      setTimeout(() => {
        pdfGenerate();
      }, 1600);
    }
  }, [data, isImagesLoaded]);

  const radiologyResult = radiologyData?.find(
    (report) => report?.investigation_test?.id === 35
  )?.result;
  return (
    <div className="d-flex flex-column">
      <div className="px-2 mt-5" ref={pdfRef}>
        <section
          className="verification-test d-flex flex-column justify-content-between container position-relative"
          style={{ minHeight: "100vh" }}
        >
          <div className="row align-items-center">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
          <div className="">
            {/* Empty div to take up the left space */}

            {/* Medical Form centered */}
            <div
              className="text-center text-uppercase text-dark fs-4 mt-4"
              style={{ textDecoration: "underline", fontWeight: "bolder" }}
            >
              General Medical Exam Report
            </div>

            {/* Empty div to take up remaining space and push Package to the right */}
            {/* <div className="flex-grow-1 d-flex justify-content-end col-4 fs-6">
          {state?.record?.package && (
            <span className="p-0 m-0 text-end text-dark fw-bold">
              Package: {state?.record?.package?.name}
            </span>
          )}
        </div> */}
          </div>

          <div className="d-flex justify-content-between fw-bolder">
            <div>
              <p className="fs-5">
                Reg No : {data?.user?.reg_prefix}
                {data?.user?.registration_no}{" "}
              </p>
            </div>
            <div>
              <p className="fs-5">
                Reg Date :{" "}
                {formatDate(data?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
              </p>
            </div>
          </div>

          {/* bar code  */}
          <div className="d-flex justify-content-between">
            <div style={{ width: "40%" }}>
              <img
                src={patientRegNameDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={50}
              />
            </div>
            <div style={{ width: "50%" }}>
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={50}
              />
            </div>
          </div>

          <UserInfoTable
            user={data?.user}
            userDetails={data}
            age={age}
            physicalData={physicalData}
            PrintVerification={1}
          />

          <div style={{ fontSize: "7px" }} className="">
            <p
              className="pt-1 mb-0"
              // style={{ position: "relative", bottom: "0px" }}
            >
              History of past ilness: <b>1) Allergy: ABSENT</b>{" "}
              <b>
                2) Psychiatric and neurological disorders (Epilepsy,
                depression): ABSENT
              </b>{" "}
              <b>3) Others: NAD</b>
            </p>
            <p
              className=" p-0 m-0 static-content-medical-form-text"
              // style={{ position: "relative", bottom: "5px" }}
              style={{ lineHeight: "8px" }}
            >
              I hereby permit the <b>{settingsData?.website_name}</b> and the
              undersigned physician to furnish such information the company many
              need pertaining to my health status and pertinent and medical
              findings and do hereby release them from any and all legal
              responsibility by doing so. I also certify that my medical history
              contained above is true and any false statement will disqualify me
              from my employment, benefits and claim.
            </p>
          </div>

          <div className="row  mt-1">
            <div className="col-12 d-flex flex-row">
              <div className="col-6">
                <table className="table border border-dark mb-0 table-content-fit">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase text-center"
                        style={{ color: "black" }}
                        colSpan={3}
                      >
                        Medical Examination
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan={1}
                        className="border text-center py-1 border border-dark text-nowrap  "
                        style={{ color: "black" }}
                      >
                        EXAMINATION TYPE
                      </th>
                      <th
                        className="border text-center border py-1 border-dark text-nowrap  "
                        style={{ color: "black" }}
                      >
                        RESULTS
                      </th>
                      {/* <th
                      className="border text-center border py-1 border-dark text-nowrap  "
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th> */}
                    </tr>
                  </thead>
                  <tbody className="">
                    {Object.entries(groupedData)?.map(
                      ([categoryId, categoryData]) => {
                        if (categoryId === "PHYSICAL") {
                          return null;
                        }

                        if (
                          categoryId === "EAR" ||
                          categoryId === "EYE VISUAL ACUITY"
                        ) {
                          return (
                            <React.Fragment key={categoryId}>
                              <tr className="fw-bold">
                                <td>{categoryData?.name || "---"}</td>
                                <td className="p-0 m-0">
                                  <table className="w-100 m-0 border-dark">
                                    <tr className="">
                                      <td className="border border-dark w-25 border-top-0 ps-1">
                                        R
                                      </td>
                                      <td className="border-bottom border-dark ps-1">
                                        {categoryData?.items[0]?.result ||
                                          "---"}{" "}
                                        {categoryData?.items[0]?.result &&
                                          categoryData?.items[0]?.result !==
                                            "N/A" &&
                                          categoryData?.items[0]?.investigation
                                            ?.investigation_tests[0]?.uom?.name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderLeft: "1px solid black",
                                          borderRight: "1px solid black",
                                          borderTop: "none",
                                        }}
                                        className="ps-1"
                                      >
                                        L
                                      </td>
                                      <td className="border-bottom-0 ps-1">
                                        {categoryData?.items[1]?.result ||
                                          "---"}{" "}
                                        {categoryData?.items[1]?.result &&
                                          categoryData?.items[1]?.result !==
                                            "N/A" &&
                                          categoryData?.items[1]?.investigation
                                            ?.investigation_tests[1]?.uom?.name}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        }

                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap fw-bold text-center text-dark"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              // Log the data object for debugging
                              if (data?.investigation_id === 14) {
                                return (
                                  <tr
                                    key={index}
                                    className="w-100 fw-bold border-0"
                                  >
                                    <td className="text-nowrap border-0">
                                      {data?.investigation?.name || "---"}:
                                      <span
                                        className={`border-0 py-1 text-start text-wrap ps-2 ${
                                          data?.result &&
                                          data?.result !== "N/A" &&
                                          data?.result !== "---"
                                            ? data?.result === "Normal"
                                              ? "text-success"
                                              : "text-danger"
                                            : "text-dark"
                                        }`}
                                        colSpan={2}
                                      >
                                        {data?.result || "---"}{" "}
                                        {data?.result &&
                                          data?.result !== "N/A" &&
                                          data?.investigation
                                            ?.investigation_tests[0]?.uom?.name}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              }
                              return (
                                <tr key={index} className="text-dark">
                                  <td
                                    className={`${
                                      data?.investigation_id === 14 && "fw-bold"
                                    }`}
                                  >
                                    {data?.investigation?.name || "---"}
                                  </td>
                                  <td
                                    className={`border text-start py-1 border-dark text-dark fw-semibold ${
                                      data?.investigation_id === 14 &&
                                      "fw-bold "
                                    }`}
                                    colSpan={data?.investigation_id === 14 && 2}
                                  >
                                    {data?.result || "---"}{" "}
                                    {data?.result &&
                                      data?.result !== "N/A" &&
                                      data?.investigation
                                        ?.investigation_tests[0]?.uom?.name}
                                  </td>
                                  {/* {data?.investigation_id !== 14 && (
                                  <td className="border text-start py-1 border-dark text-dark">
                                    {data?.investigation?.investigation_tests[0]
                                      ?.report_normal_value || ""}
                                  </td>
                                )} */}
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                    )}
                    {/* {radiologyData && (
                    <tr>
                      <td colSpan={3} className="text-nowrap text-uppercase fw-bold text-center">
                        CHEST X-RAY
                      </td>
                    </tr>
                  )} */}

                    {radiologyData && (
                      <tr className="">
                        <td colSpan={2} className="fw-bold">
                          Chest X-Ray:{" "}
                          <span
                            className={`fw-bold ${
                              radiologyResult === "Normal CXR"
                                ? "text-success"
                                : "text-primary"
                            }`}
                          >
                            {radiologyData?.find(
                              (report) => report?.investigation_test?.id === 35
                            )?.result || ""}
                          </span>
                        </td>
                      </tr>
                    )}

                    <tr className="fw-bold">
                      <td className="border border-dark">Expiry Date </td>
                      <td>
                        {format(
                          addDays(
                            new Date(data?.user?.createdAt || new Date()),
                            90
                          ),
                          "dd MMM yyyy"
                        )}
                      </td>
                    </tr>
                    <td colSpan={3} style={{ height: "50px" }}>
                      <p
                        className="text-uppercase m-0 p-0 fst-italic text-dark ps-2 mt-1"
                        style={{ lineHeight: "12px", fontSize: "10px" }}
                      >
                        Mentioned above is the medical report for{" "}
                        {data?.user?.f_name}, who is
                        <span
                          className="fw-bold text-uppercase fs-6 fst-normal p-0 m-0"
                          style={{
                            color: `${
                              data?.user?.status === 1
                                ? "black"
                                : data?.user?.status === 2
                                ? "#5cb85c"
                                : data?.user?.status === 3
                                ? "red"
                                : data?.user?.status === 4 && "#0000FF"
                            }`,
                          }}
                        >
                          {data?.user?.status === 1 && ` PROCESSING`}
                          {data?.user?.status === 2 && ` FIT`}
                          {data?.user?.status === 3 && ` UNFIT`}
                          {data?.user?.status === 4 && ` HELD UP`}
                        </span>{" "}
                        for the above mentioned job according to the medical
                        criteria.{" "}
                      </p>
                    </td>
                  </tbody>
                </table>
                <div className="col-12 d-flex text-dark">
                  <b> Remarks:</b> <div className="ps-1"></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: physicalCommentRisk,
                    }}
                  />
                  <div className="ps-1"></div>
                  {pathologyCommentRisk ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${pathologyCommentRisk}`,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="ps-1"></div>
                  {radiologyData?.find(
                    (report) =>
                      report?.investigation_test?.id === 35 &&
                      report?.result !== "Normal CXR" &&
                      report?.result !== ""
                  ) && (
                    <>
                      <span
                        className=""
                        style={{
                          color:
                            radiologyData?.find(
                              (report) => report?.investigation_test?.id === 35
                            )?.result !== "Normal CXR"
                              ? "red"
                              : "black",
                        }}
                      >
                        X-Ray
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-start col-6 ps-sm-3 ps-0">
                <table className="table border border-dark mb-0">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase text-center  "
                        style={{ color: "black" }}
                        colSpan={3}
                      >
                        Laboratory Investigation
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan={1}
                        className="border text-center py-1 border border-dark text-nowrap "
                        style={{ color: "black", width: "18%" }}
                      >
                        EXAMINATION TYPE
                      </th>
                      <th
                        className="border text-center border py-1 border-dark text-nowrap  "
                        style={{ color: "black", width: "42%" }}
                      >
                        RESULTS
                      </th>
                      <th
                        className="border text-center border py-1 border-dark text-nowrap  "
                        style={{ color: "black", width: "40%" }}
                      >
                        REF. VALUE
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {Object.entries(groupedPathologyData)?.map(
                      ([categoryId, categoryData]) => (
                        <React.Fragment key={categoryId}>
                          <tr>
                            <td
                              colSpan="3"
                              className="text-nowrap fw-bold text-center"
                            >
                              {categoryData?.name}
                            </td>
                          </tr>
                          {categoryData?.items?.map((data, index) => (
                            <tr key={index}>
                              <td className="">
                                {data?.investigation_test?.investigation
                                  ?.name || "---"}
                              </td>
                              <td className="border text-start py-1 border-dark  text-dark">
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation_test?.uom?.name}
                              </td>
                              <td className="border text-start py-1 border-dark  text-dark">
                                {data?.investigation_test
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
                <div className="d-flex">
                  <div
                    className="w-100 d-flex justify-content-end align-items-center mt-1"
                    // style={{
                    //   height: "100px",
                    //   width: "150px",
                    //   // marginTop : '-25px'
                    // }}
                  >
                    <div className="text-end mt-2">
                      <Link to={urlWithBase64ID}>
                        <QRCode value={urlWithBase64ID} size={70} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-flex justify-content-between mt-5">
            <div className="row w-100">
              <div className="d-flex justify-content-around align-items-end">
                <div className="text-dark">
                  <div
                    className="border-top mt-0 border-dark"
                    style={{ width: "125px" }}
                  ></div>
                  Chief Physician
                </div>
                <div className="text-dark">
                  <div
                    className="border-top mt-0 border-dark"
                    style={{ width: "125px" }}
                  ></div>
                  Signature
                </div>
                <div className="text-start text-dark p-0 mt-1 text-wrap">
                  Printed By: {currentUser?.f_name}
                </div>
              </div>
            </div>
          </div> */}
          </div>
          <div className="verification-footer">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.footer_image}`}
              alt="Report Footer"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
          {/* <div class="page-break"></div> */}
        </section>
        <div className="container">
          {data?.user?.status === 4 && prescriptionData?.user_id && (
            <div
              className="pres"
              style={{
                // width: data ? "1700px" : "",
                // overflowX: "scroll",
                // fontWeight: "bolder",
                // overflow: "hidden",
                // minHeight: "100vh",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "start",
                // alignItems: "center",
                pageBreakBefore: "always",
              }}
            >
              <PDFPrescriptionModal data={data} />
            </div>
          )}
        </div>
        <div
          className="container"
          style={{
            pageBreakBefore: "always",
          }}
        >
          <XrayImage verification={1} close={1} />
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end pe-5 pb-4">
        <button
          className="btn btn-lg btn-primary text-end px-2 "
          onClick={() => pdfGenerate()}
        >
          PDF Download
        </button>
      </div>
    </div>
  );
};

export default PrintVerificationReport;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AgencySummaryModal from "../../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import CountrySummaryModal from "../../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import PackageSummaryModal from "../../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import "./SpecimenCollection.scss";
import RepresentativeSummeryModal from "../../../components/Modal/RegistrationSummaryModal/RepresentativeSummaryModal";
import { UserContext } from "../../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../../utils/Loader/Loading";
import FingerprintMatch from "../../../components/Fingerprint/FingerprintMatch";

const SpecimenCollection = () => {
  const { introducerUsers, packages, accessPerm } = useContext(UserContext);

  const invCatURL = `${process.env.REACT_APP_API_BASE_URL}/investigation-category/collection`;

  const location = useLocation();
  const { state } = location;
  const [userDetails, setUserDetails] = useState([]);
  const [invCat, setInvCat] = useState([]);
  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });

  const [regInputField, setRegInputField] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });

  const [totalStatus, setTotalStatus] = useState(0);
  const [otherStatus, setOtherStatus] = useState(0);

  const options = [
    { value: "", label: "Select Introducer" }, // Default "All" option
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const status = form.status.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const country = "";
    const company = "";
    const delegate = "";
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      fromDate,
      toDate,
      status,
      packageId,
      introducer,
      country,
      company,
      delegate,
      regNo: regNo.trim(),
    };

    methodFilterPatientsDetails(filterData, "form");
  };

  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search/collection`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);

        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const methodFilterPatientsDetailsReg = async (registrationNo, form) => {
    const regNo = registrationNo;

    const filterData = {
      regNo: regNo.trim(),
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);

        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data?.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id,
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });

    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
    setOtherStatus(notDoneCount);
  };

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/all/test`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        setTotalStatus(data?.userDetails?.length);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchInvestigationCat = async () => {
    try {
      const response = await axios.get(invCatURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      const updatedData = response?.data?.data?.map((item) => {
        return {
          ...item,
        };
      });

      setInvCat(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        status: "",
        packageId: "",
        introducer: "",
        country: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        country: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  useEffect(() => {
    getUserDetails();
    fetchInvestigationCat();
  }, []);

  useEffect(() => {
    if (state?.registration_no) {
      methodFilterPatientsDetailsReg(state?.registration_no, "form");
    }
  }, [state?.registration_no]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="px-2">SL</span>
          </div>
        ),
        cell: (info) => {
          const reversedSerialNumber = userDetails?.length - info?.row?.id;
          return (
            <div className="text-start fw-bold">
              <span className="text-start">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),

      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const dateValue = new Date(info.renderValue(reversedIndex));
          const formattedDate = format(dateValue, "dd-MM-yyyy");

          return (
            <div className="d-flex justify-content-start">{formattedDate}</div>
          );
        },
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info?.row?.original?.user?.reg_prefix || ""}
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Passenger Name</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">Passport No</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info) => {
          info.getValue();
          const value1 = info.getValue();
          // const No = representative_Mob?.slice(-2);
          return (
            <div>
              <span className="text-center d-flex justify-content-start">
                {value1}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("country.name", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Package</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info?.row?.original?.user?.role_id !== 5
              ? info.getValue()
              : "Individual Test"}
          </span>
        ),
      }),
      columnHelper.accessor("user", {
        id: "collection",
        header: () => (
          <span className="d-flex justify-content-center">Collection</span>
        ),
        cell: (info) => {
          return (
            <div className="d-flex justify-content-start">
              {accessPerm(35, 15) &&
                invCat?.map((cat, index) => {
                  const isLast = index === invCat?.length - 1;
                  // Find the matching collection based on investigation_category_id
                  const matchingCollection = info
                    ?.renderValue()
                    ?.user_collections?.find(
                      (collection) =>
                        collection?.investigation_category_id === cat?.id,
                    );
                  return (
                    <>
                      <Link
                        to={`/dashboard/collection-label/${
                          info?.renderValue()?.id
                        }`}
                        className={`cursor-pointer ms-2 ${
                          matchingCollection
                            ? matchingCollection?.createdAt !== "---"
                              ? "text-success"
                              : "text-danger"
                            : "text-danger"
                        }`}
                        target="_blank"
                      >
                        {cat?.name}
                      </Link>
                      {!isLast && <div className="ms-2">|</div>}
                    </>
                  );
                })}
            </div>
          );
        },
      }),
      columnHelper.accessor("user.role.name", {
        header: () => (
          <span className="d-flex justify-content-center">Type</span>
        ),
        cell: (info) => (
          <span className="d-flex justify-content-start">
            {info.renderValue()}
          </span>
        ),
      }),
    ],
    [accessPerm, userDetails],
  );

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // fingerprint handler

  const fingerprintHandler = (user) => {
    return window.open(`/dashboard/collection-label/${user?.userId}`, "_blank");
  };
  const fingeprintUserData = useMemo(() => {
    return userDetails?.reduce((users, user) => {
      if (user?.user?.finger_print_temp) {
        users.push({
          userId: user?.user?.id,
          finger_print_temp: user?.user?.finger_print_temp,
        });
      }
      return users;
    }, []);
  }, [userDetails]);

  return (
    <>
      <div className="card patient-information specimen-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 btn btn-danger text-white text-capitalize fw-bold m-0 p-0"
                  style={{ cursor: "default" }}
                >
                  Specimen Collection
                </h6>
                <div className="">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12"
                  >
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Status</label>
                        <div className="fw-light">
                          <select
                            className="form-select medical-form-select py-2"
                            name="status"
                            aria-label="Default select example"
                            style={{ maxWidth: "140px" }}
                          >
                            <option value={""}>Select Status</option>
                            <option value={1}>PROCESSING</option>
                            <option value={2}>FIT</option>
                            <option value={3}>UNFIT</option>
                            <option value={4}>HELD-UP</option>
                          </select>
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("package", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) =>
                              handleSelect("introducer", selectedOptions)
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                      <div className=" p-1">
                        <label className="col-form-label tdt">
                          Registration/Passport No.
                        </label>
                        <div className="">
                          <input
                            style={{ width: "180px", height: "37px" }}
                            type="text"
                            onChange={(e) => setRegInputField(e.target.value)}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                          />
                        </div>
                      </div>
                      <div className="p-1 d-flex align-items-center justify-content-end gap-1">
                        <button
                          type="submit"
                          className="btn text-white bg-primary border border-3"
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning border border-3"
                          onClick={(e) => reset(e)}
                        >
                          Reset
                        </button>

                        <div className="ms-5">
                          <FingerprintMatch successHandler={fingerprintHandler} userData={fingeprintUserData}>
                            <img
                              width={40}
                              height={40}
                              src="fingerprint.png"
                              alt="Report"
                              className="cursor-pointer "
                            />
                            <p>Collection</p>
                          </FingerprintMatch>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="patient-info-table-search">
              <div className="fit-unfit-status">
                <span className="fw-bolder">Record Summary &gt; </span>
                <span className="text-success fw-bolder">{`FIT: ${statusCounts?.status2}`}</span>
                <span> | </span>
                <span className="text-danger fw-bolder">{`UNFIT: ${statusCounts?.status3}`}</span>
                <span> | </span>
                <span className="fw-bolder" style={{ color: "blue" }}>
                  {`HELD-UP: ${statusCounts?.status4}`}
                </span>
                <span> | </span>
                <span className="fw-bolder">{`Others: ${otherStatus}`}</span>
                <span> | </span>
                <span className="fw-bolder">{`Total: ${totalStatus}`}</span>
              </div>

              {/* Registration Summery Container */}
              <div className="reg-summary">
                <span className="me-1">Summary Of:</span>{" "}
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#country-summary-modal"
                >
                  Country
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#agency-summary-modal"
                >
                  Agency
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#repres-summary-modal"
                >
                  Representative
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#package-summary-modal"
                >
                  Package
                </span>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table align-middle table-hover table-bordered mb-0 rounded">
                <thead className="bg-primary text-white">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup?.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header?.id}
                          className={`p-1`}
                          style={{ backgroundColor: "#095d7e" }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
        <RepresentativeSummeryModal
          representativeWiseUsers={representativeWiseUsers}
        />
      </div>
    </>
  );
};

export default SpecimenCollection;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.scss";

const NavbarModule = () => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const notificationOffURL = `${process.env.REACT_APP_API_BASE_URL}/notifications-off`;

  const navigate = useNavigate();
  const { currentUser, accessPerm } = useContext(UserContext);
  const [data, setData] = useState({});
  const [dropdownState, setDropdownState] = useState({});
  const [roles, setRoles] = useState([]);

  const showDropdown = (id) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const hideDropdown = (id) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleNavigate = (registration_no, userId, notification_type_id) => {
    axios
      .post(notificationOffURL, { userId, notification_type_id })
      .then((response) => {
        // setNotifications(response?.data?.data);
        // setTotalNotifications(response?.data?.pagination?.total);
      })
      .catch((error) => {
        // console.log(error);
      });
    let targetPath = "/dashboard/medical-reports";

    if (notification_type_id === 1) {
      targetPath = "/dashboard/patient-info";
    } else if ([2, 3, 4].includes(notification_type_id)) {
      targetPath = "/dashboard/specimen-collection";
    }

    navigate(targetPath, {
      state: { registration_no },
    });
    window.location.reload();
    // getNotifications(1);
  };

  const getData = () => {
    axios
      .get(settingURL)
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MenuItem = ({ children }) => {
    return (
      <ul
        className="submenu-expanded d-flex flex-column align-items-start"
        style={{ listStyle: "none" }}
      >
        {children}
      </ul>
    );
  };

  const fetchAllUserRoles = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/roles`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        setRoles(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerOnSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const newPassword = form.newPassword.value;
    const confirmPassword = form.confirmPassword.value;

    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        const userInfo = {
          mobile: currentUser?.mobile,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        updatePassword(userInfo);
      } else {
        toast.error("Password Not Matched!!");
      }
    }
  };

  const updatePassword = async (userInfo) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user/password`,
        userInfo,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res.status === 200) {
        toast.success("Password Change Successfully!!");
      }
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllUserRoles();
    getData();
  }, [accessPerm]);

  return (
    <Navbar expand="lg" className="navbar navbar-expand-lg bg-body-tertiary">
      <div
        className="d-flex justify-content-between w-100 px-2"
        style={{ fontSize: "10px" }}
      >
        <div className="sidebar-header d-flex p-1">
          <Link
            to="/dashboard"
            className="logo-wrapper d-flex align-items-center"
            style={{ width: "100px" }}
          >
            <div className="d-flex justify-content-center gap-3 align-items-center">
              {data?.fav_image ? (
                <img
                  className="d-flex justify-content-start align-items-center object-fit-contain"
                  src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.fav_image}`}
                  alt=""
                  style={{
                    width: "50px",
                  }}
                />
              ) : (
                <h4 className="d-flex align-items-center text-danger justify-content-center fw-bold fs-4 mt-2">
                  {data?.short_name}
                </h4>
              )}
            </div>
          </Link>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown
              title="Registration"
              id="nav-dropdown-1"
              show={dropdownState["nav-dropdown-1"]}
              onMouseEnter={() => showDropdown("nav-dropdown-1")}
              onMouseLeave={() => hideDropdown("nav-dropdown-1")}
            >
              {accessPerm(72, 4) || accessPerm(32, 4) || accessPerm(52, 1) ? (
                <MenuItem iconClass="bi bi-arrow-down-up" title="Registration">
                  {accessPerm(72, 4) && (
                    <Link to="/dashboard/patient-pre-registration">
                      Patient Pre-Registration
                    </Link>
                  )}
                  {accessPerm(32, 4) && ( // moduleid, activity id
                    <Link to="/dashboard/patient-info">
                      Passenger Information
                    </Link>
                  )}
                  {accessPerm(52, 1) && ( // moduleid, activity id
                    <Link to="/dashboard/re-investigation">
                      Re-investigation
                    </Link>
                  )}
                  <Link to="/dashboard/medical-tests">Medical Test</Link>
                </MenuItem>
              ) : null}
            </NavDropdown>
            {accessPerm(35, 4) ? (
              <Nav.Item>
                <Nav.Link href="/dashboard/specimen-collection">
                  Specimen Collection
                </Nav.Link>
              </Nav.Item>
            ) : null}
            {accessPerm(33, 4) ? (
              <Nav.Item>
                <Nav.Link href="/dashboard/medical-reports">
                  Medical Result
                </Nav.Link>
              </Nav.Item>
            ) : null}
            <NavDropdown
              title="Investigation"
              id="nav-dropdown-2"
              show={dropdownState["nav-dropdown-2"]}
              onMouseEnter={() => showDropdown("nav-dropdown-2")}
              onMouseLeave={() => hideDropdown("nav-dropdown-2")}
            >
              {accessPerm(21, 4) ||
              accessPerm(22, 4) ||
              accessPerm(23, 4) ||
              accessPerm(24, 2) ||
              accessPerm(25, 4) ? (
                <MenuItem iconClass="bi bi-search" title="Investigation">
                  {accessPerm(21, 4) && (
                    <>
                      <Link to="/dashboard/investigation/investigation-groups">
                        Groups
                      </Link>
                    </>
                  )}
                  {accessPerm(22, 4) && (
                    <>
                      <Link to="/dashboard/investigation/investigation-categories">
                        Category
                      </Link>
                    </>
                  )}
                  {accessPerm(23, 4) && (
                    <>
                      <Link to="/dashboard/investigation/investigation-management">
                        Investigation
                      </Link>
                    </>
                  )}
                  {accessPerm(24, 2) && (
                    <>
                      <Link to="/dashboard/investigation/test-management">
                        Test
                      </Link>
                    </>
                  )}
                  {accessPerm(25, 4) && (
                    <>
                      <Link to="/dashboard/package">Package</Link>
                    </>
                  )}
                </MenuItem>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title="Accounts"
              id="nav-dropdown-1"
              show={dropdownState["nav-dropdown-3"]}
              onMouseEnter={() => showDropdown("nav-dropdown-3")}
              onMouseLeave={() => hideDropdown("nav-dropdown-3")}
            >
              {accessPerm(26, 4) ||
              accessPerm(27, 4) ||
              accessPerm(28, 4) ||
              accessPerm(29, 4) ||
              accessPerm(30, 4) ||
              accessPerm(31, 4) ||
              accessPerm(44, 1) ||
              accessPerm(44, 4) ||
              accessPerm(45, 5) ||
              accessPerm(63, 5) ||
              accessPerm(64, 5) ||
              accessPerm(46, 5) ||
              accessPerm(47, 5) ? (
                <MenuItem
                  iconClass="bi bi-file-earmark-person"
                  title="Accounts"
                >
                  {accessPerm(26, 4) && (
                    <>
                      <Link to="/dashboard/transection-type">
                        Transaction Type
                      </Link>
                    </>
                  )}
                  {accessPerm(27, 4) && (
                    <>
                      <Link to="/dashboard/head-identifier">
                        Head Identifier
                      </Link>
                    </>
                  )}
                  {accessPerm(28, 4) && (
                    <>
                      <Link to="/dashboard/head-classification">
                        Head Classification
                      </Link>
                    </>
                  )}
                  {accessPerm(29, 4) && (
                    <>
                      <Link to="/dashboard/head-group">Head Group</Link>
                    </>
                  )}
                  {accessPerm(30, 4) && (
                    <>
                      <Link to="/dashboard/head-type">Head Type</Link>
                    </>
                  )}
                  {accessPerm(31, 4) && (
                    <>
                      <Link to="/dashboard/account-head">Account Head</Link>
                    </>
                  )}
                  {accessPerm(44, 1) && (
                    <>
                      <Link to="/dashboard/account/voucher-entry">
                        Voucher Entry
                      </Link>
                    </>
                  )}
                  {accessPerm(44, 4) && (
                    <>
                      <Link to="/dashboard/account/voucher-list">
                        Voucher List
                      </Link>
                    </>
                  )}
                  {accessPerm(45, 5) && (
                    <>
                      <Link to="/dashboard/account/trail-balance">
                        Trail Balance
                      </Link>
                    </>
                  )}
                  {accessPerm(63, 5) && (
                    <>
                      <Link to="/dashboard/account/ledger">Ledger</Link>
                    </>
                  )}
                  {accessPerm(64, 5) && (
                    <>
                      <Link to="/dashboard/account/head-balance">
                        Head Balance
                      </Link>
                    </>
                  )}
                  {accessPerm(46, 5) && (
                    <>
                      <Link to="/dashboard/account/profit-loss-statement">
                        Profit Loss Statement
                      </Link>
                    </>
                  )}
                  {accessPerm(47, 5) && (
                    <>
                      <Link to="/dashboard/account/statement-financial-position">
                        Balance Sheet
                      </Link>
                    </>
                  )}
                  <>
                    <Link to="/dashboard/medical-collection">
                      Collection Report
                    </Link>
                  </>
                </MenuItem>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title="Reports"
              id="nav-dropdown-1"
              show={dropdownState["nav-dropdown-4"]}
              onMouseEnter={() => showDropdown("nav-dropdown-4")}
              onMouseLeave={() => hideDropdown("nav-dropdown-4")}
            >
              {accessPerm(67, 4) ||
              accessPerm(68, 4) ||
              accessPerm(49, 4) ||
              accessPerm(50, 4) ||
              accessPerm(51, 4) ||
              accessPerm(48, 4) ? (
                <MenuItem iconClass="bi bi-filter-square" title="Reports">
                  {accessPerm(67, 4) && (
                    <>
                      <Link to="/dashboard/medical-registration">
                        Registration
                      </Link>
                    </>
                  )}
                  {accessPerm(68, 4) && (
                    <>
                      <Link to="/dashboard/result">Result</Link>
                    </>
                  )}
                  {accessPerm(49, 4) && (
                    <>
                      <Link to="/dashboard/fit-patients">Fit Patient</Link>
                    </>
                  )}
                  {accessPerm(50, 4) && (
                    <>
                      <Link to="/dashboard/unfit-patients">Unfit Patient</Link>
                    </>
                  )}
                  {accessPerm(51, 4) && (
                    <>
                      <Link to="/dashboard/held-up-patients">
                        Held Up Patient
                      </Link>
                    </>
                  )}
                  {accessPerm(48, 4) && (
                    <>
                      <Link to="/dashboard/birthday-report">
                        Birthday Report
                      </Link>
                    </>
                  )}
                  {accessPerm(71, 5) && (
                    <>
                      <Link to="/dashboard/medical-summary">
                        Medical Summary
                      </Link>
                    </>
                  )}
                  <>
                    <Link to="/dashboard/package-print">Package Print</Link>
                  </>
                </MenuItem>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title="HR Management"
              id="nav-dropdown-5"
              show={dropdownState["nav-dropdown-5"]}
              onMouseEnter={() => showDropdown("nav-dropdown-5")}
              onMouseLeave={() => hideDropdown("nav-dropdown-5")}
            >
              {accessPerm(69, 4) ? (
                <MenuItem iconClass="bi bi-person" title="HR Management">
                  {accessPerm(69, 4) && (
                    <>
                      <Link to="/dashboard/hr-management/leave-application">
                        Leave Application
                      </Link>
                    </>
                  )}
                </MenuItem>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title="User Management"
              id="nav-dropdown-6"
              show={dropdownState["nav-dropdown-6"]}
              onMouseEnter={() => showDropdown("nav-dropdown-6")}
              onMouseLeave={() => hideDropdown("nav-dropdown-6")}
            >
              {accessPerm(5, 4) ||
              accessPerm(4, 4) ||
              accessPerm(2, 4) ||
              accessPerm(3, 4) ||
              accessPerm(6, 2) ||
              accessPerm(74, 4) ? (
                <MenuItem iconClass="bi bi-people" title="User Management">
                  {accessPerm(5, 4) && (
                    <>
                      <Link to="/dashboard/users">Users</Link>
                    </>
                  )}
                  {accessPerm(5, 4) &&
                    roles?.map((roleUser, i) => (
                      <Link
                        key={i}
                        to={`/dashboard/users/role/${roleUser?.id}`}
                      >
                        {roleUser?.name}
                      </Link>
                    ))}
                  {accessPerm(4, 4) && (
                    <>
                      <Link to="/dashboard/roles">Roles</Link>
                    </>
                  )}
                  {accessPerm(2, 4) && (
                    <>
                      <Link to="/dashboard/activity">Activities</Link>
                    </>
                  )}
                  {accessPerm(3, 4) && (
                    <>
                      <Link to="/dashboard/module">Modules</Link>
                    </>
                  )}
                  {accessPerm(6, 2) && (
                    <>
                      <Link to="/dashboard/user-management/role-permissions">
                        Apply Permissions
                      </Link>
                    </>
                  )}
                  {accessPerm(74, 4) && (
                    <>
                      <Link to="/dashboard/user-management/logged-in">
                        App Logged-In Users
                      </Link>
                    </>
                  )}
                </MenuItem>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title="Settings"
              id="nav-dropdown-7"
              show={dropdownState["nav-dropdown-7"]}
              onMouseEnter={() => showDropdown("nav-dropdown-7")}
              onMouseLeave={() => hideDropdown("nav-dropdown-7")}
            >
              {accessPerm(7, 4) ||
              accessPerm(8, 4) ||
              accessPerm(9, 4) ||
              accessPerm(10, 4) ||
              accessPerm(11, 4) ||
              accessPerm(12, 4) ||
              accessPerm(13, 4) ||
              accessPerm(14, 4) ||
              accessPerm(15, 4) ||
              accessPerm(16, 4) ||
              accessPerm(17, 4) ||
              accessPerm(18, 4) ||
              accessPerm(19, 4) ||
              accessPerm(20, 4) ||
              accessPerm(1, 2) ? (
                <MenuItem iconClass="bi bi-gear" title="Settings">
                  {accessPerm(7, 4) && (
                    <>
                      <Link to="/dashboard/static-content-groups">
                        Static Content Groups
                      </Link>
                    </>
                  )}
                  {accessPerm(8, 4) && (
                    <>
                      <Link to="/dashboard/static-content">
                        Static Contents
                      </Link>
                    </>
                  )}
                  {accessPerm(9, 4) && (
                    <>
                      <Link to="/dashboard/settings/instruments-category">
                        Instruments Category
                      </Link>
                    </>
                  )}
                  {accessPerm(10, 4) && (
                    <>
                      <Link to="/dashboard/settings/instruments">
                        Instruments
                      </Link>
                    </>
                  )}
                  {accessPerm(11, 4) && (
                    <>
                      <Link to="/dashboard/setting/drug-groups">
                        Drugs Group
                      </Link>
                    </>
                  )}
                  {accessPerm(12, 4) && (
                    <>
                      <Link to="/dashboard/setting/drugs">Drugs</Link>
                    </>
                  )}
                  {accessPerm(13, 4) && (
                    <>
                      <Link to="/dashboard/settings/room-management">
                        Room Management
                      </Link>
                    </>
                  )}
                  {accessPerm(14, 4) && (
                    <>
                      <Link to="/dashboard/settings/gender">Gender</Link>
                    </>
                  )}
                  {accessPerm(15, 4) && (
                    <>
                      <Link to="/dashboard/settings/uom">
                        Unit of Measurements
                      </Link>
                    </>
                  )}
                  {accessPerm(16, 4) && (
                    <>
                      <Link to="/dashboard/settings/professions">
                        Professions
                      </Link>
                    </>
                  )}
                  {accessPerm(17, 4) && (
                    <>
                      <Link to="/dashboard/settings/merital-status">
                        Marital Status
                      </Link>
                    </>
                  )}
                  {accessPerm(18, 4) && (
                    <>
                      <Link to="/dashboard/settings/country">Country</Link>
                    </>
                  )}
                  {accessPerm(19, 4) && (
                    <>
                      <Link to="/dashboard/settings/state">State</Link>
                    </>
                  )}
                  {accessPerm(20, 4) && (
                    <>
                      <Link to="/dashboard/settings/city">City</Link>
                    </>
                  )}
                  {accessPerm(43, 4) && (
                    <>
                      <Link to="/dashboard/settings/qualification">
                        Qualification
                      </Link>
                    </>
                  )}
                  {accessPerm(42, 4) && (
                    <>
                      <Link to="/dashboard/settings/employee-category">
                        Employee Category
                      </Link>
                    </>
                  )}
                  {accessPerm(53, 4) && (
                    <>
                      <Link to="/dashboard/settings/leave-category">
                        Leave Category
                      </Link>
                    </>
                  )}
                  {accessPerm(54, 4) && (
                    <>
                      <Link to="/dashboard/settings/holy-days">Holiday</Link>
                    </>
                  )}
                  {accessPerm(41, 4) && (
                    <>
                      <Link to="/dashboard/settings/employee-type">
                        Employee Type
                      </Link>
                    </>
                  )}
                  {accessPerm(40, 4) && (
                    <>
                      <Link to="/dashboard/settings/department">
                        Department
                      </Link>
                    </>
                  )}
                  {accessPerm(39, 4) && (
                    <>
                      <Link to="/dashboard/settings/blood-group">
                        Blood Group
                      </Link>
                    </>
                  )}

                  {accessPerm(1, 2) && (
                    <>
                      <Link to="/dashboard/settings/system-settings">
                        System Settings
                      </Link>
                    </>
                  )}
                </MenuItem>
              ) : null}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className="top-navbar-right d-flex align-items-center justify-content-center">
          <ul className="navbar-nav d-flex gap-2 align-items-center">
            <li className="nav-item dropdown dropdown-large">
              <a
                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                href="/"
                data-bs-toggle="dropdown"
              >
                <div className="user-setting d-flex align-items-center gap-1">
                  {!currentUser || !currentUser?.image ? (
                    <img
                      className="user-img"
                      src="/userimg.png"
                      alt="Profile Pic"
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "40%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      className="user-img"
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${currentUser?.image}`}
                      alt="Profile Pic"
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "40%",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  {currentUser?.f_name && currentUser?.l_name && (
                    <div className="user-name d-none d-sm-block">
                      {currentUser?.f_name} {currentUser?.l_name}
                    </div>
                  )}
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/dashboard/profile/preview"
                  >
                    <div className="d-flex align-items-center">
                      {!currentUser || !currentUser?.image ? (
                        <img
                          src="/userimg.png"
                          alt=""
                          className="rounded-circle"
                          width={70}
                          height={70}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/users/${currentUser?.image}`}
                          alt=""
                          className="rounded-circle"
                          width={70}
                          height={70}
                        />
                      )}

                      <div className="ms-3">
                        <h6 className="mb-0 dropdown-user-name">
                          {currentUser?.f_name} {currentUser?.l_name}
                        </h6>
                        <small className="mb-0 dropdown-user-designation text-secondary">
                          {currentUser?.role?.name}
                        </small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {accessPerm(75, 4) && (
                  <li>
                    <Link className="dropdown-item" to="/dashboard/balance">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="setting-icon">
                            <i className="bi bi-cash fs-5"></i>
                          </div>
                          <div className="setting-text ms-3">
                            <span>Balance</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                )}

                <li>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#changeLoginUserPassword"
                  >
                    <div className="d-flex align-items-center">
                      <div className="setting-icon">
                        <i className="bi bi-gear-fill fs-5" />
                      </div>
                      <div className="setting-text ms-3">
                        <span>Change Password</span>
                      </div>
                    </div>
                  </button>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      localStorage.removeItem("jwtToken");
                      navigate("/signin");
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="setting-icon">
                        <i className="bi bi-lock-fill fs-5" />
                      </div>
                      <div className="setting-text ms-3">
                        <span>Logout</span>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown dropdown-large">
              <div className="dropdown-menu dropdown-menu-end">
                <div className="row row-cols-3 gx-2">
                  {accessPerm(5, 4) && (
                    <div className="col">
                      <Link to="/dashboard/users">
                        <div className="apps p-2 radius-10 text-center">
                          <div className="apps-icon-box py-3 mb-1 text-white bg-danger bg-gradient">
                            <i className="bi bi-people-fill" />
                          </div>
                          <p className="mb-0 apps-name">Users</p>
                        </div>
                      </Link>
                    </div>
                  )}

                  <div className="col">
                    <Link to="/dashboard/profile">
                      <div className="apps p-2 radius-10 text-center">
                        <div className="apps-icon-box py-3 mb-1 text-white bg-purple bg-gradient">
                          <i className="bi bi-person-circle" />
                        </div>
                        <p className="mb-0 apps-name">Account</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="nav-item dropdown dropdown-large d-none d-sm-block">
              <a
                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                href=""
                data-bs-toggle="dropdown"
              >
                <div className="notifications d-flex justify-content-center mx-auto">
                  {totalNotifications ? (
                    <>
                      <span className="notify-badge fs-6 d-flex align-items-center justify-content-center">
                        {totalNotifications > 99 ? "99+" : totalNotifications}
                      </span>
                      <i
                        className="bi bi-bell-fill"
                        style={{ position: "relative", right: "9px" }}
                      />
                    </>
                  ) : (
                    <i
                      className="bi bi-bell-fill"
                      style={{ position: "relative", left: "10px" }}
                    />
                  )}
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end p-0">
                <div className="p-1 pb-1 border-bottom m-2">
                  <h5 className="mb-0 fs-5">Notifications</h5>
                </div>
                <div className="header-notifications-list p-2">
                  <ul className="d-flex ps-0 p-2 flex-column gap-1 justify-content-start">
                    {notifications?.map((notification, index) => (
                      <li
                        className="pb-3 d-flex align-items-center border-bottom w-100 notification-list-item"
                        key={notification?.id}
                        ref={
                          notifications.length === index + 1
                            ? lastNotificationRef
                            : null
                        }
                        onClick={() =>
                          handleNavigate(
                            notification?.user_detail?.user?.registration_no,
                            notification?.user_id,
                            notification?.notification_type_id
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {notification?.message}
                      </li>
                    ))}
                    {!notifications && (
                      <span className="text-secondary d-flex align-items-center justify-content-center">
                        No new notifications found
                      </span>
                    )}
                  </ul>
                  {loadingNotification && (
                    <p className="text-center">Loading...</p>
                  )}
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      {/* Change Passsword Modal Body */}
      <div
        className="modal fade"
        id="changeLoginUserPassword"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="card-body ">
                <form
                  className="form-body"
                  onSubmit={(e) => handlerOnSubmit(e)}
                >
                  <div className="row gap-3">
                    <div className="col-12">
                      <label htmlFor="inputNewPassword" className="form-label">
                        New Password
                      </label>
                      <div className="ms-auto position-relative">
                        <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control radius-30 ps-5"
                          id="inputNewPassword"
                          placeholder="Enter New Password"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label
                        htmlFor="inputConfirmPassword"
                        className="form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="ms-auto position-relative">
                        <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control radius-30 ps-5"
                          id="inputConfirmPassword"
                          placeholder="Confirm Password"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-grid gap-3">
                        <button
                          type="submit"
                          className="btn btn-primary radius-30"
                          data-bs-dismiss="modal"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default NavbarModule;

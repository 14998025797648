import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { UserContext } from "../../Context/UserContextAPI";
import "./PackagePrint.scss";

const PackagePrint = () => {
  const pdfRef = useRef();
  const { accessPerm } = useContext(UserContext);

  const packageURL = `${process.env.REACT_APP_API_BASE_URL}/packages`;
  const investigationURL = `${process.env.REACT_APP_API_BASE_URL}/investigation`;

  const [data, setData] = useState([]);
  const [investigation, setInvestigation] = useState([]);


  const fetchInvestigation = async () => {
    await axios
      .get(investigationURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setInvestigation(response?.data?.data))
      .catch((err) => console.log(err));
  };

  const handleGeneratePDF = async () => {
    const htmlContent = pdfRef.current.outerHTML;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/generate-pdf`,
        { htmlContent },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "download.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up URL object after download
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(packageURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      setData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => (
          <span className="d-flex justify-content-center">Name</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.name ? (
                <>{`${info?.row?.original?.name}`}</>
              ) : (
                <>{"---"}</>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("price", {
        header: () => (
          <span className="d-flex justify-content-center">Price</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.price ? (
                <>{`${info?.row?.original?.price}`}</>
              ) : (
                <>{"---"}</>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("investigationNames", {
        header: () => (
          <span className="d-flex justify-content-center">Investigations</span>
        ),
        cell: (info, index) => {
          return (
            <>
              {info?.row?.original?.investigationNames ? (
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  {info?.row?.original?.investigationNames
                    ?.split(",")
                    ?.map((investigation, index) => (
                      <li key={index}>{investigation}</li>
                    ))}
                </ul>
              ) : (
                <>{"---"}</>
              )}
            </>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleGeneratePDF();
  //   }, 1200);
  // }, []);

  useEffect(() => {
    fetchInvestigation();
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Package List</h6>
              <div className="col">
                {/* Button trigger modal */}
                <button
                  type="button"
                  className="btn btn-success border border-3"
                  onClick={handleGeneratePDF}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Download PDF
                </button>
              </div>
            </div>

            <hr />

            <table
              className="table align-middle table-hover table-bordered mb-0 rounded p-1"
              style={{ fontSize: "11px" }}
              ref={pdfRef}
            >
              <thead className="bg-primary">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => (
                      <th
                        key={i}
                        id={`th-${i}`}
                        className="text-light p-1"
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, i) => {
                  return (
                    <tr key={i}>
                      {row.getVisibleCells().map((cell, i) => (
                        <td key={i} className="p-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagePrint;

import { useSearchParams } from "react-router-dom";
import PrintMedicalXray from "./PrintMedicalXray";

const MedicalXrayGroupPrint = () => {
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get("userIds");
  const userIdsArr = userIds ? userIds?.split(",") : [];
  return (
    <div>
      {userIdsArr?.map((user, index) => {
        return (
          <div key={user}>
            <div>
              <PrintMedicalXray user_id={user} />
            </div>
            {index < userIdsArr?.length - 1 ? (
              <div className="print-page-break"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default MedicalXrayGroupPrint;

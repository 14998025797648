import React from "react";
import { Modal } from "react-bootstrap";
import ModuleForm from "./ModuleForm";
import axios from "axios";
import toast from "react-hot-toast";

const UpdateModuleModal = ({
  isOPen,
  onClose,
  activity,
  refresh,
  selectedData,
}) => {
  // console.log(selectedData)

  const updateHandler = async (formData) => {
    try {
      // console.log(formData);

      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/module/${selectedData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res) {
        toast.success("Module updated successfully");
        onClose();
        refresh();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={isOPen} onHide={onClose}>
      <Modal.Header>
        <h6> Update Module</h6>
      </Modal.Header>

      <Modal.Body>
        <ModuleForm
          activity={activity}
          OnClose={onClose}
          editData={selectedData}
          apiHandler={updateHandler}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModuleModal;

import React from "react";

const LaboratoryInvestigation = ({
  groupedPathologyData,
  user,
  groupedPathologyInvTest,
  from,
}) => {
  const hasPathologyData =
    groupedPathologyData && Object.keys(groupedPathologyData)?.length > 0;
  return (
    <div>
      <table className="table border border-dark mb-0">
        <thead>
          <tr>
            <th
              className="text-uppercase text-center  "
              style={{ color: "black" }}
              colSpan={3}
            >
              Laboratory Investigation
            </th>
          </tr>
          <tr>
            <th
              colSpan={1}
              className="border text-center py-1 border border-dark text-nowrap"
              style={{ color: "black", width: "50%" }}
            >
              EXAMINATION TYPE
            </th>
            <th
              className="border text-center border py-1 border-dark text-nowrap"
              style={{ color: "black", width: "25%" }}
            >
              RESULTS
            </th>
            <th
              className="border text-center border py-1 border-dark text-nowrap"
              style={{ color: "black", width: "25%" }}
            >
              REF. VALUE
            </th>
          </tr>
        </thead>
        <tbody className="">
          {Object.entries(groupedPathologyData)?.map(
            ([categoryId, categoryData]) => (
              <React.Fragment key={categoryId}>
                <tr>
                  <td
                    colSpan="3"
                    className="text-nowrap fw-bold text-center text-dark"
                  >
                    {categoryData?.name}
                  </td>
                </tr>
                {categoryData?.items?.map((data, index) => (
                  <tr key={index} style={{ fontWeight: "lighter" }}>
                    <td>
                      {data?.investigation_test?.investigation?.name || "---"}
                    </td>
                    <td className="border text-start py-1 border-dark text-dark ">
                      {data?.result || "---"}{" "}
                      {data?.result &&
                        data?.result !== "N/A" &&
                        data?.investigation_test?.uom?.name}
                    </td>
                    <td className="border text-start py-1 border-dark  text-dark ">
                      {data?.investigation_test?.report_normal_value || ""}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ),
          )}

          {!hasPathologyData &&
            from === "medicalForm" &&
            groupedPathologyInvTest &&
            Object.entries(groupedPathologyInvTest)?.map(
              ([categoryId, categoryData]) => {
                // console.log("this", categoryId);
                return (
                  <React.Fragment key={categoryId}>
                    <tr>
                      <td className="text-nowrap text-uppercase fw-bold text-center">
                        {categoryData?.name}
                      </td>
                      <td></td>
                    </tr>
                    {categoryData?.items?.map((data, index) => {
                      return (
                        <tr key={index} style={{ fontWeight: "lighter" }}>
                          <td className=" ">
                            {data?.investigation_tests[0]?.name || ""}
                          </td>
                          <td className="border text-start py-1 border-dark "></td>
                          <td className="border text-start py-1 border-dark "></td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              },
            )}
        </tbody>
      </table>
    </div>
  );
};
export default LaboratoryInvestigation;

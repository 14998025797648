import { useEffect, useState } from "react";
import "./PrescriptionModal.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import JsBarcode from "jsbarcode";
import { add, format, isValid } from "date-fns";
import QRCode from "qrcode.react";

function PrescriptionModal({ data }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const investigationURL = `${process.env.REACT_APP_API_BASE_URL}/investigation`;
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;
  const medicineURL = `${process.env.REACT_APP_API_BASE_URL}/medicine`;
  const websiteUrlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}`;

  const [addressData, setAddressData] = useState({});
  const [staticData, setStaticData] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [drugGroups, setDrugGroups] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [medicines, setMedicines] = useState([1]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [pathologyReportData, setPathologyReportData] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [defaultDate, setDefaultDate] = useState();

  const { state } = useLocation();

  const updatedState = state || data;
  console.log(updatedState);
  const [medicineForm, setMedicineForm] = useState([
    {
      user_id: updatedState?.user?.id.toString(),
      prescription_id: "",
      drug_id: "",
      rules_id: "",
      duration_id: "",
    },
  ]);

  const [prescriptionForm, setPrescriptionForm] = useState({
    user_id: updatedState?.user?.id.toString(),
    diagnosis_ids: [],
    complaint_ids: [],
    investigation_ids: [],
    advice_ids: [],
    next_visit_date: "",
  });

  let unfit = 0;
  let fit = 0;
  let heldUp = 0;
  let status = "";
  let riskFactor = "";

  let pathologyStatus = "";
  let physicalStatus = "";
  let pathologyRiskFactor = "";
  let physicalRiskFactor = "";

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       } else if (report.result < 11.8) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         pathologyStatus += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         pathologyRiskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         pathologyRiskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         pathologyRiskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         pathologyRiskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         pathologyRiskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         pathologyRiskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         pathologyStatus += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         pathologyRiskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         pathologyStatus += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         pathologyRiskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         pathologyRiskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         pathologyStatus += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         pathologyStatus += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         pathologyRiskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         pathologyRiskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         pathologyRiskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         pathologyRiskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         pathologyRiskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         pathologyStatus += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         pathologyStatus += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyRiskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //         pathologyRiskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         pathologyStatus += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  const physicalStatusCheckHandler = (reports) => {
    reports.forEach((report) => {
      // check left eye status
      if (report.investigation_id === 7) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "LEFT EYE: Squint, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "LEFT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "LEFT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "LEFT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        }
      }

      // check right eye status
      if (report.investigation_id === 8) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "RIGHT EYE: Squint";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "RIGHT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "RIGHT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "RIGHT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        }
      }

      // check left ear status
      if (report.investigation_id === 9) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "LEFT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "LEFT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        }
      }

      // check right ear status
      if (report.investigation_id === 10) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "RIGHT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "RIGHT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        }
      }

      // check hernia status
      if (report.investigation_id === 11) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hernia: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check hydrocele status
      if (report.investigation_id === 12) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hydrocele: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check BP status
      if (report.investigation_id === 3) {
        const bpAValue = parseInt(report.result?.split("/")[0]);
        const bpBValue = parseInt(report.result?.split("/")[0]);

        if (
          (bpAValue > 130 || bpAValue < 100) &&
          (bpBValue > 85 || bpBValue < 60)
        ) {
          physicalStatus += "Blood Pressure: " + report.result + ", ";
        }
      }

      // check pulse status
      if (report.investigation_id === 4) {
        const pulseValue = parseInt(report.result);

        if (pulseValue > 100 || pulseValue < 60) {
          physicalStatus += "Pulse: " + report.result + ", ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          } else {
            // riskFactor = riskFactor + "Pulse: 100%, ";
          }
        }
      }
    });
  };

  const patientRegisterBarcode = document.createElement("canvas");
  JsBarcode(
    patientRegisterBarcode,
    String(updatedState?.user?.registration_no)
  );
  const formattedMedicalDate = data?.createdAt
    ? (() => {
        const date = new Date(data?.createdAt);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const formattedBirthDate = updatedState?.user?.date_of_birth
    ? new Date(updatedState?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchAddressData = async () => {
    await axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setAddressData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(
        `${prescriptionURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const prescriptionData = prescriptionResponse?.data?.data;

      const medicineResponse = await axios.get(
        `${medicineURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const medicineData = medicineResponse?.data?.data;

      const diagnosisIdsArray = prescriptionData?.diagnosis_id
        ? prescriptionData.diagnosis_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedDiagnosis = diagnosisIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));
      const complaintIdsArray = prescriptionData?.complaint_id
        ? prescriptionData.complaint_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedComplaint = complaintIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const investigationIdsArray = prescriptionData?.investigation_ids
        ? prescriptionData.investigation_ids
            .split(",")
            .map((id) => parseInt(id, 10))
        : [];

      const selectedInvestigation = investigationIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const adviceIdsArray = prescriptionData?.advice_ids
        ? prescriptionData.advice_ids.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedAdvice = adviceIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      setPrescriptionForm((prevForm) => ({
        ...prevForm,
        diagnosis_ids: selectedDiagnosis.map((item) => item.value),
        complaint_ids: selectedComplaint.map((item) => item.value),
        investigation_ids: selectedInvestigation.map((item) => item.value),
        advice_ids: selectedAdvice.map((item) => item.value),
        next_visit_date: prescriptionData?.next_visit_date || "", // Update the next_visit_date
      }));
      setSelectedDiagnosis(selectedDiagnosis);

      setPrescriptionData(prescriptionData);
      setMedicineForm(medicineData);
    } catch (error) {
      console.log(error);
    }
  };

  const options = drugs.map((drug) => ({
    value: drug?.id,
    label: drug?.name,
  }));

  const fetchDrugsAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drugs/all-drugs`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugs(data);
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalTests(response?.data?.data);
      physicalStatusCheckHandler(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDrugsGroupAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drug-groups/all-groups`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugGroups(data);
  };

  const fetchInvestigationData = async () => {
    await axios
      .get(investigationURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setInvestigationData(response.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPhysicalTests();
    getRadiologyTests();
    setMedicines(medicines);
    fetchDrugsAPI();
    fetchDrugsGroupAPI();
    fetchAddressData();
    fetchStaticContentData();
    fetchInvestigationData();
    fetchPatientInfo();
  }, []);

  useEffect(() => {
    if (
      prescriptionForm?.next_visit_date &&
      isValid(new Date(prescriptionForm.next_visit_date))
    ) {
      setDefaultDate(prescriptionForm.next_visit_date);
    } else {
      const today = new Date();
      const sevenDaysLater = add(today, { days: 7 });
      const formattedDate = format(sevenDaysLater, "yyyy-MM-dd");
      setDefaultDate(formattedDate);
    }
  }, [prescriptionForm?.next_visit_date]);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;

  // pathologyStatusCheckHandler(pathologyReportData);
  physicalStatusCheckHandler(pathologyTests);

  return (
    <>
      {/* changing */}
      <section
        style={{
          width: data ? "1500px" : "",
          overflowX: "scroll",
          fontFamily: "Verdana",
          fontWeight: "bolder",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
        }}
        className="verified-prescription"
      >
        {data && (
          <div className="row align-items-center mt-1" id="header">
            <img
              src={`${process.env.PUBLIC_URL}/certificate-header.jpg`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "90%", height: "140px" }}
            />
          </div>
        )}
        <div className="d-flex flex-row justify-content-between mt-2 p-3">
          <div className="col-sm-2 p-1 h-100">
            <div className="report-avatar border border-dark">
              {updatedState?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${updatedState?.user?.image}`}
                  alt=""
                  style={{ height: data ? "240px" : "" }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid border border-2 border-dark"
                  style={{ height: data ? "240px" : "" }}
                />
              )}
            </div>
          </div>
          <div
            className={`col-sm-8 p-1 text-dark fw-bold`}
            style={{ width: data ? "66.5%" : "" }}
          >
            <table
              className="w-100 h-100 table-bordered border-dark mb-0"
              style={{ height: data ? "240px" : "" }}
            >
              <tbody>
                <tr>
                  <td className="text-uppercase">
                    <span className="ps-1 fw-bold">Id no: </span>
                    <span className="ps-1 fw-bold">
                      {updatedState?.user?.reg_prefix || ""}
                      {updatedState?.user?.registration_no}
                    </span>
                  </td>
                  <td className="text-uppercase">
                    <span className="ps-1 fw-bold">Reg no: </span>
                    <span className="ps-1 fw-bold">
                      {updatedState?.user?.reg_prefix || ""}
                      {updatedState?.user?.registration_no}
                    </span>
                  </td>
                  <td colSpan={2} className="text-uppercase">
                    <span className="text-uppercase ps-1 fw-bold">
                      Passport no:{" "}
                    </span>
                    <span className="text-uppercase ps-1 fw-bold">
                      {updatedState?.user?.passport_no}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1 fw-bold">Country</td>
                  <td className="text-uppercase ps-1 fw-bold">
                    {updatedState?.country?.name}
                  </td>
                  <td className="text-uppercase ps-1 fw-bold">Medical date</td>
                  <td className="text-uppercase ps-1 fw-bold">
                    {formattedMedicalDate || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1 fw-bold">Name</td>
                  <td className="text-uppercase ps-1 fw-bold" colSpan={3}>
                    {updatedState?.user?.f_name}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1 fw-bold">Father name</td>
                  <td className="text-uppercase ps-1 fw-bold" colSpan={3}>
                    {updatedState?.father_name}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1 fw-bold">Date of birth</td>
                  <td className="text-uppercase ps-1 fw-bold">
                    {formattedBirthDate || "---"}
                  </td>
                  <td className="text-uppercase ps-1 fw-bold">Gender</td>
                  <td className="text-uppercase ps-1 fw-bold">
                    {updatedState?.gender?.name}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1 fw-bold">Agency</td>
                  <td className="text-uppercase ps-1 fw-bold" colSpan={3}>
                    {updatedState?.introducer_user?.f_name}{" "}
                    {updatedState?.introducer_user?.l_name}
                  </td>
                </tr>
                {state?.company_user && (
                  <tr>
                    <td className="text-uppercase ps-1 fw-bold">Company</td>
                    <td className="text-uppercase ps-1 fw-bold" colSpan={3}>
                      {updatedState?.company_user?.f_name}{" "}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={`col-sm-2 p-1  ${data ? "h-100" : ""}`}>
            <div className="report-fingerprint border border-dark">
              {updatedState?.user?.finger_print ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${updatedState?.user?.finger_print}`}
                  className={`${data ? `h-0` : ""} border`}
                  style={{
                    height: data ? "240px" : "",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  alt=""
                />
              ) : (
                <div className="p-0 border h-100 border-2 text-dark border-bottom-1 border-dark text-center d-flex align-items-center justify-content-center report-avatar">
                  <p
                    className={`d-flex align-items-center justify-content-center ${
                      data ? "fw-bold ps-1" : ""
                    }`}
                    style={{
                      height: data ? "220px" : "",
                      objectFit: "cover",
                      width: "100%",
                    }}
                  >
                    No Fingerprint Found
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`row mt-4 text-dark ${data ? "p-4" : ""}`}>
          <div className="col-6 pe-3">
            <div>
              <div className="fs-6 fw-bold border-bottom fw-bold">
                Diagnosis:
              </div>
              <div className="p-1 fw-bold">
                {physicalStatus && (
                  <div className="text-dark">
                    Physical: {physicalStatus}
                    {physicalStatus && <br />}
                  </div>
                )}
                <div className="text-dark">
                  {pathologyStatus && (
                    <span>
                      Pathology: {pathologyStatus}
                      {radiologyFindings && <br />}
                    </span>
                  )}
                </div>
                {radiologyFindings && <div>Radiology: {radiologyFindings}</div>}
              </div>
            </div>
            <div>
              <div className="fs-6 fw-bold border-bottom">Complaints:</div>
              <ul className="pt-1 fw-bold">
                {prescriptionForm.complaint_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-6 fw-bold border-bottom">Investigations:</div>
              <ul className="pt-1 fw-bold">
                {prescriptionForm.investigation_ids.map((id) => (
                  <li key={id}>
                    {investigationData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-6 fw-bold border-bottom">Advices:</div>
              <ul className="pt-1 fw-bold">
                {prescriptionForm.advice_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-6 fw-bold border-bottom">Next Visits:</div>
              <ul className="pt-1 fw-bold">
                <li>
                  <div className="next-visit-date fw-bold">
                    Next Visit Date:{" "}
                    {defaultDate && format(new Date(defaultDate), "dd-MM-yyyy")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 ps-3">
            <div className="fs-6 fw-bold border-bottom">
              R <sub>x</sub>
            </div>
            <ul className="medicine-table medicine-no-print">
              {medicineForm.map((medicine, index) => {
                const selectedOption = options.find(
                  (option) => option.value === medicine.drug_id
                );

                return (
                  <li key={index}>
                    <div className="my-bangla-content">
                      {selectedOption?.label}
                    </div>
                    <div className="my-bangla-content">
                      {
                        staticData.find(
                          (data) => data?.id === medicine.rules_id
                        )?.name
                      }
                      <br />
                      {
                        staticData.find(
                          (data) => data?.id === medicine.duration_id
                        )?.name
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {data && (
          <div className="w-100 d-flex justify-content-center">
            <div
              style={{ background: "#9f7c28", fontSize: "13px" }}
              className="w-100 d-flex align-items-center justify-content-between p-3 mt-5 text-dark report-footer gap-3"
            >
              <div className="text-start">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4a2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6a3 3 0 0 0 0 6" />
                    </g>
                  </svg>
                  <div>
                    37/2 Zaman Tower, Purana Paltan
                    <br />
                    (Box Culvert Road), 1st Flr, Dhaka
                  </div>
                </div>
              </div>
              <div className="text-start">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42a18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <div>
                    +88-02-9569676, +88-02-9568603
                    <br />
                    <div className="fw-bold text-start">
                      Hot Line: +88 01712 547 122
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"
                  />
                </svg>{" "}
                <br />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5zM4.09 4a9.267 9.267 0 0 1 .64-1.539a6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12c.138.386.295.744.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472M3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539a6.688 6.688 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855c.173-.324.33-.682.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5m2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"
                  />
                </svg>{" "}
                {addressData?.website_url}
              </div>
              <div className="d-flex align-items-center">
                <Link
                  to={websiteUrlWithBase64ID}
                  className="d-flex align-items-center"
                >
                  <QRCode value={websiteUrlWithBase64ID} size={40} />
                </Link>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default PrescriptionModal;

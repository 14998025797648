import useFetch from "../../../../hooks/useFetch";
import request from "../../../../utils/request";

import investigationGroup from "../investigationGroup";
const SpecimentDataShow = () => {
  // // speciment data
  // const { data: specimentData } = useFetch(() =>
  //   request.getAll("investigation-groups"),
  // );
  // console.log(specimentData);

  return (
    <div
      className="d-flex flex-wrap justify-content-between ms-3 py-1"
      // style={{ width: "40%" }}
    >
      {investigationGroup?.map((specimen, i) => (
        <div className="d-flex text-dark" key={i}>
          <div className="d-flex">
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input mt-0 me-1 p-1 fs-5 border-3 border-dark "
                style={{ fontWeight: "bolder" }}
                // style={{ borderWidth: "10px" }}
                type="radio"
                id={i}
                value={JSON.stringify({
                  id: specimen?.id,
                  name: specimen?.name,
                })}
                // checked={isChecked}
              />
              <label
                className="form-check-label fs-6"
                style={{ fontWeight: "bolder" }}
                htmlFor="physicalExam"
              >
                {specimen?.name}
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default SpecimentDataShow;

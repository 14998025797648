import { format as dateFnsFormat, isValid } from "date-fns";

const formatDate = (dateString, formatString = "dd-MM-yyyy") => {
  const date = new Date(dateString);

  if (!isValid(date)) {
    return ""; // Return an empty string if the date is invalid
  }

  return dateFnsFormat(date, formatString);
};

export default formatDate;

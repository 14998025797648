import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import JsBarcode from "jsbarcode";
import { addDays, format } from "date-fns";
import "../Certificates/HeldUp.scss";
import { UserContext } from "../../../../Context/UserContextAPI";
import UserInfoTable from "../Certificates/Components/UserInfoTable";

const MedicalReportPrint = () => {
  const { currentUser, accessPerm, settingsData } = useContext(UserContext);
  const { userId } = useParams();
  const bloodResults = [];
  const urineResults = [];
  const physicalResults = [];

  const [userDetail, setUserDetail] = useState();
  const [user, setUser] = useState();
  const [physicalRiskFactor, setPhysicalRiskFactor] = useState("");
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const registrationNo = userDetail?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    format: "CODE128",
    // width: 1,
    // height: 20,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${userDetail?.user?.id}`;

  const formattedBirthDate = user?.date_of_birth
    ? (() => {
        const date = new Date(user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const [radiologyData, setRadiologyData] = useState([]);
  const [xrayImage, setXrayImage] = useState("");
  const [pathologyData, setPathologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyComment, setRadiologyComment] = useState();
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState();
  const [riskFactor, setRiskFactor] = useState("");
  const [userResultsData, setUserResultsData] = useState({
    user_id: userId,
    final_comments: "",
    advice: "",
    risk_factor: "",
    is_auto: 1,
    status: 2,
  });

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return "";
    }

    const days = Math.floor(hours / 24);

    date.setDate(date.getDate() + days);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const receivingDate = userDetail?.user_payment?.createdAt;
  const preparationDuration =
    user?.role_id !== 5
      ? userDetail?.package?.preparation_duration ?? 0
      : userDetail?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response?.data?.data;
      setPhysicalRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getradComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response?.data?.data;
      console.log(comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  function getUserResults() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-user-results/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setUserResultsData((prev) => {
          return {
            ...prev,
            final_comments: data?.final_comments,
            advice: data?.advice,
            risk_factor: data?.risk_factor,
          };
        });
      })
      .catch((err) => console.log(err));
  }

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const comments = response?.data?.data || [];

      setRadiologyComment(comments?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;

      setPhysicalCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const risk = response?.data?.data || [];

      setRadiologyRiskFactor(risk[0]?.comments);
      setRiskFactor(risk[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  pathologyData?.forEach((item) => {
    const categoryId =
      item?.investigation_test?.investigation?.investigation_category?.id;
    if (categoryId === 4) {
      bloodResults.push(item);
    } else if (categoryId === 5) {
      urineResults.push(item);
    } else if (categoryId === 7) {
      physicalResults.push(item);
    }
  });

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, userDetail?.gender?.id]);

  useEffect(() => {
    getRadiologyTests();
    getPhysicalTests();
    getPathologyData();
    getUserResults();
    getComments();
    getRiskFactor();
    getPathologyComment();
    getPhysicalComment();
    getUserDetail();
    getComment();
    getradComment();
  }, [userId]);

  useEffect(() => {
    userDetail && setUser(userDetail?.user);
  }, [userDetail]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);
  console.log(
    radiologyData?.find((report) => report?.investigation_test?.id === 35)
      ?.result !== "Normal CXR"
  );

  const birthYear = user?.date_of_birth
    ? new Date(user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";
  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      // width: 1,
      // height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientRegNameDataUrl = generateBarcode(
    String(`${user?.f_name} - ${age}`)
  );
  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }
  const radiologyResult = radiologyData?.find(
    (report) => report?.investigation_test?.id === 35
  )?.result;
  return (
    <>
      <section className="held-up-certificate d-flex flex-column justify-content-between">
        <div className="row align-items-center">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
          />
        </div>
        <div className="">
          {/* Empty div to take up the left space */}

          {/* Medical Form centered */}
          <div
            className="text-center text-uppercase text-dark fs-5"
            style={{ textDecoration: "underline", fontWeight: "bolder" }}
          >
            General Medical Exam Report
          </div>
        </div>

        <div className="d-flex justify-content-between fw-bolder">
          <div>
            <p className="fs-5">
              Reg No : {user?.reg_prefix}
              {user?.registration_no}{" "}
            </p>
          </div>
          <div>
            <p className="fs-5">
              Reg Date : {formatDate(user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
            </p>
          </div>
        </div>

        {/* bar code  */}
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "-15px" }}
        >
          <div style={{ width: "40%", marginLeft: "-8px" }}>
            <img
              src={patientRegNameDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={35}
            />
          </div>
          <div style={{ width: "50%", marginRight: "-20px" }}>
            <img
              src={patientIdDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={35}
            />
          </div>
        </div>
        <UserInfoTable
          user={user}
          userDetails={userDetail}
          age={age}
          physicalData={physicalData}
        />

        <div
          style={{ fontSize: "7px" }}
          className="static-content-medical-form-text"
        >
          <p
            className="pt-0 mb-0 static-content-medical-form-text"
            // style={{ position: "relative", bottom: "0px" }}
          >
            History of past ilness: <b>1) Allergy: ABSENT</b>{" "}
            <b>
              2) Psychiatric and neurological disorders (Epilepsy, depression):
              ABSENT
            </b>{" "}
            <b>3) Others: NAD</b>
          </p>
          <p
            className=" p-0 m-0 static-content-medical-form-text"
            // style={{ position: "relative", bottom: "5px" }}
            style={{ lineHeight: "8px" }}
          >
            I hereby permit the <b>{settingsData?.website_name}</b> and the
            undersigned physician to furnish such information the company many
            need pertaining to my health status and pertinent and medical
            findings and do hereby release them from any and all legal
            responsibility by doing so. I also certify that my medical history
            contained above is true and any false statement will disqualify me
            from my employment, benefits and claim.
          </p>
        </div>
        <div className="row  mt-1 ">
          <div className="col-sm-12 d-flex flex-column flex-sm-row">
            <div className="col-sm-6">
              <table className="table border border-dark mb-0 table-content-fit">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Medical Examination
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap  "
                      style={{ color: "black" }}
                    >
                      EXAMINATION TYPE
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap  "
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    {/* <th
                      className="border text-center border py-1 border-dark text-nowrap  "
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th> */}
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedData)?.map(
                    ([categoryId, categoryData]) => {
                      if (categoryId === "PHYSICAL") {
                        return null;
                      }

                      if (
                        categoryId === "EAR" ||
                        categoryId === "EYE VISUAL ACUITY"
                      ) {
                        return (
                          <React.Fragment key={categoryId}>
                            <tr className="fw-bold">
                              <td className="w-25">
                                {categoryData?.name || "---"}
                              </td>
                              <td className="p-0 m-0">
                                <table className="w-100 m-0 border-dark">
                                  <tr className="">
                                    <td className="border border-dark w-25 border-top-0 ps-1">
                                      R
                                    </td>
                                    <td className="border-bottom border-dark ps-1">
                                      {categoryData?.items[0]?.result || "---"}{" "}
                                      {categoryData?.items[0]?.result &&
                                        categoryData?.items[0]?.result !==
                                          "N/A" &&
                                        categoryData?.items[0]?.investigation
                                          ?.investigation_tests[0]?.uom?.name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "1px solid black",
                                        borderRight: "1px solid black",
                                        borderTop: "none",
                                      }}
                                      className="ps-1"
                                    >
                                      L
                                    </td>
                                    <td className="border-bottom-0 ps-1">
                                      {categoryData?.items[1]?.result || "---"}{" "}
                                      {categoryData?.items[1]?.result &&
                                        categoryData?.items[1]?.result !==
                                          "N/A" &&
                                        categoryData?.items[1]?.investigation
                                          ?.investigation_tests[1]?.uom?.name}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment key={categoryId}>
                          <tr>
                            <td
                              colSpan="3"
                              className="text-nowrap fw-bold text-center text-dark"
                            >
                              {categoryData?.name}
                            </td>
                          </tr>
                          {categoryData?.items?.map((data, index) => {
                            // Log the data object for debugging
                            if (data?.investigation_id === 14) {
                              return (
                                <tr
                                  key={index}
                                  className="w-100 fw-bold border-0"
                                >
                                  <td className="border-0 w-100" colSpan={2}>
                                    {data?.investigation?.name || "---"}:
                                    <span
                                      className={`border-0 py-1 text-start ps-2 ${
                                        data?.result &&
                                        data?.result !== "N/A" &&
                                        data?.result !== "---"
                                          ? data?.result === "Normal"
                                            ? "text-success"
                                            : "text-danger"
                                          : "text-dark"
                                      }`}
                                      colSpan={2}
                                    >
                                      {data?.result || "---"}{" "}
                                      {data?.result &&
                                        data?.result !== "N/A" &&
                                        data?.investigation
                                          ?.investigation_tests[0]?.uom?.name}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                            return (
                              <tr key={index} className="text-dark">
                                <td
                                  className={`${
                                    data?.investigation_id === 14 && "fw-bold"
                                  }`}
                                >
                                  {data?.investigation?.name || "---"}
                                </td>
                                <td
                                  className={`border text-start py-1 border-dark text-dark fw-semibold ${
                                    data?.investigation_id === 14 && "fw-bold "
                                  }`}
                                  colSpan={data?.investigation_id === 14 && 2}
                                >
                                  {data?.result || "---"}{" "}
                                  {data?.result &&
                                    data?.result !== "N/A" &&
                                    data?.investigation?.investigation_tests[0]
                                      ?.uom?.name}
                                </td>
                                {/* {data?.investigation_id !== 14 && (
                                  <td className="border text-start py-1 border-dark text-dark">
                                    {data?.investigation?.investigation_tests[0]
                                      ?.report_normal_value || ""}
                                  </td>
                                )} */}
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
                  {/* {radiologyData && (
                    <tr>
                      <td colSpan={3} className="text-nowrap text-uppercase fw-bold text-center">
                        CHEST X-RAY
                      </td>
                    </tr>
                  )} */}

                  {radiologyData && (
                    <tr className="">
                      <td colSpan={2} className="fw-bold border border-dark">
                        Chest X-Ray:{" "}
                        <span
                          className={`fw-bold ${
                            radiologyResult === "Normal CXR"
                              ? "text-success"
                              : "text-primary"
                          }`}
                        >
                          {radiologyData?.find(
                            (report) => report?.investigation_test?.id === 35
                          )?.result || ""}
                        </span>
                      </td>
                    </tr>
                  )}

                  <tr className="fw-bold">
                    <td className="border border-dark">Expiry Date </td>
                    <td>
                      {format(
                        addDays(new Date(user?.createdAt || new Date()), 90),
                        "dd MMM yyyy"
                      )}
                    </td>
                  </tr>
                  <td colSpan={3} style={{ height: "50px" }}>
                    <p
                      className="text-uppercase m-0 p-0 fst-italic text-dark ps-2 mt-1"
                      style={{ lineHeight: "12px", fontSize: "10px" }}
                    >
                      Mentioned above is the medical report for{" "}
                      {userDetail?.user?.f_name}, who is
                      <span
                        className="fw-bold text-uppercase fs-6 fst-normal p-0 m-0"
                        style={{
                          color: `${
                            userDetail?.user?.status === 1
                              ? "black"
                              : userDetail?.user?.status === 2
                              ? "#5cb85c"
                              : userDetail?.user?.status === 3
                              ? "red"
                              : userDetail?.user?.status === 4 && "#0000FF"
                          }`,
                        }}
                      >
                        {userDetail?.user?.status === 1 && ` PROCESSING`}
                        {userDetail?.user?.status === 2 && ` FIT`}
                        {userDetail?.user?.status === 3 && ` UNFIT`}
                        {userDetail?.user?.status === 4 && ` HELD UP`}
                      </span>{" "}
                      for the above mentioned job according to the medical
                      criteria.{" "}
                    </p>
                  </td>
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-column justify-content-start col-sm-6 ps-sm-2 ps-0">
              <table className="table border border-dark mb-0">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center  "
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Laboratory Investigation
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      EXAMINATION TYPE
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedPathologyData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap fw-bold text-center"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => (
                          <tr key={index}>
                            <td style={{ width: "2%" }}>
                              {data?.investigation_test?.investigation?.name ||
                                "---"}
                            </td>
                            <td
                              style={{ width: "58%" }}
                              className="border text-start py-1 border-dark text-dark text-nowrap"
                            >
                              {data?.result || "---"}{" "}
                              {data?.result &&
                                data?.result !== "N/A" &&
                                data?.investigation_test?.uom?.name}
                            </td>
                            <td
                              className="border text-start py-1 border-dark  text-dark text-nowrap"
                              style={{ width: "36%" }}
                            >
                              {data?.investigation_test?.report_normal_value ||
                                ""}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex flex-wrap w-100 text-dark text-start px-4 w-75 mt-1">
        <b>Remarks:</b>
        <span className="ps-1"></span>
        <span
          dangerouslySetInnerHTML={{
            __html: physicalCommentRisk,
          }}
        />
        <span className="ps-1"></span>
        {pathologyCommentRisk ? (
          <span
            dangerouslySetInnerHTML={{
              __html: `${pathologyCommentRisk}`,
            }}
          />
        ) : (
          ""
        )}
        <span className="ps-1"></span>
        {radiologyData?.find(
          (report) =>
            report?.investigation_test?.id === 35 &&
            report?.result !== "Normal CXR" &&
            report?.result !== ""
        ) && (
          <>
            <span
              style={{
                color:
                  radiologyData?.find(
                    (report) => report?.investigation_test?.id === 35
                  )?.result !== "Normal CXR"
                    ? "red"
                    : "black",
              }}
            >
              X-Ray
            </span>
          </>
        )}
      </div>
      <div className="d-flex justify-content-between w-100 medical-xray-print px-4">
        <div className="row w-100">
          <div className="d-flex justify-content-between gap-5 align-items-end">
            <div className="text-dark p-0 m-0">
              <div
                className="border-top mt-0 border-dark"
                style={{ width: "125px" }}
              ></div>
              Chief Physician
            </div>
            <div className="d-flex align-items-end gap-5">
              <div className="text-dark">
                <div
                  className="border-top mt-0 border-dark"
                  style={{ width: "125px" }}
                ></div>
                Signature
              </div>
              <div className="text-end mt-2">
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={70} />
                </Link>
                <div className="text-end text-dark p-0 text-wrap">
                  Printed By: {currentUser?.f_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2 heldup-button mt-2">
        {accessPerm(33, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default MedicalReportPrint;

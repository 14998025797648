const HeaderImg = ({ headerImg }) => {
  return (
    <div className=" text-dark">
      <div className="text-center patient-invoice-header-middle">
        <div className="">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${headerImg}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default HeaderImg;

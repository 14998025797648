import axios from "axios";
import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MedicalForm.scss";
import { UserContext } from "../../../../Context/UserContextAPI";
import { addDays, format } from "date-fns";
import "./MedicalFormCopy.module.css";
import UserInfoTable from "../Certificates/Components/UserInfoTable";

const MedicalFormCopy = ({ stateData, stateDataInv }) => {
  const { accessPerm } = useContext(UserContext);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { state } = useLocation();
  const patientData = state?.record;
  const invCsv = patientData?.package?.investigation_id;
  const invIds = invCsv?.split(",");

  const [systemData, setSystemData] = useState({});
  const userId = state?.record?.user?.id;

  const [physicalInvTest1, setPhysicalInvTests1] = useState([]);
  const [pathologyInvTest1, setPathologyInvTests1] = useState([]);
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [radiologyData, setRadiologyData] = useState([]);
  const [pathologyData, setPathologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);

  const formattedBirthDate = patientData?.user?.date_of_birth
    ? (() => {
        const date = new Date(patientData?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const birthYear = state?.record?.user?.date_of_birth
    ? new Date(state?.record?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  function getPathologyData() {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  }

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      // if(response.data.data)
      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const commentsData = response?.data?.data;

      setPhysicalCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  function getSystemData() {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response?.data?.data[0];
        setSystemData(allData);
        // formData.append('name', data.name)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPhysicalFieds() {
    try {
      const dataToSend = {
        invIds: invIds,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-data/3`, // 3 is for physical(Investigation Group Id)
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      data?.forEach((obj) => {
        obj.info = obj?.investigation_tests[0]?.info?.replace(/<[^>]*>/g, "");
      });

      setPhysicalInvTests1(data);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyResultData = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  async function getPathologyFieds() {
    try {
      const dataToSend = {
        invIds: invIds,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-data/1`, // 1 is for pathology(Investigation Group Id)
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;

      data?.forEach((obj) => {
        obj.info = obj?.investigation_tests[0]?.info?.replace(/<[^>]*>/g, "");
      });
      setPathologyInvTests1(data);
    } catch (error) {
      console.log(error);
    }
  }

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPhysicalInvTest1 = useMemo(() => {
    return physicalInvTest1.reduce((acc, item) => {
      const categoryName = item?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalInvTest1]);

  const groupedPathologyInvTest1 = useMemo(() => {
    return pathologyInvTest1.reduce((acc, item) => {
      const categoryName = item?.investigation_category?.name;

      // If no category name, return the accumulator as is
      if (!categoryName) return acc;

      // Fetch gender id and pregnancy field from state
      const genderId = state?.record?.gender?.id;
      const pregnancyField = state?.record?.introducer_user?.pregnancyField;

      // Filter condition: Do not include item with id 22 if gender id is 1
      if (item?.id === 22) {
        if (genderId === 1) {
          return acc; // Skip adding this item if gender id is 1
        } else if (genderId === 2 && pregnancyField !== 1) {
          return acc; // Skip if gender id is 2 and pregnancy field is not 1
        }
      }

      // Initialize category if it does not exist in the accumulator
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      // Push the item into its category's item array
      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyInvTest1, state]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const { investigation_id } = item || {};

      // Log the investigation_id
      console.log(investigation_id);

      // Fetch the required values from the state
      const genderId = state?.record?.gender?.id;
      const pregnancyField = state?.record?.introducer_user?.pregnancyField;

      // Condition to skip investigation_id 22 based on gender and pregnancy field
      if (investigation_id === 22) {
        if (genderId === 1) {
          // Skip this item if gender id is 1
          return acc;
        } else if (genderId === 2 && pregnancyField !== 1) {
          // Skip this item if gender id is 2 and pregnancyField is not 1
          return acc;
        }
      }

      // Fetch the investigation category name
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;

      // Initialize the category if it doesn't exist
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      // Add the item to the appropriate category
      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [
    pathologyData,
    patientData?.gender?.id,
    state?.record?.introducer_user?.pregnancyField,
  ]);

  useEffect(() => {
    getPathologyData();
  }, []);

  useEffect(() => {
    getPathologyResultData();
  }, []);

  useEffect(() => {
    getPhysicalFieds();
    getPathologyFieds();
    getPhysicalTests();
    getSystemData();
    getPhysicalComment();
    getPathologyComment();
    getRadiologyTests();
  }, [state]);

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(patientData?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
    displayValue: false,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const patientRegNameDataUrl = generateBarcode(
    String(`${state?.record?.user?.f_name} - ${age}`),
  );
  const radiologyResult = radiologyData?.find(
    (report) => report?.investigation_test?.id === 35,
  )?.result;

  return (
    <div className="medical-form">
      <div className="row align-items-center">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${systemData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <div className="d-flex align-items-center">
        {/* Empty div to take up the left space */}
        <div className="col-4"></div>

        {/* Medical Form centered */}
        <div
          className="text-center text-uppercase text-dark fs-4 col-4"
          style={{ textDecoration: "underline", fontWeight: "bolder" }}
        >
          Medical Form
        </div>

        {/* Empty div to take up remaining space and push Package to the right */}
        <div className="flex-grow-1 d-flex justify-content-end col-4 fs-6">
          {state?.record?.package && (
            <span className="p-0 m-0 text-end text-dark fw-bold">
              Package: {state?.record?.package?.name}
            </span>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between fw-bolder">
        <div className="p-0 m-0">
          <p className="fs-5 p-0 m-0">
            Reg No : {state?.record?.user?.reg_prefix}
            {state?.record?.user?.registration_no}{" "}
          </p>
        </div>
        <div className="p-0 m-0">
          <p className="fs-5 p-0 m-0">
            Reg Date :{" "}
            {formatDate(state?.record?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
          </p>
        </div>
      </div>

      {/* bar code  */}
      <div
        className="d-flex justify-content-between"
        // style={{ marginTop: "-17px" }}
      >
        <div style={{ width: "40%" }}>
          <img
            src={patientRegNameDataUrl}
            alt=""
            className="patient-barcode2"
            width={"100%"}
            height={50}
          />
        </div>
        <div style={{ width: "50%" }}>
          <img
            src={patientIdDataUrl}
            alt=""
            className="patient-barcode2"
            width={"100%"}
            height={50}
          />
        </div>
      </div>

      <UserInfoTable
        user={state?.record?.user}
        userDetails={state?.record}
        age={age}
        physicalData={physicalData}
      />
      <div className="row content-table mt-2">
        <div className="col-sm-12 d-flex flex-column flex-sm-row">
          <div className="col-sm-6">
            <table className="table border border-dark mb-0">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                    colSpan={3}
                  >
                    Medical Examination
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    className="border text-center py-1 border border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    EXAMINATION TYPE
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    RESULTS
                  </th>
                  {/* <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    REF. VALUE
                  </th> */}
                </tr>
              </thead>
              <tbody className="">
                {groupedData && Object.keys(groupedData)?.length > 0
                  ? Object.entries(groupedData)?.map(
                      ([categoryId, categoryData]) => {
                        // console.log(categoryData)
                        if (categoryId === "PHYSICAL") {
                          return null;
                        }
                        if (
                          categoryId === "EAR" ||
                          categoryId === "EYE VISUAL ACUITY"
                        ) {
                          return (
                            <React.Fragment key={categoryId}>
                              <tr className="fw-bold text-nowrap">
                                <td>{categoryData?.name || "---"}</td>
                                <td className="p-0 m-0">
                                  <table className="w-100 m-0 border-dark">
                                    <tr className="">
                                      <td className="border border-dark w-25 border-top-0 ps-1">
                                        R
                                      </td>
                                      <td className="border-bottom border-dark ps-1 w-75">
                                        {categoryData?.items[0]?.result ||
                                          "---"}{" "}
                                        {categoryData?.items[0]?.result &&
                                          categoryData?.items[0]?.result !==
                                            "N/A" &&
                                          categoryData?.items[0]?.investigation
                                            ?.investigation_tests[0]?.uom?.name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderLeft: "1px solid black",
                                          borderRight: "1px solid black",
                                          borderTop: "none",
                                        }}
                                        className="ps-1"
                                      >
                                        L
                                      </td>
                                      <td className="border-bottom-0 ps-1">
                                        {categoryData?.items[1]?.result ||
                                          "---"}{" "}
                                        {categoryData?.items[1]?.result &&
                                          categoryData?.items[1]?.result !==
                                            "N/A" &&
                                          categoryData?.items[1]?.investigation
                                            ?.investigation_tests[1]?.uom?.name}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        }
                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap text-center text-uppercase fw-bold"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              if (data?.investigation_id === 14) {
                                return (
                                  <tr
                                    key={index}
                                    className="w-100 fw-bold border-0"
                                  >
                                    <td className="text-nowrap border-0">
                                      {data?.investigation?.name || "---"}:
                                      <span
                                        className={`border-0 py-1 text-start text-wrap ps-2 ${
                                          data?.result &&
                                          data?.result !== "N/A" &&
                                          data?.result !== "---"
                                            ? data?.result === "Normal"
                                              ? "text-success"
                                              : "text-danger"
                                            : "text-dark"
                                        }`}
                                        colSpan={2}
                                      >
                                        {data?.result || "---"}{" "}
                                        {data?.result &&
                                          data?.result !== "N/A" &&
                                          data?.investigation
                                            ?.investigation_tests[0]?.uom?.name}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              }

                              // Log the data object for debugging
                              return (
                                <tr key={index}>
                                  <td className="w-25 text-nowrap">
                                    {data?.investigation?.name || "---"}
                                  </td>
                                  <td className="border text-start py-1 border-dark text-dark w-75">
                                    {data?.result || "---"}{" "}
                                    {data?.result &&
                                      data?.result !== "N/A" &&
                                      data?.investigation
                                        ?.investigation_tests[0]?.uom?.name}
                                  </td>
                                  {/* <td className="border text-start py-1 border-dark text-dark">
                                  {data?.investigation?.investigation_tests[0]
                                    ?.report_normal_value || ""}
                                </td> */}
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      },
                    )
                  : Object.entries(groupedPhysicalInvTest1)?.map(
                      ([categoryId, categoryData]) => {
                        // console.log("cat", categoryData);
                        if (categoryId === "PHYSICAL") {
                          return null;
                        }
                        if (
                          categoryId === "EAR" ||
                          categoryId === "EYE VISUAL ACUITY"
                        ) {
                          return (
                            <React.Fragment key={categoryId}>
                              <tr className="fw-bold text-nowrap">
                                <td>{categoryData?.name || "---"}</td>
                                <td className="p-0 m-0">
                                  <table className="w-100 m-0 table  border-dark ">
                                    <tbody>
                                      <tr className="">
                                        <td
                                          style={{
                                            borderLeft: "1px solid black",
                                            borderRight: "1px solid black",
                                            borderTop: "none",
                                          }}
                                        >
                                          R
                                        </td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            borderLeft: "1px solid black",
                                            borderRight: "1px solid black",
                                            borderBottom: "none",
                                          }}
                                        >
                                          L
                                        </td>
                                        <td className="border-bottom-0"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        }
                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap text-uppercase text-center fw-bold"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  {data?.id === 14 ? (
                                    <td className="fw-bold text-nowrap w-25">
                                      {data?.investigation_tests[0]?.name ||
                                        "---"}
                                      {data?.id === 14 ? ":" : ""}
                                    </td>
                                  ) : (
                                    <td className="w-50 text-nowrap">
                                      {data?.investigation_tests[0]?.name ||
                                        "---"}
                                      {data?.id === 14 ? ":" : ""}
                                    </td>
                                  )}

                                  {data?.id === 14 ? (
                                    <td className="text-start py-1 "></td>
                                  ) : (
                                    <td className="border text-start py-1 border-dark "></td>
                                  )}
                                  {/* <td className="border text-start py-1 border-dark "></td> */}
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      },
                    )}

                {radiologyData && (
                  <tr className="border border-dark">
                    <td
                      colSpan={2}
                      className="fw-bold"
                      style={{ height: "50px" }}
                    >
                      Chest X-Ray:{" "}
                      <span
                        className={`fw-bold ${
                          radiologyResult === "Normal CXR"
                            ? "text-success"
                            : "text-primary"
                        }`}
                      >
                        {radiologyData?.find(
                          (report) => report?.investigation_test?.id === 35,
                        )?.result || ""}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-column justify-content-start col-sm-6 ps-sm-2 ps-0">
            <table className="table border border-dark mb-0 ">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                    colSpan={3}
                  >
                    Laboratory Investigation
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    className="border text-center py-1 border border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    EXAMINATION TYPE
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap w-50"
                    style={{ color: "black" }}
                  >
                    RESULTS
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap w-50"
                    style={{ color: "black" }}
                  >
                    REF. VALUE
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {groupedPathologyData &&
                Object.keys(groupedPathologyData)?.length > 0
                  ? Object.entries(groupedPathologyData)?.map(
                      ([categoryId, categoryData]) => (
                        <React.Fragment key={categoryId}>
                          <tr>
                            <td
                              colSpan="3"
                              className="text-nowrap text-uppercase fw-bold text-center"
                            >
                              {categoryData?.name}
                            </td>
                          </tr>
                          {categoryData?.items?.map((data, index) => (
                            <tr key={index}>
                              <td className="" style={{ width: "20%" }}>
                                {data?.investigation_test?.investigation
                                  ?.name || "---"}
                              </td>
                              <td
                                className="border text-start py-1 border-dark  text-dark"
                                style={{ width: "40%" }}
                              >
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation_test?.uom?.name}
                              </td>
                              <td
                                className="border text-start text-nowrap py-1 border-dark text-dark"
                                style={{ width: "40%" }}
                              >
                                {data?.investigation_test
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ),
                    )
                  : Object.entries(groupedPathologyInvTest1)?.map(
                      ([categoryId, categoryData]) => {
                        // console.log("this", categoryId);
                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap text-uppercase fw-bold text-center"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className=" ">
                                    {data?.investigation_tests[0]?.name ||
                                      "---"}
                                  </td>
                                  <td className="border text-start py-1 border-dark "></td>
                                  <td className="border text-start py-1 border-dark "></td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      },
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-end mt-3">
        <div className="text-dark medical-form-sign-1">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Radiologist
        </div>
        <div className="text-dark medical-form-sign-2">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Pathologist
        </div>
        <div className="text-dark medical-form-sign-3">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Technologist
        </div>
        <div className="text-dark medical-form-sign-4">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Physician
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end printed-by">
        <div className="text-start text-dark p-0 mt-1">
          Printed By: {state?.record?.user?.created_by}
        </div>
      </div>
      {stateData ? (
        stateData !== 1 ||
        (stateDataInv === 2 && (
          <div className="print-button d-flex gap-2 mt-3 justify-content-end">
            <button
              className="btn btn-outline-secondary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
            {accessPerm(32, 23) && (
              <button
                className="btn btn-primary"
                onClick={() => window.print()}
              >
                Print
              </button>
            )}
          </div>
        ))
      ) : (
        <div className="print-button d-flex gap-2 mt-3 justify-content-end">
          <button
            className="btn btn-outline-secondary"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
          {accessPerm(32, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MedicalFormCopy;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect } from "react";

const RepresentativeSummeryModal = ({ representativeWiseUsers }) => {

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("serialNumber", {
      header: () => <span>SL No</span>,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("representative_user", {
      header: () => <span>Representative</span>,
      cell: (info) => {
        return (
          <span>
            {info?.getValue()} -(
            {info?.row?.original?.representative_mobile?.substr(-2)})
          </span>
        );
      },
    }),
    columnHelper.accessor("count", {
      header: () => <span> Medical</span>,
      cell: (info) => info?.getValue(),
    }),
    columnHelper.accessor("total", {
      header: () => <span>Total</span>,
      cell: (info) => info?.getValue(),
    }),
  ];

  const table = useReactTable({
    data: representativeWiseUsers,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className="modal fade"
        id="repres-summary-modal"
        tabIndex="-1"
        aria-labelledby="repres-summary-modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="repres-summary-modalLabel">
                Representative Summary | Total: {representativeWiseUsers?.length}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="card patient-information">
                <div className="border p-2 rounded">
                  <table className="table align-middle table-hover table-bordered mb-0 rounded">
                    <thead className="bg-primary text-white">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup?.id}>
                          {headerGroup.headers.map((header) => (
                            <th key={header?.id} className={` p-1`}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="p-1">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(RepresentativeSummeryModal);

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { utils, writeFile } from "xlsx";
import { useNavigate } from "react-router-dom";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../utils/Loader/Loading";
import toast from "react-hot-toast";
import COllectionTable from "./components/CollectionTable";

const CollectionReport = () => {
  const { accessPerm } = useContext(UserContext);
  const { introducerUsers, packages } = useContext(UserContext);
  const [collectionData, setCollectionData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [activeRowId, setActiveRowId] = useState(null);
  const [regInputField, setRegInputField] = useState("");
  const [refInputField, setRefInputField] = useState("");
  const [receivedAmounts, setReceivedAmounts] = useState({});
  const [paid, setPaid] = useState({});
  const [commission, setCommission] = useState({});
  const [dues, setDues] = useState({});
  const [netTotal, setNetTotal] = useState({});
  const [remarks, setRemarks] = useState({});
  const [initialRowSelection, setInitialRowSelection] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState(true);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalDues, setTotalDues] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  // State for input fields, assuming you have these fields
  const [inputValues, setInputValues] = useState([]);

  const [toDateSearch, setToDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd"),
  );
  const [fromDateSearch, setFromDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd"),
  );

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const tbodyRef = useRef(null);

  const [searchedData, setSearchedData] = useState();
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
    dataWise: "",
  });
  const [searchInputs, setSearchInputs] = useState({
    from_date: "",
    to_date: "",
    status: "",
    packageId: "",
    introducer: "",
    country: "",
    company: "",
    delegate: "",
    regNo: "",
    dataWise: "",
  });

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  // Function to calculate commission and dues
  const calculateCommissionAndDues = useCallback((row, receivedAmount) => {
    let netAmount = row?.package?.discounted_price || 0;

    // If the role is 5, calculate the net amount from the tests
    if (row?.user?.role_id === 5) {
      netAmount = row?.user?.tests
        ?.map((test) => test?.investigation?.discounted_price || 0)
        .reduce((sum, price) => sum + price, 0);
    }

    const commission = netAmount - receivedAmount;
    const dues = commission >= 0 ? commission : 0;

    return { commission: commission < 0 ? Math.abs(commission) : 0, dues };
  }, []);

  const calculateNetTotal = (row) => {
    if (!netTotal) {
      let netAmount;
      if (row?.user?.role_id === 5) {
        netAmount = row?.user?.tests
          ?.map((test) => test?.investigation?.discounted_price || 0)
          .reduce((sum, price) => sum + price, 0);
      } else {
        netAmount = row?.package?.discounted_price || 0;
      }
      setNetTotal(netAmount);
    }
  };

  // Function to handle key press on a row
  const handleKeyDown = (event, row) => {
    console.log(row);
    const targetId = Number(row?.id);
    const rows = Array.from(tbodyRef.current.children); // Get all rows
    const currentRow = event.target.closest("tr"); // Get current row
    const inputs = Array.from(
      currentRow.querySelectorAll('input[type="text"]'),
    ); // Get all inputs in the current row
    const inputIndex = inputs.indexOf(event.target); // Get current input index in the row
    const rowIds = rows.map((child) => Number(child.id)); // Get row ids
    const currentIndex = rowIds.indexOf(targetId); // Find the current row index

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevent default for up/down arrow keys
    }

    switch (event.key) {
      case "ArrowUp":
        if (currentIndex > 0) {
          const prevRow = rows[currentIndex - 1]; // Get previous row
          const prevInput =
            prevRow.querySelectorAll('input[type="text"]')[inputIndex]; // Get the input in the same column of the previous row
          if (prevInput) {
            prevInput.focus();
            prevInput.setSelectionRange(0, prevInput.value.length); // Highlight input text
          }
        }
        break;
      case "ArrowDown":
        if (currentIndex < rows.length - 1) {
          const nextRow = rows[currentIndex + 1]; // Get next row
          const nextInput =
            nextRow.querySelectorAll('input[type="text"]')[inputIndex]; // Get the input in the same column of the next row
          if (nextInput) {
            nextInput.focus();
            nextInput.setSelectionRange(0, nextInput.value.length); // Highlight input text
          }
        }
        break;
      case "ArrowLeft":
        if (inputIndex > 0) {
          const prevInput = inputs[inputIndex - 1]; // Get the previous input in the current row
          if (prevInput) {
            prevInput.focus();
            prevInput.setSelectionRange(0, prevInput.value.length); // Highlight input text
          }
        }
        break;
      case "ArrowRight":
        if (inputIndex < inputs.length - 1) {
          const nextInput = inputs[inputIndex + 1]; // Get the next input in the current row
          if (nextInput) {
            nextInput.focus();
            nextInput.setSelectionRange(0, nextInput.value.length); // Highlight input text
          }
        }
        break;
      default:
        // Allow default behavior for other keys (like typing)
        break;
    }
  };

  const handleFieldInputChange = useCallback(
    (id, value, field, info) => {
      const newValue = Number(value) || 0; // Convert input to number and ensure default to 0 if NaN

      // Fetch the row data
      const row = info?.row?.original;

      // Declare variables for new commission and dues
      let newCommission = row?.commission || 0;
      let newDues = row?.dues || 0;

      // Recalculate commission and dues if the receivedAmount is updated
      if (field === "receivedAmount") {
        const { commission, dues } = calculateCommissionAndDues(row, newValue);
        newCommission = commission;
        newDues = dues;

        // Update the collectionData to reflect the changes immediately
        setCollectionData((prevCollectionData) => {
          return prevCollectionData.map((item) =>
            item.id === row.user_id
              ? {
                  ...item,
                  receivedAmount: newValue,
                  commission: newCommission,
                  dues: newDues,
                }
              : item,
          );
        });
      }

      // Update inputValues state for the specific row
      setInputValues((prev) => {
        const newData = [...prev];
        newData[id] = {
          ...newData[id],
          [field]: newValue,
          commission: newCommission,
          dues: newDues,
        };

        console.log("Updated inputValues:", newData); // Debug log
        calculateTotals(newData); // Recalculate totals after input change
        return newData;
      });

      // Update totals based on new input values
      setTotalReceived(
        (prev) => prev + (field === "receivedAmount" ? newValue : 0),
      );
      setTotalNetAmount(
        (prev) => prev + (field === "netAmount" ? newValue : 0),
      );
      setTotalBalance((prev) => prev + (field === "commission" ? newValue : 0));
      setTotalDues((prev) => prev + (field === "dues" ? newValue : 0));
      setTotalPaid((prev) => prev + (field === "paid" ? newValue : 0));
    },
    [calculateCommissionAndDues],
  );

  const downloadExcel = () => {
    // Extract headers from the table
    const headers = [
      "SL", // Serial Number first
      "Reg. No",
      "Passenger",
      "Agency",
      "Country", // Add Country column
      "Test/Ref By", // Add Test/Ref By column
      "Received",
      "Net Amount",
      "Balance",
      "Dues",
      "Paid",
      "Remarks",
    ];

    // Prepare data for the worksheet
    const formattedData = userDetails.map((user, index) => {
      // Determine "Test/Ref By" value based on role_id
      let testRefBy = "";
      // if (user.user?.role_id === 5) {
      //   const tests = user.user?.tests || [];
      //   let investigation31Added = false;

      //   testRefBy =
      //     tests
      //       .filter((test) => {
      //         if (test?.investigation?.id === 31) {
      //           if (investigation31Added) return false;
      //           investigation31Added = true;
      //           return true;
      //         }
      //         return true;
      //       })
      //       .map((test) => test?.investigation?.name)
      //       .filter(Boolean)
      //       .join(", ") || "No Investigations";
      // } else {
      //   testRefBy = user.package?.name || "";
      // }

      return {
        SL: index + 1, // Serial number
        "Reg. No": `${user?.user?.reg_prefix || ""}${
          user?.user?.registration_no || ""
        }`,
        Passenger: user.user?.f_name || "",
        Agency: user.introducer_user?.f_name || "",
        Country: user.country?.name || "", // Get Country from userDetails
        // "Test/Ref By": testRefBy, // Assign Test/Ref By value
        "Test/Ref By": "Individual Test", // Assign Test/Ref By value
        Received:
          receivedAmounts[user.user.id] || user?.user_payment?.paid_amount || 0, // Rename to Paid
        "Net Amount": user?.user_payment?.net_amount || 0,
        Balance: user?.user_payment?.commission || 0,
        Dues: user?.user_payment?.due || 0,
        Paid: user?.user_payment?.paid || 0,
        Remarks: user?.user_payment?.remarks || "",
      };
    });

    // Calculate totals for the relevant columns
    const totalReceived = formattedData.reduce(
      (sum, row) => sum + row["Received"],
      0,
    );
    const totalPaid = formattedData.reduce((sum, row) => sum + row["Paid"], 0);
    const totalCommission = formattedData.reduce(
      (sum, row) => sum + row["Balance"],
      0,
    );
    const totalNetAmount = formattedData.reduce(
      (sum, row) => sum + row["Net Amount"],
      0,
    );
    const totalDues = formattedData.reduce((sum, row) => sum + row["Dues"], 0);

    // Add totals row to formattedData with the total under Test/Ref By
    formattedData.push({
      SL: "", // Leave SL blank for the total row
      "Reg. No": "",
      Passenger: "",
      Agency: "",
      Country: "", // Leave Country blank for the total row
      "Test/Ref By": "Total", // Add Total under Test/Ref By
      Received: totalReceived, // Use totalReceived
      "Net Amount": totalNetAmount,
      Balance: totalCommission,
      Dues: totalDues,
      Paid: totalPaid, // Use totalPaid
      Remarks: "",
    });

    // Create worksheet from the formatted data
    const worksheet = utils.json_to_sheet(formattedData, { header: headers });

    // Create a new workbook and append the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Collection Data");

    // Generate a file name
    const fileName = `Collection_Report_${format(new Date(), "yyyyMMdd")}.xlsx`;

    // Export the workbook
    writeFile(workbook, fileName);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const regNo = form.invoice_reg_mobile.value;
    setToDateSearch(toDate);
    setFromDateSearch(fromDateSearch);
    setFilterData({
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      //   country,
      //   company,
      //   delegate,
      //   representative,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
    });
    const filteredData = {
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
    };
    setSearchedData(searchInputs);
    methodFilterPatientsDetails(filteredData, "form");
  };

  const saveCollection = async () => {
    console.log("collectionData", collectionData);
    return;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/collection/payment`,
        collectionData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filteredData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-collection-report`,
        filteredData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data;
      if (data) {
        if (data?.userDetails) {
          const userDetails = data?.userDetails?.map((user) => {
            // Calculate net amount based on user role and tests/packages
            const netAmount =
              user?.user_payment?.net_amount ||
              (user?.user?.role_id === 5
                ? user?.user?.tests
                    ?.map((test) => test.investigation?.discounted_price || 0)
                    ?.reduce((sum, price) => sum + price, 0)
                : user?.package?.discounted_price);

            // Calculate the diff between netAmount and inputValue
            const diff =
              parseFloat(netAmount || 0) -
              parseFloat(user?.user_payment?.paid_amount || 0);

            // Set due and commission based on the diff calculation
            return {
              ...user,
              user_payment: {
                ...user.user_payment,
                net_amount:
                  (!!user?.user_payment?.net_amount &&
                    user?.user_payment?.net_amount) ||
                  (user?.user_payment?.paid_amount <= 0 ? 0 : netAmount) ||
                  0,
                due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
                due:
                  user?.user_payment?.paid > 0
                    ? user?.user_payment?.due
                    : diff > 0
                      ? diff
                      : 0,
                commission:
                  user?.user_payment?.commission ||
                  (diff <= 0 ? Math.abs(diff) : 0),
              },
            };
          });

          setUserDetails(userDetails);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;

          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.reg_prefix || ""}
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Passenger</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          // const representative_Mob =
          //   info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
          //     -2
          //   );
          return (
            <div>
              <span className="d-flex justify-content-start">
                <span className="text-center d-flex justify-content-start">
                  {value1}
                </span>
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Country
          </span>
        ),
        cell: (info) => {
          const country = info?.row?.original?.country?.name;
          return <span>{country}</span>;
        },
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center w-100">
            Test/Ref By
          </span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original?.user;

          // Check if role_id is 5
          if (user?.role_id === 5) {
            // Get the tests array from the user
            const tests = user.tests || [];

            let investigation31Added = false;

            const investigationNames = tests
              .filter((test) => {
                if (test?.investigation?.id === 31) {
                  if (investigation31Added) {
                    return false;
                  }
                  investigation31Added = true;
                  return true;
                }
                return true;
              })
              .map((test) => test.investigation?.name)
              .filter(Boolean)
              .join(", ");

            return (
              <div className="d-flex justify-content-start">
                {/* {investigationNames || "No Investigations"} */}
                Individual Test
              </div>
            );
          } else {
            // If role_id is not 5, render the default value
            const reversedIndex = userDetails.length - index - 1;
            return (
              <div className="d-flex justify-content-start">
                {info.renderValue(reversedIndex)}
              </div>
            );
          }
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Received
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <input
              className="form-control border border-dark"
              ref={row.id === activeRowId ? inputRef : null}
              type="text"
              style={{ width: "80px" }}
              defaultValue={
                receivedAmounts[row.id] || row?.user_payment?.paid_amount || 0
              }
              onChange={(e) =>
                handleFieldInputChange(
                  info.row.id,
                  e.target.value,
                  "receivedAmount",
                  info,
                )
              }
              onKeyDown={(e) => handleKeyDown(e, row)} // Attach keydown for navigation
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Net Amount</span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original;
          const row = info.row.original;

          return (
            <input
              className="form-control border border-dark"
              ref={info?.row?.id === activeRowId ? inputRef : null}
              type="text"
              style={{ width: "80px" }}
              defaultValue={row?.user_payment?.net_amount || 0}
              onChange={(e) =>
                handleFieldInputChange(
                  info.row.id,
                  e.target.value,
                  "netAmount",
                  info,
                )
              }
              onKeyDown={(e) => handleKeyDown(e, row)} // Attach keydown for navigation
            />
          );
        },
        // },
      }),
      columnHelper.accessor("commission", {
        header: () => (
          <span className="d-flex justify-content-center">Commission</span>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          const commissionValue = inputValues[rowIndex]?.commission || 0; // Use the value from inputValues
          return (
            <input
              type="text"
              value={commissionValue}
              onChange={(e) =>
                handleFieldInputChange(
                  rowIndex,
                  e.target.value,
                  "commission",
                  info,
                )
              }
              onKeyDown={(e) => handleKeyDown(e, info.row)}
            />
          );
        },
      }),
      columnHelper.accessor("dues", {
        header: () => (
          <span className="d-flex justify-content-center">Dues</span>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          const duesValue = inputValues[rowIndex]?.dues || 0; // Use the value from inputValues
          return (
            <input
              type="text"
              value={duesValue}
              onChange={(e) =>
                handleFieldInputChange(rowIndex, e.target.value, "dues", info)
              }
              onKeyDown={(e) => handleKeyDown(e, info.row)}
            />
          );
        },
      }),

      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Paid
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          // return <span>{dues}</span>;
          return (
            <input
              className="form-control border border-dark"
              ref={info?.row?.id === activeRowId ? inputRef : null}
              type="text"
              style={{ width: "80px" }}
              defaultValue={row?.user_payment?.paid || 0}
              // value={paid || 0}
              onChange={(e) =>
                handleFieldInputChange(
                  info.row.id,
                  e.target.value,
                  "paid",
                  info,
                )
              }
              onKeyDown={(e) => handleKeyDown(e, row)} // Attach keydown for navigation
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Remarks
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <input
              ref={info?.row?.id === activeRowId ? inputRef : null}
              type="text"
              name="remarks"
              defaultValue={row?.user_payment?.remarks}
              className="form-control border border-dark ps-1"
              onChange={(e) =>
                handleFieldInputChange(
                  info.row.id,
                  e.target.value,
                  "remarks",
                  info,
                )
              }
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
    ],
    [inputValues],
  );

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/collection-report-all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        if (data?.userDetails) {
          const userDetailsData = data?.userDetails?.map((user) => {
            // Calculate net amount based on user role and tests/packages
            const netAmount =
              user?.user_payment?.net_amount ||
              (user?.user?.role_id === 5
                ? user?.user?.tests
                    ?.map((test) => test.investigation?.discounted_price || 0)
                    ?.reduce((sum, price) => sum + price, 0)
                : user?.package?.discounted_price);

            // Calculate the diff between netAmount and inputValue
            const diff =
              parseFloat(netAmount || 0) -
              parseFloat(user?.user_payment?.paid_amount || 0);

            // Set due and commission based on the diff calculation
            return {
              ...user,
              user_payment: {
                ...user.user_payment,
                net_amount:
                  (!!user?.user_payment?.net_amount &&
                    user?.user_payment?.net_amount) ||
                  (user?.user_payment?.paid_amount <= 0 ? 0 : netAmount) ||
                  0,
                due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
                due:
                  user?.user_payment?.paid > 0
                    ? user?.user_payment?.due
                    : diff > 0
                      ? diff
                      : 0,
                commission:
                  user?.user_payment?.commission ||
                  (diff <= 0 ? Math.abs(diff) : 0),
              },
            };
          });

          setUserDetails(userDetailsData);
        }
        // setCountryWiseUsers(data?.countryWiseUser);
        // setPackageWiseUsers(data?.packageWiseUser);
        // setIntroducerWiseUsers(data?.introducerWiseUser);
        // setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        // status: "",
        packageId: "",
        introducer: "",
        // country: "",
        // company: "",
        // delegate: "",
        regNo: "",
        refNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        // country: null,
        // company: null,
        // delegate: null,
      });

      setRegInputField("");
      setRefInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (name, value) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (selectedRows) => {
      setRowSelection(selectedRows);
    },
  });
  useEffect(() => {
    getUserDetails();
  }, [table]);

  useEffect(() => {
    if (activeRowId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [receivedAmounts, activeRowId]);

  const calculateTotals = (data) => {
    const totalReceived = data.reduce(
      (sum, item) => sum + (item.receivedAmount || 0),
      0,
    );
    const totalNetAmount = data.reduce(
      (sum, item) => sum + (item.netAmount || 0),
      0,
    );
    const totalBalance = data.reduce(
      (sum, item) => sum + (item.commission || 0),
      0,
    );
    const totalDues = data.reduce((sum, item) => sum + (item.dues || 0), 0);
    const totalPaid = data.reduce((sum, item) => sum + (item.paid || 0), 0);

    setTotalReceived(totalReceived);
    setTotalNetAmount(totalNetAmount);
    setTotalBalance(totalBalance);
    setTotalDues(totalDues);
    setTotalPaid(totalPaid);
  };

  useEffect(() => {
    const initialData = table.getRowModel().rows.map((row) => ({
      receivedAmount: row?.original?.user_payment?.paid_amount || 0,
      netAmount: row?.original?.user_payment?.net_amount || 0,
      commission: row?.original?.user_payment?.commission || 0,
      due: row?.original?.user_payment?.due || 0,
      paid: row?.original?.user_payment?.paid || 0,
    }));

    setInputValues(initialData);
    // Calculate initial totals
    calculateTotals(initialData);
  }, [table.getRowModel().rows]);

  useEffect(() => {
    const initialData = table.getRowModel().rows; // Assuming this retrieves your row data

    let initialReceived = 0;
    let initialNetAmount = 0;
    let initialBalance = 0;
    let initialDues = 0;
    let initialPaid = 0;

    initialData.forEach((row) => {
      const { user_payment } = row.original;
      if (user_payment) {
        const { paid_amount, net_amount, commission, due, paid } = user_payment;

        initialReceived += paid_amount || 0;
        initialNetAmount += net_amount || 0;
        initialBalance += commission || 0;
        initialDues += due || 0;
        initialPaid += paid || 0;
      }
    });

    setTotalReceived(initialReceived);
    setTotalNetAmount(initialNetAmount);
    setTotalBalance(initialBalance);
    setTotalDues(initialDues);
    setTotalPaid(initialPaid);
  }, [table.getRowModel().rows]); // Dependency on your data

  useEffect(() => {
    // Calculate commission and dues for all rows and update input fields
    userDetails.forEach((row) => {
      const { commission, dues } = calculateCommissionAndDues(row);
      receivedAmounts[row.id] = row.user_payment?.paid_amount || 0; // Update receivedAmounts for later use
      setCommission(commission);
      setDues(dues);
    });
  }, [userDetails]);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Collection Report
                </h6>
                <div className="d-flex flex-wrap align-items-end justify-content-between">
                  <form onSubmit={(e) => handlerOnSearch(e)} className="">
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("from_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("to_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("package", selectedOptions);
                              handleInputChange("package", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) => {
                              handleSelect("introducer", selectedOptions);
                              handleInputChange("introducer", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>

                      <div className="p-1">
                        <label className="col-form-label">Data Wise</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("package", selectedOptions);
                              handleInputChange("package", selectedOptions);
                            }}
                          />
                        </div>
                      </div>

                      <div className="p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            type="text"
                            onChange={(e) => {
                              handleInputChange("fromDate", e.target.value);
                              setRegInputField(e.target.value);
                            }}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                            style={{ width: "180px", height: "37px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-1 p-1 d-flex align-items-end justify-content-between">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <button
                            type="submit"
                            className="btn btn-primary px-4 text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning px-4 border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex align-items-end mb-1">
                    <div className="d-flex align-items-center gap-2 justify-content-end mt-0">
                      {accessPerm(67, 24) && (
                        <button
                          className="btn bg-success px-4 border border-3 text-white"
                          onClick={saveCollection}
                        >
                          Save
                        </button>
                      )}
                      <button
                        className="btn bg-danger px-4 border border-3 text-white"
                        onClick={() =>
                          navigate("/dashboard/medical-collection/print", {
                            state: filterData,
                          })
                        }
                      >
                        PDF
                      </button>
                      <button
                        onClick={downloadExcel}
                        className="btn btn-primary px-4 border border-3"
                      >
                        Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <COllectionTable userDetails={userDetails} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionReport;

import axios from "axios";
import React, { useContext } from "react";
import { UserContext } from "../../Context/UserContextAPI";

const Balance = () => {
  const { currentUser } = useContext(UserContext);

  const payment = async () => {
    // const options = {
    //   method: "POST",
    //   headers: {
    //     accept: "application/json",
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     amount: "1",
    //     merchantInvoiceNumber: "",
    //     merchantAssociationInfo: "introducer",
    //   }),
    // };

    // try {
    //   const { data } = await axios.post(
    //     `${process.env.REACT_APP_BKASH_URL}/checkout/payment/create`,
    //     options,
    //     { withCredentials: true }
    //   );
    //   window.location.href = data?.bkashURL
    //   console.log(data);
    // } catch (error) {
    //   console.log(error?.response);
    // }
  };
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase text-dark">User's Wallet</h6>
              <div className="col">
                {/* Create User trigger modal Button */}
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card shadow-sm border-0 overflow-hidden">
                  <div className="card-body p-2">
                    <div className="profile-avatar text-center">
                      {currentUser?.user?.image === null ? (
                        <img
                          src="/userimg.png"
                          className="rounded-circle shadow"
                          width={120}
                          height={120}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/users/${
                            currentUser?.image
                              ? currentUser?.image
                              : currentUser?.user?.image
                          }`}
                          className="rounded-circle shadow"
                          width={120}
                          height={120}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="text-center mt-4 text-dark">
                      <h4 className="mb-1">
                        {currentUser?.f_name
                          ? currentUser?.f_name
                          : currentUser?.user?.f_name}
                        {currentUser?.l_name
                          ? currentUser?.l_name
                          : currentUser?.user?.l_name}
                      </h4>
                      <h6 className="mb-1">
                        {currentUser?.role?.name
                          ? currentUser?.role?.name
                          : currentUser?.user?.role?.name}
                      </h6>
                      <div className="mt-4" />
                      <h4 className="mb-1 fs-5">
                        Wallet: {currentUser?.wallet || 0}
                      </h4>
                    </div>
                    <div className="mt-2 d-flex justify-content-center gap-2">
                      {currentUser?.wallet <= 0 && (
                        <button
                          className="btn btn-success px-4 py-3 fs-6"
                          onClick={payment}
                        >
                          Add Money
                        </button>
                      )}
                      {currentUser?.wallet > 0 && (
                        <button className="btn btn-success px-4 py-3 fs-6">
                          Withdraw Money
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;

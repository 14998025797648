import generateBarcode from "../../../../utils/generateBarCode";
import QRCode from "qrcode.react";

import JsBarcode from "jsbarcode";
const BarCodeInInvoice = ({ userDetail, age }) => {
  const userNameWithAge = `${userDetail?.user?.f_name} ${age ? `-${age}` : ``}`;
  const patientRegNameDataUrl = generateBarcode(userNameWithAge);

  const patientIdDataUrl = generateBarcode(
    String(userDetail?.user?.registration_no),
  );
  const generateWidthByLength = (name) => {
    const length = name?.trim()?.length;
    if (length <= 5) {
      return "25%";
    } else if (length <= 10) {
      return "35%";
    } else if (length <= 15) {
      return "50%";
    } else {
      return "60%";
    }
  };
  const userNameBarcodeWidth = generateWidthByLength(userNameWithAge);



  return (
    <div style={{ width: "100%" }} className="d-flex justify-content-between">
      <div className="" style={{ width: "20%" }}>
        <img
          src={patientIdDataUrl}
          alt=""
          className="patient-barcode2"
          width={"100%"}
          height={"40px"}
          style={{ marginLeft: "-10px" }}
        />
      </div>

      <div className="" style={{ width: `${userNameBarcodeWidth}` }}>
        <img
          src={patientRegNameDataUrl}
          alt=""
          className="patient-barcode2"
          width={"100%"}
          height={"40px"}
        />
      </div>
    </div>
  );
};
export default BarCodeInInvoice;

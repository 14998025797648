import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import "./PrintRegistration.scss";
import "../Investigation/Report/Certificates/HeldUp.scss";
import "../Investigation/Report/Certificates/Unfit.scss";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";

const PrintPhotoRegistration = () => {
  const pdfRef = useRef();
  const { state } = useLocation();
  const { currentUser, settingsData } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });

  const now = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(now);

  const getStatusColor = (status) => {
    switch (status) {
      case 2:
        return "success";
      case 3:
        return "danger";
      case 4:
        return "primary";
      default:
        return "dark"; // You can set a default color if needed
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 2:
        return "Fit";
      case 3:
        return "Unfit";
      case 4:
        return "Held-up";
      default:
        return "Processing"; // You can set a default text if needed
    }
  };

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };

    data.forEach((userInfo) => {
      switch (userInfo?.user?.status) {
        case 1:
          // console.log(userInfo?.user?.status);
          counts.status1 = counts.status1 + 1;
          break;
        case 2:
          // console.log(userInfo?.user?.status);
          counts.status2 = counts.status2 + 1;
          break;
        case 3:
          // console.log(userInfo?.user?.status);
          counts.status3 = counts.status3 + 1;
          break;
        case 4:
          // console.log(userInfo?.user?.status);
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });

    setStatusCounts(counts);
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row) => {
          return (
            <div className="text-center d-flex justify-content-center fw-bold">
              <span className="text-center">{row?.row?.index + 1}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">
            Date
            <br />
            Invoice Number
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.createdAt ? (
                <>{`${info.row.original.user.createdAt.split("T")[0]}`}</>
              ) : (
                "---"
              )}

              {info?.row?.original?.user?.registration_no ? (
                <>
                  <br />
                  {`${info?.row?.original?.user?.reg_prefix || ""}${
                    info?.row?.original?.user?.registration_no
                  }`}{" "}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.photo", {
        header: () => (
          <span className="d-flex justify-content-center">Photo</span>
        ),
        cell: (info, index) => {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "80px" }}
            >
              {info?.row?.original?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${info?.row?.original?.user?.image}`}
                  className="img-fluid object-fit-contain mx-auto"
                  style={{ width: "90px", objectFit: "fill" }}
                  alt="patient"
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid object-fit-contain mx-auto"
                  style={{ width: "90px", objectFit: "contain" }}
                />
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">
            Reg. No
            <br />
            Name
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.registration_no ? (
                <>
                  {`${info?.row?.original?.user?.reg_prefix || ""}${
                    info?.row?.original?.user?.registration_no
                  }`}{" "}
                </>
              ) : (
                "---"
              )}

              {info?.row?.original?.user?.f_name ? (
                <>
                  <br />
                  {`${info.row.original.user.f_name}`}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">
            Passport No
            <br />
            DOB
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.passport_no ? (
                <>{`${info.row.original.user.passport_no}`}</>
              ) : (
                "---"
              )}

              {info?.row?.original?.user?.date_of_birth ? (
                <>
                  <br />
                  {`${info.row.original.user.date_of_birth.split("T")[0]}`}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span
            className="d-flex justify-content-center
        "
          >
            Father's Name
            <br />
            Introducer Name
            <br />
            M.R. Name
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.father_name ? (
                <>{`${info.row.original.father_name}`}</>
              ) : (
                "---"
              )}

              {info?.row?.original?.introducer_user?.f_name ? (
                <>
                  <br /> {`${info.row.original.introducer_user.f_name}`}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}

              {info?.row?.original?.introducer_user?.user_users_maps[0]
                ?.representative_user_map?.f_name ? (
                <>
                  <br />
                  {`${info.row.original.introducer_user.user_users_maps[0].representative_user_map.f_name}`}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("country.shortname", {
        header: () => (
          <span className="d-flex justify-content-center">
            Country
            <br />
            Company
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.country?.name ? (
                <>{`${info.row.original.country.name}`}</>
              ) : (
                "---"
              )}

              {info?.row?.original?.company_user?.f_name ? (
                <>
                  <br />
                  {`${info.row.original.company_user.f_name}`}
                </>
              ) : (
                <>
                  <br />
                  {"---"}
                </>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.status", {
        header: () => (
          <span className="d-flex justify-content-center">
            Status
            <br />
            Remarks
          </span>
        ),
        cell: (info, index) => {
          const comments =
            info?.row?.original?.user?.user_test_comments[0]?.comments;
          return (
            <div className="d-flex justify-content-start">
              <span
                className={`text-left ${getStatusColor(
                  info?.row?.original?.user?.status
                )}`}
              >
                {info?.row?.original?.user?.status === 2 ||
                (info?.row?.original?.user?.status !== 3 &&
                  info?.row?.original?.user?.status !== 4) ? (
                  `${getStatusText(info?.row?.original?.user?.status)}`
                ) : (
                  <>
                    {`${getStatusText(info?.row?.original?.user?.status)}`}
                    <br />
                    {info?.row?.original?.user?.radiology_tests &&
                    info?.row?.original?.user?.radiology_tests?.length &&
                    !!info?.row?.original?.user?.radiology_tests[0]?.result ? (
                      info?.row?.original?.user?.radiology_tests[0]?.result ===
                        "Normal CXR" ||
                      info?.row?.original?.user?.radiology_tests[0]?.result ===
                        "" ? (
                        ""
                      ) : (
                        <>
                          X-Ray:{" "}
                          {
                            info?.row?.original?.user?.radiology_tests[0]
                              ?.result
                          }
                          <br />
                        </>
                      )
                    ) : (
                      ""
                    )}
                    {info?.row?.original?.user?.physical_tests &&
                    info?.row?.original?.user?.physical_tests?.length &&
                    !!info?.row?.original?.user?.physical_tests[0]?.result ? (
                      info?.row?.original?.user?.physical_tests[0]?.result ===
                        "Normal" ||
                      info?.row?.original?.user?.physical_tests[0]?.result ===
                        "" ? (
                        ""
                      ) : (
                        <>
                          Physical:{" "}
                          {info?.row?.original?.user?.physical_tests[0]?.result}
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </>
                )}
                {info?.row?.original?.user?.user_test_comments[0]?.comments ? (
                  <>
                    Pathology:{" "}
                    <span dangerouslySetInnerHTML={{ __html: comments }} />
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: state?.checkedUsers,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const getUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        // setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          setUserDetails(data?.userDetails);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGeneratePDF = async () => {
    const htmlContent = pdfRef.current.outerHTML;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/generate-pdf`,
        { htmlContent },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "download.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up URL object after download
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleGeneratePDF();
    }, 1200);
  }, []);

  useEffect(() => {
    setUserDetails(state?.checkedUsers);
    getUserDetails();
  }, []);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="print-reg-header">
              <div className="card shadow-none mb-3">
                <div className="border p-3 rounded">
                  {state?.searchedData?.introducer ? (
                    <h6 className="mb-0 text-uppercase text-center fw-bold">
                      {state?.searchedData?.introducer?.label}{" "}
                    </h6>
                  ) : (
                    <h6 className="mb-0 text-uppercase text-center fw-bold text-dark">
                      All Registration
                    </h6>
                  )}
                  <div className="mb-0 text-center fw-light">
                    From{" "}
                    {state?.searchedData?.from_date
                      ? format(
                          new Date(state?.searchedData?.from_date),
                          "dd-MMM-yyyy",
                          { locale: enUS } // or use es for Spanish, adjust as needed
                        )
                      : format(new Date(), "dd-MMM-yyyy", {
                          locale: enUS,
                        })}{" "}
                    To{" "}
                    {state?.searchedData?.to_date
                      ? format(
                          new Date(state?.searchedData?.to_date),
                          "dd-MMM-yyyy",
                          { locale: enUS } // or use es for Spanish, adjust as needed
                        )
                      : format(new Date(), "dd-MMM-yyyy", { locale: enUS })}
                  </div>
                </div>
              </div>
            </div>

            <table
              className="table align-middle table-hover table-bordered mb-0 rounded p-1"
              style={{ fontSize: "11px" }}
              ref={pdfRef}
              // id="mytable"
            >
              <thead className="bg-primary">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => (
                      <th
                        key={i}
                        id={`th-${i}`}
                        className="text-light p-1"
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, i) => {
                  const {
                    original: {
                      user: {
                        status,
                        is_radiology_done,
                        is_pathology_done,
                        is_physical_done,
                      },
                    },
                  } = row;
                  const collection = row?.original?.user?.user_collections?.map(
                    (collection) =>
                      collection.investigation_category?.investigation_group_id
                  );

                  const uniqueCollection = [...new Set(collection)];

                  const done =
                    (uniqueCollection?.length < 2 &&
                      (is_pathology_done ||
                        is_physical_done ||
                        is_radiology_done)) ||
                    (uniqueCollection?.length >= 2 &&
                      uniqueCollection?.length < 3 &&
                      (is_pathology_done ? 1 : 0) +
                        (is_physical_done ? 1 : 0) +
                        (is_radiology_done ? 1 : 0) >=
                        2) ||
                    (uniqueCollection?.length >= 3 &&
                      is_pathology_done &&
                      is_physical_done &&
                      is_radiology_done);

                  return (
                    <tr
                      key={i}
                      className={`${done && status === 4 && "text-primary"} ${
                        done && status === 2 && "text-success"
                      } ${done && status === 3 && "text-danger"} ${
                        status === 1 && "text-dark"
                      } 
                          `}
                    >
                      {row.getVisibleCells().map((cell, i) => (
                        <td key={i} className="p-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="print-reg-footer">
          <div className="d-flex flex-column align-items-center justify-content-center pb-3 text-dark">
            <div>
              Created at {formattedDate} by {currentUser?.f_name}
            </div>
            <div>
              Copyright &copy; {new Date().getFullYear()}{" "}
              {settingsData?.website_name}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintPhotoRegistration;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

const IntroducerListModal = ({ introducerList }) => {
  // console.log(introducerList);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('introducer_user_map.registration_no', {
      header: () => <span>Number</span>,
      cell: (info) => (
        <span>{info.getValue()}</span>
        // console.log(info.getValue())
      ),
    }),
    columnHelper.accessor('introducer_user_map.f_name', {
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('introducer_user_map.status', {
      header: () => <span>Status</span>,
      cell: (info) => (info.getValue() === 1 ? 'Active' : 'Inactive'),
    }),
  ];

  const table = useReactTable({
    data: introducerList,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className='modal fade'
        id='introducer-list-modal'
        tabIndex='-1'
        aria-labelledby='introducer-list-modalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='introducer-list-modalLabel'>
                Introducer List
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='card patient-information'>
                <div className='border p-2 rounded'>
                  <table className='table align-middle table-hover table-bordered mb-0 rounded'>
                    <thead className='bg-primary text-white'>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup?.id}>
                          {headerGroup.headers.map((header) => (
                            <th key={header?.id} className={` p-1`}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className='p-1'>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroducerListModal;

import { useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";

const CollectionTableDataShow = ({ userDetails: data, setUserDetails }) => {
  const inputRefs = useRef([]);

  // Function to handle updates and calculations
  const handleChange = (index, field, value) => {
    setUserDetails((prevData) => {
      const updatedData = [...prevData];
      const user = updatedData[index];
      const payment = { ...user.user_payment, [field]: value };

      // Perform necessary calculations based on field
      if (field === "paid_amount") {
        const diff =
          parseFloat(payment.net_amount || 0) - parseFloat(value || 0);
        payment.commission = diff > 0 ? 0 : diff * -1;
        payment.due = diff > 0 ? diff : 0;
        payment.due_initial = payment.due;
      } else if (field === "net_amount") {
        const diff =
          parseFloat(value || 0) - parseFloat(payment.paid_amount || 0);
        payment.commission = diff > 0 ? 0 : diff * -1;
        payment.due = diff > 0 ? diff : 0;
        payment.due_initial = payment.due;
      } else if (field === "paid") {
        const diff =
          parseFloat(payment.due_initial || 0) - parseFloat(value || 0);
        payment.due = diff > 0 ? diff : diff * -1;
      }
      payment.hasChanged = true;
      updatedData[index].user_payment = payment;
      return updatedData;
    });
  };

  // Key navigation logic remains the same
  const handleKeyDown = (event, index, fieldName) => {
    const { key, ctrlKey } = event;
    // console.log("key", key, ctrlKey);
    const fields = [
      "paid_amount",
      "net_amount",
      "commission",
      "due",
      "paid",
      "remarks",
    ];

    if (key === "ArrowDown" && index < data.length - 1) {
      event.preventDefault();
      const nextRow = inputRefs.current[index + 1];
      if (nextRow) {
        const nextFieldIndex = fields.indexOf(fieldName);
        nextRow[nextFieldIndex]?.focus();
      }
    } else if (key === "ArrowUp" && index > 0) {
      event.preventDefault();
      const prevRow = inputRefs.current[index - 1];
      if (prevRow) {
        const prevFieldIndex = fields.indexOf(fieldName);
        prevRow[prevFieldIndex]?.focus();
      }
    } else if (key === "ArrowRight") {
      event.preventDefault();
      const nextFieldIndex = fields.indexOf(fieldName) + 1;
      if (nextFieldIndex < fields.length) {
        inputRefs.current[index][nextFieldIndex]?.focus();
      }
    } else if (key === "ArrowLeft") {
      event.preventDefault();
      const prevFieldIndex = fields.indexOf(fieldName) - 1;
      if (prevFieldIndex >= 0) {
        inputRefs.current[index][prevFieldIndex]?.focus();
      }
    }
  };

  // total calculations

  const totalReceived = data?.reduce(
    (acc, user) => acc + parseFloat(user?.user_payment?.paid_amount || 0),
    0,
  );
  const totalNetAmount = data?.reduce(
    (acc, user) => acc + parseFloat(user?.user_payment?.net_amount || 0),
    0,
  );
  const totalcommission = data?.reduce(
    (acc, user) => acc + parseFloat(user?.user_payment?.commission || 0),
    0,
  );
  const totalDue = data?.reduce(
    (acc, user) => acc + parseFloat(user?.user_payment?.due || 0),
    0,
  );

  const totalPaid = data?.reduce(
    (acc, user) => acc + parseFloat(user?.user_payment?.paid || 0),
    0,
  );

  return (
    <Table
      striped
      bordered
      hover
      className=" w-100 align-middle table-hover mb-0 rounded position-relative overflow-x-scroll"
    >
      <thead
        className="w-100"
        style={{
          position: "sticky",
          top: 0,
          color: "white",
          // zIndex: 1,
          backgroundColor: "#095d7e",
        }}
      >
        <tr>
          <th>SL</th>
          <th>Reg. NO</th>
          <th>Passenger</th>
          <th>Agency</th>
          <th>Country</th>
          <th>Test/Ref By</th>
          <th>Recieved</th>
          <th>Net Amount</th>
          <th>Balance</th>
          <th>Dues</th>
          <th>Paid</th>
          <th>Remarks</th>
        </tr>
      </thead>

      <tbody>
        {data.map((user, index) => (
          <tr
            key={user?.id}
            className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
          >
            <td>{index + 1}</td>
            <td>
              {user?.user?.reg_prefix}
              {user?.user?.registration_no}
            </td>
            <td>{user?.user?.f_name}</td>
            <td>{user?.introducer_user?.f_name}</td>
            <td>{user?.country?.name}</td>
            <td>
              {user?.user?.role_id === 5
                ? "Individual Test"
                : user?.package?.name}
            </td>

            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][0] = el;
                }}
                value={user?.user_payment?.paid_amount || 0}
                onKeyDown={(e) => handleKeyDown(e, index, "paid_amount")}
                onChange={(e) =>
                  handleChange(index, "paid_amount", e.target.value)
                }
              />
            </td>
            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][1] = el;
                }}
                value={user?.user_payment?.net_amount || 0}
                onKeyDown={(e) => handleKeyDown(e, index, "net_amount")}
                onChange={(e) =>
                  handleChange(index, "net_amount", e.target.value)
                }
              />
            </td>
            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][2] = el;
                }}
                value={user?.user_payment?.commission || 0}
                onKeyDown={(e) => handleKeyDown(e, index, "commission")}
                onChange={(e) =>
                  handleChange(index, "commission", e.target.value)
                }
              />
            </td>
            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][3] = el;
                }}
                value={user?.user_payment?.due || 0}
                onKeyDown={(e) => handleKeyDown(e, index, "due")}
                onChange={(e) => handleChange(index, "due", e.target.value)}
              />
            </td>
            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][4] = el;
                }}
                value={user?.user_payment?.paid || 0}
                onKeyDown={(e) => handleKeyDown(e, index, "paid")}
                onChange={(e) => handleChange(index, "paid", e.target.value)}
              />
            </td>
            <td>
              <Form.Control
                className={`${user?.user_payment?.commission > 0 ? "text-primary" : ""}  ${user?.user_payment?.due > 0 ? "text-danger" : ""} `}
                ref={(el) => {
                  if (!inputRefs.current[index]) inputRefs.current[index] = [];
                  inputRefs.current[index][5] = el;
                }}
                value={user?.user_payment?.remarks || ""}
                onKeyDown={(e) => handleKeyDown(e, index, "remarks")}
                onChange={(e) => handleChange(index, "remarks", e.target.value)}
              />
            </td>
          </tr>
        ))}
        <tr
          style={{
            position: "sticky",
            bottom: "-1px",
            backgroundColor: "#095d7e",
            color: "white",
          }}
        >
          <td />

          <td />

          <td />
          <td />
          <td />
          <td>Total</td>
          <td>{totalReceived}</td>
          <td>{totalNetAmount}</td>

          <td>{totalcommission}</td>
          <td>{totalDue}</td>
          <td>{totalPaid}</td>
          <td />
        </tr>
      </tbody>
    </Table>
  );
};

export default CollectionTableDataShow;

import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./MedicalXray.scss";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../../../Context/UserContextAPI";
import QRCode from "qrcode.react";
import { format } from "date-fns";
import UserInfoTable from "../Certificates/Components/UserInfoTable";
import BarCodeInInvoice from "../../Invoice/components/BarCodesInInvoice";

const PrintMedicalXray = ({ user_id }) => {
  let { userId } = useParams();
  userId = user_id ? user_id : userId;
  const pdfRef = useRef();
  const { currentUser, accessPerm, settingsData } = useContext(UserContext);

  const { state } = useLocation();

  const patientData = state?.record;
  const [xrayImage, setXrayImage] = useState();
  const [xrayLoading, setXrayLoading] = useState(true);

  const [riskFactor, setRiskFactor] = useState("");
  const [loading, setLoading] = useState(false);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyReportData, setRadiologyReportData] = useState([]);
  const [fit, setFit] = useState(0);
  const [unfit, setUnfit] = useState(0);
  const [heldUp, setHeldUp] = useState(0);
  const [imagePath, setImagePath] = useState("");

  const [radiologyComment, setRadiologyComment] = useState({
    user_id: userId,
    comments: "",
    status: 1,
    investigation_group_id: 1,
  });
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState({
    user_id: patientData?.user_id,
    comments: "",
    status: 1,
    investigation_group_id: 100,
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${user?.user?.id}`;

  const registrationNo = user?.user?.registration_no;
  const formattedBirthDate = user?.user?.date_of_birth
    ? (() => {
        const date = new Date(user?.user?.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    // height: 20,
    // margin: 0,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }
  const birthYear = user?.user?.date_of_birth
    ? new Date(user?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      // width: 1,
      // height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientRegNameDataUrl = generateBarcode(
    String(`${user?.f_name} - ${age}`),
  );

  const addDaysToDate = (dateString, hours) => {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return "";
    }

    const days = Math.floor(hours / 24);

    date.setDate(date.getDate() + days);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const receivingDate = user?.user_payment?.createdAt;
  const preparationDuration =
    user?.user?.role_id !== 5
      ? (user?.package?.preparation_duration ?? 0)
      : (user?.max_preparation_duration ?? 0);
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const comments = response?.data?.data || [];

      setRadiologyComment((previousComment) => {
        return {
          ...previousComment,
          user_id: userId,
          comments: comments[0]?.comments,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserDetail = async () => {
    try {
      setLoading(true);
      setXrayLoading(true);
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setUserDetail(response?.data?.data);
      userDetail && setUser(userDetail?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/2`,
        {
          invIds: user?.package?.investigation_id.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      setInvestigations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (radiologyTests.length <= 0) {
      investigations?.investigation_tests?.forEach((investigation) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: investigations.id,
              test_id: investigation.id,
              user_file: "",
              result: "",
            },
          ];
          return [...previousReport];
        });
      });
    }
  }, [investigations, userId]);

  useEffect(() => {
    setLoading(true);
    setRadiologyReportData([]);
    if (radiologyTests.length > 0) {
      radiologyTests.forEach((radiologyTest) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: radiologyTest.investigation_id || "",
              test_id: radiologyTest.test_id || "",
              user_file: radiologyTest?.user_file,
              result: radiologyTest.result?.replace(/<[^>]*>/g, "") || "",
            },
          ];
          return [...previousReport];
        });

        if (radiologyTest.investigation_id && radiologyTest.test_id === 1) {
          setXrayImage(
            (previousImage) => (previousImage = radiologyTest.user_file),
          );
          setXrayLoading(false);
        }
      });
    }
    setLoading(false);
  }, [userId, radiologyTests, imagePath]);

  // useEffect(() => {
  //   if (riskFactor >= 1 && riskFactor <= 70) {
  //     setRadiologyComment((previous) => ({ ...previous, status: 4 }));
  //   } else if (riskFactor === "") {
  //     setRadiologyComment((previous) => ({ ...previous, status: 2 }));
  //   } else {
  //     setRadiologyComment((previous) => ({ ...previous, status: 3 }));
  //   }
  // }, [radiologyReportData]);

  const [representative, setRepresentative] = useState();
  useEffect(() => {
    setLoading(true); // newly added
    userDetail && setUser(userDetail?.userDetail);
    if (riskFactor === undefined) {
      setRiskFactor("");
    }

    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    console.log(userDetail);
    setLoading(false); // newly added
  }, [userDetail, userDetail?.nextPage, userDetail?.previousPage, riskFactor]);

  useEffect(() => {
    if (userId) {
      setLoading(true);

      getUserDetail()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    userId && getComments();
  }, [userId]);

  useEffect(() => {
    setRadiologyComment((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: radiologyComment,
      };
    });
  }, [userId]);

  useEffect(() => {
    setRadiologyRiskFactor((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: riskFactor,
      };
    });
  }, [userId]);

  useEffect(() => {
    user && getActiveInvestigationTests();
  }, [user]);

  useEffect(() => {
    userId && getRadiologyTests();
    userId && getPhysicalTests();
  }, [userId]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        console.log(item?.user_file);
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  return (
    <div className="xray-container">
      <div className="row align-items-center">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section className="medical-xray-section" ref={pdfRef}>
        <div className="row">
          <h4 className="fw-bold fs-5 text-center" style={{ color: "#000" }}>
            MEDICAL X-RAY REPORT
          </h4>
        </div>
        <div className="d-flex justify-content-between fw-bolder">
          <div>
            <p className="fs-5 text-dark">
              Reg No : {user?.user?.reg_prefix || ""}
              {user?.user?.registration_no}{" "}
            </p>
          </div>
          <div>
            <p className="fs-5 text-dark">
              Reg Date :{" "}
              {formatDate(user?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
            </p>
          </div>
        </div>

        {/* bar code  */}

        <BarCodeInInvoice userDetail={userDetail} age={age} />

        <UserInfoTable
          user={userDetail?.userDetail?.user}
          userDetails={userDetail?.userDetail}
          age={age}
          physicalData={physicalData}
        />
        <div className="d-flex align-items-start justify-content-between w-100 mt-3">
          <div className="col-sm-5">
            {radiologyData && radiologyData?.length > 0 ? (
              <table className="table table-bordered border-dark w-100">
                <tbody className="">
                  {radiologyData?.map((radiology, key) => (
                    <tr key={key} style={{ fontWeight: "lighter" }}>
                      <th
                        style={{ fontWeight: "lighter" }}
                        className={`w-25  text-dark text-start align-top ${radiology?.test_id === 35 && "fw-bold"}`}
                      >
                        {radiology?.investigation_test?.name}
                      </th>
                      {radiology?.result === null ||
                      radiology?.result === "" ? (
                        <td className="w-75  p-1 text-dark text-start align-middle">
                          - - -
                        </td>
                      ) : (
                        <td
                          className={`text-start w-75 p-1 ${
                            radiology?.test_id === 35 &&
                            radiology?.investigation_test?.name === "Findings"
                              ? radiology?.result === "Normal CXR"
                                ? "text-success fw-bold"
                                : "text-primary fw-bold"
                              : "text-dark"
                          }`}
                        >
                          {radiology?.result}
                        </td>
                      )}
                    </tr>
                  ))}
                  <td
                    className="border border-dark"
                    colSpan={3}
                    style={{ height: "50px" }}
                  >
                    <div className="col-sm-12 d-flex text-dark ps-2">
                      <b> Remarks:</b> <div className="ps-1"></div>
                      <div className="ps-1"></div>
                      {radiologyData?.find(
                        (report) =>
                          report?.investigation_test?.id === 35 &&
                          report?.result !== "Normal CXR" &&
                          report?.result !== "",
                      ) && (
                        <>
                          <span
                            className=""
                            style={{
                              color:
                                radiologyData?.find(
                                  (report) =>
                                    report?.investigation_test?.id === 35,
                                )?.result !== "Normal CXR"
                                  ? "#0000FF"
                                  : "black",
                            }}
                          >
                            X-Ray
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                </tbody>
              </table>
            ) : (
              <div className="d-flex align-items-center justify-content-center border border-dark w-100">
                No Data Available
              </div>
            )}
          </div>
          <div className="col-sm-6 ps-2">
            <div className="d-flex justify-content-end">
              {!xrayImage ? (
                <div
                  className="d-flex align-items-center justify-content-center fs-6 border border-5 rounded-1"
                  style={{ width: "100%", height: "400px" }}
                >
                  No X-Ray Image Found
                </div>
              ) : (
                <div>
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                    style={{ width: "100%", height: "400px" }}
                    alt=""
                    className="medical-xray-print-image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-between w-100 medical-xray-print">
        <div className="row w-50">
          <div className="d-flex justify-content-start gap-5 align-items-end">
            <div className="text-dark p-0 m-0">
              <div
                className="border-top mt-0 border-dark"
                style={{ width: "125px" }}
              ></div>
              Radiologist
            </div>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-end align-items-center mt-1">
          <div className="text-end mt-2">
            <Link to={urlWithBase64ID}>
              <QRCode value={urlWithBase64ID} size={70} />
            </Link>
            <div className="text-end text-dark p-0 text-wrap">
              Printed By: {currentUser?.f_name}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={(e) => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PrintMedicalXray;

const StaticContentInReport = ({ settingsData }) => {
  return (
    <div
      // style={{ fontSize: "4px" }}
      className="static-content-medical-form-text mt-1"
    >
      <p
        className="pt-0 mb-0 static-content-medical-form-text"
        // style={{ position: "relative", bottom: "0px" }}
      >
        History of past ilness: <b>1) Allergy: ABSENT</b>{" "}
        <b>
          2) Psychiatric and neurological disorders (Epilepsy, depression):
          ABSENT
        </b>{" "}
        <b>3) Others: NAD</b>
      </p>
      <p
        className=" p-0 m-0 static-content-medical-form-text"
        // style={{ position: "relative", bottom: "5px" }}
        // style={{ lineHeight: "6px" }}
      >
        I hereby permit the <b>{settingsData?.website_name}</b> and the
        undersigned physician to furnish such information the company many need
        pertaining to my health status and pertinent and medical findings and do
        hereby release them from any and all legal responsibility by doing so. I
        also certify that my medical history contained above is true and any
        false statement will disqualify me from my employment, benefits and
        claim.
      </p>
    </div>
  );
};
export default StaticContentInReport;

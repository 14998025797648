import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContextAPI";
import useFetch from "../../../../hooks/useFetch";
import calculateAge from "../../../../utils/calculateAge";
import formatDate from "../../../../utils/formatDate";
import request from "../../../../utils/request";
import BarCodeInInvoice from "../../Invoice/components/BarCodesInInvoice";
import LaboratoryInvestigation from "../Certificates/Components/LaboratoryInvestigation";
import MedicalExamination from "../Certificates/Components/MedicalExamination";
import UserInfoTable from "../Certificates/Components/UserInfoTable";

const MedicalForm = ({ user_id }) => {
  const { accessPerm, settingsData } = useContext(UserContext);
  let { userId } = useParams();
  userId = user_id ? user_id : userId;

  const {
    data: { userDetail },
    isSuccess,
  } = useFetch(() => request.getOne("user-details/test", userId), userId);

  // setting data
  const { data: settings } = useFetch(() => request.getAll("settings"));
  const settingData = settings.length > 0 && settings[0];

  // physical Data

  const { data: physicalData } = useFetch(
    () => request.getAllById("reports/physical", userId),
    userId,
  );
  console.log("physicalData", physicalData);

  const groupedData = useMemo(() => {
    return physicalData?.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  // radiology data

  const { data: radiologyData } = useFetch(
    () => request.getAllById("reports/radiology", userId),
    userId,
  );

  //variable
  const age = calculateAge(userDetail?.user?.date_of_birth);

  const radiologyResult = radiologyData?.find(
    (report) => report?.investigation_test?.id === 35,
  )?.result;

  // get physical Filed

  const { data: physicalInvTest } = useFetch(
    () =>
      request.create("reports/get-data/3", {
        invIds: userDetail?.package?.investigation_id?.split(","),
      }),
    userDetail?.package?.investigation_id,
  );

  const groupedPhysicalInvTest = useMemo(() => {
    return (
      physicalInvTest?.length > 0 &&
      physicalInvTest?.reduce((acc, item) => {
        const categoryName = item?.investigation_category?.name;
        if (!categoryName) return acc;
        if (!acc[categoryName]) {
          acc[categoryName] = {
            name: categoryName,
            items: [],
          };
        }
        acc[categoryName].items.push(item);
        return acc;
      }, {})
    );
  }, [physicalInvTest]);

  // pathology data

  const { data: pathologyData } = useFetch(
    () => request.getAll(`reports/get-healthcard-results/${userId}/${1}`),
    userId,
  );

  const groupedPathologyData = useMemo(() => {
    return (
      pathologyData?.length > 0 &&
      pathologyData?.reduce((acc, item) => {
        const { investigation_id } = item || {};

        // Fetch the required values from the state
        const genderId = userDetail?.gender?.id;
        const pregnancyField = userDetail?.introducer_user?.pregnancyField;

        // Condition to skip investigation_id 22 based on gender and pregnancy field
        if (investigation_id === 22) {
          if (genderId === 1) {
            // Skip this item if gender id is 1
            return acc;
          } else if (genderId === 2 && pregnancyField !== 1) {
            // Skip this item if gender id is 2 and pregnancyField is not 1
            return acc;
          }
        }

        // Fetch the investigation category name
        const categoryName =
          item?.investigation_test?.investigation?.investigation_category?.name;

        if (!categoryName) return acc;

        // Initialize the category if it doesn't exist
        if (!acc[categoryName]) {
          acc[categoryName] = {
            name: categoryName,
            items: [],
          };
        }

        // Add the item to the appropriate category
        acc[categoryName].items.push(item);

        return acc;
      }, {})
    );
  }, [
    pathologyData,
    userDetail?.gender?.id,
    userDetail?.introducer_user?.pregnancyField,
  ]);

  // pathology investigation data

  const { data: pathologyInvTest } = useFetch(
    () =>
      request.create("reports/get-data/1", {
        invIds: userDetail?.package?.investigation_id?.split(","),
      }),
    userDetail?.package?.investigation_id,
  );

  const groupedPathologyInvTest = useMemo(() => {
    return (
      pathologyInvTest?.length > 0 &&
      pathologyInvTest?.reduce((acc, item) => {
        const categoryName = item?.investigation_category?.name;

        // If no category name, return the accumulator as is
        if (!categoryName) return acc;

        // Fetch gender id and pregnancy field from state
        const genderId = userDetail?.gender?.id;
        const pregnancyField = userDetail?.introducer_user?.pregnancyField;

        // Filter condition: Do not include item with id 22 if gender id is 1
        if (item?.id === 22) {
          if (genderId === 1) {
            return acc; // Skip adding this item if gender id is 1
          } else if (genderId === 2 && pregnancyField !== 1) {
            return acc; // Skip if gender id is 2 and pregnancy field is not 1
          }
        }

        // Initialize category if it does not exist in the accumulator
        if (!acc[categoryName]) {
          acc[categoryName] = {
            name: categoryName,
            items: [],
          };
        }

        // Push the item into its category's item array
        acc[categoryName].items.push(item);

        return acc;
      }, {})
    );
  }, [pathologyInvTest, userDetail]);

  // physical remarks
  const physicalRemarksItem = Object.entries(groupedData)
    .flatMap((group) => group[1].items)
    .find(
      (item) =>
        item?.investigation?.name?.toLowerCase() ===
        "Physical Remarks".toLowerCase(),
    );

  // console.log("groupedPathologyData", groupedPathologyData, pathologyData);

  const hasGroupData = groupedData && Object.entries(groupedData)?.length > 0;

  const hasPathologyData =
    groupedPathologyData && Object.entries(groupedPathologyData)?.length > 0;
  console.log("role", userDetail?.user?.role_id);
  console.log("hasGroupData", hasGroupData);
  return (
    <div className="medical-form">
      <div className="row align-items-center">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${settingData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <div className="d-flex align-items-center">
        {/* Empty div to take up the left space */}
        <div className="col-4"></div>

        {/* Medical Form centered */}
        <div
          className="text-center text-uppercase text-dark fs-4 col-4"
          style={{ textDecoration: "underline", fontWeight: "bolder" }}
        >
          Medical Form
        </div>

        {/* Empty div to take up remaining space and push Package to the right */}
        <div className="flex-grow-1 d-flex justify-content-end col-4 fs-6">
          {userDetail?.package && (
            <span className="p-0 m-0 text-end text-dark fw-bold">
              Package: {userDetail?.package?.name}
            </span>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between fw-bolder">
        <div className="p-0 m-0">
          <p className=" p-0 m-0 text-dark fs-5">
            Reg No : {userDetail?.user?.reg_prefix}
            {userDetail?.user?.registration_no}{" "}
          </p>
        </div>
        <div className="p-0 m-0 fs-5">
          <p className=" p-0 m-0 text-dark">
            Reg Date :{" "}
            {formatDate(userDetail?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
          </p>
        </div>
      </div>

      {/* bar code  */}
      <BarCodeInInvoice userDetail={userDetail} />

      <UserInfoTable
        user={userDetail?.user}
        userDetails={userDetail}
        age={age}
        // physicalData={physicalData}
      />

      <div className="row content-table mt-2">
        <div className="col-sm-12 d-flex flex-column flex-sm-row">
          {(userDetail?.user?.role_id !== 5 ||
            (userDetail?.user?.role_id === 5 &&
              (hasGroupData || radiologyData?.length > 0))) && (
            <div
              className={`${userDetail?.user?.role_id === 5 && !hasPathologyData ? "col-sm-12" : "col-sm-6"}`}
            >
              <MedicalExamination
                groupedData={groupedData}
                radiologyData={radiologyData}
                radiologyResult={radiologyResult}
                user={{ ...userDetail?.user }}
                groupedPhysicalInvTest={groupedPhysicalInvTest}
                from="medicalForm"
              />
              {/* <RemarksInReports */}
              {/*   physicalRemarksItem={physicalRemarksItem} */}
              {/*   userDetail={userDetail} */}
              {/* /> */}
            </div>
          )}

          {(userDetail?.user?.role_id !== 5 ||
            (userDetail?.user?.role_id === 5 && hasPathologyData)) && (
            <div
              className={`${userDetail?.user?.role_id === 5 && !hasGroupData ? "col-sm-12" : "col-sm-6 ps-2"}`}
            >
              <LaboratoryInvestigation
                groupedPathologyData={groupedPathologyData}
                user={userDetail?.user}
                from="medicalForm"
                groupedPathologyInvTest={groupedPathologyInvTest}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end mt-3">
        <div className="text-dark medical-form-sign-1">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Radiologist
        </div>
        <div className="text-dark medical-form-sign-2">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Pathologist
        </div>
        <div className="text-dark medical-form-sign-3">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Technologist
        </div>
        <div className="text-dark medical-form-sign-4">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Physician
        </div>
      </div>

      <div className="print-button d-flex mt-5 align-items-center justify-content-end">
        <div className="d-flex gap-2">
          {accessPerm(32, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}
          <button className="btn btn-secondary" onClick={() => window.close()}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default MedicalForm;

import { useSearchParams } from "react-router-dom";
import PrintMedicalPathology from "./PrintMedicalPathology";

const MedicalPathologyGroupPrint = () => {
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get("userIds");
  const userIdsArr = userIds ? userIds?.split(",") : [];
  return (
    <div>
      {userIdsArr?.map((user, index) => {
        return (
          <div key={user}>
            <div>
              <PrintMedicalPathology user_id={user} />
            </div>
            {index < userIdsArr?.length - 1 ? (
              <div className="print-page-break"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default MedicalPathologyGroupPrint;

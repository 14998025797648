import { useMemo } from "react";
import { FixedSizeList as List } from "react-window";

const VirtualizedMenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const itemHeight = 40;
  const optionPadding = -4; // Adjusted padding value

  // Calculate the number of visible options based on maxHeight
  const visibleOptions = Math.min(options.length, Math.floor(maxHeight / itemHeight));

  const initialOffset = useMemo(
    () => options.indexOf(value) * itemHeight,
    [options, value, itemHeight]
  );

  return (
    <div style={{ maxHeight, overflowY: "auto" }}>
      <List
        height={visibleOptions * itemHeight}
        itemCount={options.length}
        itemSize={itemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <div
            style={{ ...style, color: "black", padding: `${optionPadding}px 0` }}
          >
            {children[index]}
          </div>
        )}
      </List>
    </div>
  );
};

export default VirtualizedMenuList;

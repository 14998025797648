import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../Context/UserContextAPI";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import MedicalXrayEntry from "../Investigation/Report/MedicalXray/MedicalXrayEntry";
import MedicalPathologyEntry from "../Investigation/Report/MedicalPathology/MedicalPathologyEntry";
import MedicalPhysicalEntry from "../Investigation/Report/MedicalPhysical/MedicalPhysicalEntry";
import Loading2 from "../../utils/Loader/Loading2";

const EntryPage = () => {
  const same = 2;
  const physicalEntryRef = useRef();
  const pathologyEntryRef = useRef();
  const xrayEntryRef = useRef();

  const { accessPerm, settingsData } = useContext(UserContext);
  const { userId } = useParams();
  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [loadingState, setLoading] = useState(false);
  const [physicalStatusValue, setPhysicalStatusValue] = useState(null);
  const [physicalInputStatusValue, setPhysicalInputStatusValue] = useState(0);
  const [physicalRiskFactor, setPhysicalRiskFactor] = useState(null);
  const [pathologyRiskFactor, setPathologyRiskFactor] = useState(null);
  const [pathologyStatusValue, setPathologyStatusValue] = useState(null);
  const [radiologyStatusValue, setRadiologyStatusValue] = useState(null);
  const [radiologyInputStatusValue, setRadiologyInputStatusValue] = useState(0);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const formattedBirthDate = user?.user?.date_of_birth
    ? new Date(user?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const handlePhysicalStatusValueChange = (newStatusValue) => {
    setPhysicalStatusValue(newStatusValue);
  };

  const handlePhysicalInputStatusValueChange = (newStatusValue) => {
    setPhysicalInputStatusValue(newStatusValue);
  };

  const handlePhysicalRiskFactorChange = (newStatusValue) => {
    setPhysicalRiskFactor(newStatusValue);
  };

  const handlePathologyRiskFactorChange = (newStatusValue) => {
    setPathologyRiskFactor(newStatusValue);
  };

  const handleRadiologyInputStatusValueChange = (newStatusValue) => {
    setRadiologyInputStatusValue(newStatusValue);
  };

  const handlePathologyStatusValueChange = (newStatusValue) => {
    setPathologyStatusValue(newStatusValue);
  };

  const handleRadiologyStatusValueChange = (newStatusValue) => {
    setRadiologyStatusValue(newStatusValue);
  };

  const handlePhysicalReportData = (data) => {
    setReportData((prevReportData) => {
      const uniqueTestIds = new Map();
      prevReportData.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      data.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      const updatedReportData = Array.from(uniqueTestIds.values());
      return updatedReportData;
    });
  };

  const handlePathologyReportData = (data) => {
    setReportData((prevReportData) => {
      const uniqueTestIds = new Map();
      prevReportData.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      data.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      const updatedReportData = Array.from(uniqueTestIds.values());
      return updatedReportData;
    });
  };

  const handleRadiologyReportData = (data) => {
    setReportData((prevReportData) => {
      const uniqueTestIds = new Map();
      prevReportData.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      data.forEach((item) => {
        uniqueTestIds.set(item.test_id, item);
      });
      const updatedReportData = Array.from(uniqueTestIds.values());
      return updatedReportData;
    });
  };

  const handleXrayOnChange = (event, index) => {
    const newValue = event.target.value;

    // Update the reportData based on the index and new value
    setReportData((prevReportData) => {
      const updatedReport = [...prevReportData];
      updatedReport[index] = {
        ...updatedReport[index],
        result: newValue,
      };
      return updatedReport;
    });
  };

  const handlePathoOnChange = (event, testId) => {
    // Update the reportData based on the testId and new value
    setReportData((previousReport) => {
      const updatedReport = previousReport.map((report) => {
        if (report?.test_id === testId) {
          return {
            ...report,
            result: event.target.value,
          };
        }
        return report;
      });
      return updatedReport;
    });
  };

  const handlePhyOnChange = (event, testId) => {
    // Update the reportData based on the testId and new value
    setReportData((previousReport) => {
      const updatedReport = previousReport.map((report) => {
        if (report?.test_id === testId) {
          return {
            ...report,
            result: event.target.value,
          };
        }
        return report;
      });
      return updatedReport;
    });
  };

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setUserDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/3`,
        {
          invIds:
            user?.user?.role_id === 5
              ? user?.investigation_id?.split(",")
              : user?.package?.investigation_id?.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setInvestigations(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const savePhysicalComment = async (statusValue) => {
    const combinedData = {
      combinedObject: {
        physicalComment: {
          user_id: userId,
          comments: "",
          investigation_group_id: 3,
          status: statusValue,
        },
        physicalRiskFactor: {
          user_id: userId,
          investigation_group_id: 101,
          // status: statusValue,
          status: physicalInputStatusValue,
          comments: physicalRiskFactor,
        },
      },
    };

    try {
      const res = await axios.post(
        `${baseUrl}/reports/save-physical-comments`,
        { combinedData },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (res) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const savePathologyComment = async (statusValue) => {
    const combinedData = {
      pathologyComment: {
        user_id: userId,
        comments: "",
        status: statusValue,
        investigation_group_id: 2,
      },
      pathologyRiskFactor: {
        user_id: userId,
        comments: "thi is to save",
        investigation_group_id: 102,
        status: statusValue,
      },
    };

    try {
      const res = await axios.post(
        `${baseUrl}/reports/save-pathology-comments`,
        {
          combinedData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (res) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveRadiologyComment = async (statusValue) => {
    const combinedData = {
      combinedObject: {
        radiologyComment: {
          user_id: userId,
          comments: "",
          investigation_group_id: 2,
          status: statusValue,
        },
        radiologyRiskFactor: {
          user_id: userId,
          comments: "",
          investigation_group_id: 100,
          status: radiologyInputStatusValue,
        },
      },
    };

    try {
      const res = await axios.post(
        `${baseUrl}/reports/save-comments`,
        combinedData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (res) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const { comments, status } = response?.data?.data;
      setPhysicalInputStatusValue(status);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const risk = response?.data?.data || [];
      setRadiologyInputStatusValue(risk[0]?.status);
    } catch (error) {
      console.log(error);
    }
  }

  // const saveReport = async () => {
  //   setLoading(true);
  //   let status;
  //   if (
  //     pathologyStatusValue === 3 ||
  //     physicalStatusValue === 3 ||
  //     radiologyStatusValue === 3 ||
  //     physicalInputStatusValue === 3 ||
  //     radiologyInputStatusValue === 3
  //   ) {
  //     status = 3;
  //   } else if (
  //     pathologyStatusValue === 4 ||
  //     physicalStatusValue === 4 ||
  //     radiologyStatusValue === 4 ||
  //     physicalInputStatusValue === 4 ||
  //     radiologyInputStatusValue === 4
  //   ) {
  //     status = 4;
  //   } else if (
  //     pathologyStatusValue === 2 ||
  //     physicalStatusValue === 2 ||
  //     radiologyStatusValue === 2 ||
  //     physicalInputStatusValue === 2 ||
  //     radiologyInputStatusValue === 2
  //   ) {
  //     status = 2;
  //   } else {
  //     status = 1;
  //   }
  //   // console.log("status", status);
  //   // console.log("physical", physicalStatusValue);
  //   // console.log("pathology", pathologyStatusValue);
  //   // console.log("xray", radiologyStatusValue);
  //   // console.log("physical input", physicalInputStatusValue);
  //   // console.log("xray input", radiologyInputStatusValue);
  //   try {
  //     // Save pathology status
  //     const reportStatusResponse = await axios.put(
  //       `${process.env.REACT_APP_API_BASE_URL}/users/pathology-status/${userId}`,
  //       { data: 1 },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
  //         },
  //       }
  //     );

  //     // Save physical status
  //     const physicalStatusResponse = await axios.put(
  //       `${process.env.REACT_APP_API_BASE_URL}/users/physical-status/${userId}`,
  //       { data: 0 },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
  //         },
  //       }
  //     );

  //     if (
  //       reportStatusResponse.status === 200 &&
  //       physicalStatusResponse.status === 200
  //     ) {
  //       // Save pathology report
  //       const response = await axios.put(
  //         `${baseUrl}/reports/data/${userId}`,
  //         reportData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
  //           },
  //         }
  //       );

  //       toast.success(response?.data?.data);
  //       await savePathologyComment(status);
  //       await savePhysicalComment(status);
  //       await saveRadiologyComment(status);
  //       setLoading(false);
  //     } else {
  //       toast.error("Failed to update status");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };

  const callNormalValue = () => {
    if (physicalEntryRef.current) {
      physicalEntryRef.current.handleNormalValue();
    }
    if (pathologyEntryRef.current) {
      pathologyEntryRef.current.handleNormalValue();
    }
    if (xrayEntryRef.current) {
      xrayEntryRef.current.handleNormalValue();
    }
  };

  // const callSaveValue = () => {
  //   if (physicalEntryRef.current) {
  //     physicalEntryRef.current.savePhysicalReport();
  //   }
  //   if (pathologyEntryRef.current) {
  //     pathologyEntryRef.current.savePathologyReport();
  //   }
  //   if (xrayEntryRef.current) {
  //     xrayEntryRef.current.saveRadiologyReport();
  //   }
  //   toast.success("Values saved successfully!");
  // };

  const callSaveValue = async () => {
    try {
      const physicalPromise = physicalEntryRef.current
        ? physicalEntryRef.current.savePhysicalReport()
        : Promise.resolve();

      const pathologyPromise = pathologyEntryRef.current
        ? pathologyEntryRef.current.savePathologyReport()
        : Promise.resolve();

      const xrayPromise = xrayEntryRef.current
        ? xrayEntryRef.current.saveRadiologyReport()
        : Promise.resolve();

      await Promise.all([physicalPromise, pathologyPromise, xrayPromise]);
      toast.success("Tests saved successfully!");
    } catch (error) {
      toast.error("Failed to save tests.");
      console.error(error);
    }
  };

  useEffect(() => {
    userDetail && setUser(userDetail.userDetail);
    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          )
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userDetail]);

  useEffect(() => {
    getActiveInvestigationTests();
    // getTests();
  }, []);

  useEffect(() => {
    getUserDetail();
    getRiskFactor();
    getComment();
  }, [userId]);

  const collection = user?.user?.user_collections?.map(
    (collection) => collection.investigation_category?.investigation_group_id,
  );
  const uniqueCollection = [...new Set(collection)];
  return (
    <>
      {loadingState || status === "loading" ? (
        <Loading2 />
      ) : (
        <>
          <div className="card px-3 pt-1 medical-report">
            <p className="mb-0 fs-4 text-dark">
              Medical Report for {user?.user?.f_name} {user?.user?.l_name} [
              {user?.user?.reg_prefix || ""}
              {user?.user?.registration_no}]
            </p>
            <div className="row justify-content-center h-100 text-dark px-3">
              <>
                <div className="col-sm-2">
                  <div
                    className="pres-photo border border-end-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.user?.image ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.image}`}
                        className="img-fluid"
                        alt="patient"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src="./dummy.jpg"
                        alt="patient"
                        className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    PHOTO
                  </p>
                </div>
                <div className="col-sm-8">
                  <div className="row h-100">
                    <table
                      className="responsive-width1 table-bordered h-100"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        textAlign: "left",
                      }}
                    >
                      <tbody>
                        {/* Row 1 - Name and Client ID */}
                        <tr>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Name
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.user?.f_name || "---"}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Client ID
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.user?.reg_prefix || ""}
                            {user?.user?.registration_no || "---"}
                          </td>
                        </tr>

                        {/* Row 2 - Gender and Passport No */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Gender
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.gender?.name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Passport No
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.user?.passport_no || "---"}
                          </td>
                        </tr>

                        {/* Row 3 - Date of Birth and Travelling To */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Date of Birth
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {formattedBirthDate || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle fw-bold">
                            Country
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle fw-bold">
                            {user?.country?.name || "---"}
                          </td>
                        </tr>

                        {/* Row 4 - Father Name and Contact Number */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Father Name
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.father_name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Contact Number
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.user?.mobile || "---"}
                          </td>
                        </tr>

                        {/* Row 5 - Nationality and Agency */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Nationality
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.national_country?.nationality || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {settingsData?.introducer_label}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            colSpan={3}
                          >
                            {user?.introducer_user?.f_name || "---"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-2 h-100">
                  <div
                    className="pres-photo border border-start-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.user?.finger_print ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.finger_print}`}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      >
                        No Fingerprint Found
                      </div>
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    THUMB PRINT
                  </p>
                </div>
              </>
            </div>
            {uniqueCollection?.includes(3) && (
              <MedicalPhysicalEntry
                same={same}
                onPhysicalReportData={handlePhysicalReportData}
                onInputChange={handlePhyOnChange}
                onStatusChange={handlePhysicalStatusValueChange}
                onInputStatusChange={handlePhysicalInputStatusValueChange}
                onRiskFactorChange={handlePhysicalRiskFactorChange}
                physicalRef={physicalEntryRef}
              />
            )}

            {uniqueCollection?.includes(2) && (
              <MedicalXrayEntry
                same={same}
                xrayData={2}
                onRadiologyReportData={handleRadiologyReportData}
                onInputChange={handleXrayOnChange}
                onStatusChange={handleRadiologyStatusValueChange}
                onInputStatusChange={handleRadiologyInputStatusValueChange}
                xrayRef={xrayEntryRef}
              />
            )}

            {uniqueCollection?.includes(1) && (
              <MedicalPathologyEntry
                same={same}
                pathoData={1}
                onPathologyReportData={handlePathologyReportData}
                onInputChange={handlePathoOnChange}
                onStatusChange={handlePathologyStatusValueChange}
                onRiskFactorChange={handlePathologyRiskFactorChange}
                pathologyRef={pathologyEntryRef}
              />
            )}
          </div>
          <div className="row px-4 mb-3">
            <div className="row mt-3">
              <div className="col-6">
                <div className="d-flex gap-2">
                  {userDetail?.nextPage && (
                    <Link
                      to={`/dashboard/entry/${userDetail?.nextPage}`}
                      className="btn btn-primary"
                    >
                      Previous
                    </Link>
                  )}
                  {userDetail?.previousPage && (
                    <Link
                      to={`/dashboard/entry/${userDetail?.previousPage}`}
                      className="btn btn-primary"
                    >
                      Next
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex gap-2 justify-content-end">
                  {accessPerm(33, 22) && (
                    <button
                      className="btn btn-primary"
                      onClick={callNormalValue}
                    >
                      Set Normal
                    </button>
                  )}
                  <button
                    className="btn btn-success px-2"
                    onClick={callSaveValue}
                  >
                    Save Results
                  </button>

                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      window.close();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EntryPage;

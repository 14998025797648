import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContextAPI";
import useFetch from "../../../../hooks/useFetch";
import request from "../../../../utils/request";
import MedicalForm from "./MedicalForm";

const MedicalFormGroupPrint = () => {
    const { accessPerm, settingsData } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const userIds = searchParams.get("userIds");
    const userIdsArr = userIds ? userIds?.split(',') : [];
    // console.log('d',userIds)

    // const {
    //     data: { userDetail },
    //     isSuccess,
    // } = useFetch(() =>
    //     request.getAll(`user-details/test/get/all?userIds=${userIds}`),
    // );
    // console.log(isSuccess);

    // useEffect(() => {
    //     if (isSuccess) {
    //         setTimeout(() => {
    //             window.focus();
    //             window.print();
    //         }, 3000);
    //     }

    //     window.onafterprint = window.close;
    // }, [isSuccess]);
    return (
        <div>
             {userIdsArr?.map((user, index) => {
         
          return (
            <div key={user}>
              <div >
               <MedicalForm user_id={user}/>
              </div>
              {index < userIdsArr?.length - 1 ? (
                <div className="print-page-break"></div>
              ) : null}
            </div>
          );
        })}

        </div>
    )
}
export default MedicalFormGroupPrint;
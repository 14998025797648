const FilterComponent = ({ filterText, onFilter }) => (
  <div className="d-flex align-items-center justify-content-center w-100 gap-2">
    <input
      id="search"
      type="text"
      placeholder="Search anything..."
      value={filterText}
      onChange={onFilter}
      className="form-control w-50 ps-3 border border-dark"
    />
  </div>
);

export default FilterComponent;

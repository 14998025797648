import axios from "axios";
import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useParams } from "react-router-dom";
import "./MedicalPathology.scss";
import { UserContext } from "../../../../Context/UserContextAPI";
import Loading2 from "../../../../utils/Loader/Loading2";

const MedicalPathologyEntry = forwardRef(
  ({
    same,
    pathoData,
    onPathologyReportData,
    onInputChange,
    onStatusChange,
    pathologyRef,
    onRiskFactorChange,
  }) => {
    const { accessPerm, settingsData } = useContext(UserContext);
    const { userId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get("data") || pathoData;
    const [statusState, setStatusState] = useState(0);
    const [checked, setChecked] = useState(0);
    const [unfit, setUnfit] = useState(0);
    const [fit, setFit] = useState(0);
    const [heldUp, setHeldUp] = useState(0);
    const [status, setStatus] = useState(0);
    const [loadingState, setLoading] = useState(false);
    const [isDataSaved, setIsDataSaved] = useState(false);

    let riskFactor = "";

    const statusCheckHandler = (reports) => {
      setFit(0);
      setUnfit(0);
      setHeldUp(0);

      reports.forEach((report) => {
        const result = report.result;

        // Check if result is one of the invalid values
        const isInvalid = result === "" || result === "N/A" || result === "---";
        const parsedResult = parseFloat(result);

        // Check hemoglobin status
        if (report.investigation_id === 15) {
          if (isInvalid || (parsedResult >= 11 && parsedResult <= 16.5)) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 1 && parsedResult <= 10.9) {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">Hemoglobin</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">Hemoglobin</span>, ';
            report.status = 3;
          }
        }

        // Check R.B.S status
        if (report.investigation_id === 16) {
          if (isInvalid) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 60 && parsedResult <= 140) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 141 && parsedResult <= 220) {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">R.B.S</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">R.B.S</span>, ';
            report.status = 3;
          }
        }

        // Check S. Creatinine status
        if (report.investigation_id === 17) {
          if (isInvalid || (parsedResult >= 0.6 && parsedResult <= 1.3)) {
            setFit(1);
            report.status = 2;
          } else {
            setHeldUp(1);
            riskFactor +=
              '<span style="color: #0000FF;">S. Creatinine</span>, ';
            report.status = 4;
          }
        }

        // Check S.Bilirubin status
        if (report.investigation_id === 20) {
          if (isInvalid) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 0.32 && parsedResult <= 1.2) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 1.3 && parsedResult <= 3.5) {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">S. Bilirubin</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">S. Bilirubin</span>, ';
            report.status = 3;
          }
        }

        // Check Malarial Parasite status
        if (report.investigation_id === 25) {
          if (isInvalid || result === "Not Found") {
            setFit(1);
            report.status = 2;
          } else {
            setUnfit(1);
            riskFactor +=
              '<span style="color: red;">Malarial Parasite</span>, ';
            report.status = 3;
          }
        }

        // Check VDRL status
        if (report.investigation_id === 27) {
          if (isInvalid || result === "Non Reactive") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Reactive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">VDRL</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">VDRL</span>, ';
            report.status = 3;
          }
        }

        // Check pregnancy status
        if (report.investigation_id === 22) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">Pregnancy</span>, ';
            report.status = 3;
          }
        }

        // Check SGPT status
        if (report.investigation_id === 23) {
          if (isInvalid || (parsedResult >= 10 && parsedResult <= 55)) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 56 && parsedResult <= 200) {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">SGPT</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">SGPT</span>, ';
            report.status = 3;
          }
        }

        // Check SGOT status
        if (report.investigation_id === 24) {
          if (isInvalid || (parsedResult >= 10 && parsedResult <= 40)) {
            setFit(1);
            report.status = 2;
          } else if (parsedResult >= 41 && parsedResult <= 200) {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">SGOT</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">SGOT</span>, ';
            report.status = 3;
          }
        }

        // Check TPHA status
        if (report.investigation_id === 28) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">TPHA</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">TPHA</span>, ';
            report.status = 3;
          }
        }

        // Check Anti-HCV status
        if (report.investigation_id === 29) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">Anti-HCV</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">Anti-HCV</span>, ';
            report.status = 3;
          }
        }

        // Check HIV 1 - 2 status
        if (report.investigation_id === 30) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">HIV 1 - 2</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">HIV 1 - 2</span>, ';
            report.status = 3;
          }
        }

        // Check HBsAg status
        if (report.investigation_id === 26) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">HBsAg</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">HBsAg</span>, ';
            report.status = 3;
          }
        }

        // Check THC status
        if (report.investigation_id === 37) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">THC</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">THC</span>, ';
            report.status = 3;
          }
        }

        // Check MOP status
        if (report.investigation_id === 38) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">MOP</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">MOP</span>, ';
            report.status = 3;
          }
        }

        // Check AMP status
        if (report.investigation_id === 40) {
          if (isInvalid || result === "Negative") {
            setFit(1);
            report.status = 2;
          } else if (result === "W. Positive") {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">AMP</span>, ';
            report.status = 4;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">AMP</span>, ';
            report.status = 3;
          }
        }

        // Check CU Sugar status
        if (report.investigation_id === 21) {
          if (isInvalid || result === "Nill") {
            setFit(1);
            report.status = 2;
          } else {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">CU Sugar</span>, ';
            report.status = 4;
          }
        }

        // Check Albumin status
        if (report.investigation_id === 42) {
          if (isInvalid) {
            setFit(1);
            report.status = 2;
          } else {
            setHeldUp(1);
            report.status = 4;
            riskFactor += '<span style="color: #0000FF;">Albumin</span>, ';
          }
        }

        // Check Platelet status
        if (report.investigation_id === 49) {
          if (isInvalid || (parsedResult >= 150000 && parsedResult <= 450000)) {
            setFit(1);
            report.status = 2;
          } else {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">Platelet</span>, ';
            report.status = 4;
          }
        }

        // Check Urea status
        if (report.investigation_id === 18) {
          if (isInvalid || (parsedResult >= 5 && parsedResult <= 24)) {
            setFit(1);
            report.status = 2;
          } else {
            setHeldUp(1);
            riskFactor += '<span style="color: #0000FF;">Urea</span>, ';
            report.status = 4;
          }
        }

        // Check Leishmaniasis status
        if (report.investigation_id === 48) {
          if (isInvalid || result === "Absent") {
            setFit(1);
            report.status = 2;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">Leishmaniasis</span>, ';
            report.status = 3;
          }
        }

        // Check Microfilaria status
        if (report.investigation_id === 41) {
          if (isInvalid || result === "Absent") {
            setFit(1);
            report.status = 2;
          } else {
            setUnfit(1);
            riskFactor += '<span style="color: red;">Microfilaria</span>, ';
            report.status = 3;
          }
        }
      });

      setPathologyRiskFactor((previousComment) => ({
        ...previousComment,
        user_id: userId,
        comments: riskFactor,
        investigation_group_id: 102,
      }));

      if (unfit === 1) {
        setStatus(3);
        setStatusState(3);
      } else if (heldUp === 1) {
        setStatus(4);
        setStatusState(4);
      } else if (fit === 1) {
        setStatus(2);
        setStatusState(2);
      }
    };

    const [userDetail, setUserDetail] = useState(null);
    const [user, setUser] = useState(null);
    const [investigations, setInvestigations] = useState([]);
    const [pathologyTests, setPathologyTests] = useState([]);
    const [pathologyReportData, setPathologyReportData] = useState([]);
    const [pathologyComment, setPathologyComment] = useState({
      user_id: userId,
      comments: "",
      status: 1,
      investigation_group_id: 2,
    });
    const [pathologyRiskFactor, setPathologyRiskFactor] = useState({
      user_id: userId,
      comments: riskFactor,
      investigation_group_id: 102,
    });

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const formattedBirthDate = user?.user?.date_of_birth
      ? new Date(user?.user?.date_of_birth).toLocaleDateString("en-GB")
      : "---";

    const handleOnChange = (event, testId) => {
      setIsDataSaved(false);
      setPathologyReportData((previousReport) => {
        const updatedReport = previousReport.map((report) => {
          if (report?.test_id === testId) {
            return {
              ...report,
              result: event.target.value,
            };
          }
          return report;
        });
        return updatedReport;
      });
      onInputChange(event, testId);
    };

    const handleNormalValue = () => {
      investigations.forEach((investigation, index) => {
        setPathologyReportData((previousReport) => {
          const updatedReport = previousReport.map((report) => {
            if (report?.test_id === investigation?.investigation_tests[0]?.id) {
              if (investigation?.investigation_tests[0]?.info) {
                return {
                  ...report,
                  result: investigation?.investigation_tests[0]?.info?.replace(
                    /<[^>]*>/g,
                    "",
                  ),
                  status: 0,
                };
              }
            }
            return report;
          });
          setPathologyReportData(updatedReport);
          return updatedReport;
        });
      });

      setIsDataSaved(false);
      sendReportDataToParent();
    };

    const getUserDetail = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/user-details/test/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setUserDetail(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getActiveInvestigationTests = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/reports/get-data/${data}`,
          {
            invIds:
              user?.user?.role_id === 5
                ? user?.investigation_id?.split(",")
                : user?.package?.investigation_id?.split(","),
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setInvestigations(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getPathologyTests = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/reports/pathology/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        setPathologyTests(response?.data?.data);
        if (response?.data?.data) {
          setIsDataSaved(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getComment = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/reports/get-pathology-comments/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        const { comments, status } = response?.data?.data;

        setPathologyComment((previousComment) => {
          return { ...previousComment, comments, status };
        });
      } catch (error) {
        console.error(error);
      }
    };

    const savePathologyComment = async () => {
      setStatus((prevStatus) => {
        if (unfit === 1) {
          setStatusState(3);
          return 3;
        } else if (heldUp === 1) {
          setStatusState(4);
          return 4;
        } else if (fit === 1) {
          setStatusState(2);
          return 2;
        } else {
          return prevStatus;
        }
      });

      const combinedData = {
        pathologyComment: {
          ...pathologyComment,
          status,
        },
        pathologyRiskFactor: {
          ...pathologyRiskFactor,
          status: status,
        },
      };

      try {
        const res = await axios.post(
          `${baseUrl}/reports/save-pathology-comments`,
          {
            combinedData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );
        if (res) {
          await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const savePathologyReport = async () => {
      setLoading(true);
      try {
        const emptyResult = pathologyReportData.filter(
          (item) =>
            item.result === "" &&
            item.investigation_id !== 19 &&
            item.investigation_id !== 22,
        ).length;

        const dashCount = pathologyReportData.filter(
          (item) =>
            item.result === "---" &&
            item.investigation_id !== 19 &&
            item.investigation_id !== 22,
        ).length;

        // console.log(emptyResult, dashCount);
        // console.log(user?.package?.id === 2);
        let checkedValue = 0;
        if (user?.package?.id !== 2) {
          if (emptyResult >= 1 || dashCount >= 3) {
            setChecked(1);
            checkedValue = 1;
          }
        } else if (user?.package?.id === 2) {
          setChecked(0);
          checkedValue = 0;
        } else {
          setChecked(0);
          checkedValue = 0;
        }

        // Save pathology status
        const pathologyStatusResponse = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/pathology-status/${userId}`,
          { data: checkedValue },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        );

        if (pathologyStatusResponse.status === 200) {
          // Save pathology report
          const response = await axios.put(
            `${baseUrl}/reports/pathology/${userId}`,
            pathologyReportData,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          );

          // Save pathology comment
          await savePathologyComment();

          setIsDataSaved(true);
          // toast.success(response?.data?.data);
          setLoading(false);
        } else {
          toast.error("Failed to update pathology entry!");
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
      setLoading(false);
    };

    const sendReportDataToParent = () => {
      onPathologyReportData(pathologyReportData); // Call parent function with data
    };

    useImperativeHandle(pathologyRef, () => ({
      handleNormalValue,
      savePathologyReport,
    }));

    useEffect(() => {
      sendReportDataToParent();
    }, [userId, pathologyReportData, fit, unfit, heldUp]);

    useEffect(() => {
      statusCheckHandler(pathologyReportData);
    }, [userId, pathologyReportData, fit, unfit, heldUp, riskFactor]);

    useEffect(() => {
      const combinedData = {
        pathologyComment: {
          ...pathologyComment,
          status,
        },
        pathologyRiskFactor: {
          ...pathologyRiskFactor,
          status,
        },
      };

      const saveData = async () => {
        try {
          const res = await axios.post(
            `${baseUrl}/reports/save-pathology-comments`,
            {
              combinedData,
            },
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            },
          );
          if (res) {
            await axios.get(`${baseUrl}/reports/user-test-status/${userId}`, {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            });
          }
        } catch (error) {
          console.error(error);
        }
      };

      saveData();
    }, []);

    useEffect(() => {
      userId && getUserDetail();
      userId && getPathologyTests();
      userId && getComment();
      setPathologyComment((previousComment) => {
        return { ...previousComment, user_id: userId, comments: "" };
      });
    }, [userId]);

    useEffect(() => {
      if (pathologyTests.length <= 0) {
        investigations?.forEach((investigation) => {
          setPathologyReportData((previousReport) => {
            previousReport = [
              ...previousReport,
              {
                user_id: userId,
                investigation_id: investigation.id,
                test_id: investigation.investigation_tests[0]?.id,
                user_file: "",
                result: "",
              },
            ];

            return [...previousReport];
          });
        });
      }
    }, [investigations, userId]);

    useEffect(() => {
      // Initialize pathologyReportData with fetched values if available
      if (pathologyTests?.length > 0) {
        const initialData = investigations.map((investigation) => {
          // Find matching pathologyTest for the current investigation
          const matchedTest = pathologyTests.find(
            (test) => test.investigation_id === investigation.id,
          );

          // Determine the result and test_id based on matchedTest or default to the first investigation_test
          const result = matchedTest?.result?.replace(/<[^>]*>/g, "") || "";
          const test_id = matchedTest
            ? matchedTest.test_id
            : investigation.investigation_tests[0]?.id;

          return {
            user_id: userId,
            investigation_id: investigation.id,
            test_id: test_id,
            user_file: matchedTest ? matchedTest.user_file : "",
            result: result,
          };
        });

        setPathologyReportData(initialData);
      } else {
        // If no fetched values, initialize pathologyReportData based on investigations
        const initialData = investigations.map((investigation) => ({
          user_id: userId,
          investigation_id: investigation.id,
          test_id: investigation.investigation_tests[0].id,
          user_file: "",
          result: "",
        }));

        setPathologyReportData(initialData);
      }
    }, [userId, pathologyTests, investigations]);

    const [representative, setRepresentative] = useState();
    useEffect(() => {
      userDetail && setUser(userDetail.userDetail);
      const introducer_id = userDetail?.userDetail?.introducer_user?.id;

      if (introducer_id) {
        axios(
          `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          },
        )
          .then((res) => {
            const representative_id = res?.data?.user_id;

            axios(
              `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
                },
              },
            )
              .then((res) => {
                setRepresentative(res?.data?.data?.userQuery);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, [userDetail]);

    useEffect(() => {
      user && getActiveInvestigationTests();
    }, [user]);

    // console.log("fit", fit);
    // console.log("unfit", unfit);
    // console.log("heldp", heldUp);
    return (
      <>
        <div className="card p-3 medical-pathology">
          <div className="row mb-2 bg-primary text-white px-2 py-2">
            <h6 className="mb-0">
              Medical Pathology Report for {user?.user?.f_name}[
              {user?.user?.reg_prefix || ""}
              {user?.user?.registration_no}]
            </h6>
          </div>

          <div
            className={`bg-white
             mt-1 p-3 px-4 rounded-3 
              text-dark border
            `}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              {investigations?.map((investigation, index) => {
                const isMaleAndPregnancyTest =
                  (user?.gender?.name === "Male" ||
                    user?.gender?.name === "male") &&
                  investigation?.name === "Pregnancy Test";
                if (isMaleAndPregnancyTest) {
                  return null;
                }
                const report = pathologyReportData.find(
                  (report) =>
                    report?.test_id ===
                    investigation?.investigation_tests[0]?.id,
                );
                // Determine the text color based on status
                let textColorClass = "";
                if (isDataSaved && report) {
                  if (report.status === 2) {
                    // Fit
                    textColorClass = "text-success";
                  } else if (report.status === 3) {
                    // Unfit
                    textColorClass = "text-danger";
                  } else if (report.status === 4) {
                    // Held-up
                    textColorClass = "text-primary";
                  }
                }
                return (
                  <div key={index} className={`d-flex align-items-center fs-6`}>
                    <label
                      htmlFor={`${investigation?.name?.toLowerCase()}`}
                      className="w-25"
                    >
                      {investigation?.name}
                    </label>
                    {investigation?.investigation_tests[0]?.value_type?.toLowerCase() ===
                    "dropdown" ? (
                      <select
                        name="dropdown"
                        value={report?.result}
                        className={`form-select w-75 ${textColorClass}`}
                        id={`${investigation?.name?.toLowerCase()}`}
                        onChange={(event) =>
                          handleOnChange(
                            event,
                            investigation?.investigation_tests[0]?.id,
                          )
                        }
                      >
                        {investigation?.investigation_tests[0]?.dropdown_value
                          ?.split(",")
                          .map((v, index) => (
                            <option key={index} value={v}>
                              {v}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id={`${investigation?.name?.toLowerCase()}`}
                        value={report?.result}
                        className={`form-control w-75 ${textColorClass}`}
                        onChange={(event) =>
                          handleOnChange(
                            event,
                            investigation?.investigation_tests[0]?.id,
                          )
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {same !== 1 && (
            <div className="row mt-3">
              <div className="row mt-3">
                <div className="col-6">
                  {/* <div className="d-flex gap-2"> */}
                  {/*   {userDetail?.nextPage && ( */}
                  {/*     <Link */}
                  {/*       to={`/dashboard/medical-reports/pathology/${userDetail?.nextPage}?data=1`} */}
                  {/*       className="btn btn-primary" */}
                  {/*     > */}
                  {/*       Previous */}
                  {/*     </Link> */}
                  {/*   )} */}
                  {/*   {userDetail?.previousPage && ( */}
                  {/*     <Link */}
                  {/*       to={`/dashboard/medical-reports/pathology/${userDetail?.previousPage}?data=1`} */}
                  {/*       className="btn btn-primary" */}
                  {/*     > */}
                  {/*       Next */}
                  {/*     </Link> */}
                  {/*   )} */}
                  {/* </div> */}
                </div>
                <div className="col-6">
                  <div className="d-flex gap-2 justify-content-end">
                    {accessPerm(33, 22) && (
                      <button
                        className="btn btn-primary"
                        onClick={handleNormalValue}
                      >
                        Set Normal
                      </button>
                    )}
                    {/* <button */}
                    {/*   className="btn btn-success px-2" */}
                    {/*   onClick={savePathologyReport} */}
                    {/* > */}
                    {/*   Save Results */}
                    {/* </button> */}
                    {/**/}
                    {/* <button */}
                    {/*   className="btn btn-outline-secondary" */}
                    {/*   onClick={() => { */}
                    {/*     window.close(); */}
                    {/*   }} */}
                    {/* > */}
                    {/*   Close */}
                    {/* </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default MedicalPathologyEntry;

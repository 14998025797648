import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../Investigation/Report/MedicalXray/MedicalXray.scss";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../Context/UserContextAPI";
import "../Investigation/Report/Certificates/HeldUp.scss";
import "../Investigation/Report/Certificates/Unfit.scss";
import QRCode from "qrcode.react";
import { format } from "date-fns";
import UserInfoTable from "../Investigation/Report/Certificates/Components/UserInfoTable";

const XrayImage = ({ verification, close }) => {
  const pdfRef = useRef();
  const { currentUser, accessPerm, settingsData } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const patientData = state?.record;
  const userId = state?.user_id || id;
  const [xrayImage, setXrayImage] = useState();
  const [physicalData, setPhysicalData] = useState([]);
  const [xrayLoading, setXrayLoading] = useState(true);

  const [riskFactor, setRiskFactor] = useState("");
  const [loading, setLoading] = useState(false);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [radiologyReportData, setRadiologyReportData] = useState([]);
  const [fit, setFit] = useState(0);
  const [unfit, setUnfit] = useState(0);
  const [heldUp, setHeldUp] = useState(0);
  const [imagePath, setImagePath] = useState("");

  const [radiologyComment, setRadiologyComment] = useState({
    user_id: userId,
    comments: "",
    status: 1,
    investigation_group_id: 1,
  });
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState({
    user_id: patientData?.user_id,
    comments: "",
    status: 1,
    investigation_group_id: 100,
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${userDetail?.user?.id}`;
  const now = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const registrationNo = user?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const comments = response?.data?.data || [];

      setRadiologyComment((previousComment) => {
        return {
          ...previousComment,
          user_id: userId,
          comments: comments[0]?.comments,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getUserDetail = async () => {
    try {
      setLoading(true);
      setXrayLoading(true);
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data);
      userDetail && setUser(userDetail?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/2`,
        {
          invIds: user?.package?.investigation_id.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setInvestigations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }
  const birthYear = user?.user?.date_of_birth
    ? new Date(user?.user?.date_of_birth).getFullYear()
    : null;
  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";
  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientRegNameDataUrl = generateBarcode(
    String(`${user?.user?.f_name} - ${age}`)
  );

  useEffect(() => {
    if (radiologyTests.length <= 0) {
      investigations?.investigation_tests?.forEach((investigation) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: investigations.id,
              test_id: investigation.id,
              user_file: "",
              result: "",
            },
          ];
          return [...previousReport];
        });
      });
    }
  }, [investigations, userId]);

  useEffect(() => {
    setLoading(true);
    setRadiologyReportData([]);
    if (radiologyTests.length > 0) {
      radiologyTests.forEach((radiologyTest) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: radiologyTest.investigation_id || "",
              test_id: radiologyTest.test_id || "",
              user_file: radiologyTest?.user_file,
              result: radiologyTest.result?.replace(/<[^>]*>/g, "") || "",
            },
          ];
          return [...previousReport];
        });

        if (radiologyTest.investigation_id && radiologyTest.test_id === 1) {
          setXrayImage(
            (previousImage) => (previousImage = radiologyTest.user_file)
          );
          setXrayLoading(false);
        }
      });
    }
    setLoading(false);
  }, [userId, radiologyTests, imagePath]);

  // useEffect(() => {
  //   if (riskFactor >= 1 && riskFactor <= 70) {
  //     setRadiologyComment((previous) => ({ ...previous, status: 4 }));
  //   } else if (riskFactor === "") {
  //     setRadiologyComment((previous) => ({ ...previous, status: 2 }));
  //   } else {
  //     setRadiologyComment((previous) => ({ ...previous, status: 3 }));
  //   }
  // }, [radiologyReportData]);

  const [representative, setRepresentative] = useState();
  useEffect(() => {
    setLoading(true); // newly added
    userDetail && setUser(userDetail?.userDetail);
    if (riskFactor === undefined) {
      setRiskFactor("");
    }

    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  }, [userDetail, userDetail?.nextPage, userDetail?.previousPage, riskFactor]);

  useEffect(() => {
    if (userId) {
      setLoading(true);

      getUserDetail()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    userId && getComments();
  }, [userId]);

  useEffect(() => {
    setRadiologyComment((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: radiologyComment,
      };
    });
  }, [userId]);

  useEffect(() => {
    setRadiologyRiskFactor((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: riskFactor,
      };
    });
  }, [userId]);

  useEffect(() => {
    user && getActiveInvestigationTests();
  }, [user]);

  useEffect(() => {
    userId && getRadiologyTests();
  }, [userId]);

  useEffect(() => {
    userId && getPhysicalTests();
  }, [userId]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);
  return (
    <>
      <section
        className="held-up-certificate verification-test px-3"
        ref={pdfRef}
        style={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
        }}
      >
        <div className="row align-items-center">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
        <div className="w-100 px-2">
          <div className="row">
            <h4 className="fw-bold fs-5 text-center" style={{ color: "#000" }}>
              MEDICAL X-RAY REPORT
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-between fw-bolder">
          <div>
            <p className="fs-5 text-dark">
              Reg No : {user?.user?.reg_prefix}
              {user?.user?.registration_no}{" "}
            </p>
          </div>
          <div>
            <p className="fs-5 text-dark">
              Reg Date :{" "}
              {formatDate(user?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
            </p>
          </div>
        </div>

        {/* bar code  */}
        <div className="d-flex justify-content-between">
          <div style={{ width: "40%" }}>
            <img
              src={patientRegNameDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={50}
            />
          </div>
          <div style={{ width: "50%" }}>
            <img
              src={patientIdDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={50}
            />
          </div>
        </div>

        <UserInfoTable
          user={userDetail?.userDetail?.user}
          userDetails={userDetail?.userDetail}
          age={age}
          physicalData={physicalData}
          staticXray={1}
        />
        <div className="d-flex align-items-center w-100 mt-3 px-2">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              {!xrayImage ? (
                <div
                  className="d-flex align-items-center justify-content-center fs-6 border border-5 rounded-1 text-dark"
                  style={{ width: "100%", height: "500px" }}
                >
                  No X-Ray Image Found
                </div>
              ) : (
                <div>
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                    style={{ width: "100%", height: "500px" }}
                    alt=""
                    className="medical-xray-print-image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="container d-flex justify-content-around align-items-end">
            {/* <div className="row w-100">
              <div className="text-start">
                <div className="d-flex justify-content-around align-items-end mt-2">
                  <div className="text-dark">
                    <div
                      className="border-top mt-0 border-dark"
                      style={{ width: "125px" }}
                    ></div>
                    Chief Physician
                  </div>
                  <div className="text-dark">
                    <div
                      className="border-top mt-0 border-dark"
                      style={{ width: "125px" }}
                    ></div>
                    Signature
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="d-flex flex-column w-100 px-3">
            <div className="d-flex flex-column justify-content-center align-items-end mt-2 mb-2">
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={80} />
              </Link>
              {/* <div className="text-start text-dark p-0 mt-1">
                Printed By: {currentUser?.f_name}
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2 px-4">
        {verification !== 1 && accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}
        {close !== 1 && (
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              navigate("/medical-verification");
            }}
          >
            Back
          </button>
        )}
      </div>
    </>
  );
};

export default XrayImage;

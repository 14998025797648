import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "undo",
    "redo",
  ],
};

const RichTextEditor = ({ data, setData, changeTextEditorValue, index }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data.info}
      onChange={(_, editor) => changeTextEditorValue(editor, data.id)}
      config={config}
    />
  );
};

export default RichTextEditor;

import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import IntroducerListModal from "../../../components/Modal/IntroducerListModal/IntroducerListModal";
import PasswordChangeModal from "../../../components/Modal/PasswordChangeModal/PasswordChangeModal";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
import Loading from "../../../utils/Loader/Loading";
import { UserContext } from "../../../Context/UserContextAPI";
import { saveAs } from "file-saver";

const RoleWiseListPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState();
  const [editModalData, setEditModalData] = useState({});
  const [editId, setEditId] = useState();
  const [deleteModalData, setDeleteModalData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isSMSChecked, setIsSMSChecked] = useState();
  const [passwordModalData, setPasswordModalData] = useState({});
  const [representativeUsers, setRepresentativeUsers] = useState([]);
  const [introducerId, setIntroducerId] = useState(null);
  const [representativeUser, setRepresentativeUser] = useState({});
  const [introducerList, setIntroducerList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  const navigate = useNavigate();
  const { id: roleId } = useParams();
  const { accessPerm } = useContext(UserContext);

  const handlerOnCreateFormSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const smsCheckedValue = isSMSChecked ? "1" : "0";
    const firstName = form.firstName.value;
    const email = form.email.value;
    const dateOfBirth = form.date_of_birth.value;
    const address1 = form.address1.value;
    const address2 = form.address2.value;
    const image = form.image.files[0];
    const mobile = form.mobile.value;
    const password = form.password.value;
    const contactPerson = form.contactPerson.value;
    const nid = form.nid.value;
    const commissionRate = form.commission_rate.value;
    const remarks = form.remarks.value;
    const role = roleId !== undefined ? roleId : parseInt(form.role.value);

    const status = form.status.value;
    const userStatus = status === "true" ? 1 : 0;

    const formData = new FormData();
    formData.append("image", image);

    // Image Hosting Method(API)
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/users/user/upload-image`,
        formData,
      )
      .then((res) => {
        const imagePath = res.data.filename;

        const usersData = {
          f_name: firstName,
          l_name: "",
          email: email,
          date_of_birth: dateOfBirth,
          gender_id: "None",
          country_id: "Bangladesh",
          address1: address1,
          address2: address2,
          password: password,
          mobile: mobile,
          contactPerson: contactPerson,
          nid: nid,
          commission_rate: commissionRate,
          remarks: remarks,
          role_id: role,
          status: userStatus,
        };
        if (role === "8") {
          usersData.pregnancyField = form.pregnancyField.value;
          usersData.SMSChecked = smsCheckedValue;
          usersData.credit_limit = form.credit_limit.value;
        }
        if (imagePath) {
          usersData.image = imagePath;
        }
        methodCreateUser(usersData, form);
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  const methodCreateUser = async (usersData, form) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/signup`,
        usersData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (res.data.status === 400) {
        toast.error(res.data.message);
      } else {
        toast.success("User Created successfully!!");
        setRefresh(!refresh);
        if (roleId !== undefined) {
          await fetchRoleWiseUserAPI(roleId, currentPage, pageSize);
        }
        form.reset();
      }
    } catch (error) {
      toast.error(error?.response?.data?.data?.error);
    }
  };

  const handleLiveSearch = async (searchText) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/role/search/${roleId}`,
        { searchText },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (response.status === 200) {
        setUsers(response?.data?.data);
        setTotalRows(response?.data?.totalRows);
      } else {
        toast.error("Error while fetching search results");
      }
    } catch (error) {
      // console.error(error);
      toast.error("An error occurred during the search.");
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = users?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1,
  );

  const handlePDF = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/generate-pdf/role/${roleId}`,
        { responseType: "blob" },
      );

      // Save the PDF using file-saver
      saveAs(
        new Blob([response.data], { type: "application/pdf" }),
        `List.pdf`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="d-flex w-100">
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            handleLiveSearch(e.target.value);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
        {/* {accessPerm(5, 24) && ( */}
        <button
          type="button"
          className="btn btn-success border border-3"
          onClick={() => handlePDF()}
          style={{ whiteSpace: "nowrap" }}
        >
          Download PDF
        </button>
        {/* )} */}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handlerOnEditFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const smsCheckedValue = isSMSChecked ? "1" : "0";
    const firstName = form.firstName.value;
    const email = form.email.value;
    const dateOfBirth = form.date_of_birth.value;
    const address1 = form.address1.value;
    const address2 = form.address2.value;
    const image = form.image.files[0];
    const mobile = form.mobile.value;
    const contactPerson = form.contactPerson.value;
    const nid = form.nid.value;
    const commissionRate = form.commission_rate.value;
    const remarks = form.remarks.value;
    const role = roleId;

    const status = form.status.value;
    const userStatus = status === "true" ? 1 : 0;

    const formData = new FormData();
    formData.append("image", image);

    const usersData = {
      f_name: firstName,
      l_name: "",
      email: email,
      gender_id: "None",
      country_id: "Bangladesh",
      date_of_birth: dateOfBirth,
      address1: address1,
      address2: address2,
      mobile: mobile,
      contactPerson: contactPerson,
      nid: nid,
      commission_rate: commissionRate,
      remarks: remarks,
      role_id: role,
      status: userStatus,
    };
    if (role === "8") {
      usersData.pregnancyField = form.pregnancyField.value;
      usersData.SMSChecked = smsCheckedValue;
      usersData.credit_limit = form.credit_limit.value;
    }

    // Image Hosting Method(API)
    if (image) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/users/user/upload-image`,
          formData,
        )
        .then((res) => {
          const imagePath = res?.data?.filename;

          if (imagePath) {
            usersData.image = imagePath;
          }
          methodUpdateUser(usersData);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    } else {
      methodUpdateUser(usersData);
    }
  };

  const methodUpdateUser = async (usersData) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/user/${editModalData?.id}`,
      usersData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    if (res?.status === 200) {
      toast.success("User Updated successfully!!");
      if (roleId !== undefined) {
        await fetchRoleWiseUserAPI(roleId, currentPage, pageSize);
      }
    } else {
      toast.error(res?.data?.message);
      setRefresh(!refresh);
    }
  };

  const handlerOnDelete = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/users/user/${deleteModalData?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );
    // console.log(res);
    if (res.status === 200) {
      toast.success("User Deleted successfully!!");
      if (roleId !== undefined) {
        await fetchRoleWiseUserAPI(roleId, currentPage, pageSize);
        await handleLiveSearch(filterText);
      }
      setRefresh(!refresh);
    } else {
      toast.error("Error deleting the user");
    }
  };

  const fetchRoleAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/roles`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      setRoles(data);
      setRefresh(refresh);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSMSCheck = (e) => {
    setIsSMSChecked(e.target.checked);
  };

  const fetchRoleWiseUserAPI = async (roleId, page, pageSize) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/role/${roleId}?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (res) {
        setUsers(res?.data?.data);
        setTotalRows(res?.data?.totalRows);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchRepresentativeUserAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/representative`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      setRepresentativeUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchRepresentativeByIntroducerId = async (introducerId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const representativeUser = res?.data?.representative_user_map;

      if (representativeUser) {
        setRepresentativeUser(representativeUser);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchIntroducerUsersList = async (representativeId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/representative/${representativeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = res?.data;
      if (data) {
        setIntroducerList(data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const methodCreateUserMap = async (user_id, map_user_id) => {
    try {
      const userMapData = {
        user_id: user_id,
        map_user_id: map_user_id,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users-map`,
        userMapData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      if (res?.data?.message) {
        toast.error(res?.data?.message);
      } else {
        toast.success(`Representative User Added Successfully!!`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onSubmitRepresentativeId = async (e) => {
    e.preventDefault();

    const user_id = parseInt(e.target.representative_id.value);
    const map_user_id = introducerId;

    if (user_id && map_user_id) {
      await methodCreateUserMap(user_id, map_user_id);
    }
  };

  const handleChange = async (e, id) => {
    e.preventDefault();

    let status;
    const form = e.target;
    if (form.value === "1") {
      status = "0";
    } else {
      status = "1";
    }
    const usersData = {
      status: status,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/user/${id}`,
      usersData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      },
    );

    if (res?.status === 200) {
      toast.success("User Updated successfully!!");
      if (roleId !== undefined) {
        await fetchRoleWiseUserAPI(roleId, currentPage, pageSize);
      }
    } else {
      toast.error(res?.data?.message);
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    setRoleName(
      roles.find((role) => parseInt(role?.id) === parseInt(roleId))?.name,
    );
  }, [roles, roleId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const pageParam = urlParams.get("page") || 1;
        const pageSizeParam = urlParams.get("pageSize") || 50;

        setCurrentPage(parseInt(pageParam, 10));
        setPageSize(parseInt(pageSizeParam, 10));

        if (roleId !== undefined) {
          await fetchRoleWiseUserAPI(roleId, pageParam, pageSizeParam);
          await fetchRepresentativeUserAPI();
        }
      } catch (error) {
        console.log("Error in API calls: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchRoleAPI();
    fetchRoleWiseUserAPI(roleId, currentPage, pageSize);
  }, [currentPage, pageSize]);

  const columns = [
    {
      name: "SL",
      width: "100px",
      align: "center",
      cell: (data, index) => {
        return <div style={{ width: "50px" }}>{index + 1}</div>;
      },
    },
    {
      name: "Reg. No",
      width: "150px",
      align: "left",
      wrap: "yes",
      omit: roleId === "14" ? true : false,
      cell: (data, index) => {
        return (
          <div style={{ width: "50px" }}>
            {data?.reg_prefix || ""}
            {data?.registration_no}
          </div>
        );
      },
    },
    {
      name: "Name",
      align: "left",
      selector: (data) => data?.f_name,
      wrap: "yes",
    },
    {
      name: "Role",
      width: "140px",
      selector: (data) => data?.role?.name,
      omit: roleId ? true : false,
    },
    {
      name: "Mobile",
      width: "140px",
      selector: (data) => data?.mobile,
      wrap: "yes",
    },
    {
      name: "Introducer Name",
      cell: (data) => {
        const representativeNum =
          data?.use_details?.introducer_user?.user_users_maps[0]
            ?.representative_user_map?.mobile;
        return (
          <div className="text-start w-100">
            {data?.use_details?.introducer_user?.f_name} -{" "}
            {representativeNum && (
              <span>
                {"("}
                {representativeNum.slice(-2)}
                {")"}
              </span>
            )}
          </div>
        );
      },
      wrap: "yes",
      omit: roleId !== "14" ? true : false,
    },
    {
      name: "Email",
      selector: (data) => data?.email,
      wrap: "yes",
    },
    {
      name: "Contact Person",
      selector: (data) => data?.contact_person,
      wrap: "yes",
    },
    {
      name: "Credit Limit",
      cell: (data) => {
        return <div className="text-end w-100">{data?.credit_limit}</div>;
      },
      wrap: "yes",
      omit: roleId === "14" || roleId !== "8" ? true : false,
    },
    {
      name: "Commission",
      // sortable: true,
      cell: (data) => {
        return <div className="text-end w-100">{data?.commission_rate}</div>;
      },
      wrap: "yes",
      omit: roleId === "14" ? true : false,
    },
    {
      name: "Wallet",
      // sortable: true,
      cell: (data) => {
        return <div className="text-end w-100">{data?.wallet}</div>;
      },
      wrap: "yes",
      omit: roleId === "14" ? true : false,
    },
    {
      name: "Status",
      width: "95px",
      selector: (data) => data.status,
      // sortable: true,
      cell: (data) => {
        return <>{data?.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      name: "Action",
      width: roleId ? "190px" : "155px",
      align: "left",
      sortable: false,
      omit: roleId === "14" ? true : false,
      cell: (data) => {
        const filterRoles = roles?.filter((filterRole) => {
          return filterRole?.id !== data?.role_id;
        });

        return (
          <>
            {/* View Profile Trigger Button */}
            {accessPerm(5, 5) && (
              <button
                type="button"
                onClick={() =>
                  navigate("/dashboard/profile", { state: { data } })
                }
                className="btn btn-warning btn-sm"
                style={{ marginRight: "5px" }}
              >
                <i className="fa-regular fa-eye mx-auto"></i>
              </button>
            )}
            {/* Change Password Trigger Button */}
            {accessPerm(5, 6) && (
              <button
                type="button"
                onClick={() => setPasswordModalData(data)}
                className="btn btn-dark btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#changePasswordModal`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa-solid fa-key mx-auto"></i>
              </button>
            )}

            {/* Edit User Trigger Button */}
            {accessPerm(5, 2) && (
              <button
                type="button"
                onClick={() => {
                  setEditModalData(data);
                  if (data?.status === 1) {
                    setIsChecked(true);
                  } else {
                    setIsChecked(false);
                  }
                  if (data?.SMSChecked === 1) {
                    setIsSMSChecked(true);
                  } else {
                    setIsSMSChecked(false);
                  }
                }}
                className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#editUserModal-${data?.id}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit mx-auto"></i>
              </button>
            )}

            {/* Delete User Trigger Button */}
            {accessPerm(5, 3) && (
              <button
                type="button"
                onClick={() => setDeleteModalData(data)}
                className="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#deleteUserModal-${data?.id}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-trash mx-auto"></i>
              </button>
            )}

            {/* Edit Modal Body */}
            <div
              className="modal fade"
              id={`editUserModal-${data?.id}`}
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content modal-dialog-scrollable">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit {roleId === "1" && "Software Engineer"}
                      {roleId === "2" && "Administration"}
                      {roleId === "3" && "Doctor"}
                      {roleId === "4" && "Medical"}
                      {roleId === "5" && "Hospital Patient"}
                      {roleId === "6" && "Company"}
                      {roleId === "7" && "Delegets"}
                      {roleId === "8" && "Introducer"}
                      {roleId === "9" && "Representative"} User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form onSubmit={(e) => handlerOnEditFormSubmit(e)}>
                    <div className="modal-body ">
                      <div className="row d-flex justify-content-between">
                        <div className="col-6 pe-4 ">
                          {roleId === undefined && (
                            <div className="row mb-3">
                              <label className="col-sm-3 col-form-label">
                                Select Role
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-9">
                                <select
                                  className="form-select"
                                  name="role"
                                  aria-label="Default select example"
                                  disabled
                                  // required
                                >
                                  <option value={data?.role_id} selected>
                                    {data?.role?.name}
                                  </option>

                                  {filterRoles?.map((role) => (
                                    <option
                                      key={role?.id}
                                      value={parseInt(role?.id)}
                                    >
                                      {role?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              <img
                                className=" img-fluid w-75 h-75 rounded-circle"
                                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${data?.image}`}
                                alt="Profile Pic"
                              />
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="file"
                                accept="image/*"
                                name="image"
                                // defaultValue={}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              Name / Company
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="firstName"
                                defaultValue={data.f_name}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              Mobile Number
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="tel"
                                name="mobile"
                                defaultValue={data.mobile}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              E-mail
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="email"
                                name="email"
                                defaultValue={data?.email}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              Date of Birth
                            </label>
                            <div className="col-sm-9">
                              <input
                                // id={`$#date-${data?.id}`}
                                type="date"
                                name="date_of_birth"
                                defaultValue={data?.date_of_birth}
                                className="form-control"
                                placeholder="Date Picker..."
                              />
                            </div>
                          </div>
                          {roleId === "8" && (
                            <>
                              <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                  Pregnancy Field
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    className="form-select"
                                    name="pregnancyField"
                                    aria-label="Default select example"
                                    defaultValue={data?.pregnancyField}
                                  >
                                    <option value="">Choose One</option>
                                    <option value="0">Hide</option>
                                    <option value="1">Show</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-3 d-flex align-items-center w-75">
                                <input
                                  className="form-check-input mt-0 me-2 col-sm-3"
                                  type="checkbox"
                                  checked={isSMSChecked}
                                  name="SMSChecked"
                                  value={isSMSChecked ? 1 : 0}
                                  onChange={handleSMSCheck}
                                  id={`flexIntroducerSMSChecked-${data?.id}`}
                                />
                                <label
                                  className="form-check-label col-sm-5"
                                  htmlFor={`flexIntroducerSMSChecked-${data?.id}`}
                                >
                                  Skip SMS to Introducer
                                </label>
                              </div>
                            </>
                          )}
                          {roleId !== "8" && (
                            <>
                              <select
                                className=""
                                name="pregnancyField"
                                aria-label="Default select example"
                                defaultValue="1"
                                style={{
                                  display: "none",
                                  visibility: "hidden",
                                }}
                              >
                                <option value="">Choose One</option>
                                <option value="0">Hide</option>
                                <option value="1">Show</option>
                              </select>
                              <div
                                className="row mb-3 d-flex align-items-center w-75"
                                style={{
                                  display: "none",
                                  visibility: "hidden",
                                }}
                              >
                                <input
                                  className="form-check-input mt-0 me-2 col-sm-3"
                                  type="checkbox"
                                  defaultChecked="0"
                                  name="SMSChecked"
                                  id={`flexIntroducerSMSChecked-${data?.id}`}
                                />
                              </div>
                            </>
                          )}
                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              Contact Person
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="contactPerson"
                                defaultValue={data.contact_person}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              NID Number
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                name="nid"
                                defaultValue={data.nid}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              Address_1
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                name="address1"
                                defaultValue={data.address_1}
                                className="form-control w-100"
                                rows="3"
                                maxLength="200"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ps-4">
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              Address_2
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                name="address2"
                                defaultValue={data.address_2}
                                className="form-control w-100"
                                rows="3"
                                maxLength="200"
                              ></textarea>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              Remarks
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                name="remarks"
                                defaultValue={data.remarks}
                                className="form-control w-100"
                                rows="3"
                                maxLength="200"
                              ></textarea>
                            </div>
                          </div>
                          {roleId === "8" ? (
                            <div className="row mb-3 d-flex align-items-center">
                              <label className="col-sm-3 col-form-label">
                                Credit Limit (BDT)
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="number"
                                  name="credit_limit"
                                  className="form-control"
                                  defaultValue={data?.credit_limit}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              Commission Rate (%)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                name="commission_rate"
                                defaultValue={data?.commission_rate}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 d-flex align-items-center justify-content-end">
                            <div className="col-sm-9">
                              <div className="col-sm-12">
                                <div
                                  className="  d-flex align-items-center  justify-content-end"
                                  defaultChecked={
                                    data.status === 1 ? true : false
                                  }
                                >
                                  <input
                                    className="form-check-input mt-0 me-2"
                                    type="checkbox"
                                    defaultChecked={
                                      data.status === 1 ? true : false
                                    }
                                    name="status"
                                    value={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                    id={`flexCheckChecked-${data?.id}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`flexCheckChecked-${data?.id}`}
                                  >
                                    Active
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Update changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Representative add Trigger Button */}
            {roleId === "8" && (
              <>
                {accessPerm(5, 2) && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={`#representativeModal-${data?.id}`}
                    onClick={() => {
                      setIntroducerId(data?.id);
                      fetchRepresentativeByIntroducerId(data?.id);
                    }}
                  >
                    <i className="bi bi-person mx-auto"></i>
                  </button>
                )}

                <div
                  className="modal fade"
                  id={`representativeModal-${data?.id}`}
                  tabIndex="-1"
                  aria-labelledby="representativeModalLabel"
                  aria-hidden="true"
                  onClick={() => setRepresentativeUser({})}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="representativeModalLabel"
                        >
                          Representative
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setRepresentativeUser({})}
                        ></button>
                      </div>
                      <form onSubmit={(e) => onSubmitRepresentativeId(e)}>
                        <div className="modal-body">
                          <label htmlFor="basic-url" className="form-label">
                            Representative
                          </label>
                          <select
                            name="representative_id"
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Representative...</option>
                            {Object.keys(representativeUser).length && (
                              <option value={representativeUser?.id} selected>
                                {representativeUser?.f_name}{" "}
                                {representativeUser?.l_name}(
                                {representativeUser?.mobile?.slice(-2)})
                              </option>
                            )}
                            {representativeUsers
                              ?.filter(
                                (filtered) =>
                                  filtered?.id !== representativeUser?.id,
                              )
                              .map((representativeUser) => (
                                <option
                                  key={representativeUser?.id}
                                  value={parseInt(representativeUser?.id)}
                                >
                                  {representativeUser?.f_name}{" "}
                                  {representativeUser?.l_name} (
                                  {representativeUser?.mobile?.slice(-2)})
                                </option>
                              ))}
                          </select>

                          <div className="d-flex align-items-center justify-content-end mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Introduner show Trigger Button */}
            {roleId === "9" && (
              <>
                {accessPerm(5, 5) && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={`#introducer-list-modal`}
                    onClick={() => {
                      fetchIntroducerUsersList(data?.id);
                    }}
                  >
                    <i className="bi bi-person mx-auto"></i>
                  </button>
                )}
              </>
            )}

            {/* Delete Modal Body */}

            <div
              className="modal fade"
              id={`deleteUserModal-${data?.id}`}
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete User</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body pb-0">
                    <div className="mb-3 row ">
                      <div className="col-sm-10">
                        <p>Are you sure you want to delete?</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handlerOnDelete(deleteModalData)}
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Set Status",
      width: roleId ? "190px" : "155px",
      align: "left",
      sortable: false,
      cell: (data) => {
        return (
          <>
            {accessPerm(5, 2) && (
              <div className="form-check form-switch d-flex align-items-center">
                {/* <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Status
                </label> */}
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data?.status === 1}
                  name="status"
                  defaultValue={data?.status}
                  onClick={async (e) => {
                    setEditId(data);
                    handleChange(e, data?.id);
                  }}
                  style={{ width: "60px", height: "20px" }}
                />
              </div>
            )}
          </>
        );
      },
      omit: roleId !== "14" ? true : false,
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        fontSize: "13px",
        border: "1px solid #e0e0e0",
        fontWeight: "bold",
        backgroundColor: "#095d7e",
        color: "white",
        whiteSpace: "pre-wrap",
        height: "40px",
      },
    },
    subHeader: {
      style: {
        minHeight: "52px",
        padding: "0",
      },
    },
    headCells: {
      style: {
        border: "1px solid #e0e0e0",
        overflowWrap: "normal",
        width: "50px",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
        border: "1px solid #e0e0e0",
        padding: "4px",
        whiteSpace: "pre-wrap",
        overflowWrap: "normal",
        width: "50px",
      },
    },
  };

  const config = {
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    length_menu: [100, 200, 500],
    pagination: true,
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  // console.log(roles.find((role) => role?.id === 14));
  // console.log(roleName);

  const extraButtons = [];
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded" style={{ overflow: "auto" }}>
                <div className="card-box">
                  <h6 className="mb-0 text-uppercase text-dark">
                    {roleId ? `${roleName} List` : "Users List"}
                  </h6>
                  <div className="col">
                    {/* Create User trigger modal Button */}
                    {accessPerm(5, 1) && (
                      <button
                        type="button"
                        onClick={() => setIsChecked(true)}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#createUserModal"
                      >
                        <i className="fa-solid fa-plus mx-auto"></i> Add New
                      </button>
                    )}
                  </div>
                </div>

                <hr />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  config={config}
                  highlightOnHover
                  striped
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows} // Set the total number of rows from the server response
                  paginationPerPage={pageSize}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                    setPageSize(currentRowsPerPage);
                    setCurrentPage(currentPage);
                  }}
                  onChangePage={(page) => {
                    setCurrentPage(page);
                    navigate(`?page=${page}&pageSize=${pageSize}`);
                  }}
                  dense
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  customStyles={customStyles}
                  extraButtons={extraButtons}
                  subHeaderAlign="center"
                  responsive
                />
              </div>
            </div>
          </div>

          {/* Create Modal Body */}
          <div
            className="modal fade"
            id={`createUserModal`}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content  modal-dialog-scrollable ">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Create {roleId === "1" && "Software Engineer"}
                    {roleId === "2" && "Administration"}
                    {roleId === "3" && "Doctor"}
                    {roleId === "4" && "Medical"}
                    {roleId === "5" && "Hospital Patient"}
                    {roleId === "6" && "Company"}
                    {roleId === "7" && "Delegets"}
                    {roleId === "8" && "Introducer"}
                    {roleId === "9" && "Representative"} User
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={(e) => handlerOnCreateFormSubmit(e)}>
                  <div className="modal-body">
                    <div className="row d-flex align-items-start justify-content-between ">
                      <div className="col-6 pe-4">
                        {roleId === undefined && (
                          <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">
                              Select Role <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-select"
                                name="role"
                                aria-label="Default select example"
                                required
                              >
                                {roles?.map((role) => (
                                  <option
                                    key={role?.id}
                                    value={parseInt(role?.id)}
                                  >
                                    {role?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="row mb-3 ">
                          <label className="col-sm-3 col-form-label">
                            User Photo
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              accept="image/*"
                              name="image"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                          <label className="col-sm-3 col-form-label">
                            Name / Company
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                          <label className="col-sm-3 col-form-label">
                            Mobile Number<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="tel"
                              // pattern='[0-9]{3}-[0-9]{4}-[0-9]{4}'
                              name="mobile"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="password"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            E-mail
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Date of Birth
                          </label>
                          <div className="col-sm-9">
                            <input
                              // id='date'
                              type="date"
                              data-date-format="YYYY MM DD"
                              name="date_of_birth"
                              className="form-control"
                              placeholder="Date Picker..."
                            />
                          </div>
                        </div>
                        {roleId === "8" && (
                          <div>
                            <div className="row mb-3 d-flex align-items-center">
                              <label className="col-sm-3 col-form-label">
                                Pregnancy Field
                              </label>
                              <div className="col-sm-9">
                                <select
                                  className="form-select"
                                  name="pregnancyField"
                                  aria-label="Default select example"
                                >
                                  <option value="">Choose One</option>
                                  <option value="0">Hide</option>
                                  <option selected value="1">
                                    Show
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3 d-flex align-items-center w-75">
                              <input
                                className="form-check-input mt-0 me-2 col-sm-3"
                                type="checkbox"
                                name="SMSChecked"
                                onChange={(e) => handleSMSCheck(e)}
                                id={`flexIntroducerSMSChecked`}
                              />
                              <label
                                className="form-check-label col-sm-5"
                                htmlFor={`flexIntroducerSMSChecked`}
                              >
                                Skip SMS to Introducer
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="row mb-3 d-flex align-items-center">
                          <label className="col-sm-3 col-form-label">
                            Contact Person
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="contactPerson"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            NID Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="nid"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6 ps-4">
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Address_1
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="address1"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Address_2
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="address2"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Remarks
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="remarks"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                            ></textarea>
                          </div>
                        </div>
                        {roleId === "8" ? (
                          <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                              Credit Limit (BDT)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                name="credit_limit"
                                className="form-control"
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="row mb-3 d-flex align-items-center">
                          <label className="col-sm-3 col-form-label">
                            Commission Rate (%)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="commission_rate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-3 d-flex align-items-center justify-content-end">
                          <div className="col-sm-9">
                            <div className="col-sm-12">
                              <div className=" d-flex align-items-center  justify-content-end">
                                <input
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  checked={isChecked}
                                  name="status"
                                  value={isChecked}
                                  onChange={() => setIsChecked(!isChecked)}
                                  id="flexCheckChecked"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Delete Modal Body */}
          <PasswordChangeModal
            id={`#changePasswordModal`}
            user={passwordModalData}
          />
          <IntroducerListModal introducerList={introducerList} />
        </div>
      )}
    </>
  );
};

export default RoleWiseListPage;

import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import request from "../../../../utils/request";

const SPTAmountTable = ({ checked, userDetail }) => {
  // state
  const [itemsData, setItemsData] = useState([
    {
      item_id: "",
      item_price: "",
      type: "",
    },
  ]);

  // test investigations data
  const { data: investigations } = useFetch(() =>
    request.getAll("test/investigations"),
  );

  // test instrument data

  const { data: testInstruments } = useFetch(
    () =>
      request.create(`user-test-instruments`, { userId: userDetail?.user_id }),
    userDetail?.user_id,
  );

  // user test data

  const { data: userTestData } = useFetch(
    () =>
      request.create("user-test-instruments/user-tests", {
        userId: userDetail?.user_id,
      }),
    userDetail?.user_id,
  );

  // instrument data
  const { data: instrumentsData } = useFetch(() =>
    request.getAll("instruments"),
  );

  const instrumentsWithType = instrumentsData?.map((instrument) => ({
    ...instrument,
    type: 2,
    label: instrument.name,
    value: instrument.id,
  }));

  const investigationsWithType = investigations?.map((investigation) => ({
    ...investigation,
    type: 1,
    label: investigation.name,
    value: investigation.id,
  }));

  const combinedItems = [...investigationsWithType, ...instrumentsWithType];

  const initializeItemsData = () => {
    let initialItemsData = [];

    // Check if role_id is not 5
    if (userDetail?.user?.role_id !== 5) {
      // Convert the investigation_id string into an array of integers
      const investigationIds = userDetail?.package?.investigation_id
        ?.split(",")
        ?.map((id) => parseInt(id.trim()));
      // Filter investigations based on package investigation_id
      initialItemsData = investigations
        .filter((investigation) => investigationIds?.includes(investigation.id))
        .map((filteredInvestigation) => ({
          item_id: filteredInvestigation.id,
          item_price: filteredInvestigation.price,
          type: 1,
        }));
    } else {
      // Regular initialization for role_id 5
      initialItemsData = [...userTestData, ...testInstruments]?.map((test) => {
        const item = combinedItems?.find(
          (option) =>
            option.value === test.item_id && option.type === test.type,
        );
        return {
          item_id: item ? item?.value : test?.item_id,
          item_price: item
            ? parseFloat(test?.item_price)
            : parseFloat(item?.price), // Use custom price from test
          type: item ? item?.type : test?.type,
        };
      });
    }
    setItemsData(initialItemsData);
  };

  useEffect(() => {
    if (userTestData?.length > 0 || testInstruments?.length > 0) {
      initializeItemsData();
    } else {
      initializeItemsData();
    }
  }, [userTestData, testInstruments, investigations, instrumentsData]);

  const newTotalPrice = itemsData?.reduce((sum, investigation) => {
    const price = parseFloat(investigation.item_price) || 0;
    return sum + price;
  }, 0);

  const discountAmount =
    ((newTotalPrice || 0) * (userDetail?.user?.discount || 0)) / 100;
  const newNetTotalPrice = newTotalPrice - discountAmount;
  const dueAmount =
    (userDetail?.user?.role_id === 5
      ? newNetTotalPrice
      : userDetail?.user_payment?.amount) -
    (userDetail?.user_payment?.paid_amount || 0);

  return (
    <table className="table table-bordered mb-0 custom-table">
      <thead>
        {/* <tr className="table-active">
                <th
                  colSpan="3"
                  className="text-center colspan-heading border border-dark m-0 p-0"
                >
                  Accounts Transaction Details
                </th>
              </tr> */}
        {!checked ? (
          <>
            <tr>
              <th className="col-sm-8 border border-dark">
                Service / Package / Test
              </th>
              <th className="text-center border border-dark">Price (BDT)</th>
            </tr>
          </>
        ) : (
          ""
        )}
      </thead>
      {userDetail?.user?.role_id === 5 && (
        <tbody>
          {!checked ? (
            <>
              {itemsData?.map((item, index) => {
                const matchingItem = combinedItems?.find(
                  (option) =>
                    option.value === item?.item_id && option.type === item.type,
                );
                return (
                  <tr key={index} className="border-dark">
                    <td className="w-50">
                      {matchingItem ? matchingItem?.name : "---"}
                    </td>
                    <td className="w-50 text-end">
                      {/* Display the name of the matching item */}
                      {item ? item?.item_price : "---"}
                    </td>
                  </tr>
                );
              })}

              <tr className="no-border">
                <td className="text-end no-border">Total:</td>
                <td className="text-end no-border">{newTotalPrice || 0}</td>
              </tr>
              {discountAmount > 0 && (
                <tr className="no-border">
                  <td className="text-end no-border">
                    Discount ({discountAmount ? `${discountAmount}%` : "0"}
                    ):
                  </td>
                  <td className="text-end no-border">
                    {userDetail?.user?.discount || 0}
                  </td>
                </tr>
              )}
              <tr className="no-border">
                <td
                  className="text-end no-border fs-5"
                  style={{ fontWeight: "bolder" }}
                >
                  {" "}
                  Total Amount:
                </td>
                <td
                  className="text-end no-border fs-5"
                  style={{ fontWeight: "bolder" }}
                >
                  {newNetTotalPrice || 0}
                </td>
              </tr>
              {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Commission:</td>
                      <td className="text-end no-border">{due || 0}</td>
                    </tr> */}
            </>
          ) : (
            <>
              <tr
                className="text-center d-flex align-items-center justify-content-center border border-dark fw-bold"
                style={{ height: "70px", fontSize: "22px" }}
              >
                Paid
              </tr>
            </>
          )}
        </tbody>
      )}
      {userDetail?.user?.role_id !== 5 && (
        <tbody>
          {!checked ? (
            <>
              <tr>
                <td className="w-50 border border-dark">
                  {userDetail?.package?.name}
                </td>
                <td className="w-50 text-end border border-dark">
                  {/* Display the name of the matching item */}
                  {userDetail?.user_payment?.amount
                    ? userDetail?.user_payment?.amount
                    : 0}
                </td>
              </tr>
              <tr className="no-border" style={{ border: "none" }}>
                <td
                  className="text-end no-border fs-5 "
                  style={{ fontWeight: "bolder" }}
                >
                  {" "}
                  Total Amount:
                </td>
                <td
                  className="text-end no-border fs-5"
                  style={{ fontWeight: "bolder" }}
                >
                  {userDetail?.user_payment?.amount || 0}
                </td>
              </tr>
              {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    {due > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">Due:</td>
                        <td className="text-end no-border">{due || 0}</td>
                      </tr>
                    )} */}
            </>
          ) : (
            <>
              <tr
                className="text-center d-flex align-items-center justify-content-center border border-dark fw-bold"
                style={{ height: "70px", fontSize: "22px" }}
              >
                Paid
              </tr>
            </>
          )}
        </tbody>
      )}
    </table>
  );
};
export default SPTAmountTable;

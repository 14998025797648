import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import JsBarcode from "jsbarcode";
import { addDays, format } from "date-fns";
import { UserContext } from "../../../../Context/UserContextAPI";
import UserInfoTable from "../Certificates/Components/UserInfoTable";
import LaboratoryInvestigation from "../Certificates/Components/LaboratoryInvestigation";
import RemarksInReport from "../Certificates/Components/RemarksInReport";
import BarCodeInInvoice from "../../Invoice/components/BarCodesInInvoice";

const PrintMedicalPathology = ({ user_id }) => {
  const { currentUser, accessPerm, settingsData } = useContext(UserContext);

  let { userId } = useParams();
  userId = user_id ? user_id : userId;
  const bloodResults = [];
  const urineResults = [];
  const physicalResults = [];

  const [userDetail, setUserDetail] = useState();
  const [user, setUser] = useState();
  const [physicalRiskFactor, setPhysicalRiskFactor] = useState("");
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const registrationNo = userDetail?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    format: "CODE128",
    // width: 1,
    // height: 20,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${userDetail?.user?.id}`;

  const formattedBirthDate = user?.date_of_birth
    ? (() => {
        const date = new Date(user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const [radiologyData, setRadiologyData] = useState([]);
  const [xrayImage, setXrayImage] = useState("");
  const [pathologyData, setPathologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyComment, setRadiologyComment] = useState();
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState();
  const [riskFactor, setRiskFactor] = useState("");
  const [userResultsData, setUserResultsData] = useState({
    user_id: userId,
    final_comments: "",
    advice: "",
    risk_factor: "",
    is_auto: 1,
    status: 2,
  });

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return "";
    }

    const days = Math.floor(hours / 24);

    date.setDate(date.getDate() + days);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const receivingDate = userDetail?.user_payment?.createdAt;
  const preparationDuration =
    user?.role_id !== 5
      ? (userDetail?.package?.preparation_duration ?? 0)
      : (userDetail?.max_preparation_duration ?? 0);
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setUserDetail(response?.data?.data?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { comments } = response?.data?.data;
      setPhysicalRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getradComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { comments } = response?.data?.data;
      console.log(comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  function getUserResults() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-user-results/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      )
      .then((response) => {
        const data = response?.data;
        setUserResultsData((prev) => {
          return {
            ...prev,
            final_comments: data?.final_comments,
            advice: data?.advice,
            risk_factor: data?.risk_factor,
          };
        });
      })
      .catch((err) => console.log(err));
  }

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const comments = response?.data?.data || [];

      setRadiologyComment(comments?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const commentsData = response?.data?.data;

      setPhysicalCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const risk = response?.data?.data || [];

      setRadiologyRiskFactor(risk[0]?.comments);
      setRiskFactor(risk[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  pathologyData?.forEach((item) => {
    const categoryId =
      item?.investigation_test?.investigation?.investigation_category?.id;
    if (categoryId === 4) {
      bloodResults.push(item);
    } else if (categoryId === 5) {
      urineResults.push(item);
    } else if (categoryId === 7) {
      physicalResults.push(item);
    }
  });

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, userDetail?.gender?.id]);

  useEffect(() => {
    getRadiologyTests();
    getPhysicalTests();
    getPathologyData();
    getUserResults();
    getComments();
    getRiskFactor();
    getPathologyComment();
    getPhysicalComment();
    getUserDetail();
    getComment();
    getradComment();
  }, [userId]);

  useEffect(() => {
    userDetail && setUser(userDetail?.user);
  }, [userDetail]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);
  console.log(
    radiologyData?.find((report) => report?.investigation_test?.id === 35)
      ?.result !== "Normal CXR",
  );
  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }
  const birthYear = user?.date_of_birth
    ? new Date(user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";
  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      // width: 1,
      // height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientRegNameDataUrl = generateBarcode(
    String(`${user?.f_name} - ${age}`),
  );

  const pathologyReportCreateUserName =
    pathologyData?.length &&
    pathologyData?.find((d) => !!d?.created_by === true)?.created_by;

  return (
    <div className="pathology-container">
      <section className="medical-pathology">
        <div className="row align-items-center">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
        <div className="">
          {/* Medical Form centered */}
          <div
            className="text-center text-uppercase text-dark fs-5"
            style={{ textDecoration: "underline", fontWeight: "bolder" }}
          >
            Pathology Exam Report
          </div>
        </div>
        <div className="d-flex justify-content-between fw-bolder">
          <div>
            <p className="fs-5">
              Reg No : {user?.reg_prefix}
              {user?.registration_no}{" "}
            </p>
          </div>
          <div>
            <p className="fs-5">
              Reg Date : {formatDate(user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
            </p>
          </div>
        </div>

        {/* bar code  */}
        <BarCodeInInvoice userDetail={userDetail} age={age} />
        <UserInfoTable
          user={user}
          userDetails={userDetail}
          age={age}
          physicalData={physicalData}
        />
        <div className="row  mt-1">
          <div className="col-sm-12 d-flex flex-column flex-sm-row">
            <div className="d-flex flex-column justify-content-start col-sm-12 ps-0">
              <LaboratoryInvestigation
                groupedPathologyData={groupedPathologyData}
                user={user}
              />
              <RemarksInReport
                // physicalCommentRisk={physicalCommentRisk}
                pathologyCommentRisk={pathologyCommentRisk}
                // radiologyData={radiologyData}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 medical-pathology-print">
          <div className="row w-50">
            <div className="d-flex justify-content-start gap-5 align-items-end">
              <div className="text-dark p-0 m-0">
                <div
                  className="border-top mt-0 border-dark"
                  style={{ width: "125px" }}
                ></div>
                Pathologist
              </div>
            </div>
          </div>
          <div className="w-50 d-flex justify-content-end align-items-center mt-1">
            <div className="text-end mt-2">
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={70} />
              </Link>
              <div className="text-end text-dark p-0 text-wrap">
                Created By: {pathologyReportCreateUserName}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 heldup-button mt-2">
        {accessPerm(33, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PrintMedicalPathology;

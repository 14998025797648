import { useMemo } from "react";
import { FixedSizeList as List } from "react-window";

const VirtualizedMenuListPackage = ({
  options,
  children,
  maxHeight,
  getValue,
}) => {
  const [value] = getValue();
  const itemHeight = 40;
  const optionPadding = -4; // Adjusted padding value

  const visibleOptions = Math.min(options.length, Math.floor(maxHeight / itemHeight));

  const initialOffset = useMemo(
    () => options.indexOf(value) * itemHeight,
    [options, value, itemHeight]
  );

  return (
    <List
      height={visibleOptions * itemHeight}
      itemCount={visibleOptions}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div
          style={{ ...style, color: "black", padding: `${optionPadding}px 0` }}
        >
          {children[index]}
        </div>
      )}
    </List>
  );
};

export default VirtualizedMenuListPackage;

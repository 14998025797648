import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
import Loading from "../../../utils/Loader/Loading";
import { UserContext } from "../../../Context/UserContextAPI";

const LoggedInUsers = () => {
  const [users, setUsers] = useState([]);
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  const navigate = useNavigate();
  const { id: roleId } = useParams();
  const { accessPerm } = useContext(UserContext);

  const handleLiveSearch = async (searchText) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/another/newthing`,
        { searchText },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setUsers(response?.data?.data);
        setTotalRows(response?.data?.totalRows);
      } else {
        toast.error("Error while fetching search results");
      }
    } catch (error) {
      //   toast.error("An error occurred during the search.");
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = users?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
          handleLiveSearch(e.target.value);
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const fetchLoggedInUserAPI = async (page, pageSize) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/something/new?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res) {
        setUsers(res?.data?.data);
        setTotalRows(res?.data?.totalRows);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleChange = async (e, id) => {
    e.preventDefault();
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/going/logout`,
      { id: editId },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );

    if (res?.status === 200) {
      toast.success("User logged out  successfully!!");
      await fetchLoggedInUserAPI(currentPage, pageSize);
    } else {
      toast.error(res?.data?.message);
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const pageParam = urlParams.get("page") || 1;
        const pageSizeParam = urlParams.get("pageSize") || 50;

        setCurrentPage(parseInt(pageParam, 10));
        setPageSize(parseInt(pageSizeParam, 10));

        await fetchLoggedInUserAPI(pageParam, pageSizeParam);
      } catch (error) {
        // console.log("Error in API calls: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId, refresh]);

  useEffect(() => {
    fetchLoggedInUserAPI(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const columns = [
    {
      name: "SL",
      width: "100px",
      align: "center",
      cell: (data, index) => {
        return <div style={{ width: "50px" }}>{index + 1}</div>;
      },
    },
    {
      name: "Reg. No",
      width: "120px",
      align: "left",
      wrap: "yes",
      omit: roleId === "14" ? true : false,
      cell: (data, index) => {
        return (
          <div style={{ width: "50px" }}>
            {data?.reg_prefix || ""}
            {data?.registration_no}
          </div>
        );
      },
    },
    {
      name: "Name",
      align: "left",
      selector: (data) => data?.f_name,
      wrap: "yes",
    },
    {
      name: "Role",
      width: "140px",
      selector: (data) => data?.role?.name,
      omit: roleId ? true : false,
    },
    {
      name: "Mobile",
      width: "140px",
      selector: (data) => data?.mobile,
      wrap: "yes",
    },
    {
      name: "Email",
      selector: (data) => data?.email,
      wrap: "yes",
    },
    {
      name: "Contact Person",
      selector: (data) => data?.contact_person,
      wrap: "yes",
    },
    {
      name: "Status",
      width: "95px",
      selector: (data) => data.status,
      // sortable: true,
      cell: (data) => {
        return <>{data?.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      name: "Log User Out",
      width: roleId ? "190px" : "155px",
      align: "left",
      sortable: false,
      cell: (data) => {
        return (
          <>
            {accessPerm(5, 2) && (
              <div className="form-check form-switch d-flex align-items-center">
                <div className="w-100">
                  {accessPerm(74, 3) && (
                    <button
                      className="btn btn-md bg-danger text-white border-dark border-0"
                      onClick={() => setEditId(data?.id)}
                      data-bs-toggle="modal"
                      data-bs-target={`#logout`}
                    >
                      Log Out
                    </button>
                  )}

                  <div
                    className="modal fade"
                    id={`logout`}
                    tabIndex={-1}
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Log Out User</h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row ">
                            <div className="col-sm-10">
                              <p>Are you sure you want to log this user out?</p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => {
                              handleChange(e, data?.id);
                            }}
                            data-bs-dismiss="modal"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#logout`}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      },
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        fontSize: "13px",
        border: "1px solid #e0e0e0",
        fontWeight: "bold",
        backgroundColor: "#095d7e",
        color: "white",
        whiteSpace: "pre-wrap",
        height: "40px",
      },
    },
    subHeader: {
      style: {
        minHeight: "52px",
        padding: "0",
      },
    },
    headCells: {
      style: {
        border: "1px solid #e0e0e0",
        overflowWrap: "normal",
        width: "50px",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
        border: "1px solid #e0e0e0",
        padding: "4px",
        whiteSpace: "pre-wrap",
        overflowWrap: "normal",
        width: "50px",
      },
    },
  };

  const config = {
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    length_menu: [100, 200, 500],
    pagination: true,
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [];
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded" style={{ overflow: "auto" }}>
                <div className="card-box">
                  <h6 className="mb-0 text-uppercase">App Logged-In Users</h6>
                </div>

                <hr />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  config={config}
                  highlightOnHover
                  striped
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows} // Set the total number of rows from the server response
                  paginationPerPage={pageSize}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                    setPageSize(currentRowsPerPage);
                    setCurrentPage(currentPage);
                  }}
                  onChangePage={(page) => {
                    setCurrentPage(page);
                    navigate(`?page=${page}&pageSize=${pageSize}`);
                  }}
                  dense
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  customStyles={customStyles}
                  extraButtons={extraButtons}
                  subHeaderAlign="center"
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoggedInUsers;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { utils, writeFile } from "xlsx";
import { useNavigate } from "react-router-dom";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../utils/Loader/Loading";
import toast from "react-hot-toast";
import { Form, Table } from "react-bootstrap";
import CollectionShowTable from "./CollectionShowTable.jsx";
import { Formik } from "formik";
import COllectionTable from "./components/CollectionTable";
const CollectionReport = () => {
  const { accessPerm } = useContext(UserContext);
  const { introducerUsers, packages } = useContext(UserContext);
  const [collectionData, setCollectionData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [activeRowId, setActiveRowId] = useState(null);
  const [regInputField, setRegInputField] = useState("");
  const [refInputField, setRefInputField] = useState("");
  const [receivedAmounts, setReceivedAmounts] = useState({});
  const [color, setColor] = useState(null);
  const [initialRowSelection, setInitialRowSelection] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState(true);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalDues, setTotalDues] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [viewOptions, setViewOptions] = useState("all");

  // State for input fields, assuming you have these fields
  const [inputValues, setInputValues] = useState([]);

  const [toDateSearch, setToDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd"),
  );
  const [fromDateSearch, setFromDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd"),
  );

  // form submit button ref
  const formRef = useRef(null);

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const tbodyRef = useRef(null);

  const [searchedData, setSearchedData] = useState();
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });
  const [searchInputs, setSearchInputs] = useState({
    from_date: "",
    to_date: "",
    status: "",
    packageId: "",
    introducer: "",
    country: "",
    company: "",
    delegate: "",
    regNo: "",
  });

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  // view Options
  const viewOpntionsData = [
    {
      value: "all",
      label: "All DATA",
    },
    {
      value: "due",
      label: "DUES Only",
    },
  ];

  // Function to handle key press on a row
  const handleKeyDown = (event, row) => {
    const targetId = Number(row?.id);
    const rows = Array.from(tbodyRef.current.children); // Get all rows
    const currentRow = event.target.closest("tr"); // Get current row
    const inputs = Array.from(
      currentRow.querySelectorAll('input[type="text"]'),
    ); // Get all inputs in the current row
    const inputIndex = inputs.indexOf(event.target); // Get current input index in the row
    const rowIds = rows.map((child) => Number(child.id)); // Get row ids
    const currentIndex = rowIds.indexOf(targetId); // Find the current row index

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevent default for up/down arrow keys
    }

    if (event.key === "Backspace") {
      event.stopPropagation(); // Prevent event bubbling
    }

    const placeCursorAtEnd = (input) => {
      if (input) {
        input.focus();
        // Place cursor at the end of the input field after all characters
        setTimeout(() => {
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    };

    switch (event.key) {
      case "ArrowUp":
        if (currentIndex > 0) {
          const prevRow = rows[currentIndex - 1]; // Get previous row
          const prevInput =
            prevRow.querySelectorAll('input[type="text"]')[inputIndex]; // Get the input in the same column of the previous row
          placeCursorAtEnd(prevInput); // Place cursor at the end of the previous input
        }
        break;
      case "ArrowDown":
        if (currentIndex < rows.length - 1) {
          const nextRow = rows[currentIndex + 1]; // Get next row
          const nextInput =
            nextRow.querySelectorAll('input[type="text"]')[inputIndex]; // Get the input in the same column of the next row
          placeCursorAtEnd(nextInput); // Place cursor at the end of the next input
        }
        break;
      case "ArrowLeft":
        if (inputIndex > 0) {
          const prevInput = inputs[inputIndex - 1]; // Get the previous input in the current row
          placeCursorAtEnd(prevInput); // Place cursor at the end of the previous input
        }
        break;
      case "ArrowRight":
        if (inputIndex < inputs.length - 1) {
          const nextInput = inputs[inputIndex + 1]; // Get the next input in the current row
          placeCursorAtEnd(nextInput); // Place cursor at the end of the next input
        } else {
          // If it's the last input in the row, keep the cursor at the end of the current input
          placeCursorAtEnd(event.target);
        }
        break;
      default:
        break;
    }
  };

  const downloadExcel = () => {
    // Extract headers from the table
    const headers = [
      "SL", // Serial Number first
      "Reg. No",
      "Passenger",
      "Agency",
      "Country", // Add Country column
      "Test/Ref By", // Add Test/Ref By column
      "Received",
      "Net Amount",
      "Balance",
      "Dues",
      "Paid",
      "Remarks",
    ];

    // Prepare data for the worksheet
    const formattedData = userDetails.map((user, index) => {
      // Determine "Test/Ref By" value based on role_id
      const netAmount =
        user?.user_payment?.net_amount ||
        (user?.user?.role_id === 5
          ? user?.user?.tests
              ?.map((test) => test.investigation?.discounted_price || 0)
              ?.reduce((sum, price) => sum + price, 0)
          : user?.package?.discounted_price);

      const diff =
        parseFloat(netAmount || 0) -
        parseFloat(user?.user_payment?.paid_amount || 0);
      return {
        SL: index + 1, // Serial number
        "Reg. No": `${user?.user?.reg_prefix || ""}${
          user?.user?.registration_no || ""
        }`,
        Passenger: user.user?.f_name || "",
        Agency: user.introducer_user?.f_name || "",
        Country: user.country?.name || "", // Get Country from userDetails
        // "Test/Ref By": testRefBy, // Assign Test/Ref By value
        "Test/Ref By": "Individual Test", // Assign Test/Ref By value
        Received:
          receivedAmounts[user.user.id] || user?.user_payment?.paid_amount || 0, // Rename to Paid
        "Net Amount":
          (!!user?.user_payment?.net_amount &&
            user?.user_payment?.net_amount) ||
          (user?.user_payment?.paid_amount <= 0 ? 0 : netAmount) ||
          0,
        Balance:
          user?.user_payment?.commission || (diff <= 0 ? Math.abs(diff) : 0),
        Dues:
          user?.user_payment?.paid > 0
            ? user?.user_payment?.due
            : diff > 0
              ? diff
              : 0,
        Paid: user?.user_payment?.paid || 0,
        Remarks: user?.user_payment?.remarks || "",
      };
    });

    // Calculate totals for the relevant columns
    const totalReceived = formattedData.reduce(
      (sum, row) => sum + row["Received"],
      0,
    );
    const totalPaid = formattedData.reduce((sum, row) => sum + row["Paid"], 0);
    const totalCommission = formattedData.reduce(
      (sum, row) => sum + row["Balance"],
      0,
    );
    const totalNetAmount = formattedData.reduce(
      (sum, row) => sum + row["Net Amount"],
      0,
    );
    const totalDues = formattedData.reduce((sum, row) => sum + row["Dues"], 0);

    // Add totals row to formattedData with the total under Test/Ref By
    formattedData.push({
      SL: "", // Leave SL blank for the total row
      "Reg. No": "",
      Passenger: "",
      Agency: "",
      Country: "", // Leave Country blank for the total row
      "Test/Ref By": "Total", // Add Total under Test/Ref By
      Received: totalReceived, // Use totalReceived
      "Net Amount": totalNetAmount,
      Balance: totalCommission,
      Dues: totalDues,
      Paid: totalPaid, // Use totalPaid
      Remarks: "",
    });

    // Create worksheet from the formatted data
    const worksheet = utils.json_to_sheet(formattedData, { header: headers });

    // Create a new workbook and append the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Collection Data");

    // Generate a file name
    const fileName = `Collection_Report_${format(new Date(), "yyyyMMdd")}.xlsx`;

    // Export the workbook
    writeFile(workbook, fileName);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const regNo = form.invoice_reg_mobile.value;
    setToDateSearch(toDate);
    setFromDateSearch(fromDateSearch);
    setFilterData({
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      //   country,
      //   company,
      //   delegate,
      //   representative,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
      viewOptions,
    });
    const filteredData = {
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
      viewOptions,
    };
    setSearchedData(searchInputs);
    methodFilterPatientsDetails(filteredData, "form");
  };

  const saveCollection = async (data) => {
    try {
      // console.log("collection", data);
      const hasChangedData = userDetails?.filter(
        (user) => user?.user_payment?.hasChanged === true,
      );
      console.log("hasChanged", hasChangedData);

      const sortChangedValue = hasChangedData?.map((changedData) => ({
        id: changedData?.user_id,
        commission: !!changedData?.user_payment?.commission
          ? changedData?.user_payment?.commission
          : 0,
        dues: !!changedData?.user_payment?.due
          ? changedData?.user_payment?.due
          : 0,
        remarks: changedData?.user_payment?.remarks,
        paid: !!changedData?.user_payment?.paid
          ? changedData?.user_payment?.paid
          : 0,
        receivedAmount: !!changedData?.user_payment?.paid_amount
          ? changedData?.user_payment?.paid_amount
          : 0,
        netAmount: !!changedData?.user_payment?.net_amount
          ? changedData?.user_payment?.net_amount
          : 0,
      }));
      console.log("changed Data", sortChangedValue);
      // return;
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/collection/payment`,
        sortChangedValue,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const calculateCommission = (row) => {
    const receivedAmount = row?.user_payment?.paid_amount || 0;
    const netAmount = row?.package?.discounted_price || 0;
    const difference = netAmount - receivedAmount;
    return difference < 0 ? Math.abs(difference) : 0;
  };

  const calculateDues = (row) => {
    const receivedAmount = row?.user_payment?.paid_amount || 0;
    const netAmount = row?.package?.discounted_price || 0;
    const difference = netAmount - receivedAmount;
    return difference > 0 ? difference : 0;
  };

  const handleFieldInputChange = useCallback(
    (event, row, fieldName) => {
      const { value } = event.target;
      const userId = row?.user_id;

      // Find or create a user object in collectionData
      let userData = collectionData.find((item) => item.userId === userId);
      if (!userData) {
        // Initialize from the database values
        userData = {
          userId: userId,
          receivedAmount: row?.user_payment?.paid_amount || 0,
          netAmount: row?.user_payment?.net_amount || 0,
          commission: row?.user_payment?.commission || 0,
          dues: row?.user_payment?.due || 0,
          remarks: row?.user_payment?.remarks || "",
          paid: row?.user_payment?.paid || 0,
        };
        collectionData.push(userData);
      }

      // Update the appropriate field value
      if (fieldName === "receivedAmount") {
        userData.receivedAmount = Number(value); // Update receivedAmount
      } else if (fieldName === "netAmount") {
        userData.netAmount = Number(value); // Update netAmount
      } else if (fieldName === "remarks") {
        userData.remarks = value; // Update remarks
      } else if (fieldName === "paid") {
        userData.paid = Number(value); // Update paid
      } else if (fieldName === "commission") {
        userData.commission = Number(value); // Update commission directly
      } else if (fieldName === "dues") {
        userData.dues = Number(value); // Update dues directly
      }

      // Calculate dues and commission if receivedAmount or netAmount has changed
      if (fieldName === "receivedAmount" || fieldName === "netAmount") {
        const difference = userData.netAmount - userData.receivedAmount;

        // Recalculate dues and commission
        userData.dues = difference > 0 ? difference : 0; // Positive difference goes to dues
        userData.commission = difference < 0 ? Math.abs(difference) : 0; // Commission is the absolute value of negative difference
      }

      // Update the row's user_payment with the new values
      row.user_payment = {
        ...row.user_payment,
        commission: userData.commission,
        due: userData.dues,
        remarks: userData.remarks,
        paid: userData.paid,
        receivedAmount: userData.receivedAmount,
        netAmount: userData.netAmount,
      };

      // Logging to check the updated collection data
      console.log("Updated Collection Data:", collectionData);

      // Optional: Re-render the table if required
      setCollectionData([...collectionData]);
    },
    [collectionData],
  );

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filteredData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-collection-report`,
        filteredData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = response?.data;
      if (data) {
        if (data?.userDetails) {
          const userDetails = data?.userDetails?.reduce((acc, user) => {
            // Calculate net amount based on user role and tests/packages
            const netAmount =
              user?.user_payment?.net_amount ||
              (user?.user?.role_id === 5
                ? user?.user?.tests
                    ?.map((test) => test.investigation?.discounted_price || 0)
                    ?.reduce((sum, price) => sum + price, 0)
                : user?.package?.discounted_price);

            // Calculate the diff between netAmount and inputValue
            const diff =
              parseFloat(netAmount || 0) -
              parseFloat(user?.user_payment?.paid_amount || 0);

            // Set due and commission based on the diff calculation
            const due =
              user?.user_payment?.paid > 0
                ? user?.user_payment?.due
                : diff > 0
                  ? diff
                  : 0;

            // Construct the new user object with the updated payment details
            const updatedUser = {
              ...user,
              user_payment: {
                ...user.user_payment,
                net_amount:
                  !!user?.user_payment?.net_amount &&
                  user?.user_payment?.net_amount
                    ? user?.user_payment?.net_amount
                    : user?.user_payment?.paid_amount <= 0
                      ? 0
                      : netAmount,
                due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
                due: due,
                commission:
                  user?.user_payment?.commission ||
                  (diff <= 0 ? Math.abs(diff) : 0),
              },
            };

            // Only add the user to the accumulator if the due is not zero
            if (viewOptions === "all") {
              acc.push(updatedUser);
            } else if (viewOptions === "due") {
              if (updatedUser?.user_payment?.due > 0) {
                acc.push(updatedUser);
              }
            }

            return acc;
          }, []); // Start with an empty array for the accumulator

          setUserDetails(userDetails);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => <span className="d-flex justify-content-center">SL</span>,
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;
          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.reg_prefix || ""}
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Passenger</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          // const representative_Mob =
          //   info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
          //     -2
          //   );
          return (
            <div>
              <span className="d-flex justify-content-start">
                <span className="text-center d-flex justify-content-start">
                  {value1}
                </span>
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Country
          </span>
        ),
        cell: (info) => {
          const country = info?.row?.original?.country?.name;
          return <span>{country}</span>;
        },
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center w-100">
            Test/Ref By
          </span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original?.user;

          // Check if role_id is 5
          if (user?.role_id === 5) {
            // Get the tests array from the user
            const tests = user.tests || [];

            let investigation31Added = false;

            const investigationNames = tests
              .filter((test) => {
                if (test?.investigation?.id === 31) {
                  if (investigation31Added) {
                    return false;
                  }
                  investigation31Added = true;
                  return true;
                }
                return true;
              })
              .map((test) => test.investigation?.name)
              .filter(Boolean)
              .join(", ");

            return (
              <div className="d-flex justify-content-start">
                {/* {investigationNames || "No Investigations"} */}
                Individual Test
              </div>
            );
          } else {
            // If role_id is not 5, render the default value
            const reversedIndex = userDetails.length - index - 1;
            return (
              <div className="d-flex justify-content-start">
                {info.renderValue(reversedIndex)}
              </div>
            );
          }
        },
      }),
      columnHelper.accessor("user", {
        header: () => <span>Received</span>,
        cell: (info) => {
          const row = info.row.original;
          return (
            <Form.Control
              className={`border border-dark ${color}`}
              ref={row.id === activeRowId ? inputRef : null}
              defaultValue={row?.user_payment?.paid_amount || 0}
              type="text"
              onChange={(e) => handleFieldInputChange(e, row, "receivedAmount")} // Attach onChange handler for receivedAmount
              onKeyDown={(e) => handleKeyDown(e, row)} // Attach keydown for navigation
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Net Amount</span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original;
          const row = info?.row?.original;
          let netTotal;
          if (info?.row?.original?.user_payment?.net_amount) {
            netTotal = info?.row?.original?.user_payment?.net_amount;
          } else {
            // Check if role_id is 5
            if (user?.user?.role_id === 5) {
              const totalDiscountedPrice = user?.user?.tests
                ?.map((test) => test?.investigation?.discounted_price || 0)
                ?.reduce((sum, price) => sum + price, 0);
              netTotal = totalDiscountedPrice;
            } else {
              // If role_id is not 5, render the default value
              netTotal = user?.package?.discounted_price;
            }
          }
          return (
            <Form.Control
              className={`border border-dark ${color}`}
              ref={info?.row?.id === activeRowId ? inputRef : null}
              type="text"
              defaultValue={netTotal || 0}
              onChange={(e) => handleFieldInputChange(e, row, "netAmount")} // Attach onChange handler for netAmount
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => <span>Balance</span>,
        cell: (info) => {
          const row = info.row.original;
          const commissionValue =
            row?.user_payment?.commission ?? calculateCommission(row);

          return (
            <Form.Control
              className={`border border-dark ${color}`}
              type="text"
              value={commissionValue}
              onChange={(e) => handleFieldInputChange(e, row, "commission")} // Update commission
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => <span>Dues</span>,
        cell: (info) => {
          const row = info.row.original;
          const dueValue = row?.user_payment?.due ?? calculateDues(row);

          return (
            <Form.Control
              className={`border border-dark ${color}`}
              type="text"
              value={dueValue}
              onChange={(e) => handleFieldInputChange(e, row, "dues")} // Update dues
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Paid
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          // return <span>{dues}</span>;
          return (
            <Form.Control
              className={`border border-dark ${color}`}
              type="text"
              defaultValue={row?.user_payment?.paid || 0}
              onChange={(e) => handleFieldInputChange(e, row, "paid")}
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Remarks
          </span>
        ),
        cell: (info) => {
          const row = info.row.original;
          return (
            <input
              ref={info?.row?.id === activeRowId ? inputRef : null}
              type="text"
              name="remarks"
              defaultValue={row?.user_payment?.remarks || 0}
              className="form-control border border-dark ps-1"
              onChange={(e) => handleFieldInputChange(e, row, "remarks")}
              onKeyDown={(e) => handleKeyDown(e, row)}
            />
          );
        },
      }),
    ],
    [],
  );

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/collection-report-all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        if (data?.userDetails) {
          const userDetailsData = data?.userDetails?.map((user) => {
            // Calculate net amount based on user role and tests/packages
            const netAmount =
              user?.user_payment?.net_amount ||
              (user?.user?.role_id === 5
                ? user?.user?.tests
                    ?.map((test) => test.investigation?.discounted_price || 0)
                    ?.reduce((sum, price) => sum + price, 0)
                : user?.package?.discounted_price);

            // Calculate the diff between netAmount and inputValue
            const diff =
              parseFloat(netAmount || 0) -
              parseFloat(user?.user_payment?.paid_amount || 0);

            // Set due and commission based on the diff calculation
            return {
              ...user,
              user_payment: {
                ...user.user_payment,
                net_amount:
                  (!!user?.user_payment?.net_amount &&
                    user?.user_payment?.net_amount) ||
                  (user?.user_payment?.paid_amount <= 0 ? 0 : netAmount) ||
                  0,
                due_initial: user?.user_payment?.due || (diff > 0 ? diff : 0),
                due:
                  user?.user_payment?.paid > 0
                    ? user?.user_payment?.due
                    : diff > 0
                      ? diff
                      : 0,
                commission:
                  user?.user_payment?.commission ||
                  (diff <= 0 ? Math.abs(diff) : 0),
              },
            };
          });

          setUserDetails(userDetailsData);
        }
        // setCountryWiseUsers(data?.countryWiseUser);
        // setPackageWiseUsers(data?.packageWiseUser);
        // setIntroducerWiseUsers(data?.introducerWiseUser);
        // setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        packageId: "",
        introducer: "",
        regNo: "",
        refNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
      });

      setRegInputField("");
      setRefInputField("");
      setViewOptions("all");
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (name, value) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (selectedRows) => {
      setRowSelection(selectedRows);
    },
  });

  useEffect(() => {
    const initialData = table.getRowModel().rows.map((row) => {
      return {
        id: row?.original?.user_id,
        receivedAmount: row?.original?.user_payment?.paid_amount || 0,
        netAmount: row?.original?.user_payment?.net_amount || 0,
        commission: row?.original?.user_payment?.commission || 0,
        due: row?.original?.user_payment?.due || 0,
        paid: row?.original?.user_payment?.paid || 0,
        remarks: row?.original?.user?.remarks,
      };
    });
    // setInputValues(initialData);
    // calculateTotals(initialData);
  }, [table.getRowModel().rows]);

  useEffect(() => {
    getUserDetails();
  }, [table]);

  useEffect(() => {
    if (activeRowId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [receivedAmounts, activeRowId]);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Collection Report
                </h6>
                <div className="d-flex flex-wrap align-items-end justify-content-between">
                  <form onSubmit={(e) => handlerOnSearch(e)} className="">
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("from_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("to_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("package", selectedOptions);
                              handleInputChange("package", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) => {
                              handleSelect("introducer", selectedOptions);
                              handleInputChange("introducer", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">View Options</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="viewOptions"
                            options={viewOpntionsData}
                            value={viewOpntionsData.find(
                              (val) => val.value === viewOptions,
                            )}
                            isSearchable={true}
                            onChange={(val) => {
                              setViewOptions(val?.value);
                              setFilterData((prev) => ({
                                ...prev,
                                viewOptions: val?.value,
                              }));
                            }}
                          />
                        </div>
                      </div>

                      <div className="p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            type="text"
                            onChange={(e) => {
                              handleInputChange("fromDate", e.target.value);
                              setRegInputField(e.target.value);
                            }}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                            style={{ width: "180px", height: "37px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-1 p-1 d-flex align-items-end justify-content-between">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <button
                            type="submit"
                            className="btn btn-primary px-4 text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning px-4 border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex align-items-end mb-1">
                    <div className="d-flex align-items-center gap-2 justify-content-end mt-0">
                      {accessPerm(67, 24) && (
                        <button
                          type="submit"
                          className="btn bg-success px-4 border border-3 text-white"
                          onClick={saveCollection}
                          // onClick={() =>
                          //   formRef.current.dispatchEvent(
                          //     new Event("submit", { bubbles: true }),
                          //   )
                          // }
                        >
                          Save
                        </button>
                      )}
                      <button
                        className="btn bg-danger px-4 border border-3 text-white"
                        onClick={() => {
                          const queryString = new URLSearchParams(
                            filterData,
                          ).toString();
                          const url = `/dashboard/medical-collection/print?${queryString}`;

                          window.open(url, "_blank");
                        }}
                      >
                        PDF
                      </button>
                      <button
                        onClick={downloadExcel}
                        className="btn btn-primary px-4 border border-3"
                      >
                        Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <>
                <COllectionTable
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
                {/* <Formik */}
                {/*   validateOnChange={false} */}
                {/*   validateOnBlur={false} */}
                {/*   initialValues={{ */}
                {/*     userDetails: userDetails?.map((user) => { */}
                {/*       // Calculate net amount based on user role and tests/packages */}
                {/*       const netAmount = */}
                {/*         user?.user_payment?.net_amount || */}
                {/*         (user?.user?.role_id === 5 */}
                {/*           ? user?.user?.tests */}
                {/*               ?.map( */}
                {/*                 (test) => */}
                {/*                   test.investigation?.discounted_price || 0, */}
                {/*               ) */}
                {/*               ?.reduce((sum, price) => sum + price, 0) */}
                {/*           : user?.package?.discounted_price); */}
                {/**/}
                {/*       // Calculate the diff between netAmount and inputValue */}
                {/*       const diff = */}
                {/*         parseFloat(netAmount || 0) - */}
                {/*         parseFloat(user?.user_payment?.paid_amount || 0); */}
                {/**/}
                {/*       // Set due and commission based on the diff calculation */}
                {/*       return { */}
                {/*         ...user, */}
                {/*         user_payment: { */}
                {/*           ...user.user_payment, */}
                {/*           net_amount: */}
                {/*             (!!user?.user_payment?.net_amount && */}
                {/*               user?.user_payment?.net_amount) || */}
                {/*             (user?.user_payment?.paid_amount <= 0 */}
                {/*               ? 0 */}
                {/*               : netAmount) || */}
                {/*             0, */}
                {/*           due_initial: */}
                {/*             user?.user_payment?.due || (diff > 0 ? diff : 0), */}
                {/*           due: */}
                {/*             user?.user_payment?.paid > 0 */}
                {/*               ? user?.user_payment?.due */}
                {/*               : diff > 0 */}
                {/*                 ? diff */}
                {/*                 : 0, */}
                {/*           commission: */}
                {/*             user?.user_payment?.commission || */}
                {/*             (diff <= 0 ? Math.abs(diff) : 0), */}
                {/*         }, */}
                {/*       }; */}
                {/*     }), */}
                {/*   }} */}
                {/*   onSubmit={(e) => { */}
                {/*     // console.log(e); */}
                {/*     saveCollection(e); */}
                {/*   }} */}
                {/* > */}
                {/*   {({ handleSubmit }) => ( */}
                {/*     <Form onSubmit={handleSubmit} ref={formRef}> */}
                {/*       <CollectionShowTable userDetails={userDetails} /> */}
                {/*       {/* <button type="submit">Submit</button> */}
                {/*     </Form> */}
                {/*   )} */}
                {/* </Formik> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const MemoizedMyComponent = React.memo(CollectionReport);
export default MemoizedMyComponent;

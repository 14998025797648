import JsBarcode from "jsbarcode";
const generateBarcode = (text) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {
    format: "CODE128",
    // width: 1,
    // height: 20,
    displayValue: false,
  });
  return canvas.toDataURL();
};
export default generateBarcode;

import React, { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContextAPI";
import { useLocation, useNavigate } from "react-router-dom";
import JsBarcode from "jsbarcode";
import "./StaticXray.scss";
import UserInfoTable from "../Investigation/Report/Certificates/Components/UserInfoTable";
import { format } from "date-fns";

const StaticXray = () => {
  const staticXray = 1;
  const { settingsData } = useContext(UserContext);
  const { state } = useLocation();
  const patientData = state?.record;
  const navigate = useNavigate();
  const [physicalData, setPhysicalData] = useState([]);
  const registrationNo = patientData?.user?.registration_no;

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      // width: 1,
      // height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };

  const handlePrint = () => {
    window.print();
  };

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
  });

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const birthYear = patientData?.user?.date_of_birth
    ? new Date(patientData?.user?.date_of_birth).getFullYear()
    : null;
  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";

  const patientRegNameDataUrl = generateBarcode(
    String(`${patientData?.user?.f_name} - ${age}`)
  );

  JsBarcode(patientIdBarcode, String(registrationNo), {
    format: "CODE128",
    // width: 1,
    // height: 20,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  return (
    <div className="">
      <div className="static-xray" style={{ fontSize: "4rem", width: "100%" }}>
        <div className="">
          <div className="row align-items-center">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="text-center text-uppercase text-dark w-100 fs-6">
          <span
          className="fs-5"
            style={{ borderBottom: "2px solid black", paddingBottom: "3px" }}
          >
            X-RAY REPORT
          </span>
          <div className="d-flex justify-content-between fw-bolder">
            <div>
              <p className="fs-5">
                Reg No : {patientData?.user?.reg_prefix}
                {patientData?.user?.registration_no}{" "}
              </p>
            </div>
            <div>
              <p className="fs-5">
                Reg Date :{" "}
                {formatDate(patientData?.user?.createdAt, "dd-MM-yyyy hh:mm a")}{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "-15px" }}
        >
          <div style={{ width: "40%", marginLeft: "-8px" }}>
            <img
              src={patientRegNameDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={35}
            />
          </div>
          <div style={{ width: "50%", marginRight: "-20px" }}>
            <img
              src={patientIdDataUrl}
              alt=""
              className="patient-barcode2"
              width={"100%"}
              height={35}
            />
          </div>
        </div>
        <div className="mt-2">
          <UserInfoTable
            user={patientData?.user}
            userDetails={patientData}
            age={age}
            physicalData={physicalData}
            staticXray={staticXray}
          />
        </div>
        <div className="col-sm-12 mt-2">
          <div className="row w-100">
            <div class="col-12 d-flex justify-content-center">
              <table className="w-100 table table-bordered border-dark">
                <thead>
                  <tr>
                    <th
                      className="fs-3 text-dark text-center"
                      style={{ width: "40%" }}
                    >
                      FINDINGS TYPE
                    </th>
                    <th
                      className="fs-3 text-dark text-center w-75"
                      style={{ width: "60%" }}
                    >
                      RESULTS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fs-5 text-dark text-center">
                      X-RAY Chest P/A View
                    </td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Heart</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Diaphragm</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Lungs</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      CLEAR
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Others</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      className="fs-5 text-dark text-center d-flex justify-content-center border-end-0 align-items-center"
                      style={{ height: "100px" }}
                    >
                      Comments
                    </td>

                    <td className="fs-5 text-dark align-middle text-center">
                      NAD / NSPI / ......................................
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="fit-status-section">
          <div className="d-flex justify-content-between">
            <table
              className="table border border-dark"
              style={{ height: "70px", width: "60%" }}
            >
              <tbody>
                <tr className="d-flex align-items-center justify-content-center h-100 gap-5 px-2">
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4 text-nowrap"> *FIT</p>
                  </td>
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4 text-nowrap"> *UNFIT</p>
                  </td>
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4 text-nowrap"> *HELD-UP</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="signature-section d-flex justify-content-between mt-2 col-6 text-dark">
            <div className="w-100">
              <label className="text-uppercase fs-5">
                Instructions (If any):
              </label>
              <div
                className="w-100 border border-dark"
                style={{ height: "90px" }}
              ></div>
            </div>
          </div>
          <div className="col-12 text-dark">
            <div className="d-flex justify-content-end">
              <span className="fs-5">Signature: </span>
              <div
                className="ms-2"
                style={{ width: "150px", borderBottom: "1px solid black" }}
              >
                {/* Empty space for signature */}
              </div>
            </div>
          </div>
          <div className="static-xray-footer mt-5 text-dark">
            <div className="d-flex justify-content-between col-12">
              <p className="small-text static-xray-footer-1">
                User: {state?.record?.user?.created_by}
                <br />
              </p>
              <p className="small-text static-xray-footer-2">
                Licensed to: {settingsData?.website_name} <br />
                Vendor: Clincx Medical Solutions
              </p>
              <div className="col-2">
                <p className="small-text static-xray-footer-3 pe-5">
                  Date: <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static-xray-btn d-flex mt-5 align-items-center justify-content-end">
        <div className="d-flex gap-2 mx-3">
          <button className="btn btn-primary" onClick={() => handlePrint()}>
            Print
          </button>
          {patientData?.user?.role_id === 5 ? (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/medical-tests")}
            >
              Back
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/patient-info")}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaticXray;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IndexPage.scss";
import axios from "axios";

const IndexPage = () => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;

  const [data, setData] = useState({});
  const [vatId, setVatId] = useState(0);

  const getData = () => {
    axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
        setVatId(allData.vat_type);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw", // Ensure the container covers the full viewport width
    },
    backgroundImage: {
      position: "fixed", // Fix the background image in place
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed", // Ensure background stays fixed
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <div>
      <div className="wrapper" style={myStyle.backgroundImage}>
        {/* <!--start content--> */}
        <main
          className="h-100 d-flex align-items-center justify-content-center mx-auto"
          style={myStyle.container}
        >
          <div className="container-fluid">
            <div className="card index-card py-5">
              <div className="row align-items-center justify-content-center">
                <div className="col">
                  <div className="card-body p-6">
                    <div className="d-flex flex-column text-center p-1 mx-auto">
                      <div className="w-100">
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
                          alt=""
                          width="100px"
                        />
                      </div>
                      <h2 className="mt-2 text-dark">{data?.website_name}</h2>
                      <p className="mt-2 text-dark">Address: {data?.address}</p>
                      <p className="contact text-dark">Mobile: {data?.mobile}</p>
                    </div>

                    <div className="mt-5 d-flex flex-column justify-content-center align-items-center gap-2">
                      <Link
                        to="/signin"
                        className="text-white text-underline-none"
                      >
                        <button
                          className="btn btn-primary btn-lg px-md-5 radius-30"
                          style={{
                            backgroundColor: "#14977e",
                            border: "none",
                            color: "white",
                            width: "17rem",
                          }}
                        >
                          Login
                        </button>
                      </Link>
                      <Link
                        to="/medical-verification"
                        className="text-underline-none text-white btn btn-info btn-lg radius-30 text-white col-sm-12"
                        style={{
                          backgroundColor: "#14977e",
                          border: "none",
                          color: "white",
                          width: "17rem",
                        }}
                      >
                        Medical Verification
                      </Link>
                      {/* <Link
                        to="/signup"
                        className="text-white text-underline-none"
                      >
                        <button
                          className="btn btn-success text-white btn-lg ms-3 px-md-4 radius-30"
                          style={{ width: "11rem" }}
                        >
                          Registration
                        </button>
                      </Link> */}
                    </div>
                    {/* <div className="mt-2 d-flex w-100 justify-content-center"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default IndexPage;

import React, { useEffect, useState } from "react";
import "./MedicalVerification.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const MedicalVerification = () => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;

  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [form, setForm] = useState({
    passport: "",
    registrationNo: "",
  });

  const findData = async (e) => {
    e.preventDefault();

    // Remove leading and trailing white spaces from input values
    const trimmedPassport = form.passport.trim();
    const trimmedReg = form.registrationNo.trim();

    if (!trimmedPassport && !trimmedReg) {
      toast.error("Please fill at least one of the required fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/medical-verification`,
        {
          passport: trimmedPassport,
          registrationNo: trimmedReg,
        },
      );

    

      if (response?.data === "" || response?.data === "user not found") {
        toast.error("No user found with the provided details.");
      } else {
        console.log(response?.data?.user_id);
        navigate(`/medical-verification/result/report`, {
          state: response?.data,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching data. Please try again later.");
    }
  };

  const getData = () => {
    axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onchange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getData();
  }, []);

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw",
    },
    backgroundImage: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <div className="wrapper" style={myStyle.backgroundImage}>
      <main
        className="h-100 d-flex align-items-center justify-content-center mx-auto"
        style={myStyle.container}
      >
        <div className="container-fluid">
          <div className="card index-card py-5">
            <div className="col-sm-12 card-container text-center">
              <div className="p-2 text-left w-100 text-dark">
                <div className="w-100">
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
                    alt=""
                    width="100px"
                  />
                </div>
                <h2 className="mt-2">{data?.website_name}</h2>
                <p className="mb-1">Address: {data?.address}</p>
                <p className="contact">Mobile: {data?.mobile}</p>
              </div>
              <div className="card-body p-4">
                <div className="mt-2">
                  <form onSubmit={(e) => findData(e)}>
                    <div className="mb-1 row fw-bold justify-content-center">
                      <label
                        htmlFor="passport"
                        className="col-sm-3 col-form-label d-flex justify-content-start text-dark"
                      >
                        Passport No.
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5">
                        <input
                          onChange={(e) => onchange(e)}
                          name="passport"
                          type="text"
                          className="form-control"
                          id="passport"
                        />
                      </div>
                    </div>
                    <p className="col-sm-12 mb-1 text-center ms-5">Or,</p>
                    <div className="mb-1 row fw-bold justify-content-center">
                      <label
                        htmlFor="registrationNo"
                        className="col-sm-3 col-form-label d-flex justify-content-start text-dark"
                      >
                        Registration No. <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5">
                        <input
                          onChange={(e) => onchange(e)}
                          name="registrationNo"
                          type="tel"
                          className="form-control"
                          id="registrationNo"
                        />
                      </div>
                    </div>
                    <div className="mb-1 row mt-5 d-flex flex-column align-items-center">
                      <button
                        className=" p-2 col-sm-4 radius-30 mx-auto"
                        type="submit"
                        style={{
                          backgroundColor: "#14977e",
                          border: "none",
                          color: "white",
                          width: "17rem",
                        }}
                      >
                        Search
                      </button>

                      <Link
                        className="w-100 border-none px-md-4 radius-30 text-center text-white p-2"
                        to="/"
                      >
                        <button
                          className="btn-secondary border-0 p-2 col-sm-4 radius-30 mx-auto"
                          type="button"
                          style={{
                            width: "17rem",
                          }}
                        >
                          Back to Home
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MedicalVerification;

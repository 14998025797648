import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../../Context/UserContextAPI";
import useFetch from "../../../hooks/useFetch";
import request from "../../../utils/request";
import MoneyReceipt from "./components/MoneyReceipt";

const UserInvoiceGroupPrint = () => {
  const { accessPerm, settingsData } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get("userIds");

  const {
    data: { userDetail },
    isSuccess,
  } = useFetch(() =>
    request.getAll(`user-details/test/get/all?userIds=${userIds}`),
  );
  console.log(isSuccess);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.focus();
        window.print();
      }, 3000);
    }

    window.onafterprint = window.close;
  }, [isSuccess]);

  return (
    <div>
      <div className="patient-invoice p-3">
        {userDetail?.map((user, index) => {
          if (!user?.user_id) {
            return null;
          }
          return (
            <div key={user.user_id}>
              <div className={index > 0 && "mt-3"}>
                <MoneyReceipt userDetail={user} />

                <hr
                  style={{ borderTop: "3px dotted black", height: "0" }}
                  className="m-0"
                />

                <MoneyReceipt userDetail={user} office />
              </div>
              {index < userDetail.length - 1 ? (
                <div className="print-page-break"></div>
              ) : null}
            </div>
          );
        })}

        <div className="invoice-footer d-flex mt-5 align-items-center justify-content-end">
          <div className="d-flex gap-2">
            {accessPerm(32, 23) && (
              <button
                className="btn btn-primary"
                onClick={() => window.print()}
              >
                Print
              </button>
            )}
            <button
              className="btn btn-secondary"
              onClick={() => window.close()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserInvoiceGroupPrint;

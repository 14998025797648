import React, { useRef, useState } from "react";

const HorizontalScrollContainer = ({ children }) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    requestAnimationFrame(() => {
      const x = e.pageX - containerRef.current.offsetLeft;

      // Adjust the multiplier to control scrolling speed
      // Smaller values make scrolling slower
      const walk = (x - startX) * 0.7; // Adjust this value

      containerRef.current.scrollLeft = scrollLeft - walk;
    });
  };

  return (
    <div
      ref={containerRef}
      className=""
      style={{ overflowX: "scroll" }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </div>
  );
};

export default HorizontalScrollContainer;

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeleteModal = ({ isOpen, onClose, handleDelete }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered animation>
      <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
      <Modal.Body className="text-center p-0">
        <p>Are you sure you want to delete this module?</p>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button variant="danger" onClick={handleDelete}>
          Yes
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;

import React, { useContext, useEffect, useState } from "react";
import "./Invoice.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/UserContextAPI";
import JsBarcode from "jsbarcode";
import axios from "axios";
import { format, isValid } from "date-fns";
import QRCode from "qrcode.react";
import investigationGroup from "./investigationGroup";

const UserInvoiceCopy = ({ stateData }) => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const instrumentsURL = `${process.env.REACT_APP_API_BASE_URL}/instruments`;

  const { accessPerm, settingsData } = useContext(UserContext);
  const {
    state: {
      record: {
        user: { role_id },
      },
    },
  } = useLocation();
  const { state } = useLocation();
  const userId = state?.record?.user?.id;
  const createdBy = state?.record?.user?.created_by;
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${userId}`;

  // const formattedBirthDate = state?.record?.user?.date_of_birth
  //   ? (() => {
  //       const date = new Date(state?.record?.user.date_of_birth);
  //       const day = date.getDate();
  //       const month = date.toLocaleString("en-GB", { month: "short" });
  //       const year = date.getFullYear();
  //       return `${day} ${month} ${year}`;
  //     })()
  //   : "---";
  const birthYear = state?.record?.user?.date_of_birth
    ? new Date(state?.record?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "";

  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [data, setData] = useState({});
  const [userTests, setUserTests] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [testInstruments, setTestInstruments] = useState([]);
  const [discount, setDiscount] = useState("");
  const [due, setDue] = useState("");
  const [investigations, setInvestigations] = useState([]);
  const [itemsData, setItemsData] = useState([
    {
      item_id: "",
      item_price: "",
      type: "",
    },
  ]);
  const [checked, setChecked] = useState(false);
  const [specimenGrp, setSpecimenGrp] = useState([]);
  const paidAmount = state?.record?.user_payment?.paid_amount;
  const packageAmount = state?.record?.user_payment?.amount;
  const packageName = state?.record?.package?.name;
  const [totalPrice, setTotalPrice] = useState("");
  const [netTotalPrice, setNetTotalPrice] = useState(
    state?.record?.user?.package_price || "",
  );
  // const [discountedPrice, setDiscountedPrice] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      // width: 1,
      // height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };

  const patientRegNameDataUrl = generateBarcode(
    String(`${state?.record?.user?.f_name}${age ? `-${age}` : ``}`),
  );

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(state?.record?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    // width: 1,
    // height: 20,
    displayValue: false,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    if (!isValid(date)) {
      return ""; // Return an empty string if the date is invalid
    }

    return format(date, "dd-MM-yyyy");
  };

  const getData = () => {
    axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response?.data?.data[0];
        setData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrint = () => {
    window.print();
  };

  const calculateTotalAmount = () => {
    const newTotalPrice = itemsData?.reduce((sum, investigation) => {
      const price = parseFloat(investigation.item_price) || 0;
      return sum + price;
    }, 0);
    setDiscountedAmount(state?.record?.user?.discount);
    setTotalPrice(newTotalPrice);
    const discountAmount = (newTotalPrice * discountedAmount) / 100;
    setDiscount(discountAmount);
    const newNetTotalPrice = newTotalPrice - discountAmount;
    setNetTotalPrice(newNetTotalPrice);
    const dueAmount = newNetTotalPrice - paidAmount;
    if (state?.record?.user?.role_id === 5) {
      setDue(dueAmount);
    } else {
      const due = packageAmount - paidAmount;
      setDue(due);
    }
  };

  const getSpecimenData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/investigation-groups`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      if (res?.status === 200) {
        setSpecimenGrp(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserDetailsById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );

      const data = res?.data?.data?.userDetail;
      if (data) {
        let invArray = [];

        if (data?.user?.role_id === 4) {
          invArray = Array.isArray(data?.package?.investigation_id)
            ? data.package.investigation_id
            : data.package.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        } else if (data?.user?.role_id === 5) {
          invArray = Array.isArray(data?.investigation_id)
            ? data.investigation_id
            : data.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInvestigationsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/test/investigations`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setInvestigations(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchtestInstrumentsData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-test-instruments`,
        {
          userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setTestInstruments(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserTestsData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-test-instruments/user-tests`,
        {
          userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        },
      );
      setUserTests(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchInstrumentsData = async () => {
    try {
      const response = await axios.get(instrumentsURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      setInstruments(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      return "Invalid date";
    }
  }

  const initializeItemsData = () => {
    let initialItemsData = [];

    // Check if role_id is not 5
    if (state?.record?.user?.role_id !== 5) {
      // Convert the investigation_id string into an array of integers
      const investigationIds = state?.record?.package?.investigation_id
        ?.split(",")
        ?.map((id) => parseInt(id.trim()));
      // Filter investigations based on package investigation_id
      initialItemsData = investigations
        .filter((investigation) => investigationIds?.includes(investigation.id))
        .map((filteredInvestigation) => ({
          item_id: filteredInvestigation.id,
          item_price: filteredInvestigation.price,
          type: 1,
        }));
    } else {
      // Regular initialization for role_id 5
      initialItemsData = [...userTests, ...testInstruments]?.map((test) => {
        const item = combinedItems?.find(
          (option) =>
            option.value === test.item_id && option.type === test.type,
        );
        return {
          item_id: item ? item?.value : test?.item_id,
          item_price: item
            ? parseFloat(test?.item_price)
            : parseFloat(item?.price), // Use custom price from test
          type: item ? item?.type : test?.type,
        };
      });
    }
    setItemsData(initialItemsData);
  };

  const instrumentsWithType = instruments?.map((instrument) => ({
    ...instrument,
    type: 2,
    label: instrument.name,
    value: instrument.id,
  }));

  const investigationsWithType = investigations?.map((investigation) => ({
    ...investigation,
    type: 1,
    label: investigation.name,
    value: investigation.id,
  }));

  const combinedItems = [...investigationsWithType, ...instrumentsWithType];

  useEffect(() => {
    calculateTotalAmount();
  }, [itemsData]);

  useEffect(() => {
    if (userTests?.length > 0 || testInstruments?.length > 0) {
      initializeItemsData();
    } else {
      initializeItemsData();
    }
  }, [userTests, testInstruments, investigations, instruments]);

  useEffect(() => {
    getData();
    fetchUserDetailsById();
    fetchUserTestsData();
    fetchtestInstrumentsData();
    fetchInstrumentsData();
    fetchInvestigationsData();
    getSpecimenData();
  }, []);
  console.log(state?.record);
  return (
    <div className="patient-invoice p-3">
      {/* changing */}
      <div className="col-sm-12 text-dark">
        <div className="text-center patient-invoice-header-middle">
          <div className="">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="row px-0 justify-content-center h-100">
        <div className="d-flex align-items-center">
          <div style={{ width: "33%" }}>
            <p
              className="p-0 m-0 text-dark"
              style={{ fontSize: "8px", fontWeight: "bold" }}
            >
              REG DATE:{" "}
              {formatDate(state?.record?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
            </p>
          </div>
          <div style={{ width: "34%" }} className="p-0 m-0">
            <h6
              className="text-uppercase text-bold fs-4 mx-2 px-3 text-center text-dark p-0 m-0"
              style={{ textDecoration: "underline", fontWeight: "bolder" }}
            >
              Money Receipt
            </h6>
          </div>
          <div style={{ width: "33%" }}>
            <p
              className="p-0 m-0 text-end text-dark fs-6"
              style={{ fontWeight: "bold" }}
            >
              Customer Copy
            </p>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between "
        style={{ width: "100%" }}
      >
        <div>
          <p className="m-0 text-dark fs-6" style={{ fontWeight: "bolder" }}>
            রেজিস্ট্রেশন নম্বর: {state?.record?.user?.reg_prefix}{" "}
            {state?.record?.user?.registration_no
              ? state?.record?.user?.registration_no
              : "N/A"}
          </p>
        </div>

        <div>
          <p className="m-0 text-dark fs-6" style={{ fontWeight: "bolder" }}>
            Report Date:{" "}
            {state?.record?.user?.report_delivery_date &&
            !isNaN(new Date(state?.record?.user?.report_delivery_date))
              ? format(
                  new Date(state?.record?.user?.report_delivery_date),
                  "dd MMM yyyy",
                ).toUpperCase()
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {/* fist part in width  */}
        <div style={{ width: "75%" }}>
          {/* bar code  */}

          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <div className="" style={{ width: "40%" }}>
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={"40px"}
                style={{ marginLeft: "-10px" }}
              />
            </div>

            <div className="" style={{ width: "40%" }}>
              <img
                src={patientRegNameDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={"40px"}
              />
            </div>
          </div>

          {/* table  */}

          <div className="row justify-content-center text-dark">
            <div className="d-flex justify-content-between  w-100">
              <div className="" style={{ width: "100%" }}>
                <div className="row text-dark">
                  <table
                    className="table-bordered invoice-table h-100 text-dark fw-bold"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderCollapse: "collapse",
                      textAlign: "left",
                      // fontSize: "20px",
                    }}
                  >
                    <tbody>
                      {/* Row 1 - Name and Client ID */}
                      <tr style={{ height: "1.5rem" }}>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          style={{ width: "22%" }}
                        >
                          Name
                        </td>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          // style={{ width: "40%" }}
                          colSpan={4}
                        >
                          {state?.record?.user?.f_name || "---"}
                        </td>
                      </tr>

                      {/* Row 2 - Gender and Passport No */}
                      <tr style={{ height: "1.5rem" }}>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          // style={{ fontSize: "30px" }}
                        >
                          Passport No
                        </td>
                        <td
                          style={{ width: "23%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          {state?.record?.user?.passport_no || "---"}
                        </td>

                        <td className="text-uppercase border border-dark  align-middle fw-bold">
                          Country
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          {state?.record?.country?.name || "---"}
                        </td>
                      </tr>

                      {/* Row 3 - Date of Birth and Travelling To */}
                      <tr style={{ height: "1.5rem" }}>
                        <td className="text-uppercase border border-dark  align-middle">
                          Profession
                        </td>
                        <td
                          colSpan={4}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.profession?.name || "---"}
                        </td>
                      </tr>

                      {/* Row 4 - Father Name and Contact Number */}
                      <tr style={{ height: "1.5rem" }}>
                        <td className="text-uppercase border border-dark  align-middle">
                          GCC Slip No
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.gcc_slip_no || ""}
                        </td>
                        <td
                          style={{ width: "18%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          Slip Ex. Dt.
                        </td>
                        <td
                          style={{ width: "20%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          {state?.record?.user?.gcc_expairy_date &&
                          !isNaN(
                            new Date(state?.record?.user?.gcc_expairy_date),
                          )
                            ? format(
                                new Date(state?.record?.user?.gcc_expairy_date),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                        </td>
                      </tr>

                      {/* Row 5 - Nationality and Agency */}
                      <tr>
                        <td className="text-uppercase border border-dark  align-middle">
                          Date of Birth
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.date_of_birth &&
                          !isNaN(new Date(state?.record?.user?.date_of_birth))
                            ? format(
                                new Date(state?.record?.user?.date_of_birth),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                          {"("}
                          {age}
                          {")"}
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          Report Dt.
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.user?.report_delivery_date &&
                          !isNaN(
                            new Date(state?.record?.user?.report_delivery_date),
                          )
                            ? format(
                                new Date(
                                  state?.record?.user?.report_delivery_date,
                                ),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ width: "16%" }}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {settingsData?.introducer_label}
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.introducer_user?.f_name || "---"}
                        </td>

                        <td className="text-uppercase border border-dark  align-middle">
                          Contact
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.user?.mobile || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* specipiment */}
          <div
            className="d-flex flex-wrap justify-content-between ms-3 py-2"
            // style={{ width: "40%" }}
          >
            {investigationGroup?.map((specimen, i) => (
              <div className="d-flex text-dark" key={i}>
                <div className="d-flex">
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input mt-0 me-1 p-1 fs-5 border-3 border-dark "
                      style={{ fontWeight: "bolder" }}
                      // style={{ borderWidth: "10px" }}
                      type="radio"
                      id={i}
                      value={JSON.stringify({
                        id: specimen?.id,
                        name: specimen?.name,
                      })}
                      // checked={isChecked}
                    />
                    <label
                      className="form-check-label fs-6"
                      style={{ fontWeight: "bolder" }}
                      htmlFor="physicalExam"
                    >
                      {specimen?.name}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* account table part  */}

          <table className="table table-bordered mb-0 custom-table">
            <thead>
              {/* <tr className="table-active">
                <th
                  colSpan="3"
                  className="text-center colspan-heading border border-dark m-0 p-0"
                >
                  Accounts Transaction Details
                </th>
              </tr> */}
              {!checked ? (
                <>
                  <tr>
                    <th className="col-sm-8 border border-dark">
                      Service / Package / Test
                    </th>
                    <th className="text-center border border-dark">
                      Price (BDT)
                    </th>
                  </tr>
                </>
              ) : (
                ""
              )}
            </thead>
            {state?.record?.user?.role_id === 5 && (
              <tbody>
                {!checked ? (
                  <>
                    {itemsData?.map((item, index) => {
                      const matchingItem = combinedItems?.find(
                        (option) =>
                          option.value === item?.item_id &&
                          option.type === item.type,
                      );
                      return (
                        <tr key={index} className="border-dark">
                          <td className="w-50">
                            {matchingItem ? matchingItem?.name : "---"}
                          </td>
                          <td className="w-50 text-end">
                            {/* Display the name of the matching item */}
                            {item ? item?.item_price : "---"}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="no-border">
                      <td className="text-end no-border">Total:</td>
                      <td className="text-end no-border">{totalPrice || 0}</td>
                    </tr>
                    {discountedAmount > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">
                          Discount (
                          {discountedAmount ? `${discountedAmount}%` : "0"}):
                        </td>
                        <td className="text-end no-border">{discount || 0}</td>
                      </tr>
                    )}
                    <tr className="no-border">
                      <td
                        className="text-end no-border fs-5"
                        style={{ fontWeight: "bolder" }}
                      >
                        {" "}
                        Total Amount:
                      </td>
                      <td
                        className="text-end no-border fs-5"
                        style={{ fontWeight: "bolder" }}
                      >
                        {netTotalPrice || 0}
                      </td>
                    </tr>
                    {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Commission:</td>
                      <td className="text-end no-border">{due || 0}</td>
                    </tr> */}
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center border border-dark fw-bold"
                      style={{ height: "70px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
            {state?.record?.user?.role_id !== 5 && (
              <tbody>
                {!checked ? (
                  <>
                    <tr>
                      <td className="w-50 border border-dark">{packageName}</td>
                      <td className="w-50 text-end border border-dark">
                        {/* Display the name of the matching item */}
                        {packageAmount ? packageAmount : 0}
                      </td>
                    </tr>
                    <tr className="no-border" style={{ border: "none" }}>
                      <td
                        className="text-end no-border fs-5 "
                        style={{ fontWeight: "bolder" }}
                      >
                        {" "}
                        Total Amount:
                      </td>
                      <td
                        className="text-end no-border fs-5"
                        style={{ fontWeight: "bolder" }}
                      >
                        {packageAmount || 0}
                      </td>
                    </tr>
                    {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    {due > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">Due:</td>
                        <td className="text-end no-border">{due || 0}</td>
                      </tr>
                    )} */}
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center border border-dark fw-bold"
                      style={{ height: "70px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
          </table>

          <section
            className="patient-invoice relative bottom-0"
            // style={{ pageBreakAfter: "always" }}
          >
            <div className="w-100 d-flex align-items-center justify-content-between pb-1">
              <p
                className="static-user-invoice text-dark w-100 p-0 m-0"
                style={{ fontSize: "8px" }}
              >
                সতর্কীকরণ: এখানে Cash কাউন্টার ছাড়া বিকাশ বা অন্য কোন মাধ্যমে
                টাকা পয়সা লেনদেন করা হয়না | দালাল বা প্রতারক চক্রের হতে সাবধান
                থাকুন, ফোন বা SMS করে কেউ টাকা/ পয়সা চাইলে মেডিকেল সেন্টারের
                কাউন্টারে অবহিত করার জন্য অনুরোধ করা হলো
              </p>
            </div>
          </section>
        </div>

        {/* middle part  */}
        <div className="">
          <div className="finger-print-text  border border-dark m-0 p-0 display-inline p-2">
            <p
              className="m-0 p-0 text-center "
              style={{ fontWeight: "bolder", fontSize: "20px" }}
            >
              শুক্রবার এবং সরকারি ছুটির দিন বন্ধ
            </p>
          </div>
        </div>

        {/* image part  */}
        <div>
          <div
            // className="h-100"
            //  style={{ width: "20%" }}
            className="d-flex"
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Photo
              </p>
            </div>

            <div
              className="border border-end-0 border-dark h-100"
              // style={{ width: "100%", height: "114px" }}
            >
              {state?.record?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.image}`}
                  // className="img-fluid"
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "100%",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "40px",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              )}
            </div>
          </div>

          <div
            className="mt-1 d-flex"
            //  style={{ width: "20%" }}
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Finger Print
              </p>
            </div>
            {state?.record?.user?.finger_print ? (
              <div className="border border-dark h-100">
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.finger_print}`}
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                />
              </div>
            ) : (
              <div
                className="border border-dark border"
                style={{ height: "120px", width: "120px" }}
              ></div>
            )}
          </div>
          <div className="d-flex">
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                QR Code
              </p>
            </div>
            <div
              className="border border-dark mt-1 d-flex justify-content-center align-items-center"
              style={{
                height: "100px",
                width: "100%",
              }}
            >
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={85} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* footer part  */}

      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start text-dark">
          User: {createdBy}
        </div>
        <p className="p-0 m-0 fst-italic text-dark text-center">
          This is an auto generated report, does not require any signature.
        </p>
        <div className="text-end text-dark fst-italic p-0 m-0">
          Powered by Clincx Medical Solutions
        </div>
      </div>
      <div className="invoice-footer d-flex mt-0 align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-1 text-dark">
          <input
            type="checkbox"
            name="checkbox-invoice"
            id="checkbox-invoice"
            defaultChecked={checked}
            onClick={() => setChecked(!checked)}
          />
          <label htmlFor="checkbox-invoice">Print Without Amount</label>
        </div>
      </div>

      <hr
        style={{ borderTop: "3px dotted black", height: "0" }}
        className="m-0"
      />
      <div className="col-sm-12 text-dark">
        <div className="text-center patient-invoice-header-middle">
          <div className="">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="row px-0 justify-content-center h-100">
        <div className="d-flex align-items-center">
          <div style={{ width: "35%" }}>
            <p
              className="p-0 m-0 text-dark"
              style={{ fontSize: "8px", fontWeight: "bold" }}
            >
              REG DATE:{" "}
              {formatDate(state?.record?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
            </p>
          </div>
          <div style={{ width: "30%" }} className="p-0 m-0">
            <h6
              className="text-uppercase text-bold fs-5 mx-2 px-3 text-center text-dark p-0 m-0"
              style={{ textDecoration: "underline", fontWeight: "bolder" }}
            >
              Money Receipt
            </h6>
          </div>

          <div style={{ width: "35%" }}>
            <div>
              <p
                className="p-0 m-0 text-end text-dark fs-6"
                style={{ fontWeight: "bold" }}
              >
                Office Copy
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between "
        style={{ width: "100%" }}
      >
        <div>
          <p className="m-0 text-dark fs-6" style={{ fontWeight: "bolder" }}>
            রেজিস্ট্রেশন নম্বর: {state?.record?.user?.reg_prefix}{" "}
            {state?.record?.user?.registration_no
              ? state?.record?.user?.registration_no
              : "N/A"}
          </p>
        </div>

        <div>
          <p className="m-0 text-dark fs-6" style={{ fontWeight: "bolder" }}>
            Report Date:{" "}
            {state?.record?.user?.report_delivery_date &&
            !isNaN(new Date(state?.record?.user?.report_delivery_date))
              ? format(
                  new Date(state?.record?.user?.report_delivery_date),
                  "dd MMM yyyy",
                ).toUpperCase()
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {/* fist part in width  */}
        <div style={{ width: "75%" }}>
          {/* bar code  */}

          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <div className="" style={{ width: "40%" }}>
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={"50px"}
                style={{ marginLeft: "-10px" }}
              />
            </div>

            <div className="" style={{ width: "40%" }}>
              <img
                src={patientRegNameDataUrl}
                alt=""
                className="patient-barcode2"
                width={"100%"}
                height={"50px"}
              />
            </div>
          </div>

          {/* table  */}

          <div className="row justify-content-center text-dark">
            <div className="d-flex justify-content-between  w-100">
              <div className="" style={{ width: "100%" }}>
                <div className="row text-dark">
                  <table
                    className="table-bordered invoice-table h-100 text-dark fw-bold"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderCollapse: "collapse",
                      textAlign: "left",
                      // fontSize: "20px",
                    }}
                  >
                    <tbody>
                      {/* Row 1 - Name and Client ID */}
                      <tr style={{ height: "1.5rem" }}>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          style={{ width: "26%" }}
                        >
                          Name
                        </td>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          // style={{ width: "40%" }}
                          colSpan={4}
                        >
                          {state?.record?.user?.f_name || "---"}
                        </td>
                      </tr>

                      {/* Row 2 - Gender and Passport No */}
                      <tr style={{ height: "1.5rem" }}>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          // style={{ fontSize: "30px" }}
                        >
                          Passport No
                        </td>
                        <td
                          style={{ width: "26%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          {state?.record?.user?.passport_no || "---"}
                        </td>

                        <td
                          style={{ width: "15%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          Country
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          {state?.record?.country?.name || "---"}
                        </td>
                      </tr>

                      {/* Row 3 - Date of Birth and Travelling To */}
                      <tr style={{ height: "1.5rem" }}>
                        <td className="text-uppercase border border-dark  align-middle">
                          Profession
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.profession?.name || "---"}
                        </td>

                        <td className="text-uppercase border border-dark  align-middle">
                          P.I. Place
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.pi_place || "---"}
                        </td>
                      </tr>

                      <tr style={{ height: "1.5rem" }}>
                        <td className="text-uppercase border border-dark  align-middle">
                          Maritial Status
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.marital_status?.name || "---"}
                        </td>

                        <td className="text-uppercase border border-dark  align-middle">
                          Religion
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.religion || "---"}
                        </td>
                      </tr>

                      {/* Row 4 - Father Name and Contact Number */}
                      <tr style={{ height: "1.5rem" }}>
                        <td className="text-uppercase border border-dark  align-middle">
                          {/* GCC/Gamca no */}
                          GCC Slip No
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.gcc_slip_no || ""}
                        </td>

                        <td
                          style={{ width: "18%" }}
                          className="text-uppercase border border-dark  align-middle fw-bold"
                        >
                          Slip Ex. Dt.
                        </td>
                        <td
                          className="text-uppercase border border-dark  align-middle fw-bold"
                          style={{ width: "20%" }}
                        >
                          {state?.record?.user?.gcc_expairy_date &&
                          !isNaN(
                            new Date(state?.record?.user?.gcc_expairy_date),
                          )
                            ? format(
                                new Date(state?.record?.user?.gcc_expairy_date),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                        </td>
                      </tr>

                      <tr>
                        {" "}
                        <td className="text-uppercase border border-dark  align-middle">
                          Report Date
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.report_delivery_date &&
                          !isNaN(
                            new Date(state?.record?.user?.report_delivery_date),
                          )
                            ? format(
                                new Date(
                                  state?.record?.user?.report_delivery_date,
                                ),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                        </td>
                        <td className="text-uppercase border border-dark align-middle">
                          P.I. Date
                        </td>
                        <td className="text-uppercase border border-dark align-middle">
                          {state?.record?.user?.pi_date &&
                          !isNaN(new Date(state?.record?.user?.pi_date))
                            ? format(
                                new Date(state?.record?.user?.pi_date),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "N/A"}
                        </td>
                      </tr>

                      {/* Row 5 - Nationality and Agency */}
                      <tr>
                        <td className="text-uppercase border border-dark  align-middle">
                          Date of Birth
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.user?.date_of_birth &&
                          !isNaN(new Date(state?.record?.user?.date_of_birth))
                            ? format(
                                new Date(state?.record?.user?.date_of_birth),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "---"}
                          {"("}
                          {age}
                          {")"}
                        </td>

                        <td
                          style={{ width: "17%" }}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          P/Exp Date
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.user?.pe_date &&
                          !isNaN(new Date(state?.record?.user?.pe_date))
                            ? format(
                                new Date(state?.record?.user?.pe_date),
                                "dd MMM yyyy",
                              ).toUpperCase()
                            : "N/A"}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-uppercase border border-dark  align-middle">
                          {settingsData?.introducer_label}
                        </td>
                        <td
                          colSpan={2}
                          className="text-uppercase border border-dark  align-middle"
                        >
                          {state?.record?.introducer_user?.f_name}
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          Contact
                        </td>
                        <td className="text-uppercase border border-dark  align-middle">
                          {state?.record?.user?.mobile}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <section className="patient-invoice mt-2">
            <div className="row">
              <table className="table table-bordered mb-0 custom-table">
                <thead>
                  {!checked ? (
                    <>
                      <tr>
                        <th className="col-sm-8 border border-dark">
                          Service / Package /Test
                        </th>
                        <th className="text-center border border-dark">
                          Price (BDT)
                        </th>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </thead>
                {state?.record?.user?.role_id === 5 && (
                  <tbody>
                    {!checked ? (
                      <>
                        {itemsData?.map((item, index) => {
                          const matchingItem = combinedItems?.find(
                            (option) =>
                              option.value === item?.item_id &&
                              option.type === item.type,
                          );
                          return (
                            <tr key={index}>
                              <td className="w-50 border border-dark">
                                {matchingItem ? matchingItem?.name : "---"}
                              </td>
                              <td className="w-50 text-end border border-dark">
                                {item ? item?.item_price : "---"}
                              </td>
                            </tr>
                          );
                        })}

                        <tr className="no-border">
                          <td className="text-end no-border">Total:</td>
                          <td className="text-end no-border">
                            {totalPrice || 0}
                          </td>
                        </tr>
                        {discountedAmount > 0 && (
                          <tr className="no-border">
                            <td className="text-end no-border">
                              Discount (
                              {discountedAmount ? `${discountedAmount}%` : "0"}
                              ):
                            </td>
                            <td className="text-end no-border">
                              {discount || 0}
                            </td>
                          </tr>
                        )}
                        <tr className="no-border">
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {" "}
                            Total Amount:
                          </td>
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {netTotalPrice || 0}
                          </td>
                        </tr>
                        <tr className="no-border">
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            Paid:
                          </td>
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {paidAmount || 0}
                          </td>
                        </tr>
                        {due > 0 && (
                          <tr className="no-border">
                            <td className="text-end no-border">Commission:</td>
                            <td className="text-end no-border">{due || 0}</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        <tr
                          className="text-center d-flex align-items-center justify-content-center fw-bold"
                          style={{ height: "114px", fontSize: "22px" }}
                        >
                          Paid
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
                {state?.record?.user?.role_id !== 5 && (
                  <tbody>
                    {!checked ? (
                      <>
                        <tr>
                          <td className="w-50 border border-dark">
                            {packageName}
                          </td>
                          <td className="w-50 text-end border border-dark">
                            {packageAmount ? packageAmount : 0}
                          </td>
                        </tr>
                        <tr className="no-border">
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {" "}
                            Total Amount:
                          </td>
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {packageAmount || 0}
                          </td>
                        </tr>
                        <tr className="no-border">
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            Paid Amount:
                          </td>
                          <td
                            className="text-end no-border fs-5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {paidAmount || 0}
                          </td>
                        </tr>
                        {due > 0 && (
                          <tr className="no-border">
                            <td className="text-end no-border">Commission:</td>
                            <td className="text-end no-border">{due || 0}</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        <tr
                          className="text-center d-flex align-items-center justify-content-center border border-dark fw-bold"
                          style={{ height: "70px", fontSize: "22px" }}
                        >
                          Paid
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </section>
        </div>

        {/* middle part  */}
        <div className="">
          <div className="finger-print-text  border border-dark m-0 p-0 display-inline p-2">
            <p
              className="m-0 p-0 text-center "
              style={{ fontWeight: "bolder", fontSize: "20px" }}
            >
              শুক্রবার এবং সরকারি ছুটির দিন বন্ধ
            </p>
          </div>
        </div>

        {/* image part  */}
        <div>
          <div
            // className="h-100"
            //  style={{ width: "20%" }}
            className="d-flex"
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Photo
              </p>
            </div>

            <div
              className="border border-end-0 border-dark h-100"
              // style={{ width: "100%", height: "114px" }}
            >
              {state?.record?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.image}`}
                  // className="img-fluid"
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "100%",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "40px",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              )}
            </div>
          </div>

          <div
            className="mt-1 d-flex"
            //  style={{ width: "20%" }}
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Finger Print
              </p>
            </div>
            {state?.record?.user?.finger_print ? (
              <div className="border border-dark h-100">
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.finger_print}`}
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                />
              </div>
            ) : (
              <div
                className="border border-dark border"
                style={{ height: "120px", width: "120px" }}
              ></div>
            )}
          </div>
          <div className="d-flex">
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                QR Code
              </p>
            </div>
            <div
              className="border border-dark mt-1 d-flex justify-content-center align-items-center"
              style={{
                height: "100px",
                width: "100%",
              }}
            >
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={85} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* footer part  */}

      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start text-dark">
          User: {createdBy}
        </div>
        <p className="p-0 m-0 fst-italic text-dark text-center">
          This is an auto generated report, does not require any signature.
        </p>
        <div className="text-end text-dark fst-italic p-0 m-0">
          Powered by Clincx Medical Solutions
        </div>
      </div>
      <div className="invoice-footer d-flex mt-0 align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-1 text-dark">
          <input
            type="checkbox"
            name="checkbox-invoice"
            id="checkbox-invoice"
            defaultChecked={checked}
            onClick={() => setChecked(!checked)}
          />
          <label htmlFor="checkbox-invoice">Print Without Amount</label>
        </div>
      </div>
      <div className="invoice-footer d-flex mt-5 align-items-center justify-content-end">
        {stateData !== 1 && (
          <div className="d-flex gap-2">
            {accessPerm(32, 23) && (
              <button className="btn btn-primary" onClick={() => handlePrint()}>
                Print
              </button>
            )}
            {role_id === 5 ? (
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/dashboard/medical-tests")}
              >
                Back
              </button>
            ) : (
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/dashboard/patient-info")}
              >
                Back
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInvoiceCopy;

import React from "react";

const Loading2 = () => {
  return (
    <div className="loader-container2">
      <div className="spinner"></div>
    </div>
  );
};

export default Loading2;

import axios from "axios";
import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./HealthCard.module.scss";
import QRCode from "qrcode.react";
import { UserContext } from "../../Context/UserContextAPI";
import "../Investigation/Report/Certificates/Fit.scss";

const PrintHealthCard = () => {
  const { currentUser, accessPerm } = useContext(UserContext);

  const now = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(now);

  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [pathologyData, setPathologyData] = useState([]);
  const [filteredPathologyData, setFilteredPathologyData] = useState([]);
  const [user, setUser] = useState(null);
  const [xrayImage, setXrayImage] = useState("");

  const { userId } = useParams();

  const today = new Date();
  const createdAt = user?.user?.createdAt;

  const barcodedDate = createdAt
    ? new Date(createdAt)?.toLocaleDateString("en-GB") ?? "Invalid Date"
    : "Date not available";

  const validDate = new Date(today);
  validDate.setDate(today.getDate() + 60);

  const formattedValidDate =
    validDate.toLocaleDateString("en-GB") ?? "Invalid Date";
  const formattedBirthDate = user?.user?.date_of_birth
    ? new Date(user?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const patientRegisterBarcode = document.createElement("canvas");

  JsBarcode(patientRegisterBarcode, String(user?.user?.registration_no));

  const patientIdDataUrl = patientRegisterBarcode.toDataURL();

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUser(response.data.data.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-healthcard-results/${userId}/${3}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPhysicalData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-healthcard-results/${userId}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRadiologyData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPathologyData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const registrationNo = user?.user?.registration_no; // Replace with the actual ID
  const base64RegID = btoa(registrationNo); // Encode the ID to Base64

  const urlWithBase64RegID = `${process.env.REACT_APP_REPORT_QR_CODE}/healthcard/${base64RegID}`;

  useEffect(() => {
    let filteredData;

    if (
      user?.gender?.id === 1 ||
      (user?.gender?.id === 2 && user?.introducer_user?.pregnancyField === 0)
    ) {
      // If the first condition matches, filter out investigation_id 22 and 25
      filteredData = pathologyData.filter(
        (item) => item.investigation_id !== 22 && item.investigation_id !== 25
      );
    } else {
      // If the second condition matches, filter out investigation_id 25 only
      filteredData = pathologyData.filter(
        (item) => item.investigation_id !== 25
      );
    }

    setFilteredPathologyData(filteredData);
  }, [pathologyData, user]);

  useEffect(() => {
    if (user?.user?.registration_no) {
      setTimeout(() => {
        window.print();
      }, 1400);
    }
  }, [user]);

  useEffect(() => {
    userId && getPhysicalData();
  }, [userId]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    userId && getRadiologyData();
  }, [userId]);

  useEffect(() => {
    userId && getPathologyData();
  }, [userId]);

  useEffect(() => {
    userId && getUserDetail();
  }, [userId]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  return (
    <>
      <div className={`row ${styles["health-card-print-wrapper"]}`}>
        <div className={`col-4 ${styles["border-gap-x"]}`}>
          <table className="w-100">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={2}
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Country Name: {user?.country?.name}
                </th>
              </tr>
            </thead>
          </table>
          <div
            className={`d-flex ${
              user?.user?.role_id !== 5
                ? `justify-content-between`
                : `justify-content-center`
            } mb-0 ${styles["left-top"]}`}
          >
            <div className="w-25">
              {user?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.image}`}
                  alt=""
                  width="100%"
                  style={{ objectFit: "contain " }}
                />
              ) : (
                <img
                  src="/dummy.jpg"
                  width="100%"
                  alt="Avatar placeholder"
                  style={{ height: "100%", objectFit: "contain" }}
                />
              )}
            </div>

            <div className="w-auto text-dark d-flex flex-column justify-content-center align-items-center">
              <div>Date: {barcodedDate}</div>
              <img
                src={patientIdDataUrl}
                alt=""
                width="100%"
                height="46px"
                className="object-fit-fill"
              />
              <div>Valid: {formattedValidDate}</div>
            </div>
            <div className={`w-25 ${styles["fingerprint"]}`}>
              {user?.user?.finger_print ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.finger_print}`}
                  alt="Patient fingerprint"
                  width="100%"
                  style={{ objectFit: "contain" }}
                />
              ) : (
                <div
                  className="p-2 border text-center text-dark border d-flex align-items-center justify-content-center"
                  width="100%"
                  style={{ height: "100%", objectFit: "contain" }}
                >
                  No Fingerprint Found
                </div>
              )}
            </div>
          </div>
          <table className="w-100 mb-0 text-dark">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={2}
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Identification of the person
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fw-bold">Name: </td>
                <td className="fw-bold">{user?.user?.f_name}</td>
              </tr>
              <tr>
                <td className="fw-bold">S/O, D/O, W/O: </td>
                <td className="fw-bold">{user?.father_name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Registration No: </td>
                <td className="fw-bold">
                  {user?.user?.reg_prefix || ""}
                  {user?.user?.registration_no}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Passport No: </td>
                <td className="fw-bold">{user?.user?.passport_no}</td>
              </tr>
              <tr>
                <td className="fw-bold">
                  {user?.user?.role_id !== 5 ? `Date of Birth:` : `Age:`}
                </td>
                <td className="fw-bold">
                  {user?.user?.role_id !== 5
                    ? formattedBirthDate || "---"
                    : user?.user?.age || "---"}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Nationality: </td>
                <td className="fw-bold">
                  {user?.national_country?.nationality}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Sex: </td>
                <td className="fw-bold">{user?.gender?.name}</td>
              </tr>
              <tr>
                <td className="fw-bold d-flex align-items-start">
                  {user?.user?.role_id !== 5 ? `Introducer:` : `Reference`}
                </td>
                <td className="fw-bold">
                  {user?.user?.role_id !== 5
                    ? `${user?.introducer_user?.f_name}`
                    : `${user?.user?.reference}`}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-100 text-dark">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={2}
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Physical examination
                </th>
              </tr>
            </thead>
            <tbody>
              {physicalData?.map((physicalData, index) => (
                <tr
                  key={index}
                  className={`${
                    index > 6 && styles["hide-row"]
                  } border-dark border`}
                >
                  <td className="border-end border-dark px-1">
                    {physicalData?.investigation_test?.name}
                  </td>
                  <td className="border-end border-dark px-1">
                    {`${physicalData?.result} ${
                      physicalData?.investigation_test?.uom?.symbol ?? ""
                    }`}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`col-4 ${styles["border-gap-x"]} text-dark`}>
          <table className="w-100 mb-0">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={2}
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  X-ray (chest p/a) view
                </th>
              </tr>
            </thead>
            <tbody>
              {radiologyData?.map((pData, i) => (
                <tr
                  key={i}
                  className={`border border-dark ${
                    i > 3 && styles["hide-row"]
                  }`}
                >
                  <td className="border border-dark px-1">
                    {pData?.investigation_test?.name}
                  </td>
                  <td className="border border-dark px-1">{pData?.result}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="w-100">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={2}
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Digital cxr
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {!!xrayImage ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                      alt="X-Ray film"
                      className="w-100"
                      style={{ height: "248px" }}
                    />
                  ) : (
                    <div
                      className="text-center text-dark d-flex align-items-center justify-content-center"
                      style={{ height: "248px" }}
                    >
                      No X-Ray film found
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`col-4 d-flex flex-column justify-content-between ${styles["border-gap-x"]} text-dark`}
        >
          <table className="w-100 mb-2">
            <thead>
              <tr className="border border-dark">
                <th
                  colSpan={3}
                  className="text-center text-uppercase border-end border-dark"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Laboratory investigation
                </th>
                <th
                  className="text-center text-uppercase"
                  style={{ backgroundColor: "#fd0166", color: "#fff" }}
                >
                  Result
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredPathologyData?.map((patho, i) => (
                <tr
                  key={i}
                  style={{ border: "1px solid" }}
                  // className={`${i > 8 && styles["hide-row"]}`}
                >
                  <td
                    className="text-center px-1"
                    style={{ border: "1px solid" }}
                  >
                    {
                      patho?.investigation_test.investigation
                        .investigation_category.name
                    }
                  </td>
                  <td className="px-1" style={{ border: "1px solid" }}>
                    {patho?.investigation_test?.investigation?.name}
                  </td>
                  <td
                    className="text-left px-1"
                    style={{ border: "1px solid" }}
                  >
                    {patho?.investigation_test.name}
                  </td>
                  <td
                    className="text-left px-1"
                    style={{ border: "1px solid" }}
                  >
                    {`${patho?.result ?? ""} ${
                      patho?.investigation_test?.uom?.symbol ?? ""
                    }`}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className={`d-flex flex-column justify-content-around ${styles["signature-area"]}`}
          >
            <div className="text-dark">
              <div>Dear Sir,</div>
              <div className="ms-4">
                Above mentioned person is medically FIT
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between w-100 text-right">
              <Link to={urlWithBase64RegID}>
                <QRCode value={urlWithBase64RegID} size={80} />
              </Link>{" "}
              <div className="text-dark">
                <div className="fw-bold mb-4">Signature of physician</div>
                <div>Printed by {currentUser?.f_name}</div>
                <div>{formattedDate}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2 mt-5 fit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default PrintHealthCard;

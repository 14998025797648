import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import request from "../../utils/request";
import StaticXRayBody from "./components/StaticXRayBody";
import "./StaticXray.scss";

const StaticXrayWithID = () => {
  const { userId } = useParams();

  const {
    data: { userDetail },
    isSuccess,
  } = useFetch(() => request.getOne("user-details/test", userId), userId);
  const navigate = useNavigate();

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="">
      <StaticXRayBody patientData={userDetail} />
      <div className="static-xray-btn d-flex mt-5 align-items-center justify-content-end">
        <div className="d-flex gap-2 mx-3">
          <button className="btn btn-primary" onClick={() => handlePrint()}>
            Print
          </button>
          {userDetail?.user?.role_id === 5 ? (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/medical-tests")}
            >
              Back
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/patient-info")}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaticXrayWithID;

import { Link } from "react-router-dom";
import HeaderImg from "../../../../components/Print/HeaderImg";
import formatDate from "../../../../utils/formatDate";
import MoneyReceiptMainPart from "./MoneyReceiptMainPart";

import QRCode from "qrcode.react";
import { useState } from "react";
import BarCodeInInvoice from "./BarCodesInInvoice";
import calculateAge from "../../../../utils/calculateAge";
import MrUserTableInfo from "./MrUserTableInfo";
import SpecimentDataShow from "./SpecimentDataShow";
import SPTAmountTable from "./SPTAmountTable";
import useFetch from "../../../../hooks/useFetch";
import request from "../../../../utils/request";

const MoneyReceipt = ({ userDetail, office }) => {
  const [checked, setChecked] = useState(false);
  const urlWithBase64ID = `${process.env.REACT_APP_REPORT_QR_CODE}/medical-verification/result/report/print/${userDetail?.user_id}`;

  const age = calculateAge(userDetail?.user?.date_of_birth);

  // setting data
  const { data: settings } = useFetch(() => request.getAll("settings"));
  const settingData = settings.length > 0 && settings[0];

  return (
    <div>
      <HeaderImg headerImg={settingData?.header_image} />

      <div className="row px-0 justify-content-center h-100">
        <div className="d-flex align-items-center">
          <div style={{ width: "33%" }}>
            <p
              className="p-0 m-0 text-dark"
              style={{ fontSize: "8px", fontWeight: "bold" }}
            >
              REG DATE:{" "}
              {formatDate(userDetail?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
            </p>
          </div>
          <div style={{ width: "34%" }} className="p-0 m-0">
            <h6
              className="text-uppercase text-bold fs-4 mx-2 px-3 text-center text-dark p-0 m-0"
              style={{ textDecoration: "underline", fontWeight: "bolder" }}
            >
              Money Receipt
            </h6>
          </div>
          <div style={{ width: "33%" }}>
            <p
              className="p-0 m-0 text-end text-dark fs-6"
              style={{ fontWeight: "bold" }}
            >
              {office ? "Office" : "Customer"} Copy
            </p>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between "
        style={{ width: "100%" }}
      >
        <div>
          <p className="m-0 text-dark fs-5" style={{ fontWeight: "bolder" }}>
            রেজিস্ট্রেশন নম্বর: {userDetail?.user?.reg_prefix}{" "}
            {userDetail?.user?.registration_no
              ? userDetail?.user?.registration_no
              : "N/A"}
          </p>
        </div>

        <div>
          <p className="m-0 text-dark fs-6" style={{ fontWeight: "bolder" }}>
            Report Date:{" "}
            {userDetail?.user?.report_delivery_date &&
            !isNaN(new Date(userDetail?.user?.report_delivery_date))
              ? formatDate(
                  new Date(userDetail?.user?.report_delivery_date),
                ).toUpperCase()
              : "N/A"}
          </p>
        </div>
      </div>

      {/* main part  */}

      <div className="d-flex justify-content-between">
        <div style={{ width: "75%" }}>
          {/* bar code  */}
          <BarCodeInInvoice userDetail={userDetail} age={age} />

          {/* table  */}

          <div className="row justify-content-center text-dark">
            <div className="d-flex justify-content-between  w-100">
              <div className="" style={{ width: "100%" }}>
                <div className="row text-dark">
                  <MrUserTableInfo
                    userDetail={userDetail}
                    settingsData={settingData}
                    age={age}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* specipiment */}
          {office ? null : <SpecimentDataShow />}
          {/* account table part  */}

          <div className={`${office && "mt-1"}`}>
            <SPTAmountTable userDetail={userDetail} checked={checked} />
          </div>
        </div>

        {/* middle part  */}
        <div className="">
          <div className="finger-print-text  border border-dark m-0 p-0 display-inline p-2">
            <p
              className="m-0 p-0 text-center "
              style={{ fontWeight: "bolder", fontSize: "20px" }}
            >
              শুক্রবার এবং সরকারি ছুটির দিন বন্ধ
            </p>
          </div>
        </div>

        {/* image part  */}
        <div>
          <div
            // className="h-100"
            //  style={{ width: "20%" }}
            className="d-flex"
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Photo
              </p>
            </div>

            <div
              className="border border-end-0 border-dark h-100"
              // style={{ width: "100%", height: "114px" }}
            >
              {userDetail?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.image}`}
                  // className="img-fluid"
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "100%",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid"
                  width={"120px"}
                  height={"120px"}
                  // style={{
                  //   height: "40px",
                  //   width: "100%",
                  //   objectFit: "contain",
                  // }}
                />
              )}
            </div>
          </div>

          <div
            className="mt-1 d-flex"
            //  style={{ width: "20%" }}
          >
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                Finger Print
              </p>
            </div>
            {userDetail?.user?.finger_print ? (
              <div className="border border-dark h-100">
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.finger_print}`}
                  alt="patient"
                  width={"120px"}
                  height={"120px"}
                />
              </div>
            ) : (
              <div
                className="border border-dark border"
                style={{ height: "120px", width: "120px" }}
              ></div>
            )}
          </div>
          <div className="d-flex">
            <div className="finger-print-text d-flex justify-content-center">
              <p className="m-0 text-center" style={{ fontWeight: "bolder" }}>
                QR Code
              </p>
            </div>
            <div
              className="border border-dark mt-1 d-flex justify-content-center align-items-center"
              style={{
                height: "100px",
                width: "100%",
              }}
            >
              <Link to={urlWithBase64ID}>
                <QRCode value={urlWithBase64ID} size={85} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {office ? null : (
        <section
          className="patient-invoice relative bottom-0"
          // style={{ pageBreakAfter: "always" }}
        >
          <div className="w-100 d-flex align-items-center justify-content-between pb-1">
            <p
              className="static-user-invoice text-dark w-100 p-0 m-0"
              style={{ fontSize: "8px" }}
            >
              সতর্কীকরণ: এখানে Cash কাউন্টার ছাড়া বিকাশ বা অন্য কোন মাধ্যমে
              টাকা পয়সা লেনদেন করা হয়না | দালাল বা প্রতারক চক্রের হতে সাবধান
              থাকুন, ফোন বা SMS করে কেউ টাকা/ পয়সা চাইলে মেডিকেল সেন্টারের
              কাউন্টারে অবহিত করার জন্য অনুরোধ করা হলো
            </p>
          </div>
        </section>
      )}

      {/* footer part  */}

      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start text-dark">
          User: {userDetail?.user?.created_by}
        </div>
        <p className="p-0 m-0 fst-italic text-dark text-center">
          This is an auto generated report, does not require any signature.
        </p>
        <div className="text-end text-dark fst-italic p-0 m-0">
          Powered by Clincx Medical Solutions
        </div>
      </div>
      <div className="invoice-footer d-flex mt-0 align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-1 text-dark">
          <input
            type="checkbox"
            name="checkbox-invoice"
            id="checkbox-invoice"
            defaultChecked={checked}
            onClick={() => setChecked(!checked)}
          />
          <label htmlFor="checkbox-invoice">Print Without Amount</label>
        </div>
      </div>
    </div>
  );
};
export default MoneyReceipt;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { UserContext } from "../../../Context/UserContextAPI";

const VoucherEntry = () => {
  const { accessPerm } = useContext(UserContext);

  const [vouchers, setVouchers] = useState([
    {
      id: uuidv4(),
      from_head_group_id: "",
      from_account_head_id: "",
      to_head_group_id: "",
      to_account_head_id: "",
      transection_for: "",
      debit: 0,
      credit: 0,
      remarks: "",
    },
  ]);

  const [headGroups, setHeadGroups] = useState([]);
  const [accountHeads, setAccountHeads] = useState([]);

  function incrementTableRow() {
    setVouchers((prevVouchers) => {
      return [
        ...prevVouchers,
        {
          id: uuidv4(),
          from_head_group_id: "",
          from_account_head_id: "",
          to_head_group_id: "",
          to_account_head_id: "",
          transection_for: "",
          debit: 0,
          credit: 0,
          remarks: "",
        },
      ];
    });
  }

  function decrementTableRow(index) {
    setVouchers((prevVouchers) => {
      return prevVouchers.filter((_, i) => i !== index);
    });
  }

  function handleOnChange(e, index, fieldName) {
    const tgName = fieldName || e.target.name;
    const tgValue = e.target.value;
    setVouchers((prevVouchers) => {
      prevVouchers[index][tgName] = tgValue;

      // Check which input field is being changed
      if (tgName === "debit") {
        prevVouchers[index]["credit"] = 0;
        document.getElementById(`creditInput_${index}`).disabled =
          tgValue !== "" ? true : false;
      } else if (tgName === "credit") {
        prevVouchers[index]["debit"] = 0;
        document.getElementById(`debitInput_${index}`).disabled =
          tgValue !== "" ? true : false;
      }

      return [...prevVouchers];
    });
  }

  const handlerOnSubmitFormData = async (e) => {
    e.preventDefault();

    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/voucher/create`,
      vouchers,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );

    // console.log(res);
    if (res && res?.status === 200) {
      toast.success(res?.data?.message);
      e.target.reset();
      resetForm();
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      textAlign: "left",
      width: "180px",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  const resetForm = () => {
    // Reset the state to initial values
    setVouchers([
      {
        id: uuidv4(),
        from_head_group_id: "",
        from_account_head_id: "",
        to_head_group_id: "",
        to_account_head_id: "",
        transection_for: "",
        debit: 0,
        credit: 0,
        remarks: "",
      },
    ]);
  };

  useEffect(() => {
    const fetchHeadGroupApi = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/head-group/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setHeadGroups(data);
    };

    const fetchAccountHeadApi = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account-head/`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setAccountHeads(data);
    };
    fetchHeadGroupApi();
    fetchAccountHeadApi();
  }, [vouchers]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className=" p-1 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Voucher Entry</h6>
            </div>

            <hr />
            <form onSubmit={(e) => handlerOnSubmitFormData(e)}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-uppercase">
                      From Head Group <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">
                      From Head <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">
                      To Head Group <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">
                      To Head <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">Transection For</th>
                    <th className="text-uppercase">
                      Debit <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">
                      Credit <span className="text-danger">*</span>
                    </th>
                    <th className="text-uppercase">Remarks</th>
                    <th className="text-uppercase">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {vouchers.map((voucher, index) => (
                    <tr key={voucher?.id}>
                      <td className="">
                        <Select
                          className="patient-form-select"
                          name="from_head_group_id"
                          options={[
                            { value: "", label: "Choose One..." },
                            ...headGroups.map((from_headGroup, i) => ({
                              value: from_headGroup.id,
                              label: from_headGroup.name,
                            })),
                          ]}
                          onChange={(selectedOption) =>
                            handleOnChange(
                              {
                                target: {
                                  name: "from_head_group_id",
                                  value: selectedOption.value,
                                },
                              },
                              index,
                              "from_head_group_id"
                            )
                          }
                          placeholder="Choose One..."
                          isSearchable={true}
                          required
                          styles={customStyles}
                        />
                      </td>
                      <td className=" static-content-data">
                        <Select
                          className="patient-form-select"
                          name="from_account_head_id"
                          options={[
                            { value: "", label: "Choose One..." },
                            ...accountHeads.map((headGroup, i) => ({
                              value: headGroup.id,
                              label: headGroup.name,
                            })),
                          ]}
                          onChange={(selectedOption) =>
                            handleOnChange(
                              {
                                target: {
                                  name: "from_account_head_id",
                                  value: selectedOption.value,
                                },
                              },
                              index,
                              "from_account_head_id"
                            )
                          }
                          placeholder="Choose One..."
                          isSearchable={true}
                          required
                          styles={customStyles}
                        />
                      </td>
                      <td className="">
                        <Select
                          className="patient-form-select"
                          name="to_head_group_id"
                          options={[
                            { value: "", label: "Choose One..." },
                            ...headGroups.map((from_headGroup, i) => ({
                              value: from_headGroup.id,
                              label: from_headGroup.name,
                            })),
                          ]}
                          onChange={(selectedOption) =>
                            handleOnChange(
                              {
                                target: {
                                  name: "to_head_group_id",
                                  value: selectedOption.value,
                                },
                              },
                              index,
                              "to_head_group_id"
                            )
                          }
                          placeholder="Choose One..."
                          isSearchable={true}
                          required
                          styles={customStyles}
                        />
                      </td>
                      <td align="center" className="">
                        <Select
                          className="patient-form-select"
                          name="to_account_head_id"
                          options={[
                            { value: "", label: "Choose One..." },
                            ...accountHeads.map((from_headGroup, i) => ({
                              value: from_headGroup.id,
                              label: from_headGroup.name,
                            })),
                          ]}
                          onChange={(selectedOption) =>
                            handleOnChange(
                              {
                                target: {
                                  name: "to_account_head_id",
                                  value: selectedOption.value,
                                },
                              },
                              index,
                              "to_account_head_id"
                            )
                          }
                          placeholder="Choose One..."
                          isSearchable={true}
                          required
                          styles={customStyles}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="transection_for"
                          onChange={(e) =>
                            handleOnChange(e, index, "transection_for")
                          }
                          className="form-control form-control-input"
                          style={{ height: "38px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          onChange={(e) => handleOnChange(e, index, "debit")}
                          name="debit"
                          placeholder="0"
                          className="form-control form-control-input"
                          required
                          id={`debitInput_${index}`}
                          style={{ height: "38px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          onChange={(e) => handleOnChange(e, index, "credit")}
                          name="credit"
                          placeholder="0"
                          className="form-control form-control-input"
                          required
                          id={`creditInput_${index}`}
                          style={{ height: "38px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          onChange={(e) => handleOnChange(e, index, "remarks")}
                          name="remarks"
                          className="form-control form-control-input"
                          style={{ height: "38px" }}
                        />
                      </td>
                      <td className="">
                        <div className="d-flex gap-3">
                          {vouchers.length === 1 ||
                          index === vouchers.length - 1 ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={incrementTableRow}
                              style={{ height: "38px", width: "38px" }}
                            >
                              +
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => decrementTableRow(index)}
                              style={{ height: "38px", width: "38px" }}
                            >
                              -
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex">
                {accessPerm(44, 1) && (
                  <button
                    type="submit"
                    className="ms-auto btn btn-primary"
                    style={{
                      width: "6.25rem",
                    }}
                  >
                    save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoucherEntry;

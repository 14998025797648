const investigationGroup = [
  {
    id: 1,
    name: "BLOOD",
  },
  {
    id: 2,
    name: "URINE",
  },
  {
    id: 3,
    name: "STOOL",
  },
  {
    id: 4,
    name: "PHYSICIAN",
  },
  {
    id: 5,
    name: "X-RAY",
  },
];
export default investigationGroup;

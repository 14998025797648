import { useEffect, useState } from "react";
import "./PrescriptionModal.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import JsBarcode from "jsbarcode";
import { add, format, isValid } from "date-fns";

function PDFPrescriptionModal({ data }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const investigationURL = `${process.env.REACT_APP_API_BASE_URL}/investigation`;
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;
  const medicineURL = `${process.env.REACT_APP_API_BASE_URL}/medicine`;
  const websiteUrlWithBase64ID = `https://edmcbd.com`;

  const [addressData, setAddressData] = useState({});
  const [staticData, setStaticData] = useState([]);
  const [userDetail, setUserDetail] = useState();
  const [drugs, setDrugs] = useState([]);
  const [drugGroups, setDrugGroups] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [medicines, setMedicines] = useState([1]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [pathologyReportData, setPathologyReportData] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [defaultDate, setDefaultDate] = useState();

  const { state } = useLocation();

  const updatedState = state || data;
  const userId = updatedState?.user_id;
  console.log(updatedState?.user_id);

  const [medicineForm, setMedicineForm] = useState([
    {
      user_id: updatedState?.user?.id.toString(),
      prescription_id: "",
      drug_id: "",
      rules_id: "",
      duration_id: "",
    },
  ]);

  const [prescriptionForm, setPrescriptionForm] = useState({
    user_id: updatedState?.user?.id.toString(),
    diagnosis_ids: [],
    complaint_ids: [],
    investigation_ids: [],
    advice_ids: [],
    next_visit_date: "",
  });

  const patientRegisterBarcode = document.createElement("canvas");
  JsBarcode(
    patientRegisterBarcode,
    String(updatedState?.user?.registration_no)
  );
  const patientIdDataUrl = patientRegisterBarcode.toDataURL();

  const formattedMedicalDate = data?.createdAt
    ? (() => {
        const date = new Date(data?.createdAt);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const formattedBirthDate = updatedState?.user?.date_of_birth
    ? new Date(updatedState?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const birthYear = userDetail?.user?.date_of_birth
    ? new Date(userDetail?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "---";

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchAddressData = async () => {
    await axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setAddressData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(
        `${prescriptionURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const prescriptionData = prescriptionResponse?.data?.data;

      const medicineResponse = await axios.get(
        `${medicineURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const medicineData = medicineResponse?.data?.data;

      const diagnosisIdsArray = prescriptionData?.diagnosis_id
        ? prescriptionData.diagnosis_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedDiagnosis = diagnosisIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));
      const complaintIdsArray = prescriptionData?.complaint_id
        ? prescriptionData.complaint_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedComplaint = complaintIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const investigationIdsArray = prescriptionData?.investigation_ids
        ? prescriptionData.investigation_ids
            .split(",")
            .map((id) => parseInt(id, 10))
        : [];

      const selectedInvestigation = investigationIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const adviceIdsArray = prescriptionData?.advice_ids
        ? prescriptionData.advice_ids.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedAdvice = adviceIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      setPrescriptionForm((prevForm) => ({
        ...prevForm,
        diagnosis_ids: selectedDiagnosis.map((item) => item.value),
        complaint_ids: selectedComplaint.map((item) => item.value),
        investigation_ids: selectedInvestigation.map((item) => item.value),
        advice_ids: selectedAdvice.map((item) => item.value),
        next_visit_date: prescriptionData?.next_visit_date || "", // Update the next_visit_date
      }));
      setSelectedDiagnosis(selectedDiagnosis);

      setPrescriptionData(prescriptionData);
      setMedicineForm(medicineData);
    } catch (error) {
      console.log(error);
    }
  };

  const options = drugs.map((drug) => ({
    value: drug?.id,
    label: drug?.name,
  }));

  const fetchDrugsAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drugs/all-drugs`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugs(data);
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalTests(response?.data?.data);
      // physicalStatusCheckHandler(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDrugsGroupAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drug-groups/all-groups`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugGroups(data);
  };

  const fetchInvestigationData = async () => {
    await axios
      .get(investigationURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setInvestigationData(response.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDetail();
    getPhysicalTests();
    getRadiologyTests();
    setMedicines(medicines);
    fetchDrugsAPI();
    fetchDrugsGroupAPI();
    fetchAddressData();
    fetchStaticContentData();
    fetchInvestigationData();
    fetchPatientInfo();
  }, []);

  useEffect(() => {
    if (
      prescriptionForm?.next_visit_date &&
      isValid(new Date(prescriptionForm.next_visit_date))
    ) {
      setDefaultDate(prescriptionForm.next_visit_date);
    } else {
      const today = new Date();
      const sevenDaysLater = add(today, { days: 7 });
      const formattedDate = format(sevenDaysLater, "yyyy-MM-dd");
      setDefaultDate(formattedDate);
    }
  }, [prescriptionForm?.next_visit_date]);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;
  console.log(updatedState);
  return (
    <div
      style={{ position: "relative", minHeight: "100vh", paddingTop: "15px" }}
      className="verification-test px-3"
    >
      <div className="">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${addressData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section
        style={{
          // width: data ? "1710px" : "1710px",
          // overflowX: "scroll",
          fontFamily: "Verdana",
          fontWeight: "bolder",
          overflow: "hidden",
        }}
        className="verified-prescription"
      >
        <div className="row justify-content-center">
          <div className="col-2 p-1 h-100">
            <div className="invoice-img d-flex gap-1 flex-column border border-dark w-100">
              {userDetail?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.image}`}
                  style={{ objectFit: "fill" }}
                  alt=""
                />
              ) : (
                <img src="./dummy.jpg" alt="patient" className="img-fluid" />
              )}
            </div>
            <div className=" mt-1">
              <img
                src={patientIdDataUrl}
                alt=""
                style={{ height: "80px", width: "100%" }}
              />
            </div>
          </div>

          <div className="col-8 p-1">
            <table
              className="w-100 table-bordered border-dark mb-0 text-dark"
              style={{ height: "100%" }}
            >
              <tbody>
                <tr>
                  <td className="text-uppercase">
                    <span className="ps-1">Id no: </span>
                    <span className="ps-1 fw-bold">
                      {userDetail?.user?.reg_prefix || ""}
                      {userDetail?.user?.registration_no || "---"}
                    </span>
                  </td>
                  <td className="text-uppercase">
                    <span className="ps-1">Reg no: </span>
                    <span className="ps-1">
                      {userDetail?.user?.reg_prefix || ""}
                      {userDetail?.user?.registration_no || "---"}
                    </span>
                  </td>
                  {userDetail?.user?.role_id !== 5 ? (
                    <td colSpan={2} className="text-uppercase">
                      <span className="text-uppercase ps-1">Passport no: </span>
                      <span className="text-uppercase ps-1">
                        {userDetail?.user?.passport_no || "---"}
                      </span>
                    </td>
                  ) : (
                    <td colSpan={2} className="text-uppercase">
                      <span className="text-uppercase ps-1"></span>
                      <span className="text-uppercase ps-1"></span>
                    </td>
                  )}
                </tr>
                <tr>
                  <>
                    <td className="text-uppercase ps-1 fw-bold">Country</td>
                    <td className="text-uppercase ps-1 fw-bold">
                      {userDetail?.country?.name || "---"}
                    </td>
                  </>

                  <td className="text-uppercase ps-1">Medical date</td>
                  <td
                    className="text-uppercase ps-1"
                    colSpan={userDetail?.user?.role_id === 5 ? 3 : undefined}
                  >
                    {formattedMedicalDate || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1">Name</td>
                  <td className="text-uppercase ps-1" colSpan={3}>
                    {userDetail?.user?.f_name || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1">Father name</td>
                  <td className="text-uppercase ps-1" colSpan={3}>
                    {userDetail?.father_name || "---"}
                  </td>
                </tr>

                <tr>
                  <td className="text-uppercase ps-1">Date of birth</td>
                  <td className="text-uppercase ps-1">
                    {formattedBirthDate || "---"}
                    {formattedBirthDate !== "---" && `(${age})`}
                  </td>
                  <td className="text-uppercase ps-1">Gender</td>
                  <td className="text-uppercase ps-1">
                    {userDetail?.gender?.name || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1">
                    {addressData?.introducer_label || `---`}
                  </td>
                  <td className="text-uppercase ps-1" colSpan={3}>
                    {userDetail?.user?.role_id !== 5
                      ? userDetail?.introducer_user?.f_name || "---"
                      : userDetail?.user?.reference || "---"}
                  </td>
                </tr>
                {userDetail?.company_user && (
                  <tr>
                    <td className="text-uppercase ps-1">Company</td>
                    <td className="text-uppercase ps-1" colSpan={3}>
                      {updatedState?.company_user?.f_name || "---"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-2 p-1">
            <div className="finger-img  d-flex flex-column h-100 border border-dark">
              {userDetail?.user?.finger_print ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.finger_print}`}
                  alt=""
                  className="finger-img"
                  style={{ height: "100%" }}
                />
              ) : (
                <div
                  style={{ height: "100%" }}
                  className="p-2 border finger-img text-center text-dark finger-img d-flex align-items-center justify-content-center"
                >
                  No Fingerprint Found
                </div>
              )}
              <p className="p-0 m-0 border border-1 border-dark text-center fw-bold w-100 d-flex align-items-center justify-content-center text-dark">
                Finger Print
              </p>
            </div>
          </div>
        </div>
        <div className={`row mt-4 text-dark ${data ? "p-4" : "p-4"}`}>
          <div className="col-6 pe-3">
            <div>
              <div className="fs-5 fw-bold border-bottom fw-bold">
                Diagnosis:
              </div>
              <div className="p-1 fs-5 fw-bold">
                {/* {physicalStatus && (
                  <div className="text-dark">
                    Physical: {physicalStatus}
                    {physicalStatus && <br />}
                  </div>
                )} */}
                <div className="text-dark">
                  {/* {pathologyStatus && (
                    <span>
                      Pathology: {pathologyStatus}
                      {radiologyFindings && <br />}
                    </span>
                  )} */}
                </div>
                {radiologyFindings && <div>Radiology: {radiologyFindings}</div>}
              </div>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Complaints:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.complaint_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Investigations:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.investigation_ids.map((id) => (
                  <li key={id}>
                    {investigationData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Advices:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.advice_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Next Visits:</div>
              <ul className="pt-1 fw-bold">
                <li>
                  <div className="next-visit-date fs-5 fw-bold">
                    Next Visit Date:{" "}
                    {defaultDate && format(new Date(defaultDate), "dd-MM-yyyy")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 ps-3">
            <div className="fs-5 fw-bold border-bottom">
              R <sub>x</sub>
            </div>
            <ul className="medicine-table medicine-no-print fs-5">
              {medicineForm.map((medicine, index) => {
                const selectedOption = options.find(
                  (option) => option.value === medicine.drug_id
                );

                return (
                  <li key={index}>
                    <div className="my-bangla-content">
                      {selectedOption?.label}
                    </div>
                    <div className="my-bangla-content">
                      {
                        staticData.find(
                          (data) => data?.id === medicine.rules_id
                        )?.name
                      }
                      <br />
                      {
                        staticData.find(
                          (data) => data?.id === medicine.duration_id
                        )?.name
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      {data && (
        <div
          className="w-100 d-flex justify-content-center"
          style={{ position: "absolute", bottom: "20px", left: 0 }}
        >
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${addressData?.footer_image}`}
            alt="Report Footer"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

export default PDFPrescriptionModal;
